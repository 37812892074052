import { ModalRef } from "../Components/Common/Modal/Modal";
import { __DEV__ } from "./settings";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Components/Common/Form/form.module.scss";
import { ApiCancellationError, ApiError } from "../core/api/apiExceptions";
import { CancellationError } from "bluebird";

export function showRequestError(error: unknown): void {
  if (error instanceof ApiCancellationError) return;
  if (error instanceof ApiError) {
    ModalRef.showDialog({
      type: error.type,
      width: 620,
      title: error.name,
      text: error.message,
    });
  } else {
    ModalRef.showDialog({
      type: "error",
      width: 620,
      title: "Error",
      text: "Something went wrong",
    });
  }
}

export const showSomeError = (
  error: unknown,
  title: string = "Unknown Error",
  text?: string,
) => {
  if (error instanceof ApiError || error instanceof CancellationError) return;
  const errorString: unknown = JSON.stringify(
    JSON.parse(JSON.stringify(error)),
  );
  const modalTitle =
    title === "Unexpected Error" ? (
      <>
        {title}:
        <Button
          style={{ marginLeft: "auto" }}
          className={styles.HeaderLinkBtn}
          fillMode="flat"
          icon="refresh"
          title="Reload"
          onClick={() => {
            window.location.reload();
          }}
        />
      </>
    ) : (
      title
    );
  if (__DEV__) console.log(error);
  const errorData = __DEV__
    ? (error as {
        name?: string;
        message?: string;
        code?: number;
        response?: {
          status?: string;
          statusText?: string;
        };
        stack?: string;
      })
    : undefined;
  ModalRef.showDialog({
    type: "error",
    width: 620,
    title: modalTitle,
    text: text || "Syntax Error",
    children: __DEV__ ? (
      <div>
        {!!errorData?.name && <div>{errorData.name}</div>}
        {!!errorData?.message && <div>{errorData.message}</div>}
        {!!errorData?.code && <div>{errorData.code}</div>}
        {!!errorData?.response && (
          <div>
            Status: {errorData.response.status} {errorData.response.statusText}
          </div>
        )}
        {!!errorData?.stack && <div>Stack: {errorData.stack}</div>}
      </div>
    ) : undefined,
    details: __DEV__
      ? {
          title: "Details",
          content: errorString,
        }
      : undefined,
  });
};
