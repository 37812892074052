import { createPortal } from "react-dom";
import { Dispatch, DispatchWithoutAction, useEffect, useState } from "react";
import { IncompleteWOSiteDetails } from "./IncompleteWOSiteDetails";
import { SQL_Map_GetIncompleteWOSites_Response } from "../../core/api/generated/conterra";

export const IncompleteWOSitePortal = ({
  map,
}: {
  map: {
    on: (event: string, func: Dispatch<any> | DispatchWithoutAction) => void;
    off: (event: string, func: Dispatch<any> | DispatchWithoutAction) => void;
  };
}) => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);

  const openPopup = (e: {
    popup: {
      _contentNode: HTMLElement;
    };
  }) => {
    const node = e.popup._contentNode.childNodes[0] as HTMLElement;
    setPortalTarget(node);
  };

  const closePopup = () => {
    setPortalTarget(null);
  };

  useEffect(() => {
    if (map) {
      map.on("popupopen", openPopup);
      map.on("popupclose", closePopup);
    }

    return () => {
      if (map) {
        map.off("popupopen", openPopup);
        map.off("popupclose", closePopup);
      }
    };
  }, [map]);

  if (!portalTarget) return null;

  const pointString = portalTarget.dataset.point;
  if (!pointString || pointString === "") return null;

  const point = JSON.parse(
    pointString
  ) as SQL_Map_GetIncompleteWOSites_Response;

  return createPortal(<IncompleteWOSiteDetails point={point} />, portalTarget);
};
