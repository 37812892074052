import FilterCombobox from "../../../Components/Common/Form/FilterCombobox";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { IDispatchDataFilter, onFilterChange } from "../interfaces";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useBooleanState, useLazyAsync } from "../../../core/tools/Hooks";
import styles from "./tkToolbar.module.scss";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import api from "../../../core/api/api";
import { ISO_DATE_FORMAT } from "../../../core/tools/formats";
import {
  PropsSQLDBTKReviewRequest,
  SQL_DB_TK_Review_Response,
} from "../../../core/api/generated/conterra";
import { showSomeError } from "../../../helpers/errorHelpers";

const opacityItemRender = (
  el: React.ReactElement<
    HTMLLIElement,
    string | React.JSXElementConstructor<any>
  >,
  props: ListItemProps,
) => {
  const dataItem = props.dataItem;
  let className = el.props.className;
  if (!dataItem.hasInList) {
    className += ` ${styles.OpacityListComboboxItem}`;
  }

  return React.cloneElement(el, { ...el.props, className }, dataItem.name);
};

export function FilterByPP(props: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
}) {
  const { value, onChange, filteredTimeCards } = props;
  const [getData, { data: rawData, loading }] = useLazyAsync(
    api.sql.tkGetActivePayrollPeriods,
  );
  useEffect(() => {
    getData({});
  }, []);

  const data = useMemo(() => {
    return (rawData || []).map((x) => ({
      ...x,
      hasInList: filteredTimeCards.findIndex((y) => y.periodId === x.id) > -1,
    }));
  }, [rawData, filteredTimeCards]);

  useEffect(() => {
    getData({});
  }, []);

  return (
    <FilterCombobox
      placeholder={"Filter by Payroll Period"}
      data={data}
      value={value || null}
      width={180}
      loading={loading}
      onChange={(value: INewComboboxItem | null) =>
        onChange(value, PropsSQLDBTKReviewRequest.periodId)
      }
      popupSettings={{
        width: 250,
        popupClass: styles.FilterFlexPopup,
      }}
      itemRender={opacityItemRender}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}
interface IDispatchListItem extends INewComboboxItem {
  index: number;
  Date: string;
}
export function FilterByDispatch(props: {
  params: IDispatchDataFilter;
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
}) {
  const {
    value,
    onChange,
    params: { resource, finishDate, date },
    filteredTimeCards,
  } = props;
  const [data, setData] = useState<IDispatchListItem[]>([]);
  const isLoading = useBooleanState(false);
  const GROUPED_FIELD = "Date";

  useEffect(() => {
    const getData = async () => {
      try {
        isLoading.setTrue();
        const result = await api.sql.tkGetDispatches({
          employeeId: resource?.Id || null,
          startDate: date && moment(date).format(ISO_DATE_FORMAT),
          finishDate: finishDate && moment(finishDate).format(ISO_DATE_FORMAT),
        });

        setData(
          result.map(({ id, workOrderName, date }, index) => ({
            id,
            name: workOrderName,
            Date: moment(date).format("MM/DD/YYYY"),
            index,
          })),
        );
      } catch (e) {
        showSomeError(e);
      } finally {
        isLoading.setFalse();
      }
    };
    getData();
  }, [resource, finishDate, date]);

  const processedData = useMemo(() => {
    const dispatchesIds = filteredTimeCards.reduce((ids: number[], tc) => {
      tc.dispatchInfo.forEach((d) => {
        if (d.id) ids.push(d.id);
      });
      return ids;
    }, []);

    return data
      .map((x) => ({
        ...x,
        hasInList: dispatchesIds.includes(+x.id),
      }))
      .sort((x, y) => {
        if (x.hasInList && y.hasInList) return y.index - x.index;
        return +y.hasInList - +x.hasInList;
      });
  }, [filteredTimeCards]);

  return (
    <FilterCombobox
      placeholder={"Filter by Dispatch"}
      data={processedData}
      groupMode="modern"
      groupField={GROUPED_FIELD}
      popupSettings={{
        width: 330,
      }}
      width={150}
      value={value || null}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.dispatchId);
      }}
      itemRender={opacityItemRender}
      loading={isLoading.value}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}

export function FilterByReviewer(props: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
}) {
  const { onChange, value, filteredTimeCards } = props;
  const [data, setData] = useState<INewComboboxItem[]>();
  const loading = useBooleanState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    loading.setTrue();
    const data = await api.sql.tkGetReviewers();
    setData(data);
    loading.setFalse();
  };

  const processedData = useMemo(() => {
    return (data || []).map((x) => ({
      ...x,
      hasInList: filteredTimeCards.findIndex((y) => y.reviewerID === x.id) > -1,
    }));
  }, [data, filteredTimeCards]);

  return (
    <FilterCombobox
      placeholder={"Filter by Reviewer"}
      data={processedData}
      width={160}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.reviewerId);
      }}
      value={value || null}
      dataItemKey={"id"}
      textField={"name"}
      loading={loading.value}
      fillMode={"flat"}
      popupSettings={{
        popupClass: styles.FilterFlexPopup,
      }}
      itemRender={opacityItemRender}
    />
  );
}

export function FilterByEmployee(props: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
  data: INewComboboxItem[];
  loading: boolean;
}) {
  const { onChange, value, filteredTimeCards, data, loading } = props;
  const processedData = useMemo(() => {
    return (data || []).map((x) => ({
      ...x,
      hasInList: filteredTimeCards.findIndex((y) => y.employeeID === x.id) > -1,
    }));
  }, [data, filteredTimeCards]);

  return (
    <FilterCombobox
      placeholder={"Filter by Employee"}
      data={processedData}
      width={160}
      loading={loading}
      value={value || null}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.resourceId);
      }}
      popupSettings={{ width: 250, popupClass: styles.FilterFlexPopup }}
      itemRender={opacityItemRender}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}

export function FilterByEmployeeClass(props: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
  data: INewComboboxItem[];
  loading: boolean;
}) {
  const { onChange, value, filteredTimeCards, data, loading } = props;
  const processedData = useMemo(() => {
    return (data || []).map((x) => ({
      ...x,
      hasInList:
        filteredTimeCards.findIndex((y) => y.employeeClassId === x.id) > -1,
    }));
  }, [data, filteredTimeCards]);

  return (
    <FilterCombobox
      placeholder={"Filter by Employee Class"}
      data={processedData}
      width={165}
      loading={loading}
      value={value || null}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.employeeClassId);
      }}
      popupSettings={{ width: 250, popupClass: styles.FilterFlexPopup }}
      itemRender={opacityItemRender}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}

export function FilterByDepartment(props: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
  filteredTimeCards: SQL_DB_TK_Review_Response[];
  data: INewComboboxItem[];
  loading: boolean;
}) {
  const { onChange, value, filteredTimeCards, data, loading } = props;
  const processedData = useMemo(() => {
    return (data || []).map((x) => ({
      ...x,
      hasInList:
        filteredTimeCards.findIndex((y) => y.departmentId === x.id) > -1,
    }));
  }, [data, filteredTimeCards]);

  return (
    <FilterCombobox
      placeholder={"Filter by Department"}
      data={processedData}
      width={160}
      loading={loading}
      value={value || null}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.departmentId);
      }}
      popupSettings={{ width: 250, popupClass: styles.FilterFlexPopup }}
      itemRender={opacityItemRender}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}

export function FilterByState({
  value,
  onChange,
}: {
  onChange: onFilterChange;
  value?: INewComboboxItem | null;
}) {
  const dataRef = useRef<INewComboboxItem[]>([
    { id: "P", name: "Pending" },
    { id: "D", name: "Adjusted" },
    { id: "A", name: "Acknowledged" },
    { id: "T", name: "Change Time" },
    { id: "C", name: "Completed" },
    {
      id: "ActiveRequestState",
      name: "Active Request",
      ServerParamName: "hasActiveRequest",
      ServerParamValue: true,
    },
    {
      id: "ClosureAuditsState",
      name: "Incomplete Allocation",
      ServerParamName: "hasClosureAuditResults",
      ServerParamValue: true,
    },
    {
      id: "HasReason",
      name: "Has Reason",
      ServerParamName: "hasReason",
      ServerParamValue: true,
    },
    {
      id: "NotCompleted",
      name: "All not Completed",
      ServerParamName: "notCompleted",
      ServerParamValue: true,
    },
  ]);

  return (
    <FilterCombobox
      placeholder={"Filter by State"}
      data={dataRef.current}
      width={150}
      value={value || null}
      onChange={(value: INewComboboxItem | null) => {
        onChange(value, PropsSQLDBTKReviewRequest.reviewStateId);
      }}
      dataItemKey={"id"}
      textField={"name"}
      fillMode={"flat"}
    />
  );
}
