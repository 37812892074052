import api from "../core/api/api";
import { showSomeError } from "../helpers/errorHelpers";
import { WorkshiftMobileDataResponse } from "../core/api/generated/tk";

class WorkShiftInfoStore {
  private values: { [workShiftId: number]: WorkshiftMobileDataResponse } = {};

  GetWorkShiftInfo = async (workShiftId: number, force?: boolean) => {
    if (this.values[workShiftId] && !force) return this.values[workShiftId];
    try {
      this.values[workShiftId] =
        await api.tk.workshifts.workshiftMobileData(workShiftId);
      return this.values[workShiftId];
    } catch (error) {
      showSomeError(error);
    }
  };
}

export default new WorkShiftInfoStore();
