import React from "react";
import styles from "./tkTC.module.scss";
import { HorizontalTimeline } from "../../../Components/TC/HorizontalTimeline";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TCActions from "./Actions";
import { SQL_DB_TK_Review_Response } from "../../../core/api/generated/conterra";
import { useBooleanState } from "../../../core/tools/Hooks";
import { tabId } from "../../../Components/Tabs/interfaces";
import { Button } from "@progress/kendo-react-buttons";
import { ROW_HEIGHT } from "../helpers";
import LoaderComponent from "../../../Components/Common/Loader";
import {
  TCChatCloud,
  TCChips,
  TCClockInOut,
  TCReviewer,
  TCReviewState,
  TCTimeFormula,
} from "./Elements";

export const LightTCInfoRow = (props: {
  data?: SQL_DB_TK_Review_Response;
  isLoading: boolean;
  setInitialTab(tabId: tabId): void;
  refreshTC: (/*tcId: number*/) => void;
}) => {
  const { data, refreshTC, setInitialTab, isLoading } = props;
  const {
    reviewState,
    id,
    reviewerName,
    commentCount,
    employeeCommentCount,
    workShiftInfo,
  } = data || {};
  const isCollapsed = useBooleanState();

  if (isLoading || !data) {
    return (
      <div
        className={`${styles.Container} ${styles.MinMode}`}
        style={{ height: isCollapsed.value ? 45 : ROW_HEIGHT }}
      >
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div
      className={`${styles.Container} ${styles.MinMode}`}
      style={{ height: isCollapsed.value ? undefined : ROW_HEIGHT }}
    >
      <div className={styles.Content}>
        <div className={styles.LeftCol}>
          <div className={styles.Row}>
            <TCReviewer reviewerName={reviewerName!} />
            <TCChatCloud
              tcId={id}
              commentCount={commentCount}
              employeeCommentCount={employeeCommentCount}
              reloadDependency={data}
            />
            <TCClockInOut data={data} />
            <TCTimeFormula data={data} />
          </div>
          <div
            style={{
              display: isCollapsed.value ? "none" : undefined,
            }}
          >
            <div className={`${styles.Row}`}>
              <HorizontalTimeline data={data} />
            </div>
          </div>
        </div>

        <div>
          <Tooltip
            anchorElement="target"
            showCallout={false}
            parentTitle={true}
          >
            <div className={styles.Row}>
              <div style={{ flex: 1 }}></div>
              <div className={styles.Actions}>
                <Button
                  title={isCollapsed.value ? "Show Timeline" : "Hide Timeline"}
                  fillMode={"flat"}
                  iconClass={
                    isCollapsed.value
                      ? "mdi mdi-chevron-up"
                      : "mdi" + " mdi-chevron-down"
                  }
                  onClick={isCollapsed.toggle}
                ></Button>
                <TCActions
                  tcId={id!}
                  refreshAfterAction={refreshTC}
                  data={data}
                  isCard={true}
                />
              </div>
              <TCReviewState reviewState={reviewState!} />
            </div>
          </Tooltip>

          <div
            className={`${styles.Row} ${styles.ChipsRow}`}
            style={isCollapsed.value ? { display: "none" } : undefined}
          >
            <TCChips data={data} setInitialTab={setInitialTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
