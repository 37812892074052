import React from "react";
import {
  Button,
  ButtonGroup,
  Toolbar,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import api, { authProvider } from "../../core/api/api";
import {
  SQL_Profile_MyInfo_Response,
  SQL_Profile_MyRoles_Response,
} from "../../core/api/generated/conterra";
import BaseComponent from "../../Components/BaseComponent";
import { IFXCardProps } from "../../Components/Cards/interfaces";
import LoaderComponent from "../../Components/Common/Loader";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { simpleObject } from "../../helpers/interfaces";
import formStyles from "../../Components/Cards/card.module.scss";
import commonStyles from "../../assets/styles/common.module.scss";
import { showSomeError } from "../../helpers/errorHelpers";

type tab = "info" | "roles" | "password";

interface IState {
  isLoading: boolean;
  dataUserInfo: SQL_Profile_MyInfo_Response | null;
  activeTabId: tab;
  rolesRefreshKey: number;
}

const tabs = [
  {
    id: "info",
    text: "Info",
  },
  {
    id: "roles",
    text: "Roles",
  },
];
// todo hide inactiveTab roles
// todo add clear filter to roles tab??
class Profile extends BaseComponent<IFXCardProps, IState> {
  instanceId = authProvider.getInstanceId() as string;
  userId = authProvider.getUserId() as string;
  rolesGridRef: any;

  constructor(props: IFXCardProps) {
    super(props);
    this.state = {
      isLoading: false,
      dataUserInfo: null,
      activeTabId: "info",
      rolesRefreshKey: 0,
    };
  }

  componentDidMount() {
    this.LoadData();
  }

  renderInfo = () => {
    const { dataUserInfo } = this.state;
    if (!dataUserInfo) return null;
    return (
      <div
        style={{
          overflow: "auto",
          padding: 8,
          flex: 1,
        }}
      >
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Company:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>{this.instanceId}</div>
          </div>
        </div>
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Login:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>{dataUserInfo.login}</div>
          </div>
        </div>
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Name:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>{dataUserInfo.name}</div>
          </div>
        </div>
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Email:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>
              {dataUserInfo.email || "-"}
            </div>
          </div>
        </div>
        <div className={`${formStyles.Row} ${formStyles.RowCell}`}>
          <div className={formStyles.FormFieldLabel}>Phone:</div>
          <div>
            <div className={formStyles.ReadOnlyField}>
              {dataUserInfo.phone || "-"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderRoles = () => {
    return (
      <DashboardGrid
        ref={(ref) => (this.rolesGridRef = ref)}
        isActive={true} // todo
        isParentPage={true}
        columnsSettings={[
          {
            field: "name",
            title: "Name",
            type: "string",
            gridWidth: 250,
          },
          {
            field: "description",
            title: "Description",
            type: "string",
          },
        ]}
        getData={this.LoadGridData}
        getRowKey={({ id }: SQL_Profile_MyRoles_Response) => id + ""}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{ hide: true }}
        // initRefresh={this.InitRefresh}
      ></DashboardGrid>
    );
  };

  LoadGridData = async (filters: simpleObject) => {
    return await api.sql.profileMyRoles(filters);
  };

  render() {
    const { activeTabId, isLoading } = this.state;
    return (
      <div className={commonStyles.ScreenHeightContainerWithToolbar}>
        <Toolbar>
          <ButtonGroup>
            {tabs.map(({ id, text }) => {
              return (
                <Button
                  key={id}
                  data-id={id}
                  themeColor={activeTabId === id ? "primary" : undefined}
                  onClick={() => this.SwithchTab(id as tab)}
                >
                  {text}
                </Button>
              );
            })}
          </ButtonGroup>
          <ToolbarSpacer />
          {activeTabId === "roles" && (
            <Button
              iconClass="k-icon k-font-icon k-i-refresh"
              title={"Refresh Roles"}
              onClick={this.RefreshRoles}
            />
          )}
        </Toolbar>

        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {activeTabId === "info" && this.renderInfo()}
            {activeTabId === "roles" && this.renderRoles()}
          </>
        )}
      </div>
    );
  }

  SwithchTab = (tab: tab) => {
    if (this.state.activeTabId === tab) return;
    this.setState({ activeTabId: tab });
  };

  LoadData = async () => {
    try {
      this.setState({ isLoading: true });
      const result = await api.sql.profileMyInfo();
      this.setState({ dataUserInfo: result[0] });
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  RefreshRoles = async () => {
    try {
      await authProvider.handleUnauthorized();
      this.rolesGridRef?.Refresh?.();
    } catch (e) {}
  };
}

export default Profile;
