import React, { ComponentClass } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import BaseComponent from "../../Components/BaseComponent";
import TimeCardsGrid from "./TimeCardsGrid";
import PayrollPeriods from "../../Components/PP/PayrollPeriods";
import Attestations from "./Attestations";
import AuditResults from "./AuditResults";
import LoaderComponent from "../../Components/Common/Loader";
import styles from "./index.module.scss";
import { showSomeError } from "../../helpers/errorHelpers";

const TimeCardsViewId = "timeCards";
const AuditViewId = "audit";
const AttestationsViewId = "attestations";
const PayrollViewId = "payroll";

type viewId =
  | typeof TimeCardsViewId
  | typeof AuditViewId
  | typeof AttestationsViewId
  | typeof PayrollViewId;

interface IView {
  Id: viewId;
  Name: string;
  Component: ComponentClass<any /* TKDashboardViewProps */>;
}

const VIEWS: Array<IView> = [
  {
    Id: PayrollViewId,
    Name: "Payroll Periods",
    Component: PayrollPeriods,
  },
  {
    Id: AuditViewId,
    Name: "Audit Results",
    Component: AuditResults,
  },
  {
    Id: AttestationsViewId,
    Name: "Attestations",
    Component: Attestations,
  },
  {
    Id: TimeCardsViewId,
    Name: "Time Cards",
    Component: TimeCardsGrid,
  },
];

interface props {
  isActive: boolean;
}

interface state {
  settingsInited: boolean;
  activeViewId: viewId;
}

class TKDashboard extends BaseComponent<props, state> {
  allowAccessToReports: boolean | null = null;

  viewsRefs: { [key in viewId]?: any } = {
    [TimeCardsViewId]: React.createRef(),
    [AuditViewId]: React.createRef(),
    [AttestationsViewId]: React.createRef(),
    [PayrollViewId]: React.createRef(),
  };

  inited: { [key: string]: boolean } = {
    [PayrollViewId]: true,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      settingsInited: false,
      activeViewId: PayrollViewId,
    };
  }

  componentDidMount(): void {
    this.InitSettings();
  }

  render() {
    if (!this.state.settingsInited) return <LoaderComponent />;
    if (this.allowAccessToReports === false) {
      return (
        <TimeCardsGrid
          isActive={this.props.isActive}
          allowAccessToReports={this.allowAccessToReports}
        />
      );
    }
    return (
      <div style={{ position: "relative", height: "100%" }}>
        {VIEWS.map((view: IView) => {
          let viewId = view.Id;
          let Component = view.Component;
          if (!this.inited[viewId]) return null;
          return (
            <div
              key={viewId}
              className={`${styles.ViewContainer} ${
                this.state.activeViewId === viewId
                  ? styles.ViewContainerActive
                  : ""
              }`}
            >
              <Component
                ref={this.viewsRefs[viewId]}
                switcher={this.renderSwitcher()}
                isActive={this.props.isActive}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderSwitcher = () => {
    return (
      <ButtonGroup
        className={styles.ButtonGroup}
        width={(VIEWS.length / 2) * 95 + "px"}
      >
        {VIEWS.map(({ Id, Name }) => (
          <Button
            key={Id}
            id={Id}
            themeColor={this.state.activeViewId === Id ? "primary" : undefined}
            onClick={this.OnSwitchTab}
          >
            {Name}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  InitSettings = async () => {
    try {
      let result = await this.GetSQLData({ spName: "TK_GetReportAccess" });
      this.allowAccessToReports = result[0][0].AllowAccessToReports;
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ settingsInited: true });
    }
  };

  OnSwitchTab = (e: any) => {
    const viewId = e.currentTarget.id as viewId;
    this.inited[viewId] = true;
    const syncFunc =
      this.viewsRefs[viewId]?.current?.gridRef?.current?.SyncFilters;
    if (syncFunc) syncFunc();
    this.setState({ activeViewId: viewId });
  };
}

export default TKDashboard;
