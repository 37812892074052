import {
  CalendarDayView,
  CalendarMapView,
  CalendarMonthView,
  CalendarResourcesView,
  CalendarWeekView,
} from "./Views";
import { CalendarToolbar } from "./CalendarToolbar";
import { memo, useRef } from "react";
import { useCalendarContext } from "./CalendarContext";
import { CalendarContextProvider } from "./CalendarContexProvider";
import { CalendarContextMenu } from "./CalendarContextMenu";
import { CalendarDispatchTooltip } from "./CalendarDispatchTooltip";
import { CalendarFilters } from "./Filters";
import { CalendarTooltipContextProvider } from "./CalendarTooltipContextProvider";
import { clsx } from "clsx";
import { useBooleanState } from "../../core/tools/Hooks";
import styles from "./calendar.module.scss";
import LoaderComponent from "../Common/Loader";

const CalendarCmp = () => {
  const { mode, processing } = useCalendarContext();
  const isFiltersExpanded = useBooleanState(true);
  const mapRef = useRef(null);

  return (
    <div className={styles.CalendarTopLevel}>
      <CalendarToolbar
        filtersExpanded={isFiltersExpanded.value}
        onToggleFilters={isFiltersExpanded.toggle}
      />
      <div
        className={clsx(
          styles.CalendarFiltersContainer,
          isFiltersExpanded.value &&
            mode !== "map" &&
            styles.CalendarFiltersContainerExpanded,
        )}
      >
        <CalendarFilters />
      </div>
      {mode === "month" && <CalendarMonthView />}
      {mode === "week" && <CalendarWeekView />}
      {mode === "day" && <CalendarDayView />}
      {mode === "resources" && <CalendarResourcesView />}
      {mode === "map" || mapRef.current ? (
        <div
          className={clsx(
            styles.MapContainer,
            mode !== "map" && styles.MapHidden,
          )}
        >
          <CalendarMapView ref={mapRef} />
        </div>
      ) : null}
      <CalendarContextMenu />
      <CalendarDispatchTooltip />
      {processing === "processing" && <LoaderComponent />}
    </div>
  );
};

const CalendarWithProvider = ({ buildPlanId }: { buildPlanId?: number }) => {
  return (
    <CalendarContextProvider buildPlanId={buildPlanId}>
      <CalendarTooltipContextProvider>
        <CalendarCmp />
      </CalendarTooltipContextProvider>
    </CalendarContextProvider>
  );
};

export const Calendar = memo(CalendarWithProvider);
