const DI_TYPES = {
  ILogger: Symbol.for("ILogger"),
  ISettingsStorage: Symbol.for("ISettingsStorage"),
  IServerSettingsService: Symbol.for("IServerSettingsService"),
  IDeviceInfoProvider: Symbol.for("IDeviceInfoProvider"),
  IAuthProvider: Symbol.for("IAuthProvider"),
  ILastLoginService: Symbol.for("ILastLoginService"),
  ISasTokenService: Symbol.for("ISasTokenService"),
  IHttpFormDataBuilder: Symbol.for("IHttpFormDataBuilder"),
  IApi: Symbol.for("IApi"),
};

export default DI_TYPES;
