import React, { useCallback, useMemo } from "react";
import { WorkshiftMobileDataResponse } from "../../core/api/generated/tk";
import LoaderComponent from "../Common/Loader";
import styles from "./workshiftinfo.module.scss";
import { isTKAdvancedUser } from "../../core/tools/roles";
import moment from "moment/moment";
import { Button } from "@progress/kendo-react-buttons";
import { stopPropagationWrapper } from "../../helpers/Hooks";
import { ModalRef } from "../Common/Modal/Modal";
import { showSomeError } from "../../helpers/errorHelpers";

const WorkShiftInfoTooltipContent = (props: {
  isFullMode: boolean;
  workShiftId: number;
  data: WorkshiftMobileDataResponse | undefined;
  isLoading: boolean;
}) => {
  const { isFullMode, workShiftId, data, isLoading } = props;
  const copyToClipboard = useCallback(async (text: string | number) => {
    try {
      const id = +new Date();
      await navigator.clipboard.writeText(text.toString());
      ModalRef.showNotification({
        text: "Successfully Copied!",
        id,
        style: { fontSize: 12 },
        type: { style: "success" },
      });
      setTimeout(() => {
        ModalRef.hideNotification(id);
      }, 1500);
    } catch (error) {
      showSomeError(error);
    }
  }, []);
  const subTitleRender = useCallback((text: string) => {
    return <div className={styles.SubTitle}>{text}</div>;
  }, []);
  const rowRender = useCallback(
    (label: string, value: string | number, copy?: boolean) => {
      return (
        <div className={styles.Row}>
          {label}: {value}
          {copy && (
            <Button
              className={styles.CopyBtn}
              size={"small"}
              themeColor={"light"}
              icon={"copy"}
              onClick={() => {
                copyToClipboard(value);
              }}
              fillMode={"flat"}
            />
          )}
        </div>
      );
    },
    [copyToClipboard],
  );

  const yesNoRowRender = useCallback(
    (label: string, value: boolean | undefined | null) => {
      if (typeof value !== "boolean") return null;
      return rowRender(label, value ? "Yes" : "No");
    },
    [rowRender],
  );

  if (isLoading) {
    return (
      <div style={{ position: "relative", height: 30 }}>
        <LoaderComponent
          theme={"light"}
          style={{ background: "transparent" }}
          size={"small"}
        />
      </div>
    );
  }
  if (!data) return <div>No data. Work Shift Id: {workShiftId}</div>;

  const {
    deviceInfo: {
      deviceBrand,
      deviceId,
      deviceBuild,
      appVersion,
      osName,
      osVersion,
    },
    deviceStatus,
  } = data;

  const header = useMemo(
    () => (
      <div className={styles.Header}>
        FCX {appVersion} for {osName}
      </div>
    ),
    [appVersion, osName],
  );

  const separator = useMemo(() => <div className={styles.Separator}></div>, []);

  if (!isFullMode) {
    return (
      <div>
        {header}
        {rowRender(
          "Device brand & build",
          `${deviceBrand || ""} ${deviceBuild}`,
        )}
        {rowRender("OS", `${osName} ${osVersion}`)}
      </div>
    );
  }

  const {
    timestamp,
    startupTimestamp,
    batteryLevel,
    isTracking,
    authorization,
    isMoving,
    isGps,
    isCollectingPoints,
    isBatteryCharging,
    isBackgroundFetchAllowed,
    isPowerSave,
    isIgnoringBatteryOptimizations,
    didDeviceReboot,
    didLaunchInBackground,
    hasJustLaunched,
    isFirstRun,
  } = deviceStatus || {};

  if (isTKAdvancedUser()) {
    return (
      <div
        className={styles.FullVersion}
        onClick={stopPropagationWrapper(() => {})}
      >
        {header}
        {subTitleRender("Info")}
        {rowRender("Work Shift Id", workShiftId, true)}
        {rowRender(
          "Device brand & build",
          `${deviceBrand || ""} ${deviceBuild}`,
        )}
        {!!deviceId && rowRender("Device Id", deviceId, true)}
        {rowRender("OS", `${osName} ${osVersion}`)}
        {separator}
        {!!timestamp &&
          subTitleRender(`Status on ${moment(timestamp).format("L LT")}`)}
        {!!startupTimestamp &&
          rowRender("App started at", moment(startupTimestamp).format("L LT"))}
        {!!authorization && rowRender("Authorization Status", authorization)}
        {yesNoRowRender("Tracking", isTracking)}
        {yesNoRowRender("GPS", isGps)}
        {yesNoRowRender("Is Moving", isMoving)}
        {yesNoRowRender("Collecting Points", isCollectingPoints)}
        {yesNoRowRender("Background Allowed", isBackgroundFetchAllowed)}
        {rowRender("Battery Level", `${batteryLevel}%`)}
        {yesNoRowRender("Power Save", isPowerSave)}
        {yesNoRowRender("Charging", isBatteryCharging)}
        {yesNoRowRender(
          "Ignore Battery Optimizations",
          isIgnoringBatteryOptimizations,
        )}
        {yesNoRowRender("First Run", isFirstRun)}
        {yesNoRowRender("Just launched", hasJustLaunched)}
        {yesNoRowRender("Did Device reboot", didDeviceReboot)}
        {yesNoRowRender("Did launch in Background", didLaunchInBackground)}
      </div>
    );
  }

  return (
    <div
      className={styles.FullVersion}
      onClick={stopPropagationWrapper(() => {})}
    >
      {header}
      {subTitleRender("Info")}
      {rowRender("Device Brand", deviceBrand || "")}
      {rowRender("OS", `${osName} ${osVersion}`)}
      {separator}
      {!!timestamp &&
        subTitleRender(`Status on ${moment(timestamp).format("L LT")}`)}
      {!!startupTimestamp &&
        rowRender("App started at", moment(startupTimestamp).format("L LT"))}
      {rowRender("Battery Level", `${batteryLevel}%`)}
      {yesNoRowRender("Tracking", isTracking)}
    </div>
  );
};

export default WorkShiftInfoTooltipContent;
