// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols

import { authProvider } from "../api/api";

export const isModuleEnabledWo = () => authProvider.getRoles().includes("FSM");

export const isCLPMUser = () => {
  const roles = authProvider.getRoles();
  return (
    roles.includes("CLPMReview") ||
    roles.includes("CLPMSubm") ||
    roles.includes("CLPMCust")
  );
};

export const isModuleEnableTk = () => authProvider.getRoles().includes("TK");

export const module_enabled_am = () => {
  return authProvider.getRoles().includes("AssetUser");
};

export const module_enabled_warehouse = () => {
  return authProvider.getRoles().includes("WHUser");
};

export const module_enabled_field_materials = () => {
  return authProvider.getRoles().includes("FldMatRtrn");
};

export const IsWarehouseManager = () => {
  return authProvider.getRoles().includes("Warehouse");
};

export const IsBOMMan = () => {
  return authProvider.getRoles().includes("BOMMan");
};

export const IsMaterialLibraryManager = () => {
  return authProvider.getRoles().includes("MatLibMan");
};

export const module_enabled_cod_requests = () =>
  authProvider.getRoles().includes("Requests");

export const isSalary = () => authProvider.getRoles().includes("Salary");

export const isNoSelfie = () => authProvider.getRoles().includes("NoSelfie");

export const isFixedLocation = () =>
  authProvider.getRoles().includes("FixedLoc");

export const isWOAllocation = () => authProvider.getRoles().includes("WOAlloc");

export const isLunchAllowed = () =>
  authProvider.getRoles().includes("LunchAllow");

export const isManualTEAllowed = () =>
  authProvider.getRoles().includes("ManTEAllow");

export const isClockAdjustmentsAllowed = () =>
  authProvider.getRoles().includes("ClockAdj");

export const isCLPMReviewer = () =>
  authProvider.getRoles().includes("CLPMReview") ||
  authProvider.getRoles().includes("CLPMCust");

export const canGenerateBP = () => {
  return authProvider.getRoles().includes("GenBPs");
};

export const isTKManager = () => authProvider.getRoles().includes("TKManagers");

export const isTKAdvancedReviewer = () =>
  authProvider.getRoles().includes("AdvTKRev");

export const isTKAdvancedUser = () =>
  authProvider.getRoles().includes("AdvTKUser");

export const isTKCanCloneTCs = () =>
  authProvider.getRoles().includes("TKEnableTCClone");

export const isTKCanCompleteTCs = () =>
  authProvider.getRoles().includes("CompTCs");
