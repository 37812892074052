import {
  SQL_Map_GetIncompleteWOSites_Response,
  SQL_Map_GetIncompleteWOSites_Response_BuildPlans,
  SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders,
} from "../../core/api/generated/conterra";
import { useCallback, useContext } from "react";
import { OpenRecord } from "../../helpers/runscripts";
import CardManagement from "../Cards/CardManagement";
import { IntegrationWithDesktopContext } from "../../helpers/settings";

export const IncompleteWOSiteDetails = ({
  point,
}: {
  point: SQL_Map_GetIncompleteWOSites_Response;
}) => {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext
  );

  const openWoCardHandler = useCallback(
    (wo: SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders) => {
      OpenRecord("FSMWorkOrders", wo.woId);
    },
    []
  );

  const openWebBPCardHandler = useCallback(
    (buildPlan: SQL_Map_GetIncompleteWOSites_Response_BuildPlans) => {
      CardManagement.OpenBPCard(buildPlan.bpId, undefined, {
        id: "WorkOrders",
        defaultColumnValuesFilter: [
          {
            field: "Stage",
            values: ["Ready for Dispatch", "Scheduled", "Published"],
          },
        ],
      });
    },
    []
  );

  return (
    <div
      className="leaflet-scroll-popup-content"
      style={{ maxHeight: "450px" }}
    >
      {point.buildPlans.map((item, iBp) => {
        return (
          <div className="details-group" key={iBp}>
            <div>
              <a onClick={() => openWebBPCardHandler(item)}>{item.bpName}</a>
            </div>
            <div>
              Work Orders:
              {item.workOrders.map((woOrder, iWo) => {
                return (
                  <div key={iWo}>
                    {isIntegrationWithDesktop ? (
                      <a onClick={() => openWoCardHandler(woOrder)}>
                        {woOrder.woName}
                      </a>
                    ) : (
                      woOrder.woName
                    )}
                  </div>
                );
              })}
            </div>
            <div>Project: {item.projectName}</div>
            <div>Scenario: {item.scenarioName}</div>
            <div>Customer: {item.customerName}</div>
            <div>BPO: {item.bpoName}</div>
            {item.constructionStatusName
              ? `<div>Construction Status: {item.constructionStatusName}</div>`
              : ""}
            {item.cxStatusComment
              ? `<div>CX Status Comment: {item.cxStatusComment}</div>`
              : ""}
            {item.permitStatusName
              ? `<div>Permit Status: {item.permitStatusName}</div>`
              : ""}
            {item.permitStatusComment
              ? `<div>Permit Status Comment: {item.permitStatusComment}</div>`
              : ""}
          </div>
        );
      })}
    </div>
  );
};
