// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AccuracyAuthorization
 *  Possible values: 0 = Full, 1 = Reduced
 */
export enum AccuracyAuthorization {
  Value0 = 0,
  Value1 = 1,
}
export const EnumAccuracyAuthorization = {
  Value0: 0,
  Value1: 1,
};

/** AttestationResultDto */
export interface AttestationResultDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** Boolean */
  accepted: boolean;
  /** String */
  comment?: string | null;
}
export const PropsAttestationResultDto = {
  id: "id",
  accepted: "accepted",
  comment: "comment",
};
/**
 * AuthorizationStatus
 *  Possible values: 0 = NotDetermined, 1 = Restricted, 2 = Denied, 3 = Always, 4 = WhenInUse
 */
export enum AuthorizationStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}
export const EnumAuthorizationStatus = {
  Value0: 0,
  Value1: 1,
  Value2: 2,
  Value3: 3,
  Value4: 4,
};

/** ClockInRequest */
export interface ClockInRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /**
   * Nullable`1
   * @format date-time
   */
  clockInTime?: string | null;
  /** String */
  comment?: string | null;
  /** List`1 */
  attestationResults: AttestationResultDto[];
  deviceMetadata?: DeviceMetadataRequest;
}
export const PropsClockInRequest = {
  userTime: "userTime",
  clockInTime: "clockInTime",
  comment: "comment",
  attestationResults: "attestationResults",
  deviceMetadata: "deviceMetadata",
};
/** ClockInResponse */
export interface ClockInResponse {
  /**
   * Int32
   * @format int32
   */
  employeeId: number;
  /**
   * Int32
   * @format int32
   */
  workShiftId: number;
  /**
   * Nullable`1
   * @format int32
   */
  clockInDocumentId?: number | null;
  /**
   * DateTimeOffset
   * @format date-time
   */
  maxClockOutTime: string;
}
export const PropsClockInResponse = {
  employeeId: "employeeId",
  workShiftId: "workShiftId",
  clockInDocumentId: "clockInDocumentId",
  maxClockOutTime: "maxClockOutTime",
};
/** ClockInStatusActiveManual */
export interface ClockInStatusActiveManual {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * DateTimeOffset
   * @format date-time
   */
  start: string;
  /** String */
  workOrderName: string;
}
export const PropsClockInStatusActiveManual = {
  id: "id",
  start: "start",
  workOrderName: "workOrderName",
};
/** ClockInStatusLunch */
export interface ClockInStatusLunch {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * DateTimeOffset
   * @format date-time
   */
  start: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  finish: string;
  /** Boolean */
  confirmedLunch: boolean;
}
export const PropsClockInStatusLunch = {
  id: "id",
  start: "start",
  finish: "finish",
  confirmedLunch: "confirmedLunch",
};
/** ClockInStatusLunchOptions */
export interface ClockInStatusLunchOptions {
  /** Boolean */
  isEarlyFinishAllowed: boolean;
  /** Boolean */
  isWaiveAllowed: boolean;
  /**
   * Nullable`1
   * @format int32
   */
  duration?: number | null;
  /** Boolean */
  requireConfirmation: boolean;
}
export const PropsClockInStatusLunchOptions = {
  isEarlyFinishAllowed: "isEarlyFinishAllowed",
  isWaiveAllowed: "isWaiveAllowed",
  duration: "duration",
  requireConfirmation: "requireConfirmation",
};
/** ClockInStatusLunchSchedule */
export interface ClockInStatusLunchSchedule {
  /**
   * DateTimeOffset
   * @format date-time
   */
  time: string;
  /** String */
  text: string;
  /** Boolean */
  isCompleted: boolean;
}
export const PropsClockInStatusLunchSchedule = {
  time: "time",
  text: "text",
  isCompleted: "isCompleted",
};
/** ClockInStatusRequest */
export interface ClockInStatusRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
}
export const PropsClockInStatusRequest = {
  userTime: "userTime",
};
/** ClockInStatusResponse */
export interface ClockInStatusResponse {
  workshift?: ClockInStatusWorkshift;
  disallowedReason?: DisallowedReasonType;
}
export const PropsClockInStatusResponse = {
  workshift: "workshift",
  disallowedReason: "disallowedReason",
};
/** ClockInStatusWorkshift */
export interface ClockInStatusWorkshift {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * DateTimeOffset
   * @format date-time
   */
  start: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  maxClockOutTime: string;
  /** String */
  deviceId: string;
  /** List`1 */
  lunches: ClockInStatusLunch[];
  activeManual?: ClockInStatusActiveManual;
  /** List`1 */
  lunchSchedule: ClockInStatusLunchSchedule[];
  lunchOptions?: ClockInStatusLunchOptions;
}
export const PropsClockInStatusWorkshift = {
  id: "id",
  start: "start",
  maxClockOutTime: "maxClockOutTime",
  deviceId: "deviceId",
  lunches: "lunches",
  activeManual: "activeManual",
  lunchSchedule: "lunchSchedule",
  lunchOptions: "lunchOptions",
};
/** ClockOutRequest */
export interface ClockOutRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  operationTime: string;
  /** String */
  comment?: string | null;
  /** List`1 */
  attestationResults: AttestationResultDto[];
}
export const PropsClockOutRequest = {
  userTime: "userTime",
  operationTime: "operationTime",
  comment: "comment",
  attestationResults: "attestationResults",
};
/** ClockOutResponse */
export interface ClockOutResponse {
  /**
   * Int32
   * @format int32
   */
  workShiftId: number;
  /**
   * Nullable`1
   * @format int32
   */
  clockOutDocumentId?: number | null;
}
export const PropsClockOutResponse = {
  workShiftId: "workShiftId",
  clockOutDocumentId: "clockOutDocumentId",
};
/** CreateLunchRequest */
export interface CreateLunchRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  startTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  finishTime: string;
}
export const PropsCreateLunchRequest = {
  userTime: "userTime",
  startTime: "startTime",
  finishTime: "finishTime",
};
/** DeleteLunchRequest */
export interface DeleteLunchRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /**
   * Int32
   * @format int32
   */
  lunchId: number;
  /** String */
  operationComment: string;
}
export const PropsDeleteLunchRequest = {
  userTime: "userTime",
  lunchId: "lunchId",
  operationComment: "operationComment",
};
/** DeviceMetadataRequest */
export interface DeviceMetadataRequest {
  /** String */
  appVersion: string;
  /** String */
  osName: string;
  /** String */
  deviceId: string;
  /** String */
  deviceBrand: string;
  /** String */
  deviceBuild: string;
  /** String */
  osVersion: string;
}
export const PropsDeviceMetadataRequest = {
  appVersion: "appVersion",
  osName: "osName",
  deviceId: "deviceId",
  deviceBrand: "deviceBrand",
  deviceBuild: "deviceBuild",
  osVersion: "osVersion",
};
/** DisallowedReasonType */
export enum DisallowedReasonType {
  Undefined = "Undefined",
  UnallocatedDays = "UnallocatedDays",
  NonWorkingDay = "NonWorkingDay",
  ClockInTooEarly = "ClockInTooEarly",
  UnresolvedSolicitedRequests = "UnresolvedSolicitedRequests",
  NoDispatches = "NoDispatches",
}
export const EnumDisallowedReasonType = {
  Undefined: "Undefined",
  UnallocatedDays: "UnallocatedDays",
  NonWorkingDay: "NonWorkingDay",
  ClockInTooEarly: "ClockInTooEarly",
  UnresolvedSolicitedRequests: "UnresolvedSolicitedRequests",
  NoDispatches: "NoDispatches",
};

/** FinishManualWorkRequest */
export interface FinishManualWorkRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
}
export const PropsFinishManualWorkRequest = {
  userTime: "userTime",
};
/** GpsPoint */
export interface GpsPoint {
  /**
   * Int64
   * @format int64
   */
  time: number;
  /**
   * Decimal
   * @format double
   */
  lat: number;
  /**
   * Decimal
   * @format double
   */
  lng: number;
  /**
   * Double
   * @format double
   */
  acc: number;
}
export const PropsGpsPoint = {
  time: "time",
  lat: "lat",
  lng: "lng",
  acc: "acc",
};
/** GpsRecordArray */
export interface GpsRecordArray {
  /**
   * DateTime
   * @format date-time
   */
  timestamp: string;
  /**
   * Decimal
   * @format double
   */
  latitude: number;
  /**
   * Decimal
   * @format double
   */
  longitude: number;
  /**
   * Double
   * @format double
   */
  accuracy: number;
}
export const PropsGpsRecordArray = {
  timestamp: "timestamp",
  latitude: "latitude",
  longitude: "longitude",
  accuracy: "accuracy",
};
/** GpsSyncBatchRequest */
export interface GpsSyncBatchRequest {
  /** List`1 */
  location: GpsRecordArray[];
}
export const PropsGpsSyncBatchRequest = {
  location: "location",
};
/** IdDto */
export interface IdDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
}
export const PropsIdDto = {
  id: "id",
};
/** LocationTrackingStatusRequest */
export interface LocationTrackingStatusRequest {
  /**
   * Int64
   * @format int64
   */
  timestamp: number;
  /**
   * Int32
   * @format int32
   */
  isMoving: number;
  /**
   * Int32
   * @format int32
   */
  isGps: number;
  /**
   * Int32
   * @format int32
   */
  isCollectingPoints: number;
  /**
   * Int32
   * @format int32
   */
  isNetwork: number;
  /**
   * Int32
   * @format int32
   */
  isPowerSave: number;
  /**
   * Int32
   * @format int32
   */
  isBackgroundFetchAllowed: number;
  /**
   * Int32
   * @format int32
   */
  didDeviceReboot: number;
  /**
   * Int32
   * @format int32
   */
  didLaunchInBackground: number;
  /**
   * Int32
   * @format int32
   */
  isIgnoringBatteryOptimizations: number;
  /**
   * Int32
   * @format int32
   */
  isBatteryCharging: number;
  /**  Possible values: 0 = NotDetermined, 1 = Restricted, 2 = Denied, 3 = Always, 4 = WhenInUse */
  authorization: AuthorizationStatus;
  /**  Possible values: 0 = Full, 1 = Reduced */
  accuracy: AccuracyAuthorization;
  /**
   * Int32
   * @format int32
   */
  notSyncedPoints: number;
  /**
   * Int32
   * @format int32
   */
  batteryLevel: number;
  /**
   * Int32
   * @format int32
   */
  hasJustLaunched: number;
  /**
   * Int64
   * @format int64
   */
  startupTimestamp: number;
  /**
   * Int32
   * @format int32
   */
  isTracking: number;
  /**
   * Int32
   * @format int32
   */
  isFirstRun: number;
}
export const PropsLocationTrackingStatusRequest = {
  timestamp: "timestamp",
  isMoving: "isMoving",
  isGps: "isGps",
  isCollectingPoints: "isCollectingPoints",
  isNetwork: "isNetwork",
  isPowerSave: "isPowerSave",
  isBackgroundFetchAllowed: "isBackgroundFetchAllowed",
  didDeviceReboot: "didDeviceReboot",
  didLaunchInBackground: "didLaunchInBackground",
  isIgnoringBatteryOptimizations: "isIgnoringBatteryOptimizations",
  isBatteryCharging: "isBatteryCharging",
  authorization: "authorization",
  accuracy: "accuracy",
  notSyncedPoints: "notSyncedPoints",
  batteryLevel: "batteryLevel",
  hasJustLaunched: "hasJustLaunched",
  startupTimestamp: "startupTimestamp",
  isTracking: "isTracking",
  isFirstRun: "isFirstRun",
};
/** LocationTrackingStatusSaveRequest */
export interface LocationTrackingStatusSaveRequest {
  /** List`1 */
  data: LocationTrackingStatusRequest[];
}
export const PropsLocationTrackingStatusSaveRequest = {
  data: "data",
};
/** StartManualWorkRequest */
export interface StartManualWorkRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /**
   * Int32
   * @format int32
   */
  workOrderId: number;
}
export const PropsStartManualWorkRequest = {
  userTime: "userTime",
  workOrderId: "workOrderId",
};
/** UpdateLunchRequest */
export interface UpdateLunchRequest {
  /**
   * Int32
   * @format int32
   */
  lunchId: number;
  /**
   * DateTimeOffset
   * @format date-time
   */
  startTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  finishTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
}
export const PropsUpdateLunchRequest = {
  lunchId: "lunchId",
  startTime: "startTime",
  finishTime: "finishTime",
  userTime: "userTime",
};
/** WaiveLunchRequest */
export interface WaiveLunchRequest {
  /**
   * DateTimeOffset
   * @format date-time
   */
  userTime: string;
  /** String */
  comment: string;
}
export const PropsWaiveLunchRequest = {
  userTime: "userTime",
  comment: "comment",
};
/** WorkshiftInfo */
export interface WorkshiftInfo {
  /**
   * DateTimeOffset
   * @format date-time
   */
  startTime: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  finishTime: string;
  /**
   * Int32
   * @format int32
   */
  userId: number;
  /**
   * Int32
   * @format int32
   */
  workShiftTimeOffset: number;
}
export const PropsWorkshiftInfo = {
  startTime: "startTime",
  finishTime: "finishTime",
  userId: "userId",
  workShiftTimeOffset: "workShiftTimeOffset",
};
/** WorkshiftMobileDataResponse */
export interface WorkshiftMobileDataResponse {
  workshiftInfo: WorkshiftInfo;
  deviceInfo: WorkshiftMobileInfo;
  deviceStatus?: WorkshiftMobileStatus;
}
export const PropsWorkshiftMobileDataResponse = {
  workshiftInfo: "workshiftInfo",
  deviceInfo: "deviceInfo",
  deviceStatus: "deviceStatus",
};
/** WorkshiftMobileInfo */
export interface WorkshiftMobileInfo {
  /** String */
  appVersion?: string | null;
  /** String */
  osName?: string | null;
  /** String */
  deviceId?: string | null;
  /** String */
  deviceBrand?: string | null;
  /** String */
  deviceBuild?: string | null;
  /** String */
  osVersion?: string | null;
}
export const PropsWorkshiftMobileInfo = {
  appVersion: "appVersion",
  osName: "osName",
  deviceId: "deviceId",
  deviceBrand: "deviceBrand",
  deviceBuild: "deviceBuild",
  osVersion: "osVersion",
};
/** WorkshiftMobileStatus */
export interface WorkshiftMobileStatus {
  /**
   * DateTimeOffset
   * @format date-time
   */
  timestamp: string;
  /** Boolean */
  isMoving: boolean;
  /** Boolean */
  isGps: boolean;
  /** Boolean */
  isCollectingPoints: boolean;
  /** Boolean */
  isNetwork: boolean;
  /** Boolean */
  isPowerSave: boolean;
  /** Boolean */
  isBackgroundFetchAllowed: boolean;
  /** Boolean */
  didDeviceReboot: boolean;
  /** Boolean */
  didLaunchInBackground: boolean;
  /** Boolean */
  isIgnoringBatteryOptimizations: boolean;
  /** Boolean */
  isBatteryCharging: boolean;
  /** Nullable`1 */
  hasJustLaunched?: boolean | null;
  /**
   * Nullable`1
   * @format date-time
   */
  startupTimestamp?: string | null;
  /** Nullable`1 */
  isTracking?: boolean | null;
  /** Nullable`1 */
  isFirstRun?: boolean | null;
  /** String */
  authorization: string;
  /** String */
  accuracy: string;
  /**
   * Int32
   * @format int32
   */
  notSyncedPoints: number;
  /**
   * Int32
   * @format int32
   */
  batteryLevel: number;
}
export const PropsWorkshiftMobileStatus = {
  timestamp: "timestamp",
  isMoving: "isMoving",
  isGps: "isGps",
  isCollectingPoints: "isCollectingPoints",
  isNetwork: "isNetwork",
  isPowerSave: "isPowerSave",
  isBackgroundFetchAllowed: "isBackgroundFetchAllowed",
  didDeviceReboot: "didDeviceReboot",
  didLaunchInBackground: "didLaunchInBackground",
  isIgnoringBatteryOptimizations: "isIgnoringBatteryOptimizations",
  isBatteryCharging: "isBatteryCharging",
  hasJustLaunched: "hasJustLaunched",
  startupTimestamp: "startupTimestamp",
  isTracking: "isTracking",
  isFirstRun: "isFirstRun",
  authorization: "authorization",
  accuracy: "accuracy",
  notSyncedPoints: "notSyncedPoints",
  batteryLevel: "batteryLevel",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix Time Keeping
 * @version v1
 */

export class Api_tk {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  gps = {
    locationTrackingStatus: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: LocationTrackingStatusSaveRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/TK/Gps/location-tracking-status`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    processGpsDataForWorkshift: (
      query: {
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/TK/Gps/process-gps-data`,
        method: "POST",
        query: query,
        ...params,
      }),
    getSasToken: (params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/TK/Gps/Sas`,
        method: "GET",
        format: "json",
        ...params,
      }),
    savePoints: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsPoint[],
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/TK/Gps/save-points`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    syncBatch: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsSyncBatchRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/TK/Gps/sync-batch`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  lunch = {
    create: (data: CreateLunchRequest, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/TK/Lunch/create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    delete: (data: DeleteLunchRequest, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/TK/Lunch/delete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    update: (data: UpdateLunchRequest, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/TK/Lunch/update`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    waive: (data: WaiveLunchRequest, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/TK/Lunch/waive`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  manualWork = {
    finish: (data: FinishManualWorkRequest, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/TK/ManualWork/finish`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    start: (data: StartManualWorkRequest, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/TK/ManualWork/start`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workshifts = {
    workshiftMobileData: (id: number, params: RequestParams = {}) =>
      this.http.request<WorkshiftMobileDataResponse>({
        path: `/TK/Workshifts/${id}/mobile-diagnostics`,
        method: "GET",
        format: "json",
        ...params,
      }),
    clockIn: (data: ClockInRequest, params: RequestParams = {}) =>
      this.http.request<ClockInResponse>({
        path: `/TK/Workshifts/clock-in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    clockInStatus: (data: ClockInStatusRequest, params: RequestParams = {}) =>
      this.http.request<ClockInStatusResponse>({
        path: `/TK/Workshifts/clock-in-status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    clockOut: (data: ClockOutRequest, params: RequestParams = {}) =>
      this.http.request<ClockOutResponse>({
        path: `/TK/Workshifts/clock-out`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
