import moment from "moment";
import { simpleObject } from "../../helpers/interfaces";
import { IGridFilter, IGridFilterItem } from "./interfaces";

export const GetGridHeight = (
  selectedRow: simpleObject | null,
  gridRef: any,
  selectSettingsComponent: boolean
) => {
  const container = gridRef?.element;
  if (selectedRow && container) {
    const toolbar = container.getElementsByClassName("k-grid-toolbar")[0];
    const toolbarHeight = (toolbar && toolbar.offsetHeight) || 0;
    const heightHeader =
      container.getElementsByClassName("k-grid-header")[0].offsetHeight;
    const filterRowHeight =
      container.querySelector(".k-filter-row").offsetHeight;
    const contentDOMEl = container.querySelector(".k-grid-content");
    const scrollHeight = contentDOMEl.offsetHeight - contentDOMEl.clientHeight;
    const htmlRows = contentDOMEl.querySelectorAll("tr");
    const height =
      toolbarHeight +
      heightHeader -
      filterRowHeight +
      GridRowHeight +
      (scrollHeight || 16) +
      3;
    if (!htmlRows.length) return height;
    let selectedHTMLRow = contentDOMEl.querySelector(
      '[data-rowkey="' + selectedRow?.Key + '"]'
    );
    if (selectedHTMLRow) {
      let heightSelectedRow = selectedHTMLRow.offsetHeight;
      return (
        toolbarHeight +
        heightHeader -
        filterRowHeight +
        heightSelectedRow +
        scrollHeight +
        3
      );
    }
  }
  return selectSettingsComponent ? "100%" : undefined;
};

export const GridRowHeight = 38;

export const FILTER_OPERATORS = {
  numeric: [
    { text: "grid.filterGtOperator", operator: "gt" },
    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterLtOperator", operator: "lt" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
  text: [
    { text: "grid.filterContainsOperator", operator: "contains" },
    { text: "grid.filterNotContainsOperator", operator: "doesnotcontain" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterStartsWithOperator", operator: "startswith" },
    { text: "grid.filterEndsWithOperator", operator: "endswith" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    { text: "grid.filterIsEmptyOperator", operator: "isempty" },
    { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
  ],
  date: [
    { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
    { text: "grid.filterAfterOperator", operator: "gt" },
    { text: "grid.filterBeforeOperator", operator: "lt" },
    { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
};

export const GetDefaultGridFilter = (): IGridFilter => ({
  filters: [],
  logic: "and",
});

export const DEFAULT_OPERATOR: { [key: string]: string } = {
  numeric: "gte",
  text: "contains",
  date: "eq",
  datetime: "gte",
};

export const GetNewRemountKey = () => +new Date();

export const IsComplexGridFilter = (
  filter: IGridFilterItem | IGridFilter
): filter is IGridFilter => {
  return (filter as IGridFilter).filters !== undefined;
};

export const dateRangePeriodIds = {
  custom: "custom",
  lastWeek: "last-week",
  currMonthToDate: "curr-month-to-date",
  lastMonth: "last-month",
  currQuarterToDate: "curr-quarter-to-date",
  lastQuarter: "last-quarter",
  currYearToDate: "curr-year-to-date",
  lastYear: "last-year",
  last12Months: "last12months",
  notApplied: "notApplied",
} as const;
export type dateRangePeriodId =
  (typeof dateRangePeriodIds)[keyof typeof dateRangePeriodIds];

const dateRangePeriodsNames = {
  [dateRangePeriodIds.custom]: "Custom period",
  [dateRangePeriodIds.lastWeek]: "Last Week",
  [dateRangePeriodIds.currMonthToDate]: "Current Month to Date",
  [dateRangePeriodIds.lastMonth]: "Last Month",
  [dateRangePeriodIds.currQuarterToDate]: "Current Quarter to Date",
  [dateRangePeriodIds.lastQuarter]: "Last Quarter",
  [dateRangePeriodIds.currYearToDate]: "Current Year to Date",
  [dateRangePeriodIds.lastYear]: "Last Year",
  [dateRangePeriodIds.last12Months]: "Last 12 Months",
  [dateRangePeriodIds.notApplied]: "Not Applied",
};

export const getPeriods = (
  allowNotApplied?: boolean,
  includesPeriods?: dateRangePeriodId[]
) => {
  const periods: IDateRangePeriodItem[] = [];
  if (allowNotApplied) {
    periods.push({
      Id: dateRangePeriodIds.notApplied,
      Name: dateRangePeriodsNames[dateRangePeriodIds.notApplied],
    });
  }
  periods.push({
    Id: dateRangePeriodIds.custom,
    Name: dateRangePeriodsNames[dateRangePeriodIds.custom],
  });
  if (includesPeriods) {
    includesPeriods.forEach((Id) =>
      periods.push({
        Id,
        Name: dateRangePeriodsNames[Id],
      })
    );
    return periods;
  }

  periods.push(
    {
      Id: dateRangePeriodIds.lastWeek,
      Name: dateRangePeriodsNames[dateRangePeriodIds.lastWeek],
    },
    {
      Id: dateRangePeriodIds.currMonthToDate,
      Name: dateRangePeriodsNames[dateRangePeriodIds.currMonthToDate],
    },
    {
      Id: dateRangePeriodIds.lastMonth,
      Name: dateRangePeriodsNames[dateRangePeriodIds.lastMonth],
    },
    {
      Id: dateRangePeriodIds.currQuarterToDate,
      Name: dateRangePeriodsNames[dateRangePeriodIds.currQuarterToDate],
    },
    {
      Id: dateRangePeriodIds.lastQuarter,
      Name: dateRangePeriodsNames[dateRangePeriodIds.lastQuarter],
    },
    {
      Id: dateRangePeriodIds.currYearToDate,
      Name: dateRangePeriodsNames[dateRangePeriodIds.currYearToDate],
    },
    {
      Id: dateRangePeriodIds.lastYear,
      Name: dateRangePeriodsNames[dateRangePeriodIds.lastYear],
    },
    {
      Id: dateRangePeriodIds.last12Months,
      Name: dateRangePeriodsNames[dateRangePeriodIds.last12Months],
    }
  );
  return periods;
};

interface IDateRangePeriodItem {
  Id: dateRangePeriodId;
  Name: string;
}

export const GetDefaultDateFrom = () => {
  return moment().month(0).date(1);
};

export const GetDefaultDateTo = () => {
  return moment();
};

export const GetPeriodDates = (periodId: dateRangePeriodId) => {
  if (periodId === dateRangePeriodIds.notApplied) return [null, null];

  let dateFrom = GetDefaultDateFrom();
  let dateTo = GetDefaultDateTo();

  switch (periodId) {
    case dateRangePeriodIds.lastWeek:
      dateFrom = moment().day(-6);
      dateTo = moment().day(0);
      break;
    case dateRangePeriodIds.currMonthToDate:
      dateFrom = moment().date(1);
      break;
    case dateRangePeriodIds.lastMonth:
      dateFrom = moment().subtract(1, "month").date(1);
      dateTo = moment().date(1).subtract(1, "days");
      break;
    case dateRangePeriodIds.currQuarterToDate:
      let month = moment().quarter() * 3 - 3;
      dateFrom = moment().month(month).date(1);
      break;
    case dateRangePeriodIds.lastQuarter:
      var lastQuarter = moment().quarter() - 1;
      if (lastQuarter < 1) {
        dateFrom = moment().subtract(1, "year").month(9).date(1);
      } else {
        dateFrom = moment()
          .month(lastQuarter * 3 - 3)
          .date(1);
      }
      dateTo = moment(dateFrom).add(3, "months").date(1).subtract(1, "days");
      break;
    case dateRangePeriodIds.currYearToDate:
      dateFrom = moment().month(0).date(1);
      break;
    case dateRangePeriodIds.lastYear:
      dateFrom = moment().subtract(1, "year").month(0).date(1);
      dateTo = moment().month(0).date(1).subtract(1, "days");
      break;
    case dateRangePeriodIds.last12Months:
      dateFrom = moment().subtract(12, "month");
      break;
  }
  return [dateFrom.toDate(), dateTo.toDate()];
};

export const DateColumnWidthDefault = 105;
export const DateTimeColumnWidthDefault = 130;
export const NumberColumnWidthDefault = 125;
export const StringColumnMinWidthDefault = 100;
export const IconColumnWidthDefault = 60;
export const BooleanColumnWidthDefault = 60;
export const minDate: Date = new Date(2010, 1, 1);
export const maxDate: Date = new Date(2050, 1, 1);
