import {
  Button,
  DropDownButton,
  Toolbar,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import BaseComponent from "../../BaseComponent";
import moment from "moment";
import { RunScriptAsync, RunScriptLocal } from "../../../helpers/runscripts";
import { GridRowHeight } from "../../Dashboard/helpers";
import OpenCardLink from "../../OpenCardLink";
import { ModalRef } from "../../Common/Modal/Modal";
import AccessDenied from "../../Common/AccessDenied";
import NoSelectedBP from "../../Common/NoSelectedBP";
import Loader from "../../Common/Loader";
import styles from "./bptasks.module.scss";
import { ITaskItem } from "./interfaces";
import React from "react";
import DesktopActions from "../../Common/DesktopActions";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { showSomeError } from "../../../helpers/errorHelpers";

interface props {
  buildPlanId?: number;
  isActive: boolean;
}

interface state {
  loading: boolean;
  taskListId: number | null;
  taskListName: string;
  accessDenied: boolean;
  tasks: { data: Array<ITaskItem>; total: number };
  collapsedIds: string[];
}

class BPTasks extends BaseComponent<props, state> {
  group: Array<{ field: string }> = [{ field: "IsEnabledGroup" }];
  bpId = this.props.buildPlanId;
  expandedGroup: { [key in string]: boolean } = {};

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      taskListId: null,
      taskListName: "",
      accessDenied: false,
      tasks: { data: [], total: 0 },
      collapsedIds: [],
    };
  }

  componentDidMount() {
    if (this.props.buildPlanId) this.LoadData();
  }

  componentDidUpdate() {
    if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
      super.componentWillUnmount();
      this.bpId = this.props.buildPlanId;
      this.LoadData();
    }
  }

  render() {
    if (!this.props.buildPlanId) return <NoSelectedBP />;
    if (this.state.accessDenied) return <AccessDenied />;
    if (this.state.loading) return <Loader />;

    const { collapsedIds, tasks } = this.state;
    return (
      <>
        <Toolbar>
          <ToolbarSpacer />
          {this.state.taskListId && (
            <OpenCardLink
              refName="FSMBPTaskLists"
              dataAttr={this.state.taskListId}
              text={this.state.taskListName}
            />
          )}
          <DesktopActions
            actions={
              <>
                <Button icon="plus" onClick={this.AddNewTask}>
                  New Task
                </Button>
                <Button icon="edit" onClick={this.EditTasks}>
                  Edit Tasks
                </Button>
                <Button icon="copy" onClick={this.CopyTasks}>
                  Copy Tasks
                </Button>
              </>
            }
          />
        </Toolbar>
        <Grid
          data={setExpandedState({
            data: tasks.data,
            collapsedIds,
          })}
          filterable={false}
          scrollable="none"
          groupable={false}
          group={this.group}
          onExpandChange={this.ExpandChange}
          expandField="expanded"
          rowHeight={GridRowHeight}
        >
          <Column field="TaskName" title="Task" />
          <Column
            field="IsCompleted"
            title="Completed"
            width="100"
            cell={(props) => {
              if (props.rowType === "groupHeader") return null;
              return (
                <td className={styles.AlignCenter}>
                  {props.dataItem.IsCompleted && (
                    <span className="k-icon k-font-icon k-i-check"></span>
                  )}
                </td>
              );
            }}
            headerCell={(props) => (
              <div className={styles.AlignCenter}>{props.title}</div>
            )}
          />
          <Column field="StatusText" title="Comments" />
          <Column
            field="UpdateDate"
            title="Last Update"
            width="100px"
            format="{0:n2}"
            cell={(props) => {
              if (props.rowType === "groupHeader") return null;
              return (
                <td className={styles.AlignCenter}>
                  {props.dataItem.UpdateDate && (
                    <span className={props.dataItem.ClassUpdateDate}>
                      {props.dataItem.UpdateDate}
                    </span>
                  )}
                </td>
              );
            }}
            headerCell={(props) => (
              <div className={styles.AlignCenter}>{props.title}</div>
            )}
          />
          <Column
            field="IsEnabled"
            title=" "
            width="50px"
            cell={(props) => {
              if (props.rowType === "groupHeader") return null;
              let canDisable = !props.dataItem.CanDisable;
              let enabled = props.dataItem.IsEnabled;
              return (
                <td className={styles.UpdateCell}>
                  <DropDownButton
                    className={styles.CanDiasabledBtn}
                    icon="more-horizontal"
                    fillMode="flat"
                    disabled={canDisable}
                    items={[
                      {
                        text: "Enable",
                        disabled: canDisable || enabled,
                        taskId: props.dataItem.TaskId,
                        isEnabled: props.dataItem.IsEnabled,
                      },
                      {
                        text: "Disable",
                        disabled: canDisable || !enabled,
                        taskId: props.dataItem.TaskId,
                        isEnabled: props.dataItem.IsEnabled,
                      },
                    ]}
                    onItemClick={this.HandleSelect}
                  ></DropDownButton>
                </td>
              );
            }}
            headerCell={(props) => (
              <div className={styles.AlignCenter}>{props.title}</div>
            )}
          />
        </Grid>
      </>
    );
  }

  async LoadData() {
    this.setState({ loading: true });
    try {
      let params = { BuildPlanId: this.props.buildPlanId };
      let result: any = await this.GetSQLData({
        spName: "DB_BuildPlanTasks",
        params,
      });
      if (!result.length) {
        this.setState({ accessDenied: true });
        return;
      }

      let tasks: Array<ITaskItem> = result[1].map(
        (task: ITaskItem, i: number) => {
          if (task.UpdateDate) {
            task.UpdateDate = moment(task.UpdateDate).format("L");
          }
          task.IsEnabledGroup = task.IsEnabled ? "Enabled" : "Disabled";
          return task;
        }
      );
      const gridTasks = process(tasks, { group: this.group });
      setGroupIds({ data: gridTasks.data, group: this.group });
      this.setState({
        accessDenied: false,
        taskListId: result[0].TaskListId,
        taskListName: result[0].TaskListName,
        tasks: gridTasks,
      });
    } catch (e: any) {
    } finally {
      this.setState({ loading: false });
    }
  }

  GetCollapsedIds = () => {
    const collapsedIds: string[] = [];
    for (let groupId in this.expandedGroup) {
      if (this.expandedGroup[groupId] === false) collapsedIds.push(groupId);
    }
    return collapsedIds;
  };

  ExpandChange = (event: any) => {
    const groupId = event.dataItem.groupId;
    this.expandedGroup[groupId] = event.value;
    this.setState({ collapsedIds: this.GetCollapsedIds() });
  };

  AddNewTask = () => {
    let inputRef: any = React.createRef();
    ModalRef.showDialog({
      title: "Task Name",
      buttons: [
        {
          text: "Create",
          color: "primary",
          action: () => {
            let taskName = inputRef.current?.value;
            if (taskName) {
              this.AddTask(taskName);
            }
          },
        },
      ],
      children: <Input ref={inputRef} style={{ width: "100%" }} />,
    });
  };

  AddTask = async (taskName: string) => {
    ModalRef.hideDialog();
    await RunScriptAsync("AddBPTask", {
      BuildPlanID: this.props.buildPlanId,
      TaskName: taskName,
    });
    this.Refresh();
  };

  HandleSelect = (e: any) => {
    let data = e.item;
    RunScriptAsync("BPTaskSwitchEnabled", {
      BuildPlanId: this.props.buildPlanId,
      TaskId: data.taskId,
      Enabled: !data.isEnabled,
    })
      .catch(showSomeError)
      .finally(this.Refresh);
  };

  EditTasks = () => {
    RunScriptLocal("EditBPTasks", {
      BuildPlanId: this.props.buildPlanId,
    });
  };

  CopyTasks = () => {
    RunScriptLocal("CopyBPTasks", {
      BuildPlanId: this.props.buildPlanId,
    });
  };

  Refresh = () => {
    // external
    this.LoadData();
  };
}

export default BPTasks;
