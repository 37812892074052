// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddMaterialDetailsDto */
export interface AddMaterialDetailsDto {
  /**
   * Int32
   * @format int32
   */
  materialId: number;
  /**
   * Decimal
   * @format double
   */
  quantity: number;
  /** String */
  comment: string;
}
export const PropsAddMaterialDetailsDto = {
  materialId: "materialId",
  quantity: "quantity",
  comment: "comment",
};
/** AddMaterialItemsDto */
export interface AddMaterialItemsDto {
  /** List`1 */
  materialItemIds: number[];
}
export const PropsAddMaterialItemsDto = {
  materialItemIds: "materialItemIds",
};
/** AddMaterialsDto */
export interface AddMaterialsDto {
  /** List`1 */
  materialDetails: AddMaterialDetailsDto[];
}
export const PropsAddMaterialsDto = {
  materialDetails: "materialDetails",
};
/** AssignMaterialItemsToBomDto */
export interface AssignMaterialItemsToBomDto {
  /** List`1 */
  materialItemIds: number[];
}
export const PropsAssignMaterialItemsToBomDto = {
  materialItemIds: "materialItemIds",
};
/** AvailableMaterialItemDto */
export interface AvailableMaterialItemDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  sn: string;
  shipmentPackage: LinkDto;
  material: LinkDto;
  vendor: LinkDto;
  /**
   * DateTimeOffset
   * @format date-time
   */
  createdAt: string;
  /** String */
  vendorPartNo: string;
  /** String */
  mfgProductNo: string;
  warehouseArea?: LinkDto;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
  buildPlan?: LinkDto;
}
export const PropsAvailableMaterialItemDto = {
  id: "id",
  sn: "sn",
  shipmentPackage: "shipmentPackage",
  material: "material",
  vendor: "vendor",
  createdAt: "createdAt",
  vendorPartNo: "vendorPartNo",
  mfgProductNo: "mfgProductNo",
  warehouseArea: "warehouseArea",
  tag: "tag",
  assembly: "assembly",
  buildPlan: "buildPlan",
};
/** AvailableMaterialItemFilterDto */
export interface AvailableMaterialItemFilterDto {
  /**
   * Nullable`1
   * @format int32
   */
  materialId?: number | null;
}
export const PropsAvailableMaterialItemFilterDto = {
  materialId: "materialId",
};
/** BillOfMaterialDetailsDto */
export interface BillOfMaterialDetailsDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * Nullable`1
   * @format int32
   */
  sourceBomId?: number | null;
  /**
   * Int32
   * @format int32
   */
  conterraId: number;
  /** String */
  number: string;
  buildPlan: LinkDto;
  project: LinkDto;
  scenario: LinkDto;
  site: LinkDto;
  region: LinkDto;
  market: LinkDto;
  warehouse: LinkDto;
  requestor: LinkDto;
  stage: IEnumEntityBillOfMaterialsStageEnum;
  type: IEnumEntityBillOfMaterialsTypeEnum;
  /**
   * DateOnly
   * @format date
   */
  date: string;
  /**
   * DateOnly
   * @format date
   */
  dueDate: string;
  /** String */
  comments?: string | null;
  /**
   * Nullable`1
   * @format date
   */
  sent?: string | null;
  /**
   * Nullable`1
   * @format date
   */
  kitted?: string | null;
  /**
   * Nullable`1
   * @format date
   */
  completed?: string | null;
  /**
   * Double
   * @format double
   */
  total: number;
  /**
   * DateTimeOffset
   * @format date-time
   */
  createdAt: string;
  /** List`1 */
  bomMaterials: BomMaterialDetailsDto[];
}
export const PropsBillOfMaterialDetailsDto = {
  id: "id",
  sourceBomId: "sourceBomId",
  conterraId: "conterraId",
  number: "number",
  buildPlan: "buildPlan",
  project: "project",
  scenario: "scenario",
  site: "site",
  region: "region",
  market: "market",
  warehouse: "warehouse",
  requestor: "requestor",
  stage: "stage",
  type: "type",
  date: "date",
  dueDate: "dueDate",
  comments: "comments",
  sent: "sent",
  kitted: "kitted",
  completed: "completed",
  total: "total",
  createdAt: "createdAt",
  bomMaterials: "bomMaterials",
};
/** BillOfMaterialsStageEnum */
export enum BillOfMaterialsStageEnum {
  Pending = "Pending",
  Sent = "Sent",
  Kitted = "Kitted",
  Completed = "Completed",
  Canceled = "Canceled",
}
export const EnumBillOfMaterialsStageEnum = {
  Pending: "Pending",
  Sent: "Sent",
  Kitted: "Kitted",
  Completed: "Completed",
  Canceled: "Canceled",
};

/** IEnumEntityBillOfMaterialsStageEnum */
export interface IEnumEntityBillOfMaterialsStageEnum {
  id: BillOfMaterialsStageEnum;
  /** String */
  code: string;
  /** String */
  title: string;
}
export const PropsBillOfMaterialsStageEnumIEnumEntity = {
  id: "id",
  code: "code",
  title: "title",
};
/** BillOfMaterialsTypeEnum */
export enum BillOfMaterialsTypeEnum {
  Original = "Original",
  Return = "Return",
  Remaining = "Remaining",
}
export const EnumBillOfMaterialsTypeEnum = {
  Original: "Original",
  Return: "Return",
  Remaining: "Remaining",
};

/** IEnumEntityBillOfMaterialsTypeEnum */
export interface IEnumEntityBillOfMaterialsTypeEnum {
  id: BillOfMaterialsTypeEnum;
  /** String */
  code: string;
  /** String */
  title: string;
}
export const PropsBillOfMaterialsTypeEnumIEnumEntity = {
  id: "id",
  code: "code",
  title: "title",
};
/** BomMaterialDetailsDto */
export interface BomMaterialDetailsDto {
  material: LinkDto;
  /**
   * Decimal
   * @format double
   */
  quantity: number;
  /** Boolean */
  isSnRequired: boolean;
  /** String */
  uomName: string;
  /**
   * Double
   * @format double
   */
  cogs: number;
  /**
   * Double
   * @format double
   */
  total: number;
  /** String */
  comment?: string | null;
  /** List`1 */
  materialItems: BomMaterialItemDetailsDto[];
}
export const PropsBomMaterialDetailsDto = {
  material: "material",
  quantity: "quantity",
  isSnRequired: "isSnRequired",
  uomName: "uomName",
  cogs: "cogs",
  total: "total",
  comment: "comment",
  materialItems: "materialItems",
};
/** BomMaterialItemDetailsDto */
export interface BomMaterialItemDetailsDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  material: LinkDto;
  /** String */
  sn: string;
  vendor: LinkDto;
  /** String */
  vendorPartNo: string;
  /** String */
  mfgProductNo: string;
  warehouseArea?: LinkDto;
}
export const PropsBomMaterialItemDetailsDto = {
  id: "id",
  material: "material",
  sn: "sn",
  vendor: "vendor",
  vendorPartNo: "vendorPartNo",
  mfgProductNo: "mfgProductNo",
  warehouseArea: "warehouseArea",
};
/** CreateAndConfirmMaterialItemDto */
export interface CreateAndConfirmMaterialItemDto {
  /** String */
  sn: string;
  /**
   * Int32
   * @format int32
   */
  vendorId: number;
  /**
   * Int32
   * @format int32
   */
  materialId: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /**
   * Nullable`1
   * @format int32
   */
  warehouseAreaId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  buildPlanId?: number | null;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsCreateAndConfirmMaterialItemDto = {
  sn: "sn",
  vendorId: "vendorId",
  materialId: "materialId",
  warehouseId: "warehouseId",
  warehouseAreaId: "warehouseAreaId",
  buildPlanId: "buildPlanId",
  tag: "tag",
  assembly: "assembly",
};
/** CreateBillOfMaterialDto */
export interface CreateBillOfMaterialDto {
  /**
   * Int32
   * @format int32
   */
  buildPlanId: number;
  /**
   * DateOnly
   * @format date
   */
  date: string;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  type: CreateBomType;
  /**
   * DateOnly
   * @format date
   */
  dueDate: string;
  /** String */
  comments?: string | null;
}
export const PropsCreateBillOfMaterialDto = {
  buildPlanId: "buildPlanId",
  date: "date",
  warehouseId: "warehouseId",
  type: "type",
  dueDate: "dueDate",
  comments: "comments",
};
/** CreateBomType */
export enum CreateBomType {
  Original = "Original",
  Return = "Return",
}
export const EnumCreateBomType = {
  Original: "Original",
  Return: "Return",
};

/** CreateMaterialItemDto */
export interface CreateMaterialItemDto {
  /** String */
  sn: string;
  /**
   * Int32
   * @format int32
   */
  shipmentPackageId: number;
  /**
   * Int32
   * @format int32
   */
  vendorId: number;
  /**
   * Int32
   * @format int32
   */
  materialId: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /**
   * Nullable`1
   * @format int32
   */
  warehouseAreaId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  buildPlanId?: number | null;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsCreateMaterialItemDto = {
  sn: "sn",
  shipmentPackageId: "shipmentPackageId",
  vendorId: "vendorId",
  materialId: "materialId",
  warehouseId: "warehouseId",
  warehouseAreaId: "warehouseAreaId",
  buildPlanId: "buildPlanId",
  tag: "tag",
  assembly: "assembly",
};
/** CreateShipmentPackageDto */
export interface CreateShipmentPackageDto {
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  number: string;
}
export const PropsCreateShipmentPackageDto = {
  warehouseId: "warehouseId",
  number: "number",
};
/** CreateWarehouseAreaDto */
export interface CreateWarehouseAreaDto {
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  number: string;
  /** Boolean */
  isActive: boolean;
}
export const PropsCreateWarehouseAreaDto = {
  warehouseId: "warehouseId",
  number: "number",
  isActive: "isActive",
};
/** FindMaterialItemsDto */
export interface FindMaterialItemsDto {
  /** String */
  sn?: string | null;
  /**
   * Nullable`1
   * @format int32
   */
  materialId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  packageId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  warehouseAreaId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  warehouseId?: number | null;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsFindMaterialItemsDto = {
  sn: "sn",
  materialId: "materialId",
  packageId: "packageId",
  warehouseAreaId: "warehouseAreaId",
  warehouseId: "warehouseId",
  tag: "tag",
  assembly: "assembly",
};
/** FindShipmentPackageDto */
export interface FindShipmentPackageDto {
  /** String */
  search?: string | null;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /**
   * Nullable`1
   * @format int32
   */
  warehouseAreaId?: number | null;
  /**
   * Nullable`1
   * @format int32
   */
  materialId?: number | null;
}
export const PropsFindShipmentPackageDto = {
  search: "search",
  warehouseId: "warehouseId",
  warehouseAreaId: "warehouseAreaId",
  materialId: "materialId",
};
/** FindWarehouseAreaDto */
export interface FindWarehouseAreaDto {
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  search?: string | null;
  /**
   * Nullable`1
   * @format int32
   */
  materialId?: number | null;
}
export const PropsFindWarehouseAreaDto = {
  warehouseId: "warehouseId",
  search: "search",
  materialId: "materialId",
};
/** IdDto */
export interface IdDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
}
export const PropsIdDto = {
  id: "id",
};
/** InitiateReturnDto */
export interface InitiateReturnDto {
  /**
   * Int32
   * @format int32
   */
  materialItemId: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  returnType: ReturnType;
  /** String */
  comment?: string | null;
}
export const PropsInitiateReturnDto = {
  materialItemId: "materialItemId",
  warehouseId: "warehouseId",
  returnType: "returnType",
  comment: "comment",
};
/** KitBomDto */
export interface KitBomDto {
  /** List`1 */
  materials: KitBomMaterialDto[];
}
export const PropsKitBomDto = {
  materials: "materials",
};
/** KitBomMaterialDto */
export interface KitBomMaterialDto {
  /**
   * Int32
   * @format int32
   */
  materialId: number;
  /**
   * Decimal
   * @format double
   */
  quantity: number;
}
export const PropsKitBomMaterialDto = {
  materialId: "materialId",
  quantity: "quantity",
};
/** LinkDto */
export interface LinkDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  name: string;
}
export const PropsLinkDto = {
  id: "id",
  name: "name",
};
/** MaterialBalanceDto */
export interface MaterialBalanceDto {
  /**
   * Int32
   * @format int32
   */
  materialId: number;
  /**
   * Double
   * @format double
   */
  balance: number;
}
export const PropsMaterialBalanceDto = {
  materialId: "materialId",
  balance: "balance",
};
/** MaterialItemBomInfoDto */
export interface MaterialItemBomInfoDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  number: string;
  /**
   * DateOnly
   * @format date
   */
  date: string;
  /**
   * DateOnly
   * @format date
   */
  dueDate: string;
}
export const PropsMaterialItemBomInfoDto = {
  id: "id",
  number: "number",
  date: "date",
  dueDate: "dueDate",
};
/** MaterialItemCardDto */
export interface MaterialItemCardDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  name: string;
  /** String */
  sn: string;
  stage: IEnumEntityMaterialItemStageEnum;
  shipmentPackage: LinkDto;
  vendor: LinkDto;
  material: LinkDto;
  /**
   * DateTimeOffset
   * @format date-time
   */
  createdAt: string;
  /** String */
  vendorPartNo: string;
  /** String */
  mfgProductNo: string;
  /** Boolean */
  isBroken: boolean;
  warehouse?: LinkDto;
  warehouseArea?: LinkDto;
  buildPlan?: LinkDto;
  site?: LinkDto;
  bom?: MaterialItemBomInfoDto;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsMaterialItemCardDto = {
  id: "id",
  name: "name",
  sn: "sn",
  stage: "stage",
  shipmentPackage: "shipmentPackage",
  vendor: "vendor",
  material: "material",
  createdAt: "createdAt",
  vendorPartNo: "vendorPartNo",
  mfgProductNo: "mfgProductNo",
  isBroken: "isBroken",
  warehouse: "warehouse",
  warehouseArea: "warehouseArea",
  buildPlan: "buildPlan",
  site: "site",
  bom: "bom",
  tag: "tag",
  assembly: "assembly",
};
/** MaterialItemDetailsDto */
export interface MaterialItemDetailsDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  name: string;
  /** String */
  sn: string;
  stage: IEnumEntityMaterialItemStageEnum;
  /** Boolean */
  isBroken: boolean;
  vendor: LinkDto;
  material: LinkDto;
  warehouseArea?: LinkDto;
  buildPlan?: LinkDto;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsMaterialItemDetailsDto = {
  id: "id",
  name: "name",
  sn: "sn",
  stage: "stage",
  isBroken: "isBroken",
  vendor: "vendor",
  material: "material",
  warehouseArea: "warehouseArea",
  buildPlan: "buildPlan",
  tag: "tag",
  assembly: "assembly",
};
/** MaterialItemHistoryDto */
export interface MaterialItemHistoryDto {
  /** String */
  userName: string;
  /** String */
  eventType: string;
  /**
   * DateTimeOffset
   * @format date-time
   */
  timestamp: string;
  /** String */
  comments: string;
}
export const PropsMaterialItemHistoryDto = {
  userName: "userName",
  eventType: "eventType",
  timestamp: "timestamp",
  comments: "comments",
};
/** MaterialItemStageEnum */
export enum MaterialItemStageEnum {
  Draft = "Draft",
  NotAssignedToBom = "NotAssignedToBom",
  AssignedToBom = "AssignedToBom",
  Kitted = "Kitted",
  SentToSite = "SentToSite",
  ReturnInitiated = "ReturnInitiated",
  ReturnedToManufacturer = "ReturnedToManufacturer",
  Void = "Void",
}
export const EnumMaterialItemStageEnum = {
  Draft: "Draft",
  NotAssignedToBom: "NotAssignedToBom",
  AssignedToBom: "AssignedToBom",
  Kitted: "Kitted",
  SentToSite: "SentToSite",
  ReturnInitiated: "ReturnInitiated",
  ReturnedToManufacturer: "ReturnedToManufacturer",
  Void: "Void",
};

/** IEnumEntityMaterialItemStageEnum */
export interface IEnumEntityMaterialItemStageEnum {
  id: MaterialItemStageEnum;
  /** String */
  code: string;
  /** String */
  title: string;
}
export const PropsMaterialItemStageEnumIEnumEntity = {
  id: "id",
  code: "code",
  title: "title",
};
/** RemoveMaterialItemsFromBomDto */
export interface RemoveMaterialItemsFromBomDto {
  /** List`1 */
  materialItemIds: number[];
}
export const PropsRemoveMaterialItemsFromBomDto = {
  materialItemIds: "materialItemIds",
};
/** ReturnToManufacturerDto */
export interface ReturnToManufacturerDto {
  /** List`1 */
  materialItemIds: number[];
}
export const PropsReturnToManufacturerDto = {
  materialItemIds: "materialItemIds",
};
/** ReturnType */
export enum ReturnType {
  Faulty = "Faulty",
  ReturnToInventory = "ReturnToInventory",
}
export const EnumReturnType = {
  Faulty: "Faulty",
  ReturnToInventory: "ReturnToInventory",
};

/** SetItemsBuildPlanDto */
export interface SetItemsBuildPlanDto {
  /**
   * Nullable`1
   * @format int32
   */
  buildPlanId?: number | null;
  /** List`1 */
  materialItemIds: number[];
}
export const PropsSetItemsBuildPlanDto = {
  buildPlanId: "buildPlanId",
  materialItemIds: "materialItemIds",
};
/** ShipmentPackageDetailsDto */
export interface ShipmentPackageDetailsDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  number: string;
  /** List`1 */
  materialItems: MaterialItemDetailsDto[];
}
export const PropsShipmentPackageDetailsDto = {
  id: "id",
  warehouseId: "warehouseId",
  number: "number",
  materialItems: "materialItems",
};
/** ShipmentPackageListDto */
export interface ShipmentPackageListDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  number: string;
  /**
   * Int32
   * @format int32
   */
  itemsCount: number;
}
export const PropsShipmentPackageListDto = {
  id: "id",
  warehouseId: "warehouseId",
  number: "number",
  itemsCount: "itemsCount",
};
/** TransferMaterialItemsDto */
export interface TransferMaterialItemsDto {
  /** List`1 */
  materialItemIds: number[];
  /**
   * Int32
   * @format int32
   */
  areaId: number;
}
export const PropsTransferMaterialItemsDto = {
  materialItemIds: "materialItemIds",
  areaId: "areaId",
};
/** UpdateBillOfMaterialDto */
export interface UpdateBillOfMaterialDto {
  /**
   * DateOnly
   * @format date
   */
  date: string;
  /**
   * DateOnly
   * @format date
   */
  dueDate: string;
  /** String */
  comments?: string | null;
}
export const PropsUpdateBillOfMaterialDto = {
  date: "date",
  dueDate: "dueDate",
  comments: "comments",
};
/** UpdateMaterialCommentDto */
export interface UpdateMaterialCommentDto {
  /** String */
  comment: string;
}
export const PropsUpdateMaterialCommentDto = {
  comment: "comment",
};
/** UpdateMaterialItemDto */
export interface UpdateMaterialItemDto {
  /** String */
  sn: string;
  /**
   * Int32
   * @format int32
   */
  vendorId: number;
  /** Boolean */
  isBroken: boolean;
  /** String */
  tag?: string | null;
  /** String */
  assembly?: string | null;
}
export const PropsUpdateMaterialItemDto = {
  sn: "sn",
  vendorId: "vendorId",
  isBroken: "isBroken",
  tag: "tag",
  assembly: "assembly",
};
/** UpdateMaterialQuantityDto */
export interface UpdateMaterialQuantityDto {
  /**
   * Decimal
   * @format double
   */
  quantity: number;
}
export const PropsUpdateMaterialQuantityDto = {
  quantity: "quantity",
};
/** UpdateShipmentPackageDto */
export interface UpdateShipmentPackageDto {
  /** String */
  number: string;
}
export const PropsUpdateShipmentPackageDto = {
  number: "number",
};
/** UpdateWarehouseAreaDto */
export interface UpdateWarehouseAreaDto {
  /** String */
  number: string;
  /** Boolean */
  isActive: boolean;
}
export const PropsUpdateWarehouseAreaDto = {
  number: "number",
  isActive: "isActive",
};
/** WarehouseAreaDto */
export interface WarehouseAreaDto {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /**
   * Int32
   * @format int32
   */
  warehouseId: number;
  /** String */
  number: string;
  /** Boolean */
  isActive: boolean;
  /**
   * Int32
   * @format int32
   */
  itemsCount: number;
}
export const PropsWarehouseAreaDto = {
  id: "id",
  warehouseId: "warehouseId",
  number: "number",
  isActive: "isActive",
  itemsCount: "itemsCount",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix Warehouse
 * @version v1
 */

export class Api_warehouse {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  bom = {
    create: (data: CreateBillOfMaterialDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/bom`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.http.request<BillOfMaterialDetailsDto>({
        path: `/Warehouse/bom/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    update: (id: number, data: UpdateBillOfMaterialDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    cancel: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/cancel`,
        method: "POST",
        ...params,
      }),
    complete: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/complete`,
        method: "POST",
        ...params,
      }),
    createReturn: (id: number, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/bom/${id}/create-return`,
        method: "POST",
        format: "json",
        ...params,
      }),
    kit: (id: number, data: KitBomDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/bom/${id}/kit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    addMaterialItems: (id: number, data: AddMaterialItemsDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/material-items/add`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    assignMaterialItems: (id: number, data: AssignMaterialItemsToBomDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/material-items/assign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    getAvailableMaterialItems: (id: number, data: AvailableMaterialItemFilterDto, params: RequestParams = {}) =>
      this.http.request<AvailableMaterialItemDto[]>({
        path: `/Warehouse/bom/${id}/material-items/available`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    removeMaterialItems: (id: number, data: RemoveMaterialItemsFromBomDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/material-items/remove`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    removeMaterialItem: (id: number, materialItemId: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/material-items/remove/${materialItemId}`,
        method: "POST",
        ...params,
      }),
    updateMaterialComment: (
      id: number,
      materialId: number,
      data: UpdateMaterialCommentDto,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/materials/${materialId}/comment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    updateMaterialQuantity: (
      id: number,
      materialId: number,
      data: UpdateMaterialQuantityDto,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/materials/${materialId}/quantity`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    addMaterials: (id: number, data: AddMaterialsDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/materials/add`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    removeMaterial: (id: number, materialId: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/materials/remove/${materialId}`,
        method: "POST",
        ...params,
      }),
    return: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/return`,
        method: "POST",
        ...params,
      }),
    returnToPending: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/return-to-pending`,
        method: "POST",
        ...params,
      }),
    sendToWarehouse: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/bom/${id}/send`,
        method: "POST",
        ...params,
      }),
  };
  mi = {
    create: (data: CreateMaterialItemDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/MaterialItem`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.http.request<MaterialItemCardDto>({
        path: `/Warehouse/MaterialItem/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    update: (id: number, data: UpdateMaterialItemDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    delete: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/${id}`,
        method: "DELETE",
        ...params,
      }),
    getHistory: (id: number, params: RequestParams = {}) =>
      this.http.request<MaterialItemHistoryDto[]>({
        path: `/Warehouse/MaterialItem/${id}/history`,
        method: "GET",
        format: "json",
        ...params,
      }),
    void: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/${id}/void`,
        method: "POST",
        ...params,
      }),
    createAndConfirm: (data: CreateAndConfirmMaterialItemDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/MaterialItem/create-and-confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    find: (data: FindMaterialItemsDto, params: RequestParams = {}) =>
      this.http.request<MaterialItemDetailsDto[]>({
        path: `/Warehouse/MaterialItem/find`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    initiateReturn: (data: InitiateReturnDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/initiate-return`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    returnToManufacturer: (data: ReturnToManufacturerDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/MaterialItem/return-to-manufacturer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    setBuildPlan: (data: SetItemsBuildPlanDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/set-build-plan`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    transfer: (data: TransferMaterialItemsDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/MaterialItem/transfer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  package = {
    create: (data: CreateShipmentPackageDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/ShipmentPackage`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.http.request<ShipmentPackageDetailsDto>({
        path: `/Warehouse/ShipmentPackage/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    update: (id: number, data: UpdateShipmentPackageDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/ShipmentPackage/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    delete: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/ShipmentPackage/${id}`,
        method: "DELETE",
        ...params,
      }),
    confirm: (data: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/ShipmentPackage/confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    find: (data: FindShipmentPackageDto, params: RequestParams = {}) =>
      this.http.request<ShipmentPackageListDto[]>({
        path: `/Warehouse/ShipmentPackage/find`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  wh = {
    getBalance: (id: number, params: RequestParams = {}) =>
      this.http.request<MaterialBalanceDto[]>({
        path: `/Warehouse/warehouse/${id}/balance`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  area = {
    create: (data: CreateWarehouseAreaDto, params: RequestParams = {}) =>
      this.http.request<IdDto>({
        path: `/Warehouse/WarehouseArea`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getById: (id: number, params: RequestParams = {}) =>
      this.http.request<WarehouseAreaDto>({
        path: `/Warehouse/WarehouseArea/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
    update: (id: number, data: UpdateWarehouseAreaDto, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/WarehouseArea/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    delete: (id: number, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Warehouse/WarehouseArea/${id}`,
        method: "DELETE",
        ...params,
      }),
    find: (data: FindWarehouseAreaDto, params: RequestParams = {}) =>
      this.http.request<WarehouseAreaDto[]>({
        path: `/Warehouse/WarehouseArea/find`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
