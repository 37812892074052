import React, { useEffect, useState } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import {
  SQL_WH_BOM_AvailableTemplates_Response,
  SQL_WH_BOM_AvailableTemplates_Response_Materials,
} from "../../../core/api/generated/conterra";
import { Button } from "@progress/kendo-react-buttons";
import LoaderComponent from "../../Common/Loader";
import ExpandedHeader from "../../Common/ExpandedPanel/ExpandedHeader";
import styles from "./bomcard.module.scss";
import { showSomeError } from "../../../helpers/errorHelpers";

export const SelectFromTemplate = (props: {
  buildPlanId: number;
  addMaterials: (
    materials: SQL_WH_BOM_AvailableTemplates_Response_Materials[],
  ) => void;
}) => {
  const { buildPlanId } = props;
  const loading = useBooleanState();
  const [data, setData] = useState<SQL_WH_BOM_AvailableTemplates_Response[]>(
    [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  useEffect(() => {
    const loadData = async () => {
      try {
        loading.setTrue();
        const result = await api.sql.whBomAvailableTemplates({ buildPlanId });
        setData(result);
      } catch (e) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
    };
    loadData();
  }, []);

  return (
    <div style={{ height: 300, overflow: "auto", position: "relative" }}>
      {loading.value && <LoaderComponent />}
      {data.map(({ name, materials }) => {
        const expandedMaterials = expanded.includes(name);
        return (
          <div key={name}>
            <div>
              <ExpandedHeader
                key={name}
                expanded={expandedMaterials}
                level={2}
                onToggle={(tName: string) => {
                  if (!expanded.includes(tName)) {
                    setExpanded([...expanded, tName]);
                  } else {
                    setExpanded(expanded.filter((n) => n !== tName));
                  }
                }}
                dataId={name}
                dataText={name}
                content={
                  <>
                    <Button
                      style={{ margin: "-5px 8px -5px -8px" }}
                      icon={"plus"}
                      fillMode={"flat"}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.addMaterials(materials);
                      }}
                    />
                    {name}
                  </>
                }
              />
            </div>
            {expandedMaterials &&
              materials.map(({ id, name: materialName, quantity }) => (
                <div
                  key={id + "_" + name}
                  className={styles.TemplateMaterialRow}
                >
                  <span>{materialName}</span> <span>{quantity}</span>
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};
