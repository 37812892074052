import { useMemo } from "react";
import { calcMonthDays, calcWeekLine, getFirstDayOfPeriod } from "../utils";
import { useCalendarContext } from "../CalendarContext";
import { CalendarDay } from "../CalendarDay";
import { clsx } from "clsx";
import styles from "../calendar.module.scss";

export const CalendarMonthView = () => {
  const ctx = useCalendarContext();

  const weekLine = useMemo(
    () => calcWeekLine(ctx.firstDayOfWeek),
    [ctx.firstDayOfWeek],
  );
  const monthDays = useMemo(
    () =>
      calcMonthDays(
        getFirstDayOfPeriod(ctx.selectedDate, "month", ctx.firstDayOfWeek),
        ctx.firstDayOfWeek,
      ),
    [ctx.selectedDate, ctx.firstDayOfWeek],
  );

  return (
    <div className={styles.CalendarMonthViewContainer}>
      <div className={styles.CalendarMonthView}>
        <table className={styles.CalendarMonthViewTable}>
          <thead>
            <tr>
              <th>W</th>
              {weekLine.map((x) => (
                <th key={x.id}>{x.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {monthDays.map((week) => (
              <tr key={week[0].weekNumber}>
                <td>{week[0].weekNumber}</td>
                {week.map((day) => (
                  <td
                    key={day.date.valueOf()}
                    className={clsx(
                      styles.CalendarMonthViewCell,
                      !day.isInCurrentPeriod &&
                        styles.CalendarMonthViewCellDisabled,
                      day.isToday && styles.CalendarMonthViewCellToday,
                    )}
                  >
                    {day.isInCurrentPeriod ? (
                      <div className={styles.CalendarMonthViewDay}>
                        <div
                          className={styles.CalendarMonthViewDayTop}
                          title={day.date.format("LL")}
                        >
                          {day.date.date()}
                        </div>
                        <CalendarDay day={day} />
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
