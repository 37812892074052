import { IComboboxItem, INewComboboxItem, simpleObject } from "./interfaces";
import { IntegrationWithDesktop, settingsStorage } from "./settings";

import moment from "moment";
import api from "../core/api/api";
import config from "../config";
import { SasTokenAccessType } from "../core/api/generated/documents";
import { showSomeError } from "./errorHelpers";
import { AxiosProgressEvent } from "axios";
import DI from "../inversify/container";
import { ISasTokenService } from "../core/inversify/interfaces";
import DI_TYPES from "../core/inversify/diTypes";

export const IsDesktopIntegrationOn = () => {
  return settingsStorage.getForCurrentUser(IntegrationWithDesktop) === "true";
};

export const sortLetterNumberSpecialCharOrder = (
  aStr: string,
  bStr: string,
) => {
  const aSpecCh = (aStr.at(0) || "").match(/\W/);
  const bSpecCh = (bStr.at(0) || "").match(/\W/);
  if (aSpecCh && bSpecCh) {
    return aStr.localeCompare(bStr);
  }
  if (aSpecCh) return 1;
  if (bSpecCh) return -1;

  const aNum = parseFloat(aStr);
  const bNum = parseFloat(bStr);
  if (!isNaN(aNum) && !isNaN(bNum)) return aNum - bNum;
  if (!isNaN(aNum)) return 1;
  if (!isNaN(bNum)) return -1;

  return aStr.localeCompare(bStr);
};

export const sortByNameCombobox = (a: IComboboxItem, b: IComboboxItem) =>
  a.Name.localeCompare(b.Name);

export const sortByNameNewCombobox = (
  a: INewComboboxItem,
  b: INewComboboxItem,
) => a.name.localeCompare(b.name);

export const formattedHoursToDuration = (durationString: string) => {
  const hours = +durationString.substring(0, 2);
  const minutes = +durationString.substring(3, 5);
  if (isNaN(hours) || isNaN(minutes)) return null;
  const duration = hours + minutes / 60;
  return +duration;
};

export const formatHoursDuration = (duration: number) => {
  const [h, m] = Math.abs(duration).toString().split(".");
  let hours = +h;
  const minutesDuration = +`0.${m ? m : 0}`;
  let minutes = Math.round(60 * minutesDuration);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  const minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;
  const hoursString = `${hours < 10 ? "0" : ""}${hours}`;
  const minus =
    hoursString !== "00" && minutesString !== "00" && duration < 0 ? "-" : "";
  return `${minus}${hoursString}:${minutesString}`;
};

export const formatTimeDiff = (s: number) => {
  const hrs = Math.floor(s / 3600);
  const min = Math.floor((s % 3600) / 60);
  return `${hrs ? hrs + "h " : ""}${min}m`;
};
export const formatTimeDiffWithSeconds = (s: number) => {
  const hrs = Math.floor(s / 3600);
  const min = Math.floor((s % 3600) / 60);
  const seconds = s % 60;
  return `${hrs ? hrs + "h " : ""}${min ? min + "m " : ""}${seconds}s`;
};

export const getActualDuration = (
  start: string,
  finish: string,
): [number, string] => {
  const startMoment = moment(start);
  const finishMoment = moment(finish);
  const diff = finishMoment.diff(startMoment, "m");
  const hours: number = diff / 60;
  return [hours, formatHoursDuration(hours)];
};

export const ResetSecondsDateString = (dateTime: string) =>
  dateTime.slice(0, -2) + "00";

export const formatFinancial = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "";
  const formatValue = +Math.abs(value).toFixed(2);
  const format = formatValue.toLocaleString("en");
  let float = format.split(".")[1];
  float = float ? (float.length === 2 ? float : float + "0") : "00";
  const result = format.split(".")[0] + "." + float;
  if (value < 0) {
    return "(" + result + ")";
  }
  return result;
};

export const formatFinancialRound = (value: number) => {
  const minus = value < 0 ? "-" : "";
  const kValue = kmbFormatter(Math.abs(value));
  return minus + "$" + kValue;
};

export const kmbFormatter = (n: number) => {
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "b";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const PLFormatPercentage = (value: number) => {
  if (!isNaN(value) && !isFinite(value)) return "0.00 %";
  else if (isNaN(value)) return "n/a";
  else return `${value.toFixed(2)} %`;
};

export const TYPE_TO_EXCEL_FORMAT = {
  number: "#,##0.00",
  integer: "#,##0",
  percent: "#0.00\\%;-#0.00\\%",
  currency:
    '_([$$-en-US]* #,##0.00_);_([$$-en-US]* (#,##0.00);_([$$-en-US]* ""-""??_);_(@_)',
  date: "MM/dd/yyyy",
  datetime: "MM/dd/yyyy h:mm AM/PM",
  time: "h:mm:ss AM/PM",
  // boolean TODO
};

export const getParamsFromUrl = () => {
  const strParams = window.location.search.replace("?", "").split("&");
  const params: simpleObject = {};
  strParams.forEach(function (str) {
    const t = str.split("=");
    params[t[0]] = t[1];
  });
  return params;
};

export const addParamsToUrl = (params: simpleObject, url: string) => {
  const paramsArray: Array<string> = [];
  for (const key in params) {
    if (key !== "folder")
      paramsArray.push(`${key ? key + "=" : ""}${params[key]}`);
  }
  const f = url.indexOf("?") > -1 ? "&" : "?";
  if (paramsArray.length) return url + f + paramsArray.join("&");
  return url;
};

export function onInactive(ms: number, cb: () => void) {
  const newcb = function () {
    cb();
    onInactive(ms, cb);
  };
  let wait = setTimeout(newcb, ms);
  document.onmousemove =
    document.onmousedown =
    document.onmouseup =
    document.onkeydown =
    document.onkeyup =
    document.onfocus =
      function () {
        clearTimeout(wait);
        wait = setTimeout(newcb, ms);
      };
}

export const GetDocumentUrl = async (
  documentId: number,
  inline: boolean,
  preview?: boolean,
) => {
  try {
    const sasTokenService = DI.get<ISasTokenService>(DI_TYPES.ISasTokenService);
    const sasToken = await sasTokenService.getSasToken(
      SasTokenAccessType.DocumentRead,
    );
    return (
      config.API_URL.replace(/\/+$/, "") +
      `/Documents/Document?documentId=${documentId}&sas=${sasToken}&inline=${inline}&preview=${!!preview}`
    );
  } catch (e) {
    showSomeError(e);
    return null;
    // throw e;
  }
};

export const UpdateDocument = async (
  documentId: number,
  file: File,
  onUploadProgress?: (pecent: number) => void,
) => {
  try {
    const onProgress = onUploadProgress
      ? (e: AxiosProgressEvent) => {
          const percent = e.total ? (e.loaded / e.total) * 100 : 0;
          onUploadProgress(+percent.toFixed(0));
        }
      : undefined;
    return await api.documents.updateFileBody(
      { documentId },
      { document: file },
      { onUploadProgress: onProgress },
    );
  } catch (e: unknown) {
    showSomeError(e);
  }
};

export const GetComboboxYearsList = () => {
  const year = new Date().getFullYear();
  let startYear = year - 10;
  const endYear = year + 11;
  const years: Array<IComboboxItem> = [];
  while (startYear < endYear) {
    years.push({
      Id: startYear,
      Name: startYear + "",
    });
    startYear += 1;
  }
  return years;
};
