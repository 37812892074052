import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CalendarMode,
  CalendarProcessing,
  CommonData,
  ContextMenuData,
  FilterData,
  FirstDayOfWeek,
  Position,
} from "./models";
import moment from "moment/moment";
import api from "../../core/api/api";
import {
  SQL_DispatchCalendar_DayData_Response,
  SQL_DispatchCalendar_PeriodData_Response,
  SQL_DispatchCalendar_PeriodData_Response_Dispatches,
} from "../../core/api/generated/conterra";
import { showSomeError } from "../../helpers/errorHelpers";
import { getFirstDayOfPeriod, getLastDayOfPeriod } from "./utils";
import { CalendarContext, ICalendarContext } from "./CalendarContext";
import { EventEmitter2 } from "eventemitter2";
import { serverSettings } from "../../helpers/settings";

type IParams = {
  buildPlanId?: number;
};

type Props = IParams & {
  children: React.ReactNode;
};

const EXPAND_COLLAPSE_EVENT: string = "EXPAND_COLLAPSE" as const;
const DEFAULT_CALENDAR_MODE: CalendarMode = "week" as const;
const DEFAULT_FIRST_DAY_OF_WEEK: FirstDayOfWeek =
  FirstDayOfWeek.sunday as const;

const toMap = <T, U, V>(
  items: T[],
  selectorKey: (item: T) => U,
  selectorResult: (item: T) => V,
) => {
  return new Map(items.map((x) => [selectorKey(x), selectorResult(x)]));
};

const toDictionaryMap = (items: { id: number; name: string }[]) =>
  toMap(
    items,
    (x) => x.id,
    (x) => x.name,
  );

export const CalendarContextProvider = ({ children, buildPlanId }: Props) => {
  const [processing, setProcessing] =
    useState<CalendarProcessing>("processing");
  const [buildPlanIdValue, setBuildPlanIdValue] = useState<number | undefined>(
    buildPlanId,
  );
  const [mapBuildPlanId, setMapBuildPlanId] = useState<number | undefined>();
  const [mode, setMode] = useState<CalendarMode>(DEFAULT_CALENDAR_MODE);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState<FirstDayOfWeek>(
    DEFAULT_FIRST_DAY_OF_WEEK,
  );
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(
    moment().startOf("day"),
  );
  const [selectedDispatches, setSelectedDispatches] = useState<number[]>([]);
  const [filters, setFilters] = useState<FilterData>({
    buildPlanOwnerIds: [],
    buildPlanClassIds: [],
    crewContainerIds: [],
    customerIds: [],
    marketIds: [],
    regionIds: [],
    resourceIds: [],
    title: "",
    isMy: false,
  });
  const [commonData, setCommonData] = useState<CommonData | null>(null);
  const [eventsByDate, setEventsByDate] = useState<
    Map<string, SQL_DispatchCalendar_PeriodData_Response>
  >(new Map<string, SQL_DispatchCalendar_PeriodData_Response>());
  const [woOnDay, setWoOnDay] = useState<
    SQL_DispatchCalendar_DayData_Response[]
  >([]);
  const [contextMenuState, setContextMenuState] = useState<ContextMenuData>({
    open: false,
  });
  const [useSimpleGBP, setUseSimpleGBP] = useState<boolean>(false);

  const isFirstRender = useRef(true);

  const loadPeriodEvents = useCallback(() => {
    if (!["month", "week", "resources"].includes(mode)) return;

    const beginDate = getFirstDayOfPeriod(
      selectedDate,
      mode,
      firstDayOfWeek,
    ).format("YYYY-MM-DD");
    const endDate = getLastDayOfPeriod(
      selectedDate,
      mode,
      firstDayOfWeek,
    ).format("YYYY-MM-DD");

    setProcessing("processing");
    setSelectedDispatches([]);
    api.sql
      .dispatchCalendarPeriodData({
        beginDate: beginDate,
        endDate: endDate,
        buildPlanId: buildPlanIdValue,
      })
      .then((data) => {
        setProcessing("success");
        setEventsByDate(
          toMap(
            data,
            (x) => x.date,
            (x) => x,
          ),
        );
      })
      .catch((e: unknown) => {
        setProcessing("error");
        showSomeError(e);
      });
  }, [buildPlanIdValue, firstDayOfWeek, mode, selectedDate]);

  const loadDayWo = useCallback(() => {
    if (mode !== "day") return;
    const date = selectedDate.format("YYYY-MM-DD");

    setProcessing("processing");
    setSelectedDispatches([]);
    api.sql
      .dispatchCalendarDayData({
        date,
        buildPlanId: buildPlanIdValue,
      })
      .then((data) => {
        setProcessing("success");
        setWoOnDay(data);
      })
      .catch((e: unknown) => {
        setProcessing("error");
        showSomeError(e);
      });
  }, [buildPlanIdValue, mode, selectedDate]);

  const loadCommonData = useCallback(() => {
    setProcessing("processing");
    api.sql
      .dispatchCalendarCommonData()
      .then((result) => {
        const data = result[0];

        setProcessing("success");
        setFirstDayOfWeek(
          data.workCalendar?.firstDay || DEFAULT_FIRST_DAY_OF_WEEK,
        );
        setCommonData({
          workCalendar: data.workCalendar,
          resources: toDictionaryMap(data.resources),
          bpOwners: toDictionaryMap(data.bpOwners),
          classes: toDictionaryMap(data.classes),
          crewContainers: toDictionaryMap(data.crewContainers),
          customers: toDictionaryMap(data.customers),
          markets: toMap(
            data.markets,
            (x) => x.id,
            (x) => ({
              name: x.name,
              employeeIds: x.marketResources.map((r) => r.employeeId),
            }),
          ),
          ourCompanies: toDictionaryMap(data.ourCompanies),
          projects: toDictionaryMap(data.projects),
          reasons: toDictionaryMap(data.reasons),
          regions: toMap(
            data.regions,
            (x) => x.id,
            (x) => ({
              name: x.name,
              employeeIds: x.regionResources.map((r) => r.employeeId),
            }),
          ),
          scenarios: toDictionaryMap(data.scenarios),
          woTypes: toDictionaryMap(data.woTypes),
        });
      })
      .catch((e: unknown) => {
        setProcessing("error");
        showSomeError(e);
      });
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    const settings = serverSettings.getServerSettings();
    if (settings && settings["UseSimpleGBP"]) {
      setUseSimpleGBP(true);
    }

    loadCommonData();
  }, [loadCommonData]);

  useEffect(() => {
    setBuildPlanIdValue(buildPlanId);
  }, [buildPlanId]);

  const refresh = useCallback(() => {
    switch (mode) {
      case "month":
      case "week":
      case "resources":
        loadPeriodEvents();
        break;
      case "day":
        loadDayWo();
        break;
    }
  }, [loadDayWo, loadPeriodEvents, mode]);

  useEffect(() => {
    if (commonData) {
      refresh();
    }
  }, [commonData, refresh]);

  const addSelectedDispatch = useCallback(
    (dispatchId: number) =>
      setSelectedDispatches((prev) => [...prev, dispatchId]),
    [],
  );

  const removeSelectedDispatch = useCallback(
    (dispatchId: number) =>
      setSelectedDispatches((prev) => prev.filter((x) => x !== dispatchId)),
    [],
  );

  const openContextMenu = useCallback(
    (
      dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches,
      date: moment.Moment,
      position: Position,
    ) => {
      setContextMenuState({
        open: true,
        position,
        dispatch,
        date,
      });
    },
    [],
  );

  const closeContextMenu = useCallback(() => {
    setContextMenuState({ open: false });
  }, []);

  const collapsibleEventEmitter = useMemo(
    () =>
      new EventEmitter2({
        maxListeners: 31 /* max days in month */,
      }),
    [],
  );

  const collapsibleEmit = useCallback(
    (expand: boolean) => {
      collapsibleEventEmitter.emit(EXPAND_COLLAPSE_EVENT, expand);
    },
    [collapsibleEventEmitter],
  );

  const collapsibleSubscribe = useCallback(
    (func: (expand: boolean) => void) => {
      const listener = collapsibleEventEmitter.on(EXPAND_COLLAPSE_EVENT, func, {
        objectify: true,
      });
      return () => listener.off(EXPAND_COLLAPSE_EVENT, func);
    },
    [collapsibleEventEmitter],
  );

  const changeMode = useCallback(
    (newMode: CalendarMode, buildPlanId?: number) => {
      if (mode === "map" && newMode !== "map") {
        setMapBuildPlanId(undefined);
      } else if (newMode === "map") {
        setMapBuildPlanId(buildPlanId);
      }

      setMode(newMode);
    },
    [mode],
  );

  const ctx = useMemo(() => {
    const ctxData: ICalendarContext = {
      buildPlan: {
        buildPlanId: buildPlanIdValue,
        mapBuildPlanId,
        setMapBuildPlanId,
      },
      useSimpleGBP,
      mode,
      changeMode,
      firstDayOfWeek,
      setFirstDayOfWeek,
      selectedDate,
      setSelectedDate,
      filters,
      setFilters,
      processing,
      selectedDispatches: {
        dispatches: selectedDispatches,
        add: addSelectedDispatch,
        remove: removeSelectedDispatch,
      },
      data: {
        common: commonData,
        eventsByDate,
        woOnDay,
        refresh,
        fullRefresh: loadCommonData,
      },
      contextMenu: {
        state: contextMenuState,
        open: openContextMenu,
        close: closeContextMenu,
      },
      collapsible: {
        emit: collapsibleEmit,
        subscribe: collapsibleSubscribe,
      },
    };

    return ctxData;
  }, [
    buildPlanIdValue,
    mapBuildPlanId,
    useSimpleGBP,
    mode,
    firstDayOfWeek,
    selectedDate,
    filters,
    processing,
    selectedDispatches,
    addSelectedDispatch,
    removeSelectedDispatch,
    commonData,
    eventsByDate,
    woOnDay,
    refresh,
    contextMenuState,
    openContextMenu,
    closeContextMenu,
    collapsibleEmit,
    collapsibleSubscribe,
  ]);

  return (
    <CalendarContext.Provider value={ctx}>{children}</CalendarContext.Provider>
  );
};
