// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AdminSignInRequest */
export interface AdminSignInRequest {
  /**
   * String
   * @minLength 1
   */
  login: string;
  /**
   * String
   * @minLength 1
   */
  password: string;
}
export const PropsAdminSignInRequest = {
  login: "login",
  password: "password",
};
/** ApiKeySignInUserRequest */
export interface ApiKeySignInUserRequest {
  /**
   * String
   * @minLength 1
   */
  instanceId: string;
  /**
   * Int32
   * @format int32
   */
  userId: number;
  /**
   * String
   * @minLength 1
   */
  apiKey: string;
}
export const PropsApiKeySignInUserRequest = {
  instanceId: "instanceId",
  userId: "userId",
  apiKey: "apiKey",
};
/** AuthenticateUserResponse */
export interface AuthenticateUserResponse {
  /**
   * String
   * @minLength 1
   */
  authToken: string;
  /**
   * String
   * @minLength 1
   */
  refreshToken: string;
}
export const PropsAuthenticateUserResponse = {
  authToken: "authToken",
  refreshToken: "refreshToken",
};
/** ChangePasswordRequest */
export interface ChangePasswordRequest {
  /**
   * String
   * @minLength 1
   */
  oldPassword: string;
  /**
   * String
   * @minLength 1
   */
  newPassword: string;
}
export const PropsChangePasswordRequest = {
  oldPassword: "oldPassword",
  newPassword: "newPassword",
};
/** PasswordSignInUserRequest */
export interface PasswordSignInUserRequest {
  /**
   * String
   * @minLength 1
   */
  instanceId: string;
  /**
   * String
   * @minLength 1
   */
  login: string;
  /**
   * String
   * @minLength 1
   */
  password: string;
}
export const PropsPasswordSignInUserRequest = {
  instanceId: "instanceId",
  login: "login",
  password: "password",
};
/** RefreshTokenSignInRequest */
export interface RefreshTokenSignInRequest {
  /**
   * String
   * @minLength 1
   */
  refreshToken: string;
}
export const PropsRefreshTokenSignInRequest = {
  refreshToken: "refreshToken",
};
/** RevokeUserTokensRequest */
export interface RevokeUserTokensRequest {
  /**
   * String
   * @minLength 1
   */
  instanceId: string;
  /**
   * Int32
   * @format int32
   */
  userId: number;
  /**
   * String
   * @minLength 1
   */
  apiKey: string;
}
export const PropsRevokeUserTokensRequest = {
  instanceId: "instanceId",
  userId: "userId",
  apiKey: "apiKey",
};
/** SingleTokenResponse */
export interface SingleTokenResponse {
  /**
   * String
   * @minLength 1
   */
  authToken: string;
}
export const PropsSingleTokenResponse = {
  authToken: "authToken",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix JWT Provider
 * @version v1
 */

export class Api_auth {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  auth = {
    getTokenByPassword: (data: PasswordSignInUserRequest, params: RequestParams = {}) =>
      this.http.request<AuthenticateUserResponse>({
        path: `/Auth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getAdminToken: (data: AdminSignInRequest, params: RequestParams = {}) =>
      this.http.request<AuthenticateUserResponse>({
        path: `/Auth/admin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getTokenByApiKey: (data: ApiKeySignInUserRequest, params: RequestParams = {}) =>
      this.http.request<SingleTokenResponse>({
        path: `/Auth/api`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    changePassword: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Auth/change-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    refreshToken: (data: RefreshTokenSignInRequest, params: RequestParams = {}) =>
      this.http.request<AuthenticateUserResponse>({
        path: `/Auth/refresh`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    revokeUserTokens: (data: RevokeUserTokensRequest, params: RequestParams = {}) =>
      this.http.request<void>({
        path: `/Auth/revoke`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
