import { injectable } from "inversify";
import {
  ColoredConsoleSink,
  Logger as Serilogger,
  LoggerConfiguration,
  SeqSink,
} from "serilogger";

import config from "../config";
import { IAuthInfo, ILogger, TLoggerProps } from "../core/inversify/interfaces";

@injectable()
export default class Logger implements ILogger {
  protected _authInfo: IAuthInfo | undefined;
  private readonly seqLogger: Serilogger;

  constructor(seqLogger: Serilogger | null = null) {
    if (seqLogger) {
      this.seqLogger = seqLogger;
    } else {
      this.seqLogger = new LoggerConfiguration()
        // TODO .enrich({ UserAgent: window.navigator.userAgent })
        // TODO different env for different developers, for production
        // .enrich({ Environment: "chugun_dev" })
        .enrich(() => ({
          App: "Web",
          SourceHost: window.location.host,
          SourcePath: window.location.pathname,
        }))
        .enrich(() => {
          return {
            UserId: this._authInfo?.UserId,
            InstanceId: this._authInfo?.InstanceId,
            SessionId: this._authInfo?.SessionId,
            Login: this._authInfo?.Login,
          };
        })
        .writeTo(
          new SeqSink({
            url: config.SEQ_URL,
            apiKey: config.SEQ_KEY,
            durable: true,
          }),
        )
        .writeTo(new ColoredConsoleSink({ includeProperties: true }))
        .create();
    }
  }

  getCatcher(functionName: string, scope?: string) {
    return (error: Error | unknown) => {
      this.fatalException(
        error,
        "Caught Promise Rejection at {functionName}(). {scope}",
        functionName,
        scope,
      );
    };
  }

  public setAuthInfo(authInfo: IAuthInfo | undefined) {
    this._authInfo = authInfo;
  }

  verbose(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.verbose(message, ...properties);
  }

  debug(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.debug(message, ...properties);
  }

  info(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.info(message, ...properties);
  }

  warn(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.warn(message, ...properties);
  }

  warnException(
    exception: unknown,
    message?: string,
    ...properties: TLoggerProps[]
  ) {
    this.getEnrichedWithExceptionInfo(exception).warn(
      (message ? `${message}: ` : "Exception: ") +
        this.getExceptionTextToLog(exception),
      ...properties,
    );
  }

  verboseException(
    exception: unknown,
    message?: string,
    ...properties: TLoggerProps[]
  ) {
    this.getEnrichedWithExceptionInfo(exception).verbose(
      (message ? `${message}: ` : "Exception: ") +
        this.getExceptionTextToLog(exception),
      ...properties,
    );
  }

  error(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.error(message, ...properties);
  }

  errorException(
    exception: unknown,
    message?: string,
    ...properties: TLoggerProps[]
  ) {
    this.getEnrichedWithExceptionInfo(exception).error(
      (message ? `${message}: ` : "Exception: ") +
        this.getExceptionTextToLog(exception),
      ...properties,
    );
  }

  fatal(message: string, ...properties: TLoggerProps[]) {
    this.seqLogger.fatal(message, ...properties);
  }

  fatalException(
    exception: unknown,
    message?: string,
    ...properties: TLoggerProps[]
  ) {
    this.getEnrichedWithExceptionInfo(exception).fatal(
      (message ? `${message}: ` : "Exception: ") +
        this.getExceptionTextToLog(exception),
      ...properties,
    );
  }

  getEnrichedWithExceptionInfo(exception: unknown) {
    if (exception instanceof Error)
      return this.seqLogger.createChild({
        ExceptionName: exception.name,
        ExceptionMessage: exception.message,
        ExceptionStack: exception.stack,
      });
    if (typeof exception === "string")
      return this.seqLogger.createChild({
        ExceptionMessage: exception,
      });

    return this.seqLogger;
  }

  getExceptionTextToLog(exception: unknown) {
    if (exception instanceof Error) return exception.message;
    if (typeof exception === "string") return exception;

    return "Unknown exception type";
  }

  enrich(enricher: object) {
    return new Logger(this.seqLogger.createChild(enricher));
  }

  flush() {
    return this.seqLogger.flush();
  }
}
