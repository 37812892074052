import DashboardGrid from "../Components/Dashboard/Grid";
import { IComboboxItem, simpleObject } from "../helpers/interfaces";
import {
  IColumnSetting,
  IColumnValue,
  IFilterSetting,
  IFiltersValue,
} from "../Components/Dashboard/interfaces";
import OpenCardLink from "../Components/OpenCardLink";
import { Chip } from "@progress/kendo-react-buttons";
import CardManagement from "../Components/Cards/CardManagement";
import { getSQLData, ReferenceRecordsDataSource } from "../helpers/queries";
import { ModalRef } from "../Components/Common/Modal/Modal";
import moment from "moment";
import BaseComponent from "../Components/BaseComponent";
import { showSomeError } from "../helpers/errorHelpers";

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterPeriod",
    type: "dateRange",
    serverFilter: {
      param: "",
    },
    dateRangeSettings: {
      dateFromParam: "dateFrom",
      dateToParam: "dateTo",
      defaultPeriod: "curr-month-to-date",
      allowEmptyValues: false,
    },
    toolbarRow: 1,
  },
  {
    id: "filterEmployee",
    placeholder: "Filter by Employee",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "employeeId",
      getData: async () => {
        return (await ReferenceRecordsDataSource(
          "Employees",
        )) as IComboboxItem[];
      },
    },
    toolbarRow: 1,
  },
  {
    id: "filterRefTypes",
    placeholder: "Filter by Type",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "refTypeId",
      getData: async () => {
        try {
          const result = await getSQLData({ spName: "GetRefTypes" });
          return result[0];
        } catch (e: any) {
          showSomeError(e);
        }
      },
    },
    toolbarRow: 1,
  },
];

const ActionToName: simpleObject = {
  C: "Create",
  D: "Delete",
  E: "Edit",
};

const ActionIdToChipType: simpleObject = {
  C: "success",
  D: "error",
  E: "warning",
};
const ActionsFieldsId = ["CreateAction", "EditAction", "DeleteAction"];
const availableActions: Array<IColumnValue> = [
  {
    Name: "Create",
    Id: "C",
    FieldId: "CreateAction",
    Selected: true,
  },
  {
    Name: "Edit",
    Id: "E",
    FieldId: "EditAction",
    Selected: true,
  },
  {
    Name: "Delete",
    Id: "D",
    FieldId: "DeleteAction",
    Selected: true,
  },
];
const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RecordName",
    title: "Record Name",
    type: "string",
    format: {
      type: "string",
      customFormat: true,
      getCustomFormat(dataItem: simpleObject) {
        if (dataItem.DeleteAction) return dataItem.RecordName;
        return (
          <OpenCardLink
            text={dataItem.RecordName}
            refName={dataItem.ReferenceCode}
            dataAttr={dataItem.RecordID}
          />
        );
      },
    },
  },
  {
    field: "ReferenceTitle",
    title: "Reference Title",
    type: "string",
    format: {
      type: "string",
      fieldId: "ReferenceTitle",
    },
  },
  {
    field: "UserName",
    title: "User Name",
    type: "string",
    format: {
      type: "string",
      fieldId: "UserName",
    },
  },
  {
    field: "ActionsNames",
    title: "Actions",
    type: "string",
    gridWidth: 200,
    values: availableActions,
    format: {
      type: "string",
      fieldId: "Actions",
      customFormat: true,
      getCustomFormat(dataItem) {
        return (
          <div style={{ textAlign: "center" }}>
            {ActionsFieldsId.map((field) => {
              let value = dataItem[field];
              if (!value) return null;
              return (
                <Chip
                  key={value}
                  fillMode="outline"
                  style={{ margin: "0 2px" }}
                  text={ActionToName[value]}
                  themeColor={ActionIdToChipType[value]}
                />
              );
            })}
          </div>
        );
      },
    },
  },
  {
    field: "Min Date",
    title: "Min Date",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
  {
    field: "Max Date",
    title: "Max Date",
    type: "date",
    gridWidth: 120,
    format: {
      type: "datetime",
    },
  },
];

interface props {
  isActive: boolean;
}

class ActionsHistory extends BaseComponent<props, {}> {
  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        isParentPage={true}
        columnsSettings={COLUMNS}
        filters={FILTERS}
        getData={this.LoadGridData}
        excelFileName="ActionsHistory.xlsx"
        getRowKey={(dataItem: simpleObject) => dataItem.RecordID}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        onRowContextMenu={this.OnRowContextMenu}
        initRefresh={this.InitRefresh}
        isValidFilters={this.IsValidFilters}
      ></DashboardGrid>
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_ActionsHistory",
      params: filters,
    });
    const data: simpleObject[] = result[0];
    for (let item of data) {
      let names = "";
      const { CreateAction, EditAction, DeleteAction } = item;
      if (CreateAction) names += ActionToName[CreateAction] + " ";
      if (EditAction) names += ActionToName[EditAction] + " ";
      if (DeleteAction) names += ActionToName[DeleteAction] + " ";
      item.ActionsNames = names;
    }
    return data;
  };

  IsValidFilters = (params: IFiltersValue) => {
    let dateFrom = moment(params.dateFrom);
    let dateTo = moment(params.dateTo);
    let diff = dateTo.diff(dateFrom, "days");
    if (diff > 31 && !params.employeeId && !params.refTypeId) {
      ModalRef.showDialog({
        title: "Attention",
        text: "Apply filter by Employee/Type to see the data for more than 31 days period",
      });
      return false;
    }
    return true;
  };

  OnRowContextMenu = (dataItem: simpleObject) => {
    CardManagement.OpenRecordHistoryCard(
      dataItem.RecordID,
      dataItem.RecordName,
    );
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default ActionsHistory;
