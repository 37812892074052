import React, { Fragment } from "react";
import { simpleObject } from "../../../helpers/interfaces";
import { GetEmptyPromise } from "../../../core/tools/helpers";

export class CardsStackRef {
  static ref: any;
  static initializedPromise = GetEmptyPromise();

  static setRef(ref: any) {
    this.ref = ref;
    this.initializedPromise.resolve();
  }

  static async checkRefInit() {
    if (this.ref === undefined) await this.initializedPromise.promise;
  }

  static async showCard(card: any, params: simpleObject) {
    await this.checkRefInit();
    this.ref.showCard(card, params);
  }

  static async hideCard(id: string) {
    await this.checkRefInit();
    this.ref.hideCard(id);
  }

  static async closeAllCards() {
    await this.checkRefInit();
    this.ref.closeAllCards();
  }
}

interface ICard {
  el: any;
  id: number;
}

interface state {
  cards: Array<ICard>;
}

export class CardsStack extends React.Component<{}, state> {
  constructor(props: {}) {
    super(props);
    this.state = {
      cards: [],
    };
  }

  componentDidMount() {
    CardsStackRef.setRef(this);
  }

  render() {
    if (!this.state.cards.length) return null;
    return (
      <>
        {this.state.cards.map((card) => (
          <Fragment key={card.id}>{card.el}</Fragment>
        ))}
      </>
    );
  }

  showCard = (Component: any, params: simpleObject = {}) => {
    const cards = this.state.cards;
    const cardId = +new Date();
    cards.push({
      id: cardId,
      el: (
        <Component
          {...params}
          finally={() => {
            this.hideCard(cardId);
            if (params.finally) params.finally();
          }}
        />
      ),
    });
    if (cards.length === 1) {
      document.addEventListener("keydown", this.ESCEvent);
    }
    this.setState({ cards: [...cards] });
  };

  hideCard = (cardId: number) => {
    let cards = this.state.cards;
    let index = cards.findIndex((card) => card.id === cardId);
    if (index > -1) cards.splice(index, 1);
    if (cards.length === 0) {
      document.removeEventListener("keydown", this.ESCEvent);
    }
    this.setState({ cards });
  };

  closeAllCards = () => {
    document.removeEventListener("keydown", this.ESCEvent);
    this.setState({ cards: [] });
  };

  ESCEvent = (e: any) => {
    if (e.which === 27) {
      const lastCard = this.state.cards[this.state.cards.length - 1];
      if (lastCard) {
        this.hideCard(lastCard.id);
      }
    }
  };
}
