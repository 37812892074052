import { PropsSQLDBTKReviewRequest } from "../../core/api/generated/conterra";
import { INewComboboxItem } from "../../helpers/interfaces";

export enum ETCPerformAction {
  ChangeTime = "ChangeTime",
  Reassign = "Reassign",
  Reject = "Reject",
  Complete = "Complete",
  Confirm = "Confirm",
  NoChanges = "NoChanges",
}

export interface IFilters {
  ["resourceId"]?: INewComboboxItem | null;
  ["reviewerId"]?: INewComboboxItem | null;
  ["periodId"]?: INewComboboxItem | null;
  ["dispatchId"]?: INewComboboxItem | null;
  ["reviewStateId"]?: INewComboboxItem | null;
  ["departmentId"]?: INewComboboxItem | null;
  ["employeeClassId"]?: INewComboboxItem | null;
  ["date"]?: Date | null;
  ["finishDate"]?: Date | null;
}

export type TKFilterKey =
  (typeof PropsSQLDBTKReviewRequest)[keyof typeof PropsSQLDBTKReviewRequest];

export interface IDispatchDataFilter {
  dispatch?: INewComboboxItem | null;
  resource?: INewComboboxItem | null;
  date?: Date | null;
  finishDate?: Date | null;
}

export type onFilterChange = (
  value: INewComboboxItem | null,
  name: TKFilterKey
) => void;
