import React from "react";

export function stopPropagationWrapper(callback: () => unknown) {
  return (event: {
    stopPropagation?: () => void;
    syntheticEvent?: React.MouseEvent<any>;
  }) => {
    if (event.stopPropagation) event.stopPropagation();
    else if (event.syntheticEvent) {
      event.syntheticEvent.stopPropagation();
    }
    callback();
  };
}
