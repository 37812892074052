import React, { useCallback, useMemo } from "react";
import moment from "moment/moment";
import formStyles from "../card.module.scss";
import {
  MaterialItemCardDto,
  UpdateMaterialItemDto,
} from "../../../core/api/generated/warehouse";
import { IRenderToolbarProps } from "../../TabsNew/interfaces";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { IsWarehouseManager } from "../../../core/tools/roles";
import api from "../../../core/api/api";
import FilterCombobox from "../../Common/Form/FilterCombobox";
import { useBooleanState } from "../../../core/tools/Hooks";
import LoaderComponent from "../../Common/Loader";
import { showSomeError } from "../../../helpers/errorHelpers";

export const MaterialItemCardGeneral = (props: {
  data: MaterialItemCardDto;
  renderToolbar(props?: IRenderToolbarProps): JSX.Element;
  refresh(): void;
}) => {
  const { data, refresh, renderToolbar } = props;
  const {
    material: { id: materialId },
    vendor: { id: vendorId, name: vendorName },
  } = data;
  const isEditable = IsWarehouseManager();
  const isProcessing = useBooleanState();

  const EditMIField = useCallback(
    async (updateInfo: UpdateMaterialItemDto) => {
      try {
        isProcessing.setTrue();
        await api.wh.mi.update(data.id, updateInfo);
      } catch (e) {
        showSomeError(e);
      } finally {
        refresh();
        isProcessing.setFalse();
      }
    },
    [data, isEditable],
  );

  const renderReadOnlyValue = useCallback(
    (label: string, value: string, isLargeLabel?: boolean) => (
      <div className={formStyles.Row}>
        <div
          className={formStyles.FormFieldLabel}
          style={isLargeLabel ? { width: 120 } : undefined}
        >
          {label}:
        </div>
        <div>
          <div className={formStyles.ReadOnlyField}>{value}</div>
        </div>
      </div>
    ),
    [],
  );

  const loadVendors = useCallback(async () => {
    return api.sql.whMiAvailableVendors({ materialId });
  }, [materialId]);

  const EditableVendor = useMemo(() => {
    return (
      <div className={formStyles.Row}>
        <div className={formStyles.FormFieldLabel}>Vendor:</div>
        <div>
          <FilterCombobox
            key={"vendor" + data.vendor.id}
            placeholder="Vendor"
            getData={loadVendors}
            value={{ id: vendorId, name: vendorName }}
            required={true}
            onChange={(e) => {
              if (e) {
                EditMIField({
                  isBroken: data.isBroken,
                  sn: data.sn,
                  vendorId: e.id,
                });
              }
            }}
            className={`${formStyles.FormField} ${formStyles.MBO}`}
            dataItemKey={"id"}
            textField={"name"}
          />
        </div>
      </div>
    );
  }, [EditMIField, data]);

  const EditableSN = useMemo(() => {
    return (
      <div className={formStyles.Row}>
        <div className={formStyles.FormFieldLabel}>SN:</div>
        <div>
          <div className={formStyles.ReadOnlyField}>
            <Input
              key={"sn" + data.sn}
              style={{ width: 230 }}
              defaultValue={data.sn}
              required={true}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value !== data.sn) {
                  EditMIField({
                    isBroken: data.isBroken,
                    sn: value,
                    vendorId: data.vendor.id,
                    tag: data.tag,
                    assembly: data.assembly,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [EditMIField, data]);

  const EditableTag = useMemo(() => {
    return (
      <div className={formStyles.Row}>
        <div className={formStyles.FormFieldLabel}>Tag #:</div>
        <div>
          <div className={formStyles.ReadOnlyField}>
            <Input
              key={"tag" + data.tag}
              style={{ width: 230 }}
              defaultValue={data.tag || ""}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value !== (data.tag || "")) {
                  EditMIField({
                    isBroken: data.isBroken,
                    sn: data.sn,
                    vendorId: data.vendor.id,
                    tag: value,
                    assembly: data.assembly,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [EditMIField, data]);

  const EditableAssembly = useMemo(() => {
    return (
      <div className={formStyles.Row}>
        <div className={formStyles.FormFieldLabel}>Assembly #:</div>
        <div>
          <div className={formStyles.ReadOnlyField}>
            <Input
              key={"sap" + data.assembly}
              style={{ width: 230 }}
              defaultValue={data.assembly || ""}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value !== (data.assembly || "")) {
                  EditMIField({
                    isBroken: data.isBroken,
                    sn: data.sn,
                    vendorId: data.vendor.id,
                    tag: data.tag,
                    assembly: value,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [EditMIField, data]);

  return (
    <>
      {renderToolbar()}
      <div style={{ padding: 12 }}>
        {isProcessing.value && <LoaderComponent />}
        <div
          className={`${formStyles.Row}`}
          style={{ alignItems: "flex-start", gap: 12 }}
        >
          <div className={formStyles.RowCell}>
            <div
              className={formStyles.Row}
              style={{
                alignItems: "flex-start",
                padding: "5px 0",
              }}
            >
              <div className={formStyles.FormFieldLabel}>Material:</div>
              <div>
                <div
                  className={formStyles.ReadOnlyField}
                  style={{
                    padding: 0,
                    minHeight: "auto",
                  }}
                >
                  {data.material.name}
                </div>
              </div>
            </div>
            {isEditable
              ? EditableSN
              : renderReadOnlyValue("SN", data.sn, false)}
            {isEditable
              ? EditableTag
              : renderReadOnlyValue("Tag #", data.tag || "", true)}
            {isEditable
              ? EditableAssembly
              : renderReadOnlyValue("Assembly #", data.assembly || "", true)}
            {renderReadOnlyValue("Stage", data.stage.title, false)}
            {renderReadOnlyValue("Package", data.shipmentPackage.name, false)}
            {renderReadOnlyValue(
              "Warehouse",
              data.warehouse?.name || "",
              false,
            )}
            {renderReadOnlyValue("Area", data.warehouseArea?.name || "", false)}
            {renderReadOnlyValue(
              "Created",
              moment(data.createdAt).format("L"),
              false,
            )}
            <div className={formStyles.Row}>
              <div className={formStyles.FormFieldLabel}>Broken:</div>
              <div>
                <div className={formStyles.ReadOnlyField}>
                  <Checkbox
                    value={data.isBroken}
                    disabled={!isEditable}
                    size={"large"}
                    onChange={(e) => {
                      EditMIField({
                        isBroken: e.value,
                        sn: data.sn,
                        vendorId: data.vendor.id,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={formStyles.RowCell}>
            {isEditable
              ? EditableVendor
              : renderReadOnlyValue("Vendor", data.vendor.name, true)}
            {renderReadOnlyValue(
              "Vendor Part NO",
              data.vendorPartNo || "",
              true,
            )}
            {renderReadOnlyValue(
              "MFG Product #",
              data.mfgProductNo || "",
              true,
            )}
            {renderReadOnlyValue(
              "Build Plan",
              data.buildPlan?.name || "",
              true,
            )}
            {renderReadOnlyValue("Site", data.site?.name || "", true)}
            {renderReadOnlyValue("BOM Number", data.bom?.number || "", true)}
            {renderReadOnlyValue(
              "BOM Date",
              data.bom?.date ? moment(data.bom.date).format("L") : "",
              true,
            )}
            {renderReadOnlyValue(
              "BOM Due Date",
              data.bom?.dueDate ? moment(data.bom.dueDate).format("L") : "",
              true,
            )}
          </div>
        </div>
      </div>
    </>
  );
};
