import { ModalRef } from "../Components/Common/Modal/Modal";
import { IGetSQLDataParams, simpleObject } from "./interfaces";
import api from "../core/api/api";
import { showSomeError } from "./errorHelpers";

export async function getSQLData(params: IGetSQLDataParams) {
  try {
    if (params.modal) ModalRef.startProcessing();
    return await api.getSqlData(params.spName, params.params);
  } finally {
    if (params.modal) ModalRef.stopProcessing();
  }
}

export async function ReferenceRecordsDataSource(
  refName: string,
  params: simpleObject = {},
) {
  // by default server return activeOnly === true
  try {
    const result = await getSQLData({
      spName: "DB_SelectRefRecords",
      params: { refName, ...params },
    });
    return result[0];
  } catch (e) {
    showSomeError(e);
    return [];
  }
}

export async function GetReferenceRecordName(recordId: number) {
  try {
    const result = await getSQLData({
      spName: "DB_SelectRefRecords",
      params: { recordId },
    });
    return (result[0] && result[0][0] && result[0][0].Name) || "No Name";
  } catch {
    return "No Name";
  }
}
