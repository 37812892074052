import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Dispatch, memo, useCallback, useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { settingsStorage } from "../../../helpers/settings";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import "./CalendarSelectFilter.scss";

type Props = {
  filterName: string;
  onSelect: Dispatch<number[]>;
  items: ComboBoxItem[];
  placeholder?: string;
};

export type ComboBoxItem = {
  key: number;
  value: string;
};

const CalendarFilterCmp = ({
  filterName,
  onSelect,
  items,
  placeholder,
}: Props) => {
  const [data, setData] = useState<ComboBoxItem[]>([]);
  const [selected, setSelected] = useState<ComboBoxItem[]>([]);

  useEffect(() => {
    const dataItems = items.slice();

    setData(dataItems);
    const filterStringValue = settingsStorage.getForCurrentUser(
      `DispatchCalendar_${filterName}`,
    );

    if (filterStringValue) {
      try {
        const values = JSON.parse(filterStringValue) as number[];
        if (values && values.length > 0) {
          const selectedItems = values
            .map((x) => dataItems.find((i) => i.key === x))
            .filter((x) => !!x)
            .map((x) => x as ComboBoxItem);

          if (selectedItems.length > 0) {
            setSelected(selectedItems);
            onSelect(selectedItems.map((x) => x.key));
          }
        }
      } catch {
        //empty
      }
    }
  }, [items, filterName, onSelect]);

  const handleChange = useCallback(
    (e: MultiSelectChangeEvent) => {
      const values = e.value as ComboBoxItem[];
      setSelected(values);
      settingsStorage.setForCurrentUser(
        `DispatchCalendar_${filterName}`,
        JSON.stringify(values.map((x) => x.key)),
      );
      onSelect(values.map((x) => x.key));
    },
    [filterName, onSelect],
  );

  const filterChange = (e: MultiSelectFilterChangeEvent) => {
    setData(filterBy(items.slice(), e.filter));
  };

  return (
    <MultiSelect
      autoClose={false}
      data={data}
      filterable={true}
      textField="value"
      dataItemKey="key"
      value={selected}
      onChange={handleChange}
      onFilterChange={filterChange}
      placeholder={placeholder}
      fillMode="flat"
      suffix={<SvgIcon icon={caretAltDownIcon} />}
      className="calendar-select-filter"
      popupSettings={{
        popupClass: "calendar-select-filter-popup",
      }}
    />
  );
};

export const CalendarSelectFilter = memo(CalendarFilterCmp);
