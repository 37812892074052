import { useCalendarContext } from "../CalendarContext";
import { useMemo } from "react";
import { calcWeekDays, calcWeekLine, getFirstDayOfPeriod } from "../utils";
import { clsx } from "clsx";
import { CalendarDay } from "../CalendarDay";
import styles from "../calendar.module.scss";

export const CalendarWeekView = () => {
  const ctx = useCalendarContext();

  const weekLine = useMemo(
    () => calcWeekLine(ctx.firstDayOfWeek),
    [ctx.firstDayOfWeek],
  );
  const weekDays = useMemo(
    () =>
      calcWeekDays(
        getFirstDayOfPeriod(ctx.selectedDate, "week", ctx.firstDayOfWeek),
        ctx.firstDayOfWeek,
      ),
    [ctx.selectedDate, ctx.firstDayOfWeek],
  );

  return (
    <div className={styles.CalendarWeekViewContainer}>
      <div className={styles.CalendarWeekView}>
        <table className={styles.CalendarWeekViewTable}>
          <thead>
            <tr>
              <th>W</th>
              {weekLine.map((x, i) => (
                <th key={x.id}>
                  {x.title} {weekDays[i].date.format("M/D")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{weekDays[0].weekNumber}</td>
              {weekDays.map((day) => (
                <td
                  key={day.date.valueOf()}
                  className={clsx(
                    styles.CalendarWeekViewCell,
                    !day.isInCurrentPeriod &&
                      styles.CalendarWeekViewCellDisabled,
                    day.isToday && styles.CalendarWeekViewCellToday,
                  )}
                >
                  {day.isInCurrentPeriod ? (
                    <div className={styles.CalendarWeekViewDay}>
                      <div
                        className={styles.CalendarWeekViewDayTop}
                        title={day.date.format("LL")}
                      >
                        {day.date.date()}
                      </div>
                      <CalendarDay day={day} />
                    </div>
                  ) : null}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
