import React from "react";
import { simpleObject } from "../../helpers/interfaces";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import DashboardGrid from "../../Components/Dashboard/Grid";
import BaseComponent from "../../Components/BaseComponent";
import api from "../../core/api/api";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { ModalRef } from "../../Components/Common/Modal/Modal";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { SQL_WH_MI_AvailableToAssignToBP_Response } from "../../core/api/generated/conterra";
import { showSomeError } from "../../helpers/errorHelpers";

interface IProps {
  bpId?: number;
  onSuccess(): void;
}
interface IState {
  selectedCount: number;
  selectedBPId: number | undefined;
}

export default class AssignMaterialItemsToBP extends BaseComponent<
  IProps,
  IState
> {
  gridRef: any = React.createRef();
  COLUMNS: IColumnSetting[];
  selectedIds: number[] = [];
  filters: IFilterSetting[] = !this.props.bpId
    ? [
        {
          id: "filterBuildPlan",
          placeholder: "Select Build Plan",
          type: "combobox",
          width: 300,
          serverFilter: {
            param: "buildPlanId",
            getData: async () => {
              const result = await api.sql.whMiAvailableBuildPlans();
              return result.map(({ id: Id, name: Name }) => ({
                Id,
                Name,
              }));
            },
          },
          toolbarRow: 1,
        },
      ]
    : [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedCount: 0,
      selectedBPId: undefined,
    };
    this.COLUMNS = [
      {
        field: "checked",
        title: " ",
        type: "string",
        filterable: false,
        columnMenu: false,
        sortable: false,
        format: {
          type: "string",
          customFormat: true,
          getCustomFormat: this.renderColumnCheck,
        },
        gridWidth: 40,
      },
      {
        field: "stageTitle",
        title: "Stage",
        type: "string",
        gridWidth: 100,
      },
      {
        field: "warehouseName",
        title: "Warehouse",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "warehouseId",
        },
      },
      {
        field: "warehouseAreaNumber",
        title: "Area",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "warehouseAreaId",
        },
      },
      {
        field: "materialName",
        title: "Material",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "materialId",
        },
      },
      {
        field: "sn",
        title: "SN",
        type: "string",
        gridWidth: 120,
      },
      {
        field: "createdAt",
        title: "Created",
        type: "date",
        format: {
          type: "date",
        },
      },
      {
        field: "daysPast",
        title: "Days Past",
        type: "number",
        gridWidth: 90,
      },
      {
        field: "buildPlanName",
        title: "Build Plan",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "buildPlanId",
        },
      },
      {
        field: "shipmentPackageNumber",
        title: "Shipment Package",
        type: "string",
        gridWidth: 150,
        format: {
          type: "string",
          fieldId: "shipmentPackageId",
        },
      },
      {
        field: "",
        title: "Vendor",
        gridWidth: 300,
        type: "parent",
        columns: [
          {
            field: "vendorName",
            title: "Name",
            type: "string",
            gridWidth: 150,
            format: {
              type: "string",
              fieldId: "vendorId",
            },
          },
          {
            field: "vendorPartNo",
            title: "Part #",
            type: "string",
            gridWidth: 120,
          },
          {
            field: "mfgProductNo",
            title: "MFG Product #",
            type: "string",
            gridWidth: 120,
          },
        ],
      },
    ];
  }

  render() {
    return (
      <DashboardGrid
        isActive={true}
        ref={this.gridRef}
        getData={this.LoadGridData}
        excelFileName="MaterialItems.xlsx"
        initRefresh={this.InitRefresh}
        columnsSettings={this.COLUMNS}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        filters={this.filters}
        getRowKey={(dataItem: simpleObject) => dataItem.Id}
        toolbar={{
          isSingleRowToolbar: true,
          switcher: this.renderSelectedItemsCounter(),
          actionBtns: (
            <Button
              onClick={this.AssignToBuildPlan}
              disabled={!this.state.selectedCount || !this.state.selectedBPId}
            >
              Assign Items to Build Plan
            </Button>
          ),
        }}
      />
    );
  }

  renderSelectedItemsCounter = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <span>Selected Items:</span>
        <Chip
          text={this.state.selectedCount + ""}
          style={{
            width: 35,
            textAlign: "center",
            fontSize: 12,
            marginRight: 8,
          }}
        />
      </div>
    );
  };

  renderColumnCheck = ({ id }: SQL_WH_MI_AvailableToAssignToBP_Response) => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all" }}>
        <Checkbox
          key={id}
          id={id + "check"}
          data-id={id}
          label={" "}
          defaultChecked={
            this.selectedIds.findIndex((selectedId) => selectedId === id) > -1
          }
          onChange={this.OnChangeCheck}
        />
      </div>
    );
  };

  OnChangeCheck = (e: CheckboxChangeEvent) => {
    const { value: checked, element } = e.target;
    const Id = element?.dataset.id;
    if (checked) {
      this.selectedIds.push(+Id!);
    } else {
      this.selectedIds = this.selectedIds.filter((id) => id !== +Id!);
    }
    this.setState({ selectedCount: this.selectedIds.length });
  };

  AssignToBuildPlan = async () => {
    const { selectedBPId } = this.state;
    if (!selectedBPId && !this.selectedIds.length) {
      ModalRef.showDialog({
        title: "Error",
        type: "error",
        text: "Select Build Blan and at least 1 Item.",
      });
      return;
    }
    try {
      ModalRef.startProcessing();
      await api.wh.mi.setBuildPlan({
        materialItemIds: this.selectedIds,
        buildPlanId: selectedBPId,
      });
      this.props.onSuccess();
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
      this.Refresh();
      this.selectedIds = [];
      this.setState({ selectedCount: 0 });
    }
  };

  LoadGridData = async (filters: simpleObject) => {
    this.selectedIds = [];
    this.setState({
      selectedBPId: filters.buildPlanId || this.props.bpId,
      selectedCount: 0,
    });
    if (!filters.buildPlanId && !this.props.bpId) return [];
    return api.sql.whMiAvailableToAssignToBp({
      buildPlanId: this.props.bpId || filters.buildPlanId,
    });
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}
