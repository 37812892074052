import { DayData, DispatchStatuses } from "./models";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useCalendarContext } from "./CalendarContext";
import { clsx } from "clsx";
import { Collapsible, CollapsibleRef } from "./Collapsible";
import CardManagement from "../../Components/Cards/CardManagement";
import { SQL_DispatchCalendar_PeriodData_Response_Dispatches } from "../../core/api/generated/conterra";
import { useCalendarTooltipContext } from "./CalendarTooltipContext";
import { filterDispatches } from "./utils";
import styles from "./calendar.module.scss";

const DispatchStatusToClassMap: Map<string, string> = new Map([
  [DispatchStatuses.Scheduled, styles.Scheduled],
  [DispatchStatuses.Published, styles.Published],
  [DispatchStatuses.Completed, styles.Completed],
  [DispatchStatuses.Cancelled, styles.Cancelled],
]);

const CalendarDayCmp = ({ day }: { day: DayData }) => {
  const ctx = useCalendarContext();
  const ctxTooltip = useCalendarTooltipContext();
  const refs = useRef<CollapsibleRef[]>([]);

  const getResourceName = useCallback(
    (resourceId: number) => ctx.data.common?.resources.get(resourceId),
    [ctx.data.common?.resources],
  );
  const getReasonName = useCallback(
    (reasonId: number) => ctx.data.common?.reasons.get(reasonId),
    [ctx.data.common?.reasons],
  );

  const handleEventClick = useCallback(
    (dispatchId: number) => {
      CardManagement.OpenDispatchCard({
        newDispatch: false,
        dsId: dispatchId,
        afterSave: ctx.data.refresh,
      });
    },
    [ctx.data.refresh],
  );

  const handlePublishChecked = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, dispatchId: number) => {
      if (e.target.checked) {
        ctx.selectedDispatches.add(dispatchId);
      } else {
        ctx.selectedDispatches.remove(dispatchId);
      }
    },
    [ctx],
  );

  const handleContextMenu = useCallback(
    (
      e: React.MouseEvent<HTMLElement>,
      dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches,
    ) => {
      e.preventDefault();
      ctx.contextMenu.open(dispatch, day.date, {
        x: e.clientX,
        y: e.clientY,
      });
    },
    [ctx, day.date],
  );

  const onMouseEnter = useCallback(
    (dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches) => {
      ctxTooltip.hoverDispatch({
        dispatch: dispatch,
        date: day.date,
      });
    },
    [ctxTooltip, day.date],
  );

  const onMouseLeave = useCallback(
    () => ctxTooltip.hoverDispatch(null),
    [ctxTooltip],
  );

  const dayEvent = useMemo(
    () => ctx.data.eventsByDate.get(day.date.format("YYYY-MM-DDT00:00:00")),
    [ctx.data.eventsByDate, day.date],
  );

  const dispatches = useMemo(() => {
    if (!dayEvent) {
      return [];
    }

    return filterDispatches(
      dayEvent.dispatches,
      ctx.buildPlan.buildPlanId === undefined ? ctx.filters : undefined,
    );
  }, [dayEvent, ctx]);

  const available = useMemo(() => {
    if (!dayEvent) {
      return {
        items: [],
        count: 0,
      };
    }

    const items = dayEvent.available
      .filter((x) => {
        return (
          (ctx.filters.resourceIds.length === 0 ||
            x.resources.some((r) =>
              ctx.filters.resourceIds.includes(r.employeeId),
            )) &&
          (ctx.filters.regionIds.length === 0 ||
            ctx.filters.marketIds.length > 0 ||
            ctx.filters.regionIds.some((regionId) => {
              const region = ctx.data.common?.regions.get(regionId);
              return (
                region &&
                x.resources.some((r) =>
                  region.employeeIds.includes(r.employeeId),
                )
              );
            })) &&
          (ctx.filters.marketIds.length === 0 ||
            ctx.filters.marketIds.some((marketId) => {
              const market = ctx.data.common?.markets.get(marketId);
              return (
                market &&
                x.resources.some((r) =>
                  market.employeeIds.includes(r.employeeId),
                )
              );
            }))
        );
      })
      .map((x) => {
        return {
          categoryId: x.categoryId,
          resources: x.resources
            .map((r) => ({
              name: getResourceName(r.employeeId),
              employeeId: r.employeeId,
              isLimited: r.isLimited,
            }))
            .filter((x) => x.name),
        };
      });

    return {
      items,
      count: items
        .map((x) => x.resources.length)
        .reduce((x, sum) => sum + x, 0),
    };
  }, [dayEvent, ctx]);

  const notAvailable = useMemo(() => {
    if (!dayEvent) {
      return [];
    }

    return dayEvent.notAvailable.filter((x) => {
      return (
        ctx.filters.resourceIds.length === 0 ||
        ctx.filters.resourceIds.includes(x.employeeId)
      );
    });
  }, [dayEvent, ctx]);

  const expandOrCollapse = useCallback((expand: boolean) => {
    refs.current.forEach((x) => {
      if (expand) {
        x.expand();
      } else {
        x.collapse();
      }
    });
  }, []);

  useEffect(() => {
    const listener = ctx.collapsible.subscribe(expandOrCollapse);
    return () => listener();
  }, [ctx.collapsible, expandOrCollapse]);

  if (!dayEvent) return null;

  return (
    <div className={styles.CalendarEvents}>
      {dispatches.map((x) => (
        <div
          className={clsx(
            styles.CalendarEvent,
            DispatchStatusToClassMap.get(x.status),
            x.isPastChanged &&
              x.status === DispatchStatuses.Published &&
              styles.PastChanged,
          )}
          style={{ borderColor: x.borderColor || undefined }}
          key={x.id}
          id={`dispatch_${x.id}`}
          onClick={() => handleEventClick(x.id)}
          onContextMenu={(e) => handleContextMenu(e, x)}
          onMouseEnter={() => onMouseEnter(x)}
          onMouseLeave={onMouseLeave}
        >
          <Collapsible
            tooltip="Show/Hide Resources"
            expandedByDefault={ctx.mode === "week"}
            ref={(ref) => {
              if (ref) {
                refs.current.push(ref);
              }
            }}
            titleContent={
              <div className={styles.CalendarEventTitle}>
                {x.status === DispatchStatuses.Scheduled &&
                x.isPublishAllowed ? (
                  <input
                    type="checkbox"
                    className={styles.PublishCheckbox}
                    checked={ctx.selectedDispatches.dispatches.includes(x.id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handlePublishChecked(e, x.id)}
                  />
                ) : null}
                <div>
                  {x.allowOvernight ? (
                    <span
                      className={clsx(
                        "mdi mdi-weather-night",
                        styles.OvernightIcon,
                      )}
                    ></span>
                  ) : null}
                  {x.isImportant ? (
                    <span
                      className={clsx(
                        "mdi mdi-exclamation-thick",
                        styles.ExclamationIcon,
                      )}
                    ></span>
                  ) : null}
                  {x.matchesCertificationRequirements === false ? (
                    <span
                      className={clsx(
                        "mdi mdi-license",
                        styles.CertificateIcon,
                      )}
                    ></span>
                  ) : null}
                  {x.hasPendingResults ? (
                    <span
                      className={clsx(
                        "mdi mdi-camera-outline",
                        styles.CameraIcon,
                      )}
                    ></span>
                  ) : null}
                  {x.title}
                </div>
              </div>
            }
          >
            <div className={styles.CalendarEventContent}>
              {x.resources.map((resource) => (
                <div key={resource.employeeId}>
                  <span
                    className={clsx(resource.isBusy && styles.BusyResource)}
                  >
                    {getResourceName(resource.employeeId)}{" "}
                  </span>
                  {resource.dayHours || "-"} ({resource.periodHours})
                </div>
              ))}
            </div>
          </Collapsible>
        </div>
      ))}

      {available.count > 0 ? (
        <div className={clsx(styles.CalendarEvent, styles.Available)}>
          <Collapsible
            titleContent={
              <div className={styles.CalendarEventTitle}>
                ({available.count}) Available
              </div>
            }
            clickOnWholeTitle={true}
            ref={(ref) => {
              if (ref) {
                refs.current.push(ref);
              }
            }}
          >
            <div className={styles.CalendarEventContent}>
              {available.items.map((x, iCategory) => {
                return x.resources.map((resource, iResource) => (
                  <div key={resource.employeeId}>
                    {iCategory > 0 && iResource === 0 ? <br /> : null}
                    {resource.isLimited ? (
                      <span
                        className={styles.Limited}
                        title="Resourse Restricted from regular activities due to expired certifications or injury. Please contact HR Manager before scheduling."
                      >
                        !
                      </span>
                    ) : null}
                    {resource.name}
                  </div>
                ));
              })}
            </div>
          </Collapsible>
        </div>
      ) : null}

      {notAvailable.length > 0 && (
        <div className={clsx(styles.CalendarEvent, styles.NotAvailable)}>
          <div className={styles.CalendarEventTitle}>
            ({notAvailable.length}) Not Available
          </div>
          <div className={styles.CalendarEventContent}>
            {notAvailable.map((x) => (
              <div key={x.employeeId}>
                {getResourceName(x.employeeId)} ({getReasonName(x.reasonId)}){" "}
                {x.dayHours || "-"} ({x.periodHours})
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export const CalendarDay = memo(CalendarDayCmp);
