const isProduction = process.env.REACT_APP_ENV === "production";

const config = {
  SEQ_URL: "https://seq.fieldclix.com",
  MAP_QUEST_KEY: "SoMm2tfHQ6fueiRsj6gfFMhGiIA2BM4r",
  API_URL: isProduction
    ? "https://api.fieldclix.com/"
    : "https://api.dev.fieldclix.com/",
  SEQ_KEY: isProduction ? "2AMmahQudyoRdlnRoWpu" : "tMuQHgghjzryuUSRefs0",
  AG_GRID_KEY:
    "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-075532}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Fieldclix}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Fieldclix}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Fieldclix}_need_to_be_licensed___{Fieldclix}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{22_January_2026}____[v3]_[0102]_MTc2OTA0MDAwMDAwMA==9afbcd2b18b9340435f1b7835ff9f70d",
  CUSTOM_API_URL: {
    ironbo: "https://api.fieldclix.ironbo.com/",
    merge: "https://api.dev.fieldclix.com/",
  } as Record<string, string>,
};

export default config;
