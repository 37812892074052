import React from "react";
import {getSQLData} from "../helpers/queries";
import {IGetSQLDataParams} from "../helpers/interfaces";

export default class BaseComponent<
    T = {},
    S = {},
    SS = {}
> extends React.Component<T, S, SS> {
    abortController = new AbortController();

    constructor(props: T) {
        super(props);
    }

    componentWillUnmount() {
        this.CancelPrevRequestUpdateToken();
    }

    GetSQLData = (params: IGetSQLDataParams) => {
        return getSQLData(params);
    };

    CancelPrevRequestUpdateToken = () => {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
    };
}
