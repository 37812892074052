import React from "react";
import DashboardGrid from "../Dashboard/Grid";
import { IColumnSetting } from "../Dashboard/interfaces";
import { RunScriptAsync } from "../../helpers/runscripts";
import moment from "moment";
import { simpleObject } from "../../helpers/interfaces";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import { ModalRef } from "../Common/Modal/Modal";
import BaseComponent from "../BaseComponent";
import { showSomeError } from "../../helpers/errorHelpers";

interface IProps {
  buildPlanId?: number;
  woId?: number;
  isActive?: boolean;
  doNotSaveFilters?: boolean;

  onChangeGridData?(data: Array<simpleObject>): void;
}

class BPHistory extends BaseComponent<IProps> {
  gridRef: any = React.createRef();
  isWOCard = !!this.props.woId;
  objectId: number | string | undefined = this.isWOCard
    ? this.props.woId
    : this.props.buildPlanId;
  COLUMNS: IColumnSetting[] = [
    {
      field: "ServerTime",
      title: "Time",
      type: "date",
      filterable: false,
      gridWidth: 125,
      columnMenu: false,
      format: {
        type: "datetime",
        customFormat: true,
        getCustomFormat: ({ ServerTime }) =>
          ServerTime && moment.utc(ServerTime).local().format("MM/DD/YY LT"),
      },
    },
    {
      field: "EmployeeName",
      title: "User",
      type: "string",
      gridWidth: 150,
      format: {
        type: "link",
        fieldId: "EmployeeId",
        refName: "Employees",
      },
    },
    {
      field: "EventType",
      title: "Event Type",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
        fieldId: "EventType",
      },
    },
    {
      field: "Details",
      title: "Details",
      type: "string",
      minWidth: 230,
    },
    {
      field: "WorkOrderName",
      title: "Work Order",
      type: "string",
      gridWidth: 100,
      format: {
        type: "link",
        fieldId: "WorkOrderId",
        refName: "FSMWorkOrders",
      },
      hiddenColumn: !this.isWOCard,
    },
  ];

  render() {
    if (this.isWOCard && !this.objectId) return null;
    if (
      !this.isWOCard &&
      this.props.isActive &&
      this.objectId !== this.props.buildPlanId
    ) {
      this.objectId = this.props.buildPlanId;
    }
    if (
      (this.isWOCard && !this.objectId) ||
      (!this.isWOCard &&
        !this.props.isActive &&
        this.objectId !== this.props.buildPlanId)
    )
      return null;

    return (
      <DashboardGrid
        ref={this.gridRef}
        isActive={
          this.props.isActive === undefined ? true : this.props.isActive
        }
        key={this.props.buildPlanId || this.props.woId || this.objectId}
        columnsSettings={this.COLUMNS}
        getData={this.LoadGridData}
        excelFileName="Build Plan History.xlsx"
        initRefresh={this.InitRefresh}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        toolbar={{
          actionBtns: !this.isWOCard ? (
            <Button onClick={this.ShowDialog}>New Notes</Button>
          ) : undefined,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_BuildPlanHistory",
      params: { objectId: this.objectId, ...filters },
    });
    const data = result[0];
    data.sort((itemA: simpleObject, itemB: simpleObject) =>
      moment(itemA.ServerTime).isBefore(moment(itemB.ServerTime)) ? -1 : 1,
    );
    return data;
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };

  ShowDialog = () => {
    let notesTextRef: any;
    ModalRef.showDialog({
      title: "Notes",
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: () => {
            let notesText = notesTextRef.element.current.value;
            if (notesText) {
              ModalRef.hideDialog();
              this.AddNotes(notesText);
            }
          },
        },
      ],
      children: (
        <TextArea
          ref={(ref) => {
            notesTextRef = ref;
            if (ref && ref.focus) {
              ref?.focus();
            }
          }}
          rows={5}
          style={{ width: "100%" }}
        />
      ),
    });
  };

  AddNotes = (notesText: string) => {
    RunScriptAsync("FSMBuildPlans_AddNotes", {
      BPID: this.props.buildPlanId,
      Notes: notesText,
    })
      .catch(showSomeError)
      .finally(this.Refresh);
  };
}

export default BPHistory;
