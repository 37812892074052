import axios, { AxiosError } from "axios";
import { inject, injectable } from "inversify";
import config from "../../config";
import DI_TYPES from "../inversify/diTypes";
import type {
  IAuthInfo,
  IAuthProvider,
  IDeviceInfoProvider,
  ILastLogin,
  ILastUserService,
  ILogger,
  ILoginCredentials,
  ISettingsStorage,
  ITokens,
  IUserIdentity,
} from "../inversify/interfaces";
import { GetEmptyPromise } from "../tools/helpers";
import { IPromiseActions } from "../tools/interfaces";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import EventEmitter2, { Listener, ListenerFn } from "eventemitter2";
import { ApiNetworkError, transformApiError } from "./apiExceptions";
import { useSyncExternalStore } from "react";

const STORAGE_KEY_AUTH_INFO = "FCX_AuthInfo";
const STORAGE_KEY_LAST_LOGINS = "FCX_LastLogins";
const AUTH_STATUS_CHANGED = "AUTH_STATUS_CHANGED";
const SETTINGS_SERVER_CHANGED = "SETTINGS_SERVER_CHANGED";

type TAccessToken = {
  Roles: string;
  UserId: string;
  InstanceId: string;
  SessionId: string;
  Login: string;
  slu: number;
};

@injectable()
export default class AuthProvider implements IAuthProvider {
  private _refreshPromise?: IPromiseActions;
  private _authInfo: IAuthInfo | undefined;
  private _roles: string[] = [];
  private _allAuthInfos: IAuthInfo[] = [];
  private _isAuthorized: boolean | undefined = undefined;
  private _eventEmitter = new EventEmitter2();

  constructor(
    @inject(DI_TYPES.ILogger) private logger: ILogger,
    @inject(DI_TYPES.ISettingsStorage) private _storage: ISettingsStorage,
    @inject(DI_TYPES.IDeviceInfoProvider)
    private _deviceInfoProvider: IDeviceInfoProvider,
    @inject(DI_TYPES.ILastLoginService)
    private _lastLoginService: ILastUserService,
  ) {
    this._updateFromStorage();
    this._storage.subscribeNotMineChanges(
      STORAGE_KEY_AUTH_INFO,
      this._updateFromStorage,
    );

    const lastUserIdentity = this._lastLoginService.getLastUserIdentity();

    if (lastUserIdentity) {
      this.tryUseLogin(lastUserIdentity);
    }
  }

  public getIsAuthorized = () => this._isAuthorized;

  public getUserId = () => this._authInfo?.UserId;

  public getInstanceId = () => this._authInfo?.InstanceId;

  public getUserIdentity = (): IUserIdentity | undefined =>
    this.getIsAuthorized()
      ? { InstanceId: this.getInstanceId()!, UserId: this.getUserId()! }
      : undefined;

  public getUserIdentityString = () =>
    this.getIsAuthorized()
      ? "instance=" + this.getInstanceId() + "_user=" + this.getUserId()
      : undefined;

  public getRoles = () => this._roles;

  public getSettingsLastUpdate = () => this._authInfo?.slu;

  public getApiUrl = (forInstanceId?: string) => {
    const instanceId = forInstanceId || this._authInfo?.InstanceId;
    const instanceIdKey = instanceId?.trim().toLowerCase() || "";
    return config.CUSTOM_API_URL[instanceIdKey] || config.API_URL;
  };
  public getHeaders = () => {
    return {
      Authorization: `Bearer ${this._authInfo?.authToken}`,
      ...this._getDeviceHeaders(),
    };
  };

  public handleUnauthorized = async () => {
    if (this._refreshPromise) {
      await this._refreshPromise.promise;
    }
    this._refreshPromise = GetEmptyPromise();
    const refreshToken = this._authInfo?.refreshToken;
    try {
      const authInfo = await this._refreshAccessToken();

      this._processNewTokens(authInfo);
    } catch (error: unknown) {
      const transformedError = transformApiError(error);
      if (transformedError instanceof ApiNetworkError) {
        throw error;
      }
      // Do nothing if refresh token was changed, it means another thread/tab/window did it
      if (this._authInfo && refreshToken === this._authInfo.refreshToken) {
        this._deleteAuthInfo(this._authInfo);
      }
    } finally {
      this._refreshPromise.resolve();
    }
  };

  public login = async (source: ILoginCredentials) => {
    const credentials: ILoginCredentials = {
      instanceId: source.instanceId.trim().toLowerCase(),
      login: source.login.trim(),
      password: source.password.trim(),
    };
    const axiosInstance = this._getAxiosInstance(source.instanceId);
    try {
      const response = await axiosInstance.post<ITokens>("Auth", credentials);
      const authInfo = this._processNewTokens(response.data);
      if (response.status !== 200) {
        this.logger.verbose(
          "Auth Incorrect: {HttpStatus} {HttpMethod} {HttpUrl} {HttpParams}: " +
            " {HttpStatusText}",
          response.status,
          response.config?.method,
          response.config?.url,
          JSON.stringify(response.config),
          response.statusText,
        );
        return "Invalid Credentials";
      }
      this._saveLastSuccessLogin(credentials, authInfo);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const transformedError = transformApiError(error);
        const messageTemplate =
          "{HttpMethod} {HttpStatus} {HttpUrl} {HttpParams}";
        const messageParams = [
          error.config?.method,
          error.response?.status,
          error.config?.url,
          JSON.stringify(error.config?.params),
        ];
        if (transformedError instanceof ApiNetworkError) {
          this.logger.verboseException(
            error,
            "Auth Network Error: " + messageTemplate,
            ...messageParams,
          );
          return "Network Error";
        }
        if (error.response?.status === 403) {
          this.logger.verboseException(
            error,
            "Auth Invalid Credentials: " + messageTemplate,
            ...messageParams,
          );
        } else {
          this.logger.errorException(
            error,
            "Auth Error: " + messageTemplate,
            ...messageParams,
          );
        }
      } else {
        this.logger.errorException(error, "Non Axios exception");
      }
      return "Invalid Credentials";
    }
  };

  public tryUseLogin = (userIdentity: IUserIdentity) => {
    const userAuthInfo = this._allAuthInfos.find(
      (i) =>
        i.InstanceId.toLowerCase() === userIdentity.InstanceId.toLowerCase() &&
        i.UserId === userIdentity.UserId,
    );
    if (userAuthInfo) {
      this._setCurrentAuthInfo(userAuthInfo);
      this._lastLoginService.setLastUserIdentity(userIdentity);
    }
  };

  public getLastLogins(): ILastLogin[] {
    const strLastLogins = this._storage.get(STORAGE_KEY_LAST_LOGINS);
    if (!strLastLogins) {
      return [];
    }

    const lastLogins: ILastLogin[] = JSON.parse(strLastLogins);

    for (const login of lastLogins) {
      login.isAuthorized =
        this._allAuthInfos.findIndex(
          (i) =>
            i.InstanceId.toLowerCase() === login.InstanceId.toLowerCase() &&
            i.UserId === login.UserId,
        ) > -1;
    }
    return lastLogins;
  }

  public logout = () => {
    if (this._authInfo) {
      this._deleteAuthInfo(this._authInfo);
    }
  };

  public subscribeAuthorizationChange = (listenerFn: ListenerFn) => {
    const listener = this._eventEmitter.on(AUTH_STATUS_CHANGED, listenerFn, {
      objectify: true,
    }) as Listener;
    return () => listener.off();
  };

  public subscribeSettingsLastUpdateChange = (listenerFn: ListenerFn) => {
    const listener = this._eventEmitter.on(
      SETTINGS_SERVER_CHANGED,
      listenerFn,
      {
        objectify: true,
      },
    ) as Listener;
    return () => listener.off();
  };

  public useIsAuthorized = () => {
    return (
      useSyncExternalStore(
        this.subscribeAuthorizationChange,
        this.getIsAuthorized,
      ) || false
    );
  };

  private _getAxiosInstance = (instanceId: string) => {
    return axios.create({
      baseURL: this.getApiUrl(instanceId),
      ...{
        headers: {
          "Content-Type": "application/json",
          ...this._getDeviceHeaders(),
        },
      },
    });
  };

  private _setCurrentAuthInfo = (authInfo?: IAuthInfo) => {
    this._authInfo = authInfo;
    this.logger.setAuthInfo(authInfo);
    this._storage.setAuthInfo(authInfo);
    this._isAuthorized = !!this._authInfo;

    if (authInfo) {
      const roles = jwtDecode<TAccessToken>(authInfo.authToken).Roles;
      this._roles = roles.split(",");
    }

    this._eventEmitter.emit(AUTH_STATUS_CHANGED);
  };

  private _updateFromStorage = () => {
    const allAuthInfosStr = this._storage.get(STORAGE_KEY_AUTH_INFO);
    // TODO filter by expired
    let authInfosFromStorage: IAuthInfo[] = [];
    if (allAuthInfosStr) {
      try {
        const parsedJSON = JSON.parse(allAuthInfosStr);
        if (Array.isArray(parsedJSON)) {
          parsedJSON.forEach((element) => {
            const ownPropNames = Object.getOwnPropertyNames(element);
            const authInfoProps: Array<keyof IAuthInfo> = [
              "UserId",
              "InstanceId",
              "SessionId",
              "Login",
              "authToken",
              "refreshToken",
            ];
            authInfoProps.forEach((propName) => {
              if (!ownPropNames.includes(propName)) {
                throw new Error(
                  `Auth Info from storage is not valid, no property "${propName}"`,
                );
              }
            });
          });
          authInfosFromStorage = parsedJSON;
        } else {
          this.logger.fatal("Auth Info from storage is not an Array");
        }
      } catch (e) {
        this.logger.fatalException(e);
      }
    }
    this._allAuthInfos = authInfosFromStorage.filter((info) => {
      const decodedAuthToken = jwtDecode<Record<string, string | number>>(
        info.authToken,
      );
      const decodedRefreshToken = jwtDecode<Record<string, string | number>>(
        info.refreshToken,
      );

      const expireDateAuthToken = moment(Number(decodedAuthToken.exp) * 1000);
      const expireDateRefreshToken = moment(
        Number(decodedRefreshToken.exp) * 1000,
      );
      const currentDate = moment();

      return (
        currentDate.isBefore(expireDateAuthToken) ||
        currentDate.isBefore(expireDateRefreshToken)
      );
    });

    const newAuthInfo =
      this._authInfo &&
      this._allAuthInfos.find(
        (i) =>
          i.InstanceId.toLowerCase() ===
            this._authInfo?.InstanceId.toLowerCase() &&
          i.UserId.toLowerCase() === this._authInfo.UserId.toLowerCase(),
      );
    this._setCurrentAuthInfo(newAuthInfo);
  };

  private _processNewTokens = (tokens: ITokens) => {
    const decoded = jwtDecode<TAccessToken>(tokens.authToken);
    // "slu": 1715780382,
    // "nbf": 1715860936,
    // "exp": 1715862136,
    // "iat": 1715860936
    const newAuthInfo: IAuthInfo = {
      ...tokens,
      UserId: decoded.UserId,
      InstanceId: decoded.InstanceId,
      SessionId: decoded.SessionId,
      Login: decoded.Login,
      slu: decoded.slu,
    };
    this._allAuthInfos = this._allAuthInfos.filter(
      (l) =>
        !(
          l.UserId.toLowerCase() === newAuthInfo.UserId.toLowerCase() &&
          l.InstanceId.toLowerCase() === newAuthInfo.InstanceId.toLowerCase()
        ),
    );
    this._allAuthInfos.push(newAuthInfo);
    this._saveAllAuthInfos();
    this._setCurrentAuthInfo(newAuthInfo);

    this._eventEmitter.emit(SETTINGS_SERVER_CHANGED);
    this.logger.setAuthInfo(newAuthInfo);
    this._storage.setAuthInfo(newAuthInfo);

    return newAuthInfo;
  };

  private _saveAllAuthInfos = () => {
    this._storage.set(
      STORAGE_KEY_AUTH_INFO,
      JSON.stringify(this._allAuthInfos),
    );
  };

  private _deleteAuthInfo = (authInfo: IAuthInfo) => {
    this._allAuthInfos = this._allAuthInfos.filter(
      (l) =>
        !(
          l.UserId.toLowerCase() === authInfo.UserId.toLowerCase() &&
          l.InstanceId.toLowerCase() === authInfo.InstanceId.toLowerCase()
        ),
    );
    this._saveAllAuthInfos();
    this._setCurrentAuthInfo();
  };

  private _getDeviceHeaders = () => {
    return {
      "FX-UserTimeOffset": this._deviceInfoProvider.getTimeOffset().toString(),
    };
  };

  private _refreshAccessToken = async () => {
    if (!this._authInfo?.refreshToken) {
      throw Error("Refresh Token not found");
    }

    const axiosInstance = this._getAxiosInstance(this._authInfo?.InstanceId);

    const response = await axiosInstance.post<ITokens>("Auth/refresh", {
      refreshToken: this._authInfo.refreshToken,
    });
    return response.data;
  };

  private _saveLastSuccessLogin = (
    credentials: ILoginCredentials,
    authInfo: IAuthInfo,
  ) => {
    const lastLogins = this.getLastLogins();
    const login = lastLogins.find(
      (l) =>
        l.login.toLowerCase() === credentials.login.toLowerCase() &&
        l.InstanceId.toLowerCase() === credentials.instanceId.toLowerCase(),
    );

    if (login) {
      login.epoch = new Date().valueOf();
      this._lastLoginService.setLastUserIdentity(login);
    } else {
      const newLastLogin: ILastLogin = {
        UserId: authInfo.UserId,
        InstanceId: credentials.instanceId,
        login: credentials.login,
        epoch: new Date().valueOf(),
      };

      lastLogins.push(newLastLogin);
      this._lastLoginService.setLastUserIdentity(newLastLogin);
    }

    this._storage.set(STORAGE_KEY_LAST_LOGINS, JSON.stringify(lastLogins));
  };
}
