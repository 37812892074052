import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popover, Tooltip } from "@progress/kendo-react-tooltip";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownTree,
  DropDownTreeChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { IAdjustedTimeLineItem, IAdjustmentSettings } from "../interfaces";
import styles from "./adjustmentsEdit.module.scss";
import DurationInput from "../../../Common/Form/DurationInput";
import OpenCardLink from "../../../OpenCardLink";
import debounce from "lodash.debounce";
import AllocationInfo from "./AllocationInfo";
import {
  PropsSQLTKGetDataForAdjustmentResponseTaxCodes,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo,
  SQL_TK_GetAvailableWOs_Response,
  SQL_TK_GetDataForAdjustment_Response_States,
  SQL_TK_GetDataForAdjustment_Response_TaxCodes,
} from "../../../../core/api/generated/conterra";

interface IProps {
  isFinish: boolean;
  tcId: number;
  tcInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo;
  index: number;
  data: IAdjustedTimeLineItem;
  availableStates: SQL_TK_GetDataForAdjustment_Response_States[];
  updateStateCode: (oldData: IAdjustedTimeLineItem, stateCode: string) => void;
  availableTaxCodes: SQL_TK_GetDataForAdjustment_Response_TaxCodes[];
  updateTaxCode: (oldData: IAdjustedTimeLineItem, taxCode: string) => void;
  workOrders: SQL_TK_GetAvailableWOs_Response[];
  onCloseAllocationCard: () => void;
  settings: IAdjustmentSettings;

  updateAllocation(
    oldData: IAdjustedTimeLineItem,
    allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
    stateCode: string | null,
  ): void;

  selectCostType(e: any, data: IAdjustedTimeLineItem): void;

  updateApprovedHours(
    hours: number,
    valueString: string,
    data: IAdjustedTimeLineItem,
  ): void;
}

const AdjustmentEditRow = (props: IProps) => {
  const {
    data,
    workOrders,
    tcInfo,
    tcId,
    settings: { showStateAllocation, showTaxCodes },
    availableTaxCodes,
    availableStates,
    updateTaxCode,
  } = props;
  const {
    start,
    finish,
    actualDurationString,
    approvedDurationString,
    approvedDuration,
    costTypeCode,
    stateCode,
    taxCode,
    color,
    manualAllocation,
    costTypeName,
  } = data;

  const [isShownPopover, setIsShownPopover] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const insidePopoverContent = useRef(false);
  const insideTE = useRef(false);
  const isLunch = data.costTypeCode === "LUNCH";

  useEffect(() => {
    insidePopoverContent.current = false;
    insideTE.current = false;
    setIsShownPopover(false);
  }, [data.manualAllocation, setIsShownPopover]);

  const changeCostType = useCallback(
    (e: any) => {
      props.selectCostType(e, data);
    },
    [props.selectCostType, data],
  );

  const onChangeStateCode = useCallback(
    (event: DropDownListChangeEvent) => {
      props.updateStateCode(data, event.value?.code);
    },
    [props.updateStateCode, data],
  );
  const onChangeTaxCode = useCallback(
    (event: DropDownTreeChangeEvent) => {
      updateTaxCode(data, event.value?.code);
    },
    [updateTaxCode, data],
  );

  const onTimeChange = useCallback(
    (hours: number | null, value: string) => {
      props.updateApprovedHours(
        hours !== null ? hours : 0.0,
        value || "00:00",
        data,
      );
    },
    [props.updateApprovedHours, data],
  );

  const onMouseEvent = useCallback(
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const value = e.type === "mouseover";
      insideTE.current = value;
      if (!value && insidePopoverContent.current) return;
      setIsShownPopover(value);
    }, 200),
    [setIsShownPopover],
  );

  const onContentMouseEvent = useCallback(
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const value = e.type === "mouseover";
      insidePopoverContent.current = value;
      if (!insideTE.current && !value) {
        setIsShownPopover(false);
      }
    }, 100),
    [setIsShownPopover],
  );

  const renderPopoverContent = useCallback(() => {
    return (
      <div
        className={styles.TEDetailsContent}
        onMouseOver={onContentMouseEvent}
        onMouseOut={onContentMouseEvent}
      >
        {!!manualAllocation?.length && (
          <div>
            {manualAllocation.map((item) => (
              <div
                key={`${item.rowNumber}_${item.workOrderId}`}
                className={styles.TEDetailsRow}
              >
                <span
                  style={{
                    width: 30,
                    flex: "0 0 auto",
                  }}
                >
                  {item.percentage}%
                </span>
                <OpenCardLink
                  text={item.workOrderName}
                  dataAttr={item.workOrderId}
                  refName="FSMWorkOrders"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }, [data, manualAllocation]);

  const renderStateCodeDropDown = useCallback(
    (StateCode: string | null) => {
      return (
        <DropDownList
          className={styles.AdjustmentDropDown}
          data={availableStates}
          dataItemKey={"code"}
          textField={"code"}
          value={StateCode ? { code: StateCode } : null}
          onChange={onChangeStateCode}
          popupSettings={{
            width: 70,
          }}
        />
      );
    },
    [onChangeStateCode, availableStates],
  );
  const renderTaxCodeDropDown = useCallback(
    (taxCode: string | null) => {
      const value = taxCode
        ? availableTaxCodes.find((x) => x.code === taxCode) || null
        : null;
      return (
        <DropDownTree
          className={`${styles.AdjustmentDropDown} ${styles.AdjustmentDropDownTree}`}
          data={availableTaxCodes}
          dataItemKey={PropsSQLTKGetDataForAdjustmentResponseTaxCodes.code}
          textField={PropsSQLTKGetDataForAdjustmentResponseTaxCodes.name}
          value={value}
          onChange={onChangeTaxCode}
          popupSettings={{
            width: 80,
            className: styles.AdjustmentDropDownTreePopup,
          }}
        />
      );
    },
    [onChangeTaxCode, availableTaxCodes],
  );

  return (
    <div
      className={`${styles.AdjustmentEdit} ${
        props.isFinish ? styles.EditRowBorderBottom : ""
      }`}
    >
      <div className={styles.TE}>
        <Tooltip anchorElement={"target"} parentTitle={true} position={"right"}>
          <span
            title={costTypeName || "Empty cost type"}
            className={`${styles.TECostType} ${
              !isLunch && !approvedDuration ? styles.thin : ""
            }`}
            style={{ color }}
            onClick={changeCostType}
          ></span>
        </Tooltip>
        <div className={styles.TEContentBox}>
          <div className={styles.TEContent}>
            {isLunch && (
              <div className={styles.TEText}>
                <span className={styles.LunchDuration}>
                  {approvedDurationString}
                </span>
                {showStateAllocation && renderStateCodeDropDown(stateCode)}
                {showTaxCodes && renderTaxCodeDropDown(taxCode)}
                {costTypeCode} ({actualDurationString})
              </div>
            )}
            {!isLunch && (
              <div className={styles.TEText}>
                <span className={styles.TEEditDuration}>
                  <DurationInput
                    start={start}
                    finish={finish}
                    duration={approvedDuration}
                    onChange={onTimeChange}
                  />
                </span>
                {showStateAllocation && renderStateCodeDropDown(stateCode)}
                {showTaxCodes && renderTaxCodeDropDown(taxCode)}
                <div
                  ref={elementRef}
                  onMouseOut={
                    data.manualAllocation?.length ? onMouseEvent : undefined
                  }
                  onMouseOver={
                    data.manualAllocation?.length ? onMouseEvent : undefined
                  }
                  style={{ flex: 1 }}
                >
                  <AllocationInfo
                    tcId={tcId}
                    tcInfo={tcInfo}
                    updateAllocation={props.updateAllocation}
                    data={data}
                    workOrders={workOrders}
                    onCloseAllocationCard={props.onCloseAllocationCard}
                  />
                </div>
              </div>
            )}
          </div>
          <Popover
            animate={false}
            callout={false}
            show={isShownPopover}
            position={"left"}
            margin={{ vertical: 0, horizontal: 10 }}
            anchor={elementRef && elementRef.current}
            className={styles.TEDetailsPopover}
          >
            {renderPopoverContent()}
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentEditRow;
