import { CalendarMode, DayData, FilterData, FirstDayOfWeek } from "./models";
import moment from "moment/moment";
import { SQL_DispatchCalendar_PeriodData_Response_Dispatches } from "../../core/api/generated/conterra";

export const calcWeekLine = (firstDayOfWeek: FirstDayOfWeek) => {
  return Array.from({ length: 7 }).map((_, i) => ({
    id: i,
    title: moment()
      .isoWeekday(i + firstDayOfWeek)
      .format("ddd"),
  }));
};

export const calcWeekDays = (
  firstDayOfPeriod: moment.Moment,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  const startDate = getFirstDayOfWeek(firstDayOfPeriod, firstDayOfWeek);
  const today = moment();

  return Array.from({ length: 7 }).map((_, i) => {
    const day = startDate.clone().add(i, "days");
    return {
      date: day,
      isToday: day.isSame(today, "day"),
      isInCurrentPeriod: true,
      weekNumber: day.isoWeek(),
    } as DayData;
  });
};

export const calcMonthDays = (
  firstDayOfMonth: moment.Moment,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  const startDate = getFirstDayOfWeek(firstDayOfMonth, firstDayOfWeek);
  const endDate = getFirstDayOfWeek(
    firstDayOfMonth.clone().endOf("month"),
    firstDayOfWeek,
  ).add(6, "days");
  const daysCount = endDate.diff(startDate, "days") + 1;
  const calendar = new Array<DayData[]>();
  let row = new Array<DayData>();

  const today = moment();

  for (let i = 0; i < daysCount; i++) {
    const day = startDate.clone().add(i, "days");

    row.push({
      date: day,
      isToday: day.isSame(today, "day"),
      isInCurrentPeriod: day.month() === firstDayOfMonth.month(),
      weekNumber: day.isoWeek(),
    });

    if (row.length === 7) {
      calendar.push(row);
      row = new Array<DayData>();
    }
  }

  return calendar;
};

export const getFirstDayOfPeriod = (
  date: moment.Moment,
  mode: CalendarMode,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  switch (mode) {
    case "month":
      return date.clone().startOf("month").startOf("day");
    case "week":
    case "resources":
      return getFirstDayOfWeek(date, firstDayOfWeek);
    case "day":
    case "map":
      return date.clone().startOf("day");
    default:
      throw "Unsupported mode!";
  }
};

export const getLastDayOfPeriod = (
  date: moment.Moment,
  mode: CalendarMode,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  switch (mode) {
    case "month":
      return date.clone().endOf("month").startOf("day");
    case "week":
    case "resources":
      return getFirstDayOfWeek(date, firstDayOfWeek).add(6, "days");
    case "day":
    case "map":
      return date.clone().startOf("day");
    default:
      throw "Unsupported mode!";
  }
};

export const getPreviousPeriodDate = (
  date: moment.Moment,
  mode: CalendarMode,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  switch (mode) {
    case "month":
      return date.clone().startOf("month").subtract(1, "month");
    case "week":
    case "resources":
      return getFirstDayOfWeek(date, firstDayOfWeek).subtract(7, "day");
    case "day":
    case "map":
      return date.clone().startOf("day").subtract(1, "day");
    default:
      throw "Unsupported mode!";
  }
};

export const getNextPeriodDate = (
  date: moment.Moment,
  mode: CalendarMode,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  switch (mode) {
    case "month":
      return date.clone().startOf("month").add(1, "month");
    case "week":
    case "resources":
      return getFirstDayOfWeek(date, firstDayOfWeek).add(7, "day");
    case "day":
    case "map":
      return date.clone().startOf("day").add(1, "day");
    default:
      throw "Unsupported mode!";
  }
};

export const getFormattedPeriod = (
  date: moment.Moment,
  mode: CalendarMode,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  const startDate = getFirstDayOfPeriod(date, mode, firstDayOfWeek);
  switch (mode) {
    case "month":
      return startDate.format("MMMM YYYY");
    case "week":
    case "resources": {
      const endDate = getLastDayOfPeriod(startDate, "week", firstDayOfWeek);
      const sameYear = startDate.year() === endDate.year();
      const sameMonth = startDate.month() === endDate.month();

      //NOTE 3 variants: "Dec 29, 2024 - Jan 4, 2025", "Jan 26 - Feb 1, 2025", "Feb 2 - 8, 2025"
      return `${startDate.format("MMM")} ${startDate.date()}${
        sameMonth || !sameYear ? "" : " - "
      }${sameYear ? "" : ", " + startDate.year() + " - "} ${
        sameMonth ? " - " : endDate.format("MMM")
      } ${endDate.date()}, ${endDate.year()}`;
    }
    case "day":
    case "map":
      return startDate.format("LL");
    default:
      throw "Unsupported mode!";
  }
};

export const filterDispatches = (
  dispatches: SQL_DispatchCalendar_PeriodData_Response_Dispatches[],
  filters?: FilterData,
) => {
  return filters
    ? dispatches.filter(
        (x) =>
          !x.details ||
          ((filters.regionIds.length === 0 ||
            filters.regionIds.includes(x.details.regionId)) &&
            (filters.marketIds.length === 0 ||
              filters.marketIds.includes(x.details.marketId)) &&
            (filters.buildPlanClassIds.length === 0 ||
              (x.details.buildPlanClassId !== null &&
                filters.buildPlanClassIds.includes(
                  x.details.buildPlanClassId,
                ))) &&
            (filters.customerIds.length === 0 ||
              (x.details.customerId !== null &&
                filters.customerIds.includes(x.details.customerId))) &&
            (filters.crewContainerIds.length === 0 ||
              (x.details.crewContainerId !== null &&
                filters.crewContainerIds.includes(
                  x.details.crewContainerId,
                ))) &&
            (filters.buildPlanOwnerIds.length === 0 ||
              filters.buildPlanOwnerIds.includes(x.details.buildPlanOwnerId)) &&
            (filters.resourceIds.length === 0 ||
              x.resources.some((r) =>
                filters.resourceIds.includes(r.employeeId),
              )) &&
            (filters.title === "" ||
              x.title.toLowerCase().indexOf(filters.title) > -1) &&
            (!filters.isMy || x.details?.isMy === true)),
      )
    : dispatches;
};

const getFirstDayOfWeek = (
  date: moment.Moment,
  firstDayOfWeek: FirstDayOfWeek,
) => {
  const startDate = date.clone();

  const daysToSubtract =
    startDate.isoWeekday() >= firstDayOfWeek
      ? startDate.isoWeekday() - firstDayOfWeek
      : 7 + startDate.isoWeekday() - firstDayOfWeek;

  return startDate.subtract(daysToSubtract, "days");
};
