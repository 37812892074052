import { useCalendarContext } from "../CalendarContext";
import { useCallback, useMemo } from "react";
import {
  calcWeekDays,
  calcWeekLine,
  filterDispatches,
  getFirstDayOfPeriod,
} from "../utils";
import { clsx } from "clsx";
import { DispatchStatuses } from "../models";
import CardManagement from "../../../Components/Cards/CardManagement";
import { RecordLink } from "../RecordLink";
import styles from "../calendar.module.scss";

const DispatchStatusToClassMap: Map<string, string> = new Map([
  [DispatchStatuses.Scheduled, styles.CalendarResourcesViewEventScheduled],
  [DispatchStatuses.Published, styles.CalendarResourcesViewEventPublished],
  [DispatchStatuses.Completed, styles.CalendarResourcesViewEventCompleted],
  [DispatchStatuses.Cancelled, styles.CalendarResourcesViewEventCancelled],
]);

export const CalendarResourcesView = () => {
  const ctx = useCalendarContext();

  const weekLine = useMemo(
    () => calcWeekLine(ctx.firstDayOfWeek),
    [ctx.firstDayOfWeek],
  );
  const weekDays = useMemo(
    () =>
      calcWeekDays(
        getFirstDayOfPeriod(ctx.selectedDate, "resources", ctx.firstDayOfWeek),
        ctx.firstDayOfWeek,
      ),
    [ctx.selectedDate, ctx.firstDayOfWeek],
  );

  const data = useMemo(() => {
    if (!ctx.data.common || ctx.data.common.resources.size === 0) {
      return [];
    }

    const allDispatches = weekDays.map((day) => ({
      day,
      dispatches: filterDispatches(
        ctx.data.eventsByDate.get(day.date.format("YYYY-MM-DDT00:00:00"))
          ?.dispatches || [],
        ctx.buildPlan.buildPlanId === undefined ? ctx.filters : undefined,
      ),
    }));

    const resources = Array.from(
      ctx.data.common.resources,
      ([resourceId, resourceName]) => ({
        resourceId,
        resourceName,
      }),
    ).filter((x) =>
      ctx.filters.resourceIds.length === 0
        ? true
        : ctx.filters.resourceIds.includes(x.resourceId),
    );

    const result = resources.map(({ resourceId, resourceName }) => ({
      id: resourceId,
      name: resourceName,
      periodHours: null as number | null,
      dispatchesByDays: allDispatches.map((x) => {
        const dispatches = x.dispatches
          .filter((d) => d.resources.some((r) => r.employeeId === resourceId))
          .map((d) => ({
            ...d,
            resources: d.resources.filter((r) => r.employeeId === resourceId),
          }));

        let dayHours: number | null = null;

        for (let i = 0; i < dispatches.length; i++) {
          if (dispatches[i].resources[0].dayHours !== null) {
            dayHours = dispatches[i].resources[0].dayHours;
            break;
          }
        }

        return {
          day: x.day,
          dayHours: dayHours,
          dispatches: dispatches,
        };
      }),
    }));

    result.forEach((x) => {
      if (x.dispatchesByDays.length > 0) {
        for (let i = 0; i < x.dispatchesByDays.length; i++) {
          for (let j = 0; j < x.dispatchesByDays[i].dispatches.length; j++) {
            if (
              x.dispatchesByDays[i].dispatches[j].resources[0].periodHours !==
              null
            ) {
              x.periodHours =
                x.dispatchesByDays[i].dispatches[j].resources[0].periodHours;
              return;
            }
          }
        }
      }
    });

    return result;
  }, [ctx, weekDays]);

  const handleEventClick = useCallback(
    (dispatchId: number) => {
      CardManagement.OpenDispatchCard({
        newDispatch: false,
        dsId: dispatchId,
        afterSave: ctx.data.refresh,
      });
    },
    [ctx.data.refresh],
  );

  return (
    <div className={styles.CalendarResourcesViewContainer}>
      <div className={styles.CalendarResourcesView}>
        <table className={styles.CalendarResourcesViewTable}>
          <thead>
            <tr>
              <th>Resource</th>
              <th>Hours</th>
              {weekLine.map((x, i) => (
                <th key={x.id}>
                  {x.title} {weekDays[i].date.format("M/D")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((x) => (
              <tr key={x.id}>
                <td>
                  <RecordLink recordId={x.id} refName={"Employees"}>
                    {x.name}
                  </RecordLink>
                </td>
                <td
                  className={clsx(
                    (x.periodHours || 0) > 30 &&
                      styles.CalendarResourcesViewPeriodHoursExceeded,
                  )}
                >
                  {x.periodHours}
                </td>
                {x.dispatchesByDays.map((d) => (
                  <td key={d.day.date.valueOf()}>
                    <div className={styles.CalendarResourcesViewCell}>
                      <div>{d.dayHours}</div>
                      <div className={styles.CalendarResourcesViewEvents}>
                        {d.dispatches.map((dispatch) => (
                          <div
                            key={dispatch.id}
                            className={clsx(
                              styles.CalendarResourcesViewEvent,
                              DispatchStatusToClassMap.get(dispatch.status),
                            )}
                            style={{
                              borderColor: dispatch.borderColor || undefined,
                            }}
                            onClick={() => handleEventClick(dispatch.id)}
                          >
                            {dispatch.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
