import {
  createContext,
  Dispatch,
  DispatchWithoutAction,
  SetStateAction,
  useContext,
} from "react";
import {
  CalendarMode,
  CalendarProcessing,
  CommonData,
  ContextMenuData,
  FilterData,
  FirstDayOfWeek,
  Position,
} from "./models";
import moment from "moment/moment";
import {
  SQL_DispatchCalendar_DayData_Response,
  SQL_DispatchCalendar_PeriodData_Response,
  SQL_DispatchCalendar_PeriodData_Response_Dispatches,
} from "../../core/api/generated/conterra";

export type ICalendarContext = {
  buildPlan: {
    buildPlanId: number | undefined;
    mapBuildPlanId: number | undefined;
    setMapBuildPlanId: Dispatch<number | undefined>;
  };
  useSimpleGBP: boolean;
  mode: CalendarMode;
  changeMode: (newMode: CalendarMode, buildPlanId?: number) => void;
  firstDayOfWeek: FirstDayOfWeek;
  setFirstDayOfWeek: Dispatch<FirstDayOfWeek>;
  selectedDate: moment.Moment;
  setSelectedDate: Dispatch<moment.Moment>;
  processing: CalendarProcessing;
  selectedDispatches: {
    dispatches: number[];
    add: Dispatch<number>;
    remove: Dispatch<number>;
  };
  filters: FilterData;
  setFilters: Dispatch<SetStateAction<FilterData>>;
  data: {
    common: CommonData | null;
    eventsByDate: Map<string, SQL_DispatchCalendar_PeriodData_Response>;
    woOnDay: SQL_DispatchCalendar_DayData_Response[];
    refresh: DispatchWithoutAction;
    fullRefresh: DispatchWithoutAction;
  };
  contextMenu: {
    state: ContextMenuData;
    open: (
      dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches,
      date: moment.Moment,
      position: Position,
    ) => void;
    close: DispatchWithoutAction;
  };
  collapsible: {
    subscribe: (func: (expand: boolean) => void) => () => void;
    emit: (expand: boolean) => void;
  };
};

export const CalendarContext = createContext<ICalendarContext | undefined>(
  undefined,
);

export const useCalendarContext = () => {
  const ctx = useContext(CalendarContext);

  if (!ctx) {
    throw "CalendarContext is undefined!";
  }

  return ctx;
};
