// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** SasTokenAccessType */
export enum SasTokenAccessType {
  DocumentRead = "DocumentRead",
  DocumentWrite = "DocumentWrite",
}
export const EnumSasTokenAccessType = {
  DocumentRead: "DocumentRead",
  DocumentWrite: "DocumentWrite",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix Documents
 * @version v1
 */

export class Api_documents {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  documents = {
    getFileBodyBySas: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /** String */
        sas: string;
        /**
         * Boolean
         * @default false
         */
        preview?: boolean;
        /**
         * Boolean
         * @default false
         */
        inline?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<File>({
        path: `/Documents/Document`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    getFileBody: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /**
         * Boolean
         * @default false
         */
        inline?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<File>({
        path: `/Documents/File`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    updateFileBody: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
      },
      data: {
        /**
         * IFormFile
         * @format binary
         */
        document?: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/File`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
    deleteFileBody: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/File`,
        method: "DELETE",
        query: query,
        ...params,
      }),
    updateFrom: (
      query: {
        /**
         * Int32
         * @format int32
         */
        fromDocumentId: number;
        /**
         * Int32
         * @format int32
         */
        toDocumentId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/File/From`,
        method: "PUT",
        query: query,
        ...params,
      }),
    getFileBodyBySas2: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /** String */
        sas: string;
        /**
         * Boolean
         * @default false
         */
        preview?: boolean;
        /**
         * Boolean
         * @default false
         */
        inline?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<File>({
        path: `/Documents/File/Sas`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    updateFileBodyWithSas: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /** String */
        sas: string;
      },
      data: {
        /**
         * IFormFile
         * @format binary
         */
        document?: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/File/Sas`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
    getMultiDocZip: (
      zipFilename: string,
      query: {
        /** String */
        instanceId: string;
        /**
         * Guid
         * @format uuid
         */
        linkId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<File>({
        path: `/Documents/MultiDocZip/${zipFilename}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    getMultiDocZipUrl: (
      query: {
        /** String */
        zipFileName: string;
      },
      data: number[],
      params: RequestParams = {},
    ) =>
      this.http.request<string>({
        path: `/Documents/MultiDocZipUrl`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getPreview: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /**
         * Boolean
         * @default false
         */
        inline?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/Preview`,
        method: "GET",
        query: query,
        ...params,
      }),
    getSasToken: (
      query: {
        accessType: SasTokenAccessType;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<string>({
        path: `/Documents/Sas`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    uploadClpmImage: (
      query: {
        /**
         * Int32
         * @format int32
         */
        documentId: number;
        /** String */
        sas: string;
      },
      data: {
        /**
         * IFormFile
         * @format binary
         */
        document?: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Documents/upload-clpm-image`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),
  };
}
