import { IStatusPanelParams } from "ag-grid-community";
import styles from "../aggrid.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

export interface StatusPanel_ReloadRows_Props extends IStatusPanelParams {
  getRows: () => void;
}

export default function StatusPanel_ReloadRows(
  props: StatusPanel_ReloadRows_Props
) {
  const { getRows } = props;
  return (
    <div className={styles.ActionsContainer}>
      <Button
        fillMode={"flat"}
        icon={"refresh"}
        onClick={() => getRows()}
        themeColor={"primary"}
      ></Button>
    </div>
  );
}
