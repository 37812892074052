import { ETCPerformAction } from "./interfaces";
import { ModalRef } from "../../Components/Common/Modal/Modal";
import { RunScriptAsync } from "../../helpers/runscripts";
import { showSomeError } from "../../helpers/errorHelpers";

export const ROW_HEIGHT = 120;

export const PerformActionToSettings = {
  [ETCPerformAction.ChangeTime]: {
    title: "Change Time",
    iconClass: "mdi mdi-calendar-clock",
    themeColor: "error",
  },
  [ETCPerformAction.Reassign]: {
    title: "Reassign",
    iconClass: "mdi mdi-account-multiple-outline",
    themeColor: undefined,
  },
  [ETCPerformAction.Reject]: {
    title: "Reject",
    iconClass: "k-icon k-font-icon k-i-undo",
    themeColor: "error",
  },
  [ETCPerformAction.Complete]: {
    title: "Complete",
    iconClass: "mdi mdi-check-all",
    themeColor: "success",
  },
  [ETCPerformAction.Confirm]: {
    title: "Confirm",
    iconClass: "mdi mdi-check-bold",
    themeColor: "success",
  },
  [ETCPerformAction.NoChanges]: {
    title: "Acknowledged",
    iconClass: "mdi mdi-check-bold",
    themeColor: "success",
  },
};

export const reprocessTC = (tcId: number, refreshAfterAction: () => void) => {
  ModalRef.startProcessing();
  RunScriptAsync("ADM_ReprocessTimeCard", { TCID: tcId })
    .catch(showSomeError)
    .finally(() => {
      ModalRef.stopProcessing();
      refreshAfterAction();
    });
};
