import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./map.module.scss";
import { nodeType } from "./interfaces";
import {
  BindNodeTooltip,
  fitBoundsGroup,
  getFigureObjectLayer,
  getObjectMapData,
  MARKERS_COLORS,
  OpenObject,
} from "./helpers";
import {
  Notification,
  NotificationEvent,
} from "@progress/kendo-react-notification";
import { ModalRef } from "../Common/Modal/Modal";
import CardManagement from "../Cards/CardManagement";
import { IComboboxItem } from "../../helpers/interfaces";
import { Badge } from "@progress/kendo-react-indicators";
import api from "../../core/api/api";
import {
  SQL_Map_GetAllAddresses_Request,
  SQL_Map_GetAllAddresses_Response,
  SQL_Map_GetIncompleteWOSites_Response,
  SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders,
} from "../../core/api/generated/conterra";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { showSomeError } from "../../helpers/errorHelpers";

interface IProps {
  map: any;
  className: string;
}

const MAX_NODES_TO_SHOW = 1000;
const COLORS: { [key in nodeType]: string } = {
  Location: MARKERS_COLORS.AQUA,
  Site: MARKERS_COLORS.PINK,
  IncompleteWOSite: MARKERS_COLORS.ORANGE,
};
const renderSettings: {
  typeId: nodeType;
  text: string;
  className: string;
}[] = [
  {
    typeId: "Location",
    text: "Nearby Locations",
    className: styles.LocationsGroup,
  },
  {
    typeId: "Site",
    text: "Nearby Sites (All)",
    className: styles.SitesGroup,
  },
  {
    typeId: "IncompleteWOSite",
    text: "Nearby Sites (Incomplete WOs)",
    className: styles.AllSitesGroup,
  },
];

const OtherNodesControl = (props: IProps) => {
  const { map } = props;
  const lastBounds = useRef<{
    [key in nodeType]?: SQL_Map_GetAllAddresses_Request;
  }>({});
  const [lastMapChanged, setLastMapChanged] = useState<number>(+new Date());

  const [isLocationsShown, setLocationsState] = useState<boolean>(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(false);
  const [isSitesShown, setSitesState] = useState<boolean>(false);
  const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false);
  const [isIncSitesShown, setIncSitesState] = useState<boolean>(false);
  const [isLoadingIncSites, setIsLoadingIncSites] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<{
    [key in nodeType]?: {
      text: string;
      timer: any;
    };
  }>({});
  const lastLoadedDataInfo = useRef<{
    [key in nodeType]: {
      time: number;
      layers: any[];
    } | null;
  }>({
    Location: null,
    Site: null,
    IncompleteWOSite: null,
  });
  const incompleteWOSitesFiltersRef = useRef<{
    [key: string]: IComboboxItem | null;
  }>({});
  const [appliedIncompleteWOSitesFilters, setAppliedIncompleteWOSitesFilters] =
    useState(0);
  const incompleteWOSites = useRef<SQL_Map_GetIncompleteWOSites_Response[]>([]);

  const clusterRef = useRef<any>(
    window.L.markerClusterGroup({
      // zoomToBoundsOnClick: false,
      iconCreateFunction: function (cluster: any) {
        const childCount = cluster.getChildCount();
        return new window.L.DivIcon({
          className: "marker-cluster-other",
          iconSize: [20, 20],
          iconAnchor: [10, 20],
          html: "<span>" + childCount + "</span>",
        });
      },
    })
  );

  useEffect(() => {
    if (map) {
      map.addLayer(clusterRef.current);
      map.on("dragend", onMapChange);
      map.on("zoomend", onMapChange);
    }

    return () => {
      if (map) {
        map.off("dragend", onMapChange);
        map.off("zoomend", onMapChange);
      }
    };
  }, [map]);

  const onMapChange = () => setLastMapChanged(+new Date());

  const onShowNodes = async (e: any) => {
    const [type]: [nodeType, string] = e.currentTarget.id.split("_");
    showNodes(type);
  };

  const showNodes = async (type: nodeType, prevBounds?: boolean) => {
    try {
      if (type === "Site") {
        setSitesState(true);
        setIsLoadingSites(true);
      } else if (type === "Location") {
        setIsLoadingLocations(true);
        setLocationsState(true);
      } else {
        setIsLoadingIncSites(true);
        setIncSitesState(true);
      }
      removeNodesFromMap(type);
      lastLoadedDataInfo.current[type] = {
        time: +new Date(),
        layers: [],
      };
      if (!prevBounds) {
        const bounds = map.getBounds();
        lastBounds.current[type] = {
          clientRectTopLat: bounds._northEast.lat,
          clientRectBottomLat: bounds._southWest.lat,
          clientRectLeftLng: bounds._southWest.lng,
          clientRectRightLng: bounds._northEast.lng,
          objectType: type,
        };
      }

      if (type === "IncompleteWOSite") {
        const { objectType, ...incomleteWOSitesParams } =
          lastBounds.current[type]!;
        const nodes = await api.sql.mapGetIncompleteWoSites(
          incomleteWOSitesParams
        );
        incompleteWOSites.current = nodes;
        drawIncompleteWONodes(nodes);
      } else {
        const nodes = await api.sql.mapGetAllAddresses(
          lastBounds.current[type]!
        );
        if (notifications[type]?.timer)
          clearTimeout(notifications[type]!.timer);
        if (nodes.length >= MAX_NODES_TO_SHOW) {
          nodes.length = MAX_NODES_TO_SHOW;
          setNotifications({
            ...notifications,
            [type]: {
              text: `First ${nodes.length} ${
                type === "Location" ? "Locations" : "Sites"
              } are shown`,
              timer: setTimeout(() => {
                hideNotification(type);
              }, 3000),
            },
          });
        }
        drawNodes(nodes, type);
      }
    } catch (e) {
      showSomeError(e);
    } finally {
      if (type === "Site") {
        setIsLoadingSites(false);
      } else if (type === "Location") {
        setIsLoadingLocations(false);
      } else {
        setIsLoadingIncSites(false);
      }
    }
  };

  const hideNodes = (e: any) => {
    const [type]: [nodeType, string] = e.currentTarget.id.split("_");
    if (type === "Location") setLocationsState(false);
    else if (type === "Site") setSitesState(false);
    else setIncSitesState(false);
    removeNodesFromMap(type);
  };

  const removeNodesFromMap = (type: nodeType) => {
    const layers = lastLoadedDataInfo.current[type]?.layers;
    if (layers) {
      for (const layer of layers) {
        clusterRef.current.removeLayer(layer);
        layer.removeFrom(map);
      }
    }
    lastLoadedDataInfo.current[type] = null;
  };

  const onSaveObject = async (objectId: number, objectType: nodeType) => {
    try {
      ModalRef.startProcessing("", "rgba(255, 255, 255, 0.2)");
      const info = lastLoadedDataInfo.current[objectType];
      if (!info) return;
      const layers = info.layers.filter(
        (layer) => +layer.options.objectId !== objectId
      );
      const objectLayers = info.layers.filter(
        (layer) => +layer.options.objectId === objectId
      );
      for (const layer of objectLayers) {
        layer.removeFrom(map);
        clusterRef.current.removeLayer(layer);
      }

      const result = await getObjectMapData(objectId);
      if (!result) return;
      const {
        Lat,
        Lng,
        ObjectId,
        ObjectName,
        LocationColor,
        AddressString,
        Boundaries,
        Radius,
      } = result.mainAddress;
      if (!Lat || !Lng) return;

      const coords = [Lat, Lng];
      const MarkerLayer = getMarkerLayer(
        ObjectId,
        coords,
        objectType,
        LocationColor
      );
      BindNodeTooltip(MarkerLayer, ObjectName, AddressString);
      const FigureLayer = getFigureObjectLayer(
        ObjectId,
        objectType,
        LocationColor || COLORS[objectType],
        "black",
        coords,
        Boundaries,
        Radius
      );
      if (objectType === "IncompleteWOSite") {
        bindPopupForIncompleteWOSiteMarker(
          MarkerLayer,
          incompleteWOSites.current?.find((x) => x.siteId === ObjectId)
        );
      }
      MarkerLayer.addTo(map);
      FigureLayer.addTo(map);
      clusterRef.current.addLayer(MarkerLayer);
      layers.push(MarkerLayer);
      layers.push(FigureLayer);
      info.layers = layers;

      fitBoundsGroup(
        new window.L.FeatureGroup([MarkerLayer, FigureLayer]),
        map
      );
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
    }
  };

  const OpenNodeCard = (e: any) => {
    const markerLayerOptions = e.target.options;
    const { refName, objectId, objectType } = markerLayerOptions;
    if (!objectId || !refName) return;
    OpenObject(e, (objectId: number) =>
      onSaveObject(objectId, objectType as nodeType)
    );
  };

  const FilterIncompleteWOSites = (filters: {
    [key: string]: IComboboxItem | null;
  }) => {
    incompleteWOSitesFiltersRef.current = filters;
    const bpKendoFilters: FilterDescriptor[] = [];
    const woKendoFilters: FilterDescriptor[] = [];
    for (const filterName in filters) {
      const value = filters[filterName];
      if (value) {
        if (
          filterName === "woType" ||
          filterName === "woCategory" ||
          filterName === "lastCrewLead"
        ) {
          woKendoFilters.push({
            field: filterName + "Id",
            value: value.Id,
            operator: "eq",
          });
        } else {
          bpKendoFilters.push({
            field: filterName + "Id",
            value: value.Id,
            operator: "eq",
          });
        }
      }
    }

    const dataForDrawing = !Object.keys(filters).length
      ? incompleteWOSites.current
      : incompleteWOSites.current.filter((site) => {
          const filteredBuildPlans = bpKendoFilters.length
            ? filterBy(site.buildPlans, {
                logic: "and",
                filters: bpKendoFilters,
              })
            : site.buildPlans;
          if (!filteredBuildPlans.length) return false;

          if (woKendoFilters.length) {
            const allWOs = filteredBuildPlans.reduce((result, current) => {
              return [...result, ...current.workOrders];
            }, [] as SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders[]);

            const filteredWOs = filterBy(allWOs, {
              logic: "and",
              filters: woKendoFilters,
            });

            if (!filteredWOs.length) return false;
          }
          return true;
        });

    setAppliedIncompleteWOSitesFilters(
      bpKendoFilters.length + woKendoFilters.length
    );
    const layers = lastLoadedDataInfo.current.IncompleteWOSite?.layers;
    if (layers) {
      clusterRef.current.clearLayers();
      const layersToCluster = [];
      for (const layer of layers) {
        layer.removeFrom(map);
        if (
          dataForDrawing.findIndex(
            (node) => node.siteId === layer.options.objectId
          ) > -1
        ) {
          if (layer.options.radius === undefined) {
            layersToCluster.push(layer);
          }
          layer.addTo(map);
        }
      }
      clusterRef.current.addLayers(layersToCluster);
    }
  };

  const getMarkerHTML = (primaryColor: string, isImportantIcon?: boolean) => {
    const icon = isImportantIcon
      ? `<span class="mdi mdi-exclamation-thick" style="font-size: 14px; line-height: 1; color: red;"></span>`
      : "";
    return `<div class="" style="box-sizing: border-box; width: 20px; height: 20px; border-radius: 50%; border: 3px solid ${primaryColor}; background: ${MARKERS_COLORS.WHITE};">${icon}</div>`;
  };

  const getMarkerLayer = (
    objectId: number,
    coords: number[],
    objectType: nodeType,
    color?: string | null,
    isImportantIncompleteWO?: boolean
  ) => {
    const isSites = objectType !== "Location";
    return window.L.marker(coords, {
      icon: window.L.divIcon({
        className: "-icon-box",
        iconSize: [20, 20],
        iconAnchor: [10, 20],
        html: getMarkerHTML(
          color || COLORS[objectType],
          isImportantIncompleteWO
        ),
      }),
      riseOnHover: true,
      refName: isSites ? "FSMSites" : "Locations",
      objectId,
      objectType,
    }).on("contextmenu", OpenNodeCard);
  };

  const bindPopupForIncompleteWOSiteMarker = (
    MarkerLayer: any,
    point?: SQL_Map_GetIncompleteWOSites_Response
  ) => {
    MarkerLayer.bindPopup(
      `<div data-point='${point ? JSON.stringify(point) : ""}'></div>`,
      {
        autoClose: false,
        closeButton: false,
        minWidth: 300,
        className: "leaflet-scroll-popup",
      }
    );
  };

  const drawNodes = (
    points: SQL_Map_GetAllAddresses_Response[],
    objectType: nodeType
  ) => {
    const info = lastLoadedDataInfo.current[objectType]!;
    const unicPoints: {
      [key: number]: {
        data: SQL_Map_GetAllAddresses_Response[];
        addedToMap: boolean;
      };
    } = {};
    if (objectType === "IncompleteWOSite") {
      for (const point of points) {
        if (!unicPoints[point.objectId]) {
          unicPoints[point.objectId] = { data: [], addedToMap: false };
        }
        unicPoints[point.objectId].data.push(point);
      }
    }

    for (const point of points) {
      const details = unicPoints[point.objectId];
      if (details?.addedToMap) continue;
      const { objectId, objectName, address, geoFenceRadius, boundaries } =
        point;
      const coords = [point.lat, point.lng];
      const FigureLayer = getFigureObjectLayer(
        objectId,
        objectType,
        point.color || COLORS[objectType],
        "black",
        coords,
        boundaries,
        geoFenceRadius
      );
      const MarkerLayer = getMarkerLayer(
        objectId,
        coords,
        objectType,
        point.color
      );
      BindNodeTooltip(MarkerLayer, objectName, address);
      MarkerLayer.addTo(map);
      FigureLayer.addTo(map);
      clusterRef.current.addLayer(MarkerLayer);
      info.layers.push(MarkerLayer);
      info.layers.push(FigureLayer);
    }
  };

  const drawIncompleteWONodes = (
    points: SQL_Map_GetIncompleteWOSites_Response[]
  ) => {
    const info = lastLoadedDataInfo.current.IncompleteWOSite!;
    for (const point of points) {
      const { siteId, siteName, address, geoFenceRadius, boundaries } = point;
      const coords = [point.lat, point.lng];
      const FigureLayer = getFigureObjectLayer(
        siteId,
        "IncompleteWOSite",
        COLORS.IncompleteWOSite,
        "black",
        coords,
        boundaries,
        geoFenceRadius
      );
      const allWOs = point.buildPlans.reduce((result, current) => {
        return [...result, ...current.workOrders];
      }, [] as SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders[]);
      const isImportant = allWOs.findIndex((wo) => wo.isImportant) > -1;

      const MarkerLayer = getMarkerLayer(
        siteId,
        coords,
        "IncompleteWOSite",
        undefined,
        isImportant
      );
      BindNodeTooltip(MarkerLayer, siteName, address);

      bindPopupForIncompleteWOSiteMarker(MarkerLayer, point);
      MarkerLayer.addTo(map);
      FigureLayer.addTo(map);
      clusterRef.current.addLayer(MarkerLayer);
      info.layers.push(MarkerLayer);
      info.layers.push(FigureLayer);
    }
  };

  const isMarkBtn = (type: nodeType) => {
    const info = lastLoadedDataInfo.current[type];
    return info !== null && info.time < lastMapChanged;
  };

  const onCloseNotification = (e: NotificationEvent) => {
    // @ts-ignore
    const [typeId] = e.target.props.id.split("_") as nodeType;
    hideNotification(typeId);
  };

  const hideNotification = (typeId: nodeType) => {
    clearTimeout(notifications[typeId]?.timer);
    delete notifications[typeId];
    setNotifications({ ...notifications });
  };

  return (
    <div className={`${props.className} ${styles.Controls}`}>
      {renderSettings.map(({ typeId, text, className }) => {
        const isNodesShown =
          typeId === "Location"
            ? isLocationsShown
            : typeId === "Site"
            ? isSitesShown
            : isIncSitesShown;
        const isDataLoading =
          typeId === "Location"
            ? isLoadingLocations
            : typeId === "Site"
            ? isLoadingSites
            : isLoadingIncSites;

        return (
          <div className={`${styles.GroupBtns} ${className}`} key={typeId}>
            {!isNodesShown ? (
              <Button
                id={`${typeId}_Show`}
                iconClass="mdi mdi-map-marker-outline"
                title={`Show ${text}`}
                onClick={onShowNodes}
              />
            ) : (
              <>
                <Button
                  id={`${typeId}_Refresh`}
                  className={`${isDataLoading ? styles.InProcess : ""} ${
                    isMarkBtn(typeId) ? styles.NotActualBtn : ""
                  }`}
                  iconClass="mdi mdi-refresh"
                  title={`Refresh ${text}`}
                  onClick={onShowNodes}
                  disabled={isDataLoading}
                />
                <Button
                  iconClass="mdi mdi-eye-off-outline"
                  title={`Hide ${text}`}
                  id={`${typeId}_Hide`}
                  onClick={hideNodes}
                />
                {typeId === "IncompleteWOSite" && (
                  <Button
                    iconClass="mdi mdi-filter-outline"
                    style={{ color: "#000" }}
                    onClick={() => {
                      CardManagement.OpenIncompleteWOSitesFiltersCard(
                        incompleteWOSites.current,
                        FilterIncompleteWOSites,
                        incompleteWOSitesFiltersRef.current
                      );
                    }}
                  >
                    {!!appliedIncompleteWOSitesFilters && (
                      <Badge
                        themeColor="error"
                        rounded={"full"}
                        style={{
                          zIndex: 6,
                          marginLeft: -5,
                          position: "absolute",
                          top: -5,
                          left: "100%",
                        }}
                        position={"inside"}
                      >
                        {appliedIncompleteWOSitesFilters + ""}
                      </Badge>
                    )}
                  </Button>
                )}
                {!!notifications[typeId] && (
                  <Notification
                    className={styles.OtherNodesNotification}
                    type={{ style: "info", icon: false }}
                    closable={true}
                    onClose={onCloseNotification}
                    // @ts-ignore
                    id={typeId + "_notification"}
                  >
                    {notifications[typeId]?.text}
                  </Notification>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OtherNodesControl;
