import React, {useCallback, useMemo, useState} from "react";
import FXCard from "../../Common/FXCard/FXCard";
import {OpenRecord} from "../../../helpers/runscripts";
import {ITCCardFinalProps} from "../interfaces";
import OpenCardIconLink from "../OpenCardIconLink";
import TCTabsNew from "../../TabsNew/TCTabsNew";
import {Button} from "@progress/kendo-react-buttons";
import moment from "moment";
import {LightTCInfoRow} from "../../../Pages/TKReview/TimeCard/LightTC";
import LoaderComponent from "../../Common/Loader";
import {stopPropagationWrapper} from "../../../helpers/Hooks";

const TCCard = (props: ITCCardFinalProps) => {
    const {
        tcInfo,
        handleNext,
        handlePrev,
        handleClose,
        reload,
        initialTabId,
        cardStageDefault,
        isLoading,
    } = props;
    const [tabId, setTabId] = useState(initialTabId);
    const [cardStage, setCardStage] = useState<
        "DEFAULT" | "FULLSCREEN" | "MINIMIZED"
    >(cardStageDefault || "DEFAULT");
    const handleOpenDesktopTC = useCallback(() => {
        if (tcInfo) OpenRecord("TKTimeCards", tcInfo.id);
    }, [tcInfo]);

    const topBtnsJSX = useMemo(() => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: 8,
                    marginLeft: "auto",
                    paddingRight: 8,
                }}
            >
                {!!handlePrev || !!handleNext ? (
                    <>
                        <Button
                            icon={"arrow-60-left"}
                            disabled={!handlePrev}
                            fillMode={"flat"}
                            onClick={
                                handlePrev ? stopPropagationWrapper(handlePrev) : undefined
                            }
                        />
                        <Button
                            icon={"arrow-60-right"}
                            disabled={!handleNext}
                            fillMode={"flat"}
                            onClick={
                                handleNext ? stopPropagationWrapper(handleNext) : undefined
                            }
                        />
                    </>
                ) : null}
                <Button icon={"refresh"} fillMode={"flat"} onClick={reload}/>
            </div>
        );
    }, [reload, handleNext, handlePrev]);

    const titleJSX = useMemo(() => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                <OpenCardIconLink onClick={handleOpenDesktopTC} title="Open TC"/>
                {isLoading ? (
                    <span>Loading...</span>
                ) : (
                    tcInfo && (
                        <span>
              {moment(tcInfo.date).format("L")} {tcInfo.employeeName}
            </span>
                    )
                )}
                {topBtnsJSX}
            </div>
        );
    }, [handleOpenDesktopTC, tcInfo, topBtnsJSX, isLoading]);

    return (
        <FXCard
            title={titleJSX}
            onClose={handleClose}
            stage={cardStage}
            onStageChange={({state}) => setCardStage(state || "DEFAULT")}
            className={"TCCard"}
            overlayStyle={{zIndex: "19999 !important"}}
        >
            {!tcInfo ? (
                <LoaderComponent/>
            ) : (
                <>
                    <div style={{padding: "0 8px"}}>
                        <LightTCInfoRow
                            isLoading={!!isLoading}
                            data={tcInfo}
                            refreshTC={reload}
                            setInitialTab={setTabId}
                        />
                    </div>
                    <TCTabsNew
                        timeCardId={tcInfo.id}
                        initialTab={tabId}
                        refreshTC={reload}
                        reloadDependency={tcInfo}
                    />
                </>
            )}
        </FXCard>
    );
};

export default TCCard;
