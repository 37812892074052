import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Drawer,
  DrawerContent,
  DrawerItemProps,
  Menu,
  MenuItem,
} from "@progress/kendo-react-layout";
import logo from "../../assets/img/Fieldclix-Logo.svg";
import { pageId, simpleObject } from "../../helpers/interfaces";
import { PAGES } from "../../helpers/pages";

import BaseComponent from "../../Components/BaseComponent";
import { ModalRef } from "../../Components/Common/Modal/Modal";

import { IDrawerItemServer, IMenuItem, parentMenuItem } from "./interfaces";
import "./Home.scss";
import styles from "./Home.module.scss";
import { Badge } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { authProvider } from "../../core/api/api";
import ProfileInfo from "./ProfileInfo";
import QuickLaunch from "./QuickLaunch";
import CounterStore from "../../stores/CounterStore";
import { showSomeError } from "../../helpers/errorHelpers";

interface IState {
  selectedPageId: pageId | null;
  expanded: boolean;
  menuItems: IMenuItem[];
  refreshVimKey: number;
}

interface IProps {
  isIntegrationWithDesktop: boolean;
}

class Home extends BaseComponent<IProps, IState> {
  initedPages: pageId[] = [];
  pagesRefs: { [key in pageId]?: any } = {};

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedPageId: null,
      expanded: true,
      menuItems: [],
      refreshVimKey: +new Date(),
    };
  }

  componentDidMount() {
    document.title = "Dashboards";
    this.LoadAvailableDashboards();
    CounterStore.Load();
  }

  renderCustomItem = () => {
    return (
      <Menu
        key={this.state.selectedPageId}
        vertical={true}
        style={{
          width: this.state.expanded ? "240px" : "45px",
          background: "#fff",
        }}
        onSelect={this.OnSelectPage}
        itemRender={this.renderMenuItemContent}
      >
        {this.state.menuItems.map((item) => this.renderMenuItem(item))}
      </Menu>
    );
  };

  renderMenuItem = (item: IMenuItem) => {
    const { selectedPageId } = this.state;
    const children = item.children?.length
      ? item.children.map((subItem) => this.renderMenuItem(subItem))
      : undefined;
    const selected = item.children?.length
      ? item.children.find((subItem) => subItem.route === selectedPageId)
      : item.route === selectedPageId;
    return (
      <MenuItem
        icon={item.icon}
        text={item.text}
        data={item.route}
        key={item.route}
        cssClass={`${selected ? "k-selected" : ""} main-menu-item ${
          item.route === "Profile" ? "profile-menu-item" : ""
        }`}
      >
        {children}
      </MenuItem>
    );
  };

  renderMenuItemContent = (e: any) => {
    const text = e.item.text;
    const icon = e.item.icon;
    const route: pageId | parentMenuItem = e.item.data;
    return (
      <div
        style={{
          display: "flex",
          width: this.state.expanded ? "195px" : "auto",
          alignItems: "center",
        }}
      >
        <span title={text} className={`${styles.MenuIcon} ${icon}`}></span>
        <span className={styles.DrawerItemText}>{text}</span>
        {(route === "VIM" || route === "VIM_Review") &&
          this.renderBadgeCounter(
            CounterStore.GetValues().vimInvoiceCount,
            CounterStore.VIM_COUNTER_CLASS_NAME,
          )}

        {(route === "TK" || route === "TKAuditCover") &&
          this.renderBadgeCounter(
            CounterStore.GetValues().reviewTimeCards,
            CounterStore.TKReview_COUNTER_CLASS_NAME,
          )}

        {route === "InboxDashboard" &&
          this.renderBadgeCounter(
            CounterStore.GetValues().unReadJobs,
            CounterStore.INBOX_COUNTER_CLASS_NAME,
          )}
      </div>
    );
  };

  renderBadgeCounter = (counter: number, idClass: string) => {
    return (
      <Badge
        className={`${idClass} ${styles.MenuBadge}`}
        themeColor="error"
        align={{ horizontal: "start", vertical: "top" }}
        size="large"
        rounded={"full"}
        cutoutBorder={true}
      >
        {counter > 0 ? counter : ""}
      </Badge>
    );
  };

  render() {
    return (
      <div>
        <AppBar className={styles.mainAppBar}>
          <AppBarSection>
            <Button
              iconClass={"mdi mdi-menu"}
              onClick={this.ToggleDrawer}
              className={`${styles.ToggleDrawer} noFocus`}
              fillMode={"flat"}
            />
            <img src={logo} className={styles.Logo} alt="Fieldclix" />
          </AppBarSection>
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
          <AppBarSection>
            <div className={styles.CompanyCode}>
              {authProvider.getInstanceId()}
            </div>
          </AppBarSection>
          <AppBarSpacer />
          <QuickLaunch />
          <AppBarSection>
            <ProfileInfo routeTo={this.RouteTo} />
          </AppBarSection>
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
          <AppBarSection>
            <a
              href="https://fieldclix.freshdesk.com/support/home"
              target="_blank"
              rel="noreferrer"
              className={`${styles.InfoLink} k-icon`}
            >
              <span className={" mdi mdi-help-circle-outline"}></span>
            </a>
          </AppBarSection>
        </AppBar>
        <Drawer
          className={styles.mainMenuDrawer}
          expanded={this.state.expanded}
          position={"start"}
          mode={"overlay"}
          mini={true}
          miniWidth={45}
          items={[{ text: "" }]}
          item={this.renderCustomItem}
          onOverlayClick={this.ToggleDrawer}
          onSelect={this.OnSelectPage}
        >
          <DrawerContent>
            <>
              {this.initedPages.map((pageId) => {
                const PageComponent = PAGES[pageId].component;
                const isActive = this.state.selectedPageId === pageId;
                const props: simpleObject = { isActive };
                if (pageId === "LiveMap") {
                  props.mode = "page";
                  props.prefix = "page";
                }
                return (
                  <div
                    id={pageId}
                    key={pageId}
                    className={`${styles.PageWrapper} ${
                      !isActive ? styles.PageHidden : ""
                    }`}
                  >
                    <PageComponent
                      {...props}
                      ref={
                        pageId === "LiveMap" ||
                        pageId === "TKAuditCover" ||
                        pageId === "NewStatusDashboard" ||
                        pageId === "Calendar"
                          ? undefined
                          : (ref: any) => {
                              if (
                                ref &&
                                !this.pagesRefs[pageId] &&
                                this.pagesRefs[pageId] !== ref
                              ) {
                                this.pagesRefs[pageId] = ref;
                              }
                            }
                      } // todo do not use refs use context instead ??
                    />
                  </div>
                );
              })}
            </>
          </DrawerContent>
        </Drawer>
      </div>
    );
  }

  GetMenuItemIconClassName = (icon: string) => {
    const iconName = icon.split("/")[1];
    const prefix = icon.split("/")[0] === "Foundation" ? "fi-" : "mdi mdi-";
    return `${prefix}${iconName}`;
  };

  LoadAvailableDashboards = async () => {
    try {
      ModalRef.startProcessing();
      const result = await this.GetSQLData({
        spName: "DB_AvailableDashboards",
        modal: true,
      });
      const availablePages: IDrawerItemServer[] = result?.[0] || [];
      const topLevelItems = availablePages.filter(
        (page) => page.LeadingItem === null,
      );
      const subItems = availablePages.filter((page) => !!page.LeadingItem);
      const statusTopItem = topLevelItems.find(
        (x) => x.Item === "StatusDashboard",
      );
      if (statusTopItem) {
        subItems.push({
          Icon: "MaterialCommunityIcons/table",
          Item: "StatusDashboard",
          LeadingItem: "StatusDashboard",
          Title: "Classic",
        });
        subItems.push({
          Icon: "MaterialCommunityIcons/table-border",
          Item: "NewStatusDashboard",
          LeadingItem: "StatusDashboard",
          Title: "Beta",
        });
      }
      const selectedPageId =
        availablePages.length === 1 && this.IsPageID(availablePages[0].Item)
          ? availablePages[0].Item
          : null;

      const menuItems: DrawerItemProps[] = [];
      for (const { Icon, Item: route, Title: text } of topLevelItems) {
        const subMenu = subItems.filter(
          (subItem) => subItem.LeadingItem === route,
        );
        const children: DrawerItemProps[] = subMenu.map(
          ({ Icon, Item: route, Title: text }) => {
            return {
              icon: this.GetMenuItemIconClassName(Icon),
              text,
              route,
            };
          },
        );
        menuItems.push({
          icon: this.GetMenuItemIconClassName(Icon),
          text,
          route,
          children: children.length ? children : undefined,
        });
      }
      if (selectedPageId) this.initedPages.push(selectedPageId);
      this.setState({
        menuItems,
        selectedPageId,
        expanded: !selectedPageId,
      });
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
    }
  };

  IsPageID(page: pageId | parentMenuItem): page is pageId {
    return !!page;
  }

  OnSelectPage = (e: any) => {
    const route: pageId = e.item.data;
    this.RouteTo(route);
  };

  RouteTo = (route: pageId) => {
    if (!PAGES[route]) return;

    if (this.initedPages.indexOf(route) === -1) {
      this.initedPages.push(route);
    }
    this.setState({ selectedPageId: route, expanded: false });

    const pageRef = this.pagesRefs[route];
    if (pageRef && pageRef.Refresh) pageRef.Refresh();
  };

  ToggleDrawer = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
}

export default Home;
