import moment from "moment/moment";
import React, { useCallback } from "react";
import styles from "./tkTC.module.scss";
import { ROW_HEIGHT } from "../helpers";
import { HorizontalTimeline } from "../../../Components/TC/HorizontalTimeline";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { tabId } from "../../../Components/Tabs/interfaces";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { STATE_ID_TO_NAME } from "./helpers";
import TCActions from "./Actions";
import CompareCheckbox from "./CompareCheckBox";
import TKListCheckbox from "../ListCheckBox";
import { SQL_DB_TK_Review_Response } from "../../../core/api/generated/conterra";
import {
  TCChatCloud,
  TCChips,
  TCClockInOut,
  TCReviewer,
  TCTimeFormula,
} from "./Elements";
import { isTKManager } from "../../../core/tools/roles";
import WorkShiftInfo from "../../../Components/WorkShiftsInfo";

export const TKReviewRow = (props: {
  data: SQL_DB_TK_Review_Response;
  onToggleCheck: (tcId: number) => void;
  isCheckedForAction: boolean;
  setDispatchFilter(dispatch: INewComboboxItem): void;
  onClick(initialTabId?: tabId): void;
  isCompareMode: boolean;
  onToggleCompareCheck(tcId: number): void;
  isCheckedForComparing: boolean;
  refreshTC: (tcId: number) => void;
  isSelected: boolean;
}) => {
  const {
    data,
    isSelected,
    isCheckedForAction,
    isCompareMode,
    isCheckedForComparing,
    onToggleCheck,
    onToggleCompareCheck,
    onClick,
    refreshTC,
    setDispatchFilter,
  } = props;
  const {
    reviewState,
    id,
    date,
    reviewerName,
    employeeName,
    isPermitted,
    isCrewLead,
    commentCount,
    employeeCommentCount,
    workShiftInfo,
  } = data;
  const toggleCompare = useCallback(
    () => onToggleCompareCheck(id),
    [onToggleCompareCheck, id],
  );

  const renderCheckBox = () => {
    if (isCompareMode) {
      return (
        <CompareCheckbox
          isChecked={isCheckedForComparing}
          handleClick={toggleCompare}
        />
      );
    }
    return (
      <div className={styles.Check}>
        {isPermitted && (
          <TKListCheckbox
            tcId={id}
            isChecked={isCheckedForAction}
            handleChange={onToggleCheck}
          />
        )}
      </div>
    );
  };

  const renderEmployee = () => {
    return (
      <div className={styles.EmployeeContainer}>
        <span
          className={styles.EmployeeName}
          style={isCrewLead ? { fontWeight: 600 } : {}}
          title={`${isCrewLead ? "Crew Lead: " : ""}${employeeName}`}
        >
          {employeeName}
        </span>
        <TCChatCloud
          tcId={id}
          commentCount={commentCount}
          employeeCommentCount={employeeCommentCount}
          reloadDependency={data}
        />
      </div>
    );
  };

  const renderDate = () => (
    <div className={styles.Date}>{moment(date).format("L")}</div>
  );

  return (
    <div
      className={`${styles.Container} ${
        isCompareMode && !isSelected ? styles.CompareMode : ""
      } ${isSelected ? styles.SelectedTC : ""}`}
      style={{ height: ROW_HEIGHT + "px" }}
      onClick={() => onClick()}
    >
      <div className={styles.Content}>
        <div className={styles.LeftCol}>
          <Tooltip anchorElement="target" showCallout={false}>
            <div className={styles.Row}>
              {!isSelected && renderCheckBox()}
              {renderDate()}
              {renderEmployee()}
              <TCClockInOut data={data} />
              <TCTimeFormula data={data} />
            </div>
          </Tooltip>
          <div className={`${styles.Row}`}>
            <HorizontalTimeline data={data} />
            {!!workShiftInfo?.workShiftId && !!workShiftInfo.osName && (
              <WorkShiftInfo
                workShiftId={workShiftInfo.workShiftId}
                osName={workShiftInfo.osName}
              />
            )}
          </div>
        </div>

        <div>
          <Tooltip
            anchorElement="target"
            showCallout={false}
            parentTitle={true}
          >
            <div className={styles.Row}>
              <div style={{ flex: 1 }}></div>
              <div className={styles.Actions}>
                <TCActions
                  tcId={id}
                  refreshAfterAction={() => refreshTC(data.id)}
                  data={data}
                />
              </div>
              <div className={styles.Status}>
                <span>{STATE_ID_TO_NAME[reviewState]}</span>
              </div>
            </div>
          </Tooltip>
          <div className={`${styles.Row} ${styles.ChipsRow}`}>
            <TCChips
              data={data}
              setInitialTab={onClick}
              setDispatchFilter={setDispatchFilter}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {isTKManager() && <TCReviewer reviewerName={reviewerName!} />}
          </div>
        </div>
      </div>
    </div>
  );
};
