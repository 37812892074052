import { createContext, Dispatch, useContext } from "react";
import { HoverData } from "./models";

export type ICalendarTooltipContext = {
  hoveredDispatch: HoverData | null;
  hoverDispatch: Dispatch<HoverData | null>;
};

export const CalendarTooltipContext = createContext<
  ICalendarTooltipContext | undefined
>(undefined);

export const useCalendarTooltipContext = () => {
  const ctx = useContext(CalendarTooltipContext);

  if (!ctx) {
    throw "CalendarTooltipContext is undefined!";
  }

  return ctx;
};
