import {
  useBooleanState,
  useLatestNotEmptyValue,
  useRefresher,
} from "../../../core/tools/Hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IAdjustedTimelinesData } from "./interfaces";
import LiveMap from "../../../Pages/LiveMap/LiveMap";
import SideBar from "../../Common/SideBar/SideBar";
import formStyles from "../../Cards/card.module.scss";
import api from "../../../core/api/api";
import { GetPreparedAdjustedTimeline } from "./helpers";
import TotalDurations from "../TotalDurations";
import Adjustments from "./Adjustments";
import styles from "../tc.module.scss";
import { refresherAction } from "../../../core/tools/interfaces";
import { SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo } from "../../../core/api/generated/conterra";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../../Cards/CardManagement";
import { ModalRef } from "../../Common/Modal/Modal";
import { RunScriptAsync } from "../../../helpers/runscripts";
import { cardId, pageId } from "../../../helpers/interfaces";
import AdjustmentsEdit from "./AdjustmentsEdit";
import { TRenderToolbar } from "../../TabsNew/interfaces";
import SideBarToggleBtn from "../../Common/SideBar/SideBarToggleBtn";
import { showSomeError } from "../../../helpers/errorHelpers";
import { serverSettings } from "../../../helpers/settings";

interface IProps {
  tcId?: number;
  modalTCMapWindowStage?: string | null;
  isActive: boolean;
  parentId?: pageId | cardId;
  renderToolbar?: TRenderToolbar;
  reloadDependency?: unknown;

  refreshTC(): void;
}

const TCMap = (props: IProps) => {
  const {
    tcId,
    modalTCMapWindowStage,
    isActive,
    reloadDependency,
    renderToolbar,
    refreshTC,
    parentId,
  } = props;
  const isCard = parentId === "TCMapCard";
  const isEditMode = useBooleanState(isCard);
  const refreshTCMapKey = useRefresher();
  const mapResizeRefresher = useRefresher();
  const loading = useBooleanState(false);
  const [state, setState] = useState<IAdjustedTimelinesData>();
  const mapHidden = useBooleanState(!!modalTCMapWindowStage);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentTcId = useLatestNotEmptyValue(tcId);
  useEffect(() => {
    if (!mapHidden.value) mapResizeRefresher();
  }, [modalTCMapWindowStage, mapHidden.value]);

  const loadData = useCallback(
    async (TCId: number) => {
      try {
        loading.setTrue();
        if (!isCard) isEditMode.setFalse();
        const [data] = await api.sql.dbTkGetTcAdjustmentInfo({ TCId });
        const {
          timeCardInfo,
          adjustmentTimeLines,
          adjustmentAllocation,
          auditAdjustmentInfo,
          teTimeLines,
          teAllocation,
        } = data;
        const adjustmentInfo = data.adjustmentInfo[0];
        const timeLine = adjustmentInfo ? adjustmentTimeLines : teTimeLines;
        const allocation = adjustmentInfo ? adjustmentAllocation : teAllocation;

        const {
          timeline: preparedTimeline,
          totalApprovedHours,
          totalClockedHours,
        } = GetPreparedAdjustedTimeline(timeLine, allocation);
        const lunchHours =
          (adjustmentInfo
            ? adjustmentInfo.lunchDuration
            : auditAdjustmentInfo[0].lunchDuration) || undefined;
        setState({
          timeCardInfo: timeCardInfo[0],
          adjustmentInfo: adjustmentInfo,
          auditAdjustmentInfo: auditAdjustmentInfo[0],
          timeline: preparedTimeline,
          totals: {
            clockedHours: totalClockedHours,
            approvedHours: totalApprovedHours,
            totalHours: totalApprovedHours - (lunchHours || 0),
            lunchHours,
          },
        });
      } catch (e) {
        showSomeError(e);
      } finally {
        loading.setFalse();
      }
    },
    [loading, setState],
  );

  const lastAppliedDependency = useRef<unknown>(reloadDependency);
  useEffect(() => {
    if (currentTcId) loadData(currentTcId);
  }, []);
  useEffect(() => {
    if (
      currentTcId &&
      isActive &&
      lastAppliedDependency.current !== reloadDependency
    ) {
      lastAppliedDependency.current = reloadDependency;
      loadData(currentTcId);
      refreshTCMapKey();
    }
  }, [currentTcId, loadData, reloadDependency, isActive]);

  const manageAdjustment = useCallback(
    async (action: "Apply" | "Delete" | "Reject") => {
      if (!state?.adjustmentInfo) return;
      const { id } = state.adjustmentInfo;
      try {
        ModalRef.startProcessing();
        await RunScriptAsync("TKAdjustments_Manage", {
          AdjID: id,
          Action: action,
        });
        refreshTC();
      } catch (e) {
        showSomeError(e);
      } finally {
        ModalRef.stopProcessing();
      }
    },
    [state],
  );

  const renderToggleMapBtn = () => {
    return (
      <div style={{ width: 16, position: "relative" }}>
        <SideBarToggleBtn
          toggle={mapHidden.toggle}
          btnText={"Map"}
          opened={!mapHidden.value}
          isRightSide={true}
        />
      </div>
    );
  };

  if (!currentTcId) return null;

  const doShowAdjustmentsPanel =
    !!state?.adjustmentInfo?.id ||
    (!!state?.auditAdjustmentInfo?.canChange && isEditMode.value);

  return (
    <>
      {renderToolbar?.({
        actionBtns: (
          <>
            {!isEditMode.value &&
              !state?.adjustmentInfo &&
              !!state?.auditAdjustmentInfo?.canChange && (
                <Button
                  iconClass={"mdi mdi-pencil-outline"}
                  fillMode={"flat"}
                  onClick={isEditMode.setTrue}
                >
                  Create Adjustments
                </Button>
              )}
          </>
        ),
      })}
      <div
        ref={containerRef}
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          overflow: "hidden",
          height: !renderToolbar ? "100%" : undefined,
        }}
      >
        {renderToggleMapBtn()}
        <div style={{ flex: 1, display: "flex" }}>
          <LiveMap
            isActive={isActive}
            tcId={currentTcId}
            sideBarBtnText={`GPS Timeline`}
            resizeRefresher={mapResizeRefresher.value}
            refreshKey={refreshTCMapKey.value}
            isMapHidden={mapHidden.value}
          />
          {!loading.value &&
          doShowAdjustmentsPanel &&
          !!state &&
          !!currentTcId ? (
            <AdjustmentsPanel
              isMapHidden={mapHidden.value}
              mapSizeRefresher={mapResizeRefresher}
            >
              <AdjustmentsPanelContent
                state={state}
                onAdjustmentsSave={refreshTC}
                tcId={currentTcId}
                manage={manageAdjustment}
                isEditMode={isEditMode}
              />
            </AdjustmentsPanel>
          ) : null}
        </div>
      </div>
    </>
  );
};

const AdjustmentsPanelContent = (props: {
  state: IAdjustedTimelinesData;
  onAdjustmentsSave(): void;
  manage(action: "Apply" | "Delete" | "Reject"): void;
  tcId: number;
  isEditMode: {
    setFalse: () => void;
    setTrue: () => void;
    toggle: () => void;
    value: boolean;
  };
}) => {
  const { state, onAdjustmentsSave, tcId, manage, isEditMode } = props;
  const { adjustmentInfo, auditAdjustmentInfo, ...restState } = state;

  const CanChange = adjustmentInfo
    ? adjustmentInfo.canChange
    : auditAdjustmentInfo?.canChange;

  const ResetAdjustments = useCallback(async () => {
    const AdjID = adjustmentInfo?.id;
    if (!AdjID) return;
    try {
      ModalRef.stopProcessing();
      await RunScriptAsync("TKAdjustments_Reset", { AdjID });
      onAdjustmentsSave();
    } finally {
      ModalRef.stopProcessing();
    }
  }, [adjustmentInfo, onAdjustmentsSave]);

  const renderAdjustmentAction = () => {
    if (!state) return;
    const { adjustmentInfo } = state;

    if (!adjustmentInfo) {
      if (!!state?.auditAdjustmentInfo?.canChange && isEditMode.value) {
        return (
          <Button
            icon={"cancel"}
            title={"Cancel adding Adjustments"}
            fillMode={"flat"}
            themeColor={"error"}
            onClick={isEditMode.setFalse}
          ></Button>
        );
      }
      return null;
    }
    if (!adjustmentInfo.isActive) {
      return (
        <Button
          icon={"check"}
          title={"Apply Adjustments"}
          themeColor={"success"}
          fillMode={"flat"}
          onClick={() => manage("Apply")}
        ></Button>
      );
    }

    if (adjustmentInfo.canDelete) {
      return (
        <Button
          icon={"trash"}
          title={"Delete Adjustments"}
          fillMode={"flat"}
          themeColor={"error"}
          onClick={() => manage("Delete")}
        ></Button>
      );
    }
    return (
      <Button
        icon={"cancel"}
        title={"Reject Adjustments"}
        themeColor={"error"}
        fillMode={"flat"}
        onClick={() => manage("Reject")}
      ></Button>
    );
  };

  return (
    <div
      className={formStyles.FormWrapper}
      style={{
        position: "relative",
        padding: "12px 4px 0",
        boxSizing: "border-box",
      }}
    >
      {CanChange ? (
        <AdjustmentsEdit
          onSave={onAdjustmentsSave}
          tcId={tcId}
          resetAdjustments={adjustmentInfo?.id ? ResetAdjustments : undefined}
          adjustmentInfo={{
            lunchDuration:
              (adjustmentInfo
                ? adjustmentInfo.lunchDuration
                : auditAdjustmentInfo?.lunchDuration) || null,
            comment: adjustmentInfo?.comment || null,
          }}
          {...restState}
          action={
            <>
              {!!adjustmentInfo && <AdjustmentsInfoBtn info={adjustmentInfo} />}
              {renderAdjustmentAction()}
            </>
          }
        />
      ) : (
        <>
          <TotalDurations values={state.totals} />
          <div style={{ flex: 1, overflow: "auto" }}>
            <Adjustments data={state.timeline} />
          </div>
          {!!state.adjustmentInfo?.comment && (
            <div className={styles.TCAdjustmentComment}>
              {state.adjustmentInfo.comment}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 8,
              padding: 12,
              marginTop: "auto",
            }}
          >
            {!!adjustmentInfo && <AdjustmentsInfoBtn info={adjustmentInfo} />}
            {renderAdjustmentAction()}
          </div>
        </>
      )}
    </div>
  );
};

const AdjustmentsPanel = ({
  mapSizeRefresher,
  isMapHidden,
  children,
}: {
  mapSizeRefresher: refresherAction;
  isMapHidden: boolean;
  children: any;
}) => {
  const [isAdjustmentsPanelOpened, setAdjustmentsPanelOpened] = useState(true);
  const onToggleSideBar = (state: boolean) => {
    setAdjustmentsPanelOpened(state);
  };

  useEffect(() => {
    if (!isMapHidden) mapSizeRefresher();
  }, [isMapHidden, isAdjustmentsPanelOpened]);
  const settings = serverSettings.getServerSettings();
  let adjustmentsPanelWidth = 430;
  if (
    settings &&
    settings["TKEnableGPSStates"] &&
    settings["TKEnableTaxCodes"]
  ) {
    adjustmentsPanelWidth = 490;
  }
  return (
    <SideBar
      boxStyle={{
        flex:
          isAdjustmentsPanelOpened && isMapHidden
            ? 1
            : isAdjustmentsPanelOpened
              ? `1 0 ${adjustmentsPanelWidth + 16}px`
              : "1 0 16px",
        height: "100%",
      }}
      width={
        isAdjustmentsPanelOpened
          ? isMapHidden
            ? "100%"
            : adjustmentsPanelWidth
          : 16
      }
      defaultOpened={true}
      mode="push"
      onToggle={onToggleSideBar}
      insideContent={children}
      btnText={"Adjustments"}
      isRightSide={true}
    ></SideBar>
  );
};

const AdjustmentsInfoBtn = (props: {
  info: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo;
}) => {
  const { info } = props;
  const renderStatus = () => {
    if (info.canChange) {
      return <span>Adjusted</span>;
    }
    if (info.isActive) {
      return <span style={{ color: "green" }}>Applied</span>;
    }
    return <span style={{ color: "red" }}>Rejected</span>;
  };

  const renderContent = useCallback(() => {
    const { authorName, lastChangeDate, lastChangeByName } = info;
    return (
      <>
        <div>Status: {renderStatus()}</div>
        <div>Author: {authorName}</div>
        <div>
          Last Change{" "}
          {!!lastChangeDate && moment(lastChangeDate).format("L LT")} by{" "}
          {lastChangeByName}
        </div>
        <div
          style={{
            marginTop: 8,
            textAlign: "center",
            opacity: 0.6,
            fontSize: "90%",
          }}
        >
          Click to Open History
        </div>
      </>
    );
  }, [info]);

  return (
    <Tooltip
      anchorElement="target"
      position="top"
      parentTitle={true}
      style={{ width: "190px" }}
      openDelay={400}
      showCallout={true}
      content={renderContent}
    >
      <Button
        iconClass="mdi mdi-information-outline"
        fillMode={"flat"}
        themeColor={"info"}
        onClick={() => {
          const { id } = info;
          if (id) CardManagement.OpenRecordHistoryCard(id);
        }}
        title={info.authorName || "Author"}
      ></Button>
    </Tooltip>
  );
};

export default TCMap;
