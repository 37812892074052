import { useCalendarContext } from "./CalendarContext";
import { DispatchWithoutAction, useCallback, useEffect, useState } from "react";
import { IsDesktopIntegrationOn } from "../../helpers/helpers";
import { RunScriptAsync, RunScriptLocal } from "../../helpers/runscripts";
import CardManagement from "../../Components/Cards/CardManagement";
import { showSomeError } from "../../helpers/errorHelpers";
import { SQL_DispatchCalendar_PeriodData_Response_Dispatches } from "../../core/api/generated/conterra";
import moment from "moment/moment";
import styles from "./calendar.module.scss";

type ContextMenuItem = {
  id: string;
  dispatchId: number;
  title: string;
  action: DispatchWithoutAction;
};

export const CalendarContextMenu = () => {
  const ctx = useCalendarContext();

  const [menuItems, setMenuItems] = useState<ContextMenuItem[]>([]);

  const getMenuItems = useCallback(
    (
      dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches,
      date: moment.Moment,
    ) => {
      const data = new Array<ContextMenuItem>();

      data.push({
        id: `copy_${dispatch.id}`,
        dispatchId: dispatch.id,
        title: "Copy Dispatch",
        action: () => {
          CardManagement.OpenDispatchCard({
            newDispatch: true,
            buildPlanId: ctx.buildPlan.buildPlanId,
            dsId: dispatch.id,
            afterSave: ctx.data.refresh,
          });
        },
      });

      if (IsDesktopIntegrationOn()) {
        data.push({
          id: `time_cards_${dispatch.id}`,
          dispatchId: dispatch.id,
          title: "Time Cards",
          action: () => {
            RunScriptLocal("ShowDispatchTimeCards", {
              DispatchID: dispatch.id,
            });
          },
        });
      }

      if (dispatch.details) {
        const details = dispatch.details;

        data.push({
          id: `build_plan_${dispatch.id}`,
          dispatchId: dispatch.id,
          title: "Build Plan",
          action: () => {
            CardManagement.OpenBPCard(details.buildPlanId, ctx.data.refresh);
          },
        });

        data.push({
          id: `checklist_${dispatch.id}`,
          dispatchId: dispatch.id,
          title: "Checklist Results",
          action: () => {
            CardManagement.OpenClmReviewInterface(
              details.buildPlanId,
              details.workOrderId,
            );
          },
        });

        data.push({
          id: `map_${dispatch.id}`,
          dispatchId: dispatch.id,
          title: "Map",
          action: () => {
            ctx.setSelectedDate(date);
            ctx.changeMode("map", dispatch.details?.buildPlanId);
          },
        });
      }

      data.push({
        id: `delete_${dispatch.id}`,
        dispatchId: dispatch.id,
        title: "Delete Dispatch",
        action: () => {
          RunScriptAsync(
            "WAFSM_DeleteDS",
            {
              DSID: dispatch.id,
            },
            true,
          )
            .catch(showSomeError)
            .finally(ctx.data.refresh);
        },
      });

      return data;
    },
    [ctx],
  );

  useEffect(() => {
    const controller = new AbortController();

    if (
      ctx.contextMenu.state.open &&
      ctx.contextMenu.state.dispatch &&
      ctx.contextMenu.state.date
    ) {
      document.addEventListener(
        "click",
        () => {
          ctx.contextMenu.close();
        },
        { signal: controller.signal },
      );

      setMenuItems(
        getMenuItems(
          ctx.contextMenu.state.dispatch,
          ctx.contextMenu.state.date,
        ),
      );
    }

    return () => {
      controller.abort();
    };
  }, [ctx, ctx.contextMenu.state.open, getMenuItems]);

  return ctx.contextMenu.state.open &&
    ctx.contextMenu.state.dispatch &&
    ctx.contextMenu.state.date ? (
    <div
      className={styles.CalendarContextMenu}
      style={{
        left: ctx.contextMenu.state.position?.x,
        top: ctx.contextMenu.state.position?.y,
      }}
    >
      {menuItems.map((x) => (
        <div
          className={styles.CalendarContextMenuItem}
          key={x.id}
          onClick={x.action}
        >
          {x.title}
        </div>
      ))}
    </div>
  ) : null;
};
