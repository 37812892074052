import React from "react";
import { INewComboboxItem, simpleObject } from "../../helpers/interfaces";
import { tabId } from "../../Components/Tabs/interfaces";
import {
  gridChangeDataAction,
  IColumnSetting,
} from "../../Components/Dashboard/interfaces";
import DashboardGrid from "../../Components/Dashboard/Grid";
import BaseComponent from "../../Components/BaseComponent";
import api from "../../core/api/api";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../../Components/Cards/CardManagement";
import {
  PropsSQLWHMIDashboardResponse,
  SQL_WH_MI_Dashboard_Response,
} from "../../core/api/generated/conterra";
import { ModalRef } from "../../Components/Common/Modal/Modal";
import { IsDesktopIntegrationOn } from "../../helpers/helpers";
import ButtonLink from "../../Components/Common/Buttons/ButtonLink";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import FilterCombobox from "../../Components/Common/Form/FilterCombobox";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { IsWarehouseManager } from "../../core/tools/roles";
import { OpenRecord } from "../../helpers/runscripts";
import { showSomeError } from "../../helpers/errorHelpers";

interface IState {
  remountCheckboxesKey: number;
  contextMenu:
    | {
        position: {
          left: number;
          top: number;
        };
        materialItemId: number;
        canDelete: boolean;
        canVoid: boolean;
        rowData: SQL_WH_MI_Dashboard_Response;
      }
    | undefined;
}

interface IProps {
  buildPlanId?: number;
  doNotSaveFilters?: boolean;
  isActive: boolean;

  switchTab(tabId: tabId): void;

  onChangeGridData?(
    data: Array<simpleObject>,
    action?: gridChangeDataAction,
  ): void;
}

export default class MaterialItems extends BaseComponent<IProps, IState> {
  bpId = this.props.buildPlanId;
  gridRef: any = React.createRef();
  isBPTab: boolean = !!this.props.doNotSaveFilters;
  COLUMNS: IColumnSetting[];
  COLUMNS_BP_TAB: IColumnSetting[];
  checkedItems: number[] = [];
  allData: SQL_WH_MI_Dashboard_Response[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      remountCheckboxesKey: +new Date(),
      contextMenu: undefined,
    };

    const checkColumn: IColumnSetting = {
      field: "checked",
      title: " ",
      type: "string",
      filterable: false,
      columnMenu: false,
      sortable: false,
      format: {
        type: "string",
        customFormat: true,
        getCustomFormat: this.renderColumnCheck,
      },
      titleHTML: this.renderCheckAll(),
      gridWidth: 40,
    };
    const stageColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.stageTitle,
      title: "Stage",
      type: "string",
      gridWidth: 150,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.stageId,
      },
    };
    const whColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.warehouseName,
      title: "Warehouse",
      type: "string",
      gridWidth: 150,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.warehouseId,
      },
    };
    const whAreaColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.warehouseAreaNumber,
      title: "Area",
      type: "string",
      gridWidth: 150,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.warehouseAreaId,
      },
    };
    const materialColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.materialName,
      title: "Material",
      type: "string",
      gridWidth: 280,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.materialId,
      },
    };

    const snColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.sn,
      title: "SN",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
        customFormat: true,
        getCustomFormat: this.renderSNLink,
      },
    };
    const assetTagColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.tag,
      title: "Tag #",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
      },
    };
    const sapColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.assembly,
      title: "Assembly #",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
      },
    };

    const createdAtColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.createdAt,
      title: "Created",
      type: "date",
      format: {
        type: "date",
      },
    };
    const daysPastColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.daysPast,
      title: "Days Past",
      type: "number",
      gridWidth: 90,
    };
    const isBrokenColumn: IColumnSetting = {
      field: "isBrokenStr",
      title: "Broken",
      type: "string",
      gridWidth: 90,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.isBroken,
      },
    };
    const shipmentPackageColumn: IColumnSetting = {
      field: PropsSQLWHMIDashboardResponse.shipmentPackageNumber,
      title: "Shipment Package",
      type: "string",
      gridWidth: 150,
      format: {
        type: "string",
        fieldId: PropsSQLWHMIDashboardResponse.shipmentPackageId,
      },
    };
    const vendorColumnsGroup: IColumnSetting = {
      field: "",
      title: "Vendor",
      gridWidth: 300,
      type: "parent",
      columns: [
        {
          field: PropsSQLWHMIDashboardResponse.vendorName,
          title: "Name",
          type: "string",
          gridWidth: 150,
          format: {
            type: "string",
            fieldId: PropsSQLWHMIDashboardResponse.vendorId,
          },
        },
        {
          field: PropsSQLWHMIDashboardResponse.vendorPartNo,
          title: "Part #",
          type: "string",
          gridWidth: 120,
        },
        {
          field: PropsSQLWHMIDashboardResponse.mfgProductNo,
          title: "MFG Product #",
          type: "string",
          gridWidth: 120,
        },
      ],
    };
    this.COLUMNS = [
      checkColumn,
      stageColumn,
      whColumn,
      whAreaColumn,
      materialColumn,
      snColumn,
      assetTagColumn,
      sapColumn,
      createdAtColumn,
      daysPastColumn,
      isBrokenColumn,
      {
        field: PropsSQLWHMIDashboardResponse.buildPlanName,
        title: "Build Plan",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: PropsSQLWHMIDashboardResponse.buildPlanId,
          refName: "FSMBuildPlans",
        },
      },
      {
        field: PropsSQLWHMIDashboardResponse.siteName,
        title: "Site",
        type: "string",
        gridWidth: 100,
        format: {
          type: "link",
          fieldId: PropsSQLWHMIDashboardResponse.siteId,
          refName: "FSMSites",
        },
      },
      {
        field: "",
        title: "Active BOM",
        gridWidth: 300,
        type: "parent",
        columns: [
          {
            field: PropsSQLWHMIDashboardResponse.boM_Number,
            title: "Number",
            type: "string",
            gridWidth: 150,
            format: {
              type: "link",
              fieldId: PropsSQLWHMIDashboardResponse.boM_Id,
              refName: "FSMBOM",
            },
          },
          {
            field: PropsSQLWHMIDashboardResponse.boM_Date,
            title: "Date",
            type: "date",
            format: {
              type: "date",
            },
          },
          {
            field: PropsSQLWHMIDashboardResponse.boM_DaysPast,
            title: "Days Past",
            type: "number",
            gridWidth: 90,
          },
          {
            field: PropsSQLWHMIDashboardResponse.boM_DueDate,
            title: "Due Date",
            type: "date",
            format: {
              type: "date",
            },
          },
        ],
      },
      shipmentPackageColumn,
      vendorColumnsGroup,
    ];

    this.COLUMNS_BP_TAB = [
      checkColumn,
      stageColumn,
      whColumn,
      whAreaColumn,
      materialColumn,
      snColumn,
      assetTagColumn,
      sapColumn,
      createdAtColumn,
      daysPastColumn,
      isBrokenColumn,
      {
        field: PropsSQLWHMIDashboardResponse.boM_Number,
        title: "BOM",
        type: "string",
        gridWidth: 150,
        format: {
          type: "link",
          fieldId: PropsSQLWHMIDashboardResponse.boM_Id,
          refName: "FSMBOM",
        },
      },
      shipmentPackageColumn,
      vendorColumnsGroup,
    ];
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("click", this.CloseContextMenu);
  }

  renderSNLink = (dataItem: SQL_WH_MI_Dashboard_Response) => (
    <ButtonLink
      text={dataItem.sn}
      dataAttr={dataItem.id}
      onClick={(
        e: React.BaseSyntheticEvent<object, any, any> | undefined,
        dataAttr: number,
      ) => {
        CardManagement.OpenMaterialItemCard(dataAttr, this.Refresh);
      }}
    />
  );

  renderCheckAll = () => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all", width: 32 }}>
        <Checkbox
          key={`all-${this.state.remountCheckboxesKey}`}
          id={"Allcheck"}
          name={"all"}
          label={" "}
          onChange={this.OnChangeCheckAll}
        />
      </div>
    );
  };

  renderColumnCheck = ({ id }: SQL_WH_MI_Dashboard_Response) => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all" }}>
        <Checkbox
          key={`${id}-${this.state.remountCheckboxesKey}`}
          id={id + "micheck"}
          name={id + ""}
          label={" "}
          checked={
            this.checkedItems.findIndex((checkedId) => checkedId === id) > -1
          }
          onChange={this.OnChangeCheck}
        />
      </div>
    );
  };

  renderButtons = () => {
    return (
      <>
        <Button
          iconClass={"mdi mdi-swap-horizontal"}
          disabled={!this.checkedItems.length}
          fillMode={"flat"}
          onClick={() =>
            CardManagement.OpenChangeWHAreaForMIsCard(
              this.checkedItems,
              this.Refresh,
            )
          }
        >
          Change WH Area
        </Button>
        <Button
          iconClass={"mdi mdi-attachment-remove"}
          disabled={!this.checkedItems.length}
          fillMode={"flat"}
          onClick={() => this.RemoveItemsFromBP()}
        >
          Remove From BP
        </Button>
        {!this.props.buildPlanId && (
          <Button
            iconClass={"mdi mdi-attachment-plus"}
            disabled={!this.checkedItems.length}
            fillMode={"flat"}
            onClick={() => this.SelectBP()}
          >
            Add TO BP
          </Button>
        )}
        <Button
          iconClass={"mdi mdi-arrow-left-top"}
          disabled={!this.checkedItems.length}
          fillMode={"flat"}
          onClick={() => this.ReturnToManufacturer()}
        >
          Return to Manufacturer
        </Button>
      </>
    );
  };

  renderToolbarActionBtns = () => {
    return (
      <>
        {IsWarehouseManager() ? (
          <Button
            onClick={() => {
              CardManagement.OpenNewMICard({
                afterCreate: this.Refresh,
                buildPlanId: this.props.buildPlanId,
              });
            }}
            fillMode={"flat"}
          >
            New MI
          </Button>
        ) : null}
        {this.props.buildPlanId ? (
          <Button fillMode={"flat"} onClick={this.AddToBuildPlan}>
            Add Items to Build Plan
          </Button>
        ) : null}
      </>
    );
  };

  render() {
    if (
      this.isBPTab &&
      this.props.isActive &&
      this.bpId !== this.props.buildPlanId
    ) {
      this.bpId = this.props.buildPlanId;
    }

    const bpId = this.props.buildPlanId;
    const key = this.isBPTab ? bpId : "inventoryMaterialItems";
    if (this.isBPTab && !bpId) return null;
    if (
      this.isBPTab &&
      (!this.props.buildPlanId ||
        (!this.props.isActive && this.bpId !== this.props.buildPlanId))
    ) {
      return null;
    }

    return (
      <>
        <DashboardGrid
          isActive={this.props.isActive}
          key={key}
          ref={this.gridRef}
          getData={this.LoadGridData}
          saveSettings={
            this.props.buildPlanId
              ? undefined
              : { prefix: "MaterialItems", saveFilters: true }
          }
          excelFileName="MaterialItems.xlsx"
          initRefresh={this.InitRefresh}
          columnsSettings={
            this.props.buildPlanId ? this.COLUMNS_BP_TAB : this.COLUMNS
          }
          columnMenu={{
            filters: true,
            columns: false,
          }}
          selectSettings={{
            pageId: "InboxDashboard",
            onSelectRow: (dataItem: SQL_WH_MI_Dashboard_Response | null) => {
              if (dataItem) {
                CardManagement.OpenMaterialItemCard(dataItem.id, this.Refresh);
              }
            },
          }}
          getRowKey={(dataItem: simpleObject) => dataItem.Id}
          onChangeGridData={this.props.onChangeGridData}
          toolbar={{
            switcher: this.renderButtons(),
            actionBtns: this.renderToolbarActionBtns(),
          }}
          onRowContextMenu={this.OnRowContextMenu}
        />
        <Popup
          offset={this.state.contextMenu?.position}
          show={!!this.state.contextMenu}
        >
          <Menu
            vertical={true}
            onSelect={this.OnSelectContextMenuItem}
            className={"k-context-menu"}
          >
            {IsWarehouseManager() && (
              <MenuItem key={"copy"} data={"copy"} text="Copy"></MenuItem>
            )}
            <MenuItem
              key={"addToBP"}
              data={"addToBP"}
              text="Add to BP"
            ></MenuItem>
            <MenuItem
              key={"removeFromBP"}
              data={"removeFromBP"}
              text="Remove From BP"
            ></MenuItem>
            <MenuItem
              key={"changeWHArea"}
              data={"changeWHArea"}
              text="Change WH Area"
            ></MenuItem>
            <MenuItem
              key={"returnToManufacturer"}
              data={"returnToManufacturer"}
              text="Return to Manufacturer"
            ></MenuItem>
            {this.state.contextMenu?.canVoid && (
              <MenuItem key={"void"} data={"void"} text="Void"></MenuItem>
            )}
            {this.state.contextMenu?.canDelete && (
              <MenuItem key={"delete"} data={"delete"} text="Delete"></MenuItem>
            )}
          </Menu>
        </Popup>
      </>
    );
  }

  SelectBP = async (materialItemId?: number) => {
    let selectedBP: INewComboboxItem | null = null;
    ModalRef.showDialog({
      title: "Select Build Plan",
      width: 300,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: async () => {
            if (!selectedBP) return;
            ModalRef.hideDialog();
            try {
              ModalRef.startProcessing();
              await api.wh.mi.setBuildPlan({
                materialItemIds: materialItemId
                  ? [materialItemId]
                  : this.checkedItems,
                buildPlanId: +selectedBP.id,
              });
              this.Refresh();
              this.ClearChecked();
            } catch (e) {
              showSomeError(e);
            } finally {
              ModalRef.stopProcessing();
            }
          },
        },
      ],
      children: (
        <div>
          <FilterCombobox
            placeholder={"Select BP"}
            onChange={(value: INewComboboxItem | null) => {
              selectedBP = value;
            }}
            getData={api.sql.whMiAvailableBuildPlans}
            dataItemKey={"id"}
            textField={"name"}
            required={true}
          />
        </div>
      ),
    });
  };

  OnChangeCheckAll = (e: CheckboxChangeEvent) => {
    const { value: checked } = e.target;
    if (!checked) {
      this.ClearChecked();
    } else {
      this.checkedItems = this.allData.map((item) => item.id);
      this.UpdateCheckBoxes();
    }
  };

  OnChangeCheck = (e: CheckboxChangeEvent) => {
    const { value: checked, name: idStr } = e.target;

    if (checked) {
      this.checkedItems.push(+idStr!);
    } else {
      this.checkedItems = this.checkedItems.filter((id) => id !== +idStr!);
    }

    this.UpdateCheckBoxes();
  };

  ClearChecked = () => {
    this.checkedItems = [];
    this.UpdateCheckBoxes();
  };

  UpdateCheckBoxes = () => {
    this.setState({
      remountCheckboxesKey: +new Date(),
    });
  };

  CloseContextMenu = () => {
    this.setState({ contextMenu: undefined });
  };

  OnSelectContextMenuItem = (event: MenuSelectEvent) => {
    const action = event.item.data;
    const { contextMenu } = this.state;
    if (!contextMenu || !action) return;
    const { materialItemId, rowData } = contextMenu;
    if (action === "removeFromBP") {
      this.RemoveItemsFromBP(materialItemId);
    } else if (action === "addToBP") {
      this.SelectBP(materialItemId);
    } else if (action === "changeWHArea") {
      CardManagement.OpenChangeWHAreaForMIsCard([materialItemId], this.Refresh);
    } else if (action === "returnToManufacturer") {
      this.ReturnToManufacturer(materialItemId);
    } else if (action === "delete") {
      this.ConfirmAndDeleteMI(materialItemId);
    } else if (action === "void") {
      this.VoidMI(materialItemId);
    } else if (action === "copy") {
      const {
        sn,
        stageId,
        warehouseId,
        warehouseName,
        warehouseAreaNumber,
        warehouseAreaId,
        materialName,
        materialId,
        buildPlanName,
        buildPlanId,
        vendorId,
        vendorName,
        tag,
        assembly,
      } = rowData;

      CardManagement.OpenNewMICard({
        afterCreate: this.Refresh,
        buildPlanId: this.props.buildPlanId,
        defaultValues: {
          warehouse:
            stageId !== "Void" && warehouseId && warehouseName
              ? { id: warehouseId, name: warehouseName }
              : undefined,
          area:
            warehouseAreaId && warehouseAreaNumber && warehouseId
              ? {
                  id: warehouseAreaId,
                  number: warehouseAreaNumber,
                  warehouseId,
                  itemsCount: 0,
                  isActive: true,
                }
              : undefined,
          material: { id: materialId, name: materialName },
          buildPlan:
            buildPlanId && buildPlanName
              ? { id: buildPlanId, name: buildPlanName }
              : undefined,
          sn: stageId === "Void" ? sn : undefined,
          tag: stageId === "Void" ? tag : undefined,
          assembly: stageId === "Void" ? assembly : undefined,
          vendor: { id: vendorId, name: vendorName },
        },
      });
    }
  };

  VoidMI = async (materialItemId: number) => {
    try {
      ModalRef.startProcessing();
      await api.wh.mi.void(materialItemId);
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
      this.Refresh();
    }
  };

  ConfirmAndDeleteMI = (materialItemId: number) => {
    ModalRef.showDialog({
      title: "Confirmation",
      text: `Delete Material Item?`,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
            this.CloseContextMenu();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: async () => {
            ModalRef.hideDialog();
            this.CloseContextMenu();
            try {
              ModalRef.startProcessing();
              await api.wh.mi.delete(materialItemId);
            } catch (e) {
              showSomeError(e);
            } finally {
              ModalRef.stopProcessing();
              this.Refresh();
            }
          },
        },
      ],
    });
  };

  OnRowContextMenu = (dataItem: SQL_WH_MI_Dashboard_Response, e: any) => {
    const { clientX: left, clientY: top } = e;
    this.setState({
      contextMenu: {
        position: { left, top },
        materialItemId: dataItem.id,
        canDelete:
          dataItem.stageId === "NotAssignedToBom" && IsWarehouseManager(),
        canVoid:
          dataItem.stageId === "NotAssignedToBom" && IsWarehouseManager(),
        rowData: dataItem,
      },
    });
    document.addEventListener("click", this.CloseContextMenu);
  };

  RemoveItemsFromBP = (materialItemId?: number) => {
    const Ids = materialItemId ? [materialItemId] : this.checkedItems;
    ModalRef.showDialog({
      title: "Material Items Action",
      text: `Clear Assigned Build Plan for ${Ids.length} Material Item${
        Ids.length ? "s" : ""
      }?`,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Clear",
          color: "primary",
          action: async () => {
            ModalRef.hideDialog();
            try {
              ModalRef.startProcessing("Clearing Material Item Build Plan");
              await api.wh.mi.setBuildPlan({
                buildPlanId: undefined,
                materialItemIds: Ids,
              });
              this.ClearChecked();
            } catch (e) {
              showSomeError(e);
            } finally {
              ModalRef.stopProcessing();
              this.Refresh();
            }
          },
        },
      ],
    });
  };

  ReturnToManufacturer = (materialItemId?: number) => {
    const Ids = materialItemId ? [materialItemId] : this.checkedItems;
    ModalRef.showDialog({
      title: "Material Items Action",
      text: `Return ${Ids.length} Material Item${
        Ids.length ? "s" : ""
      } to Manufacturer?`,
      buttons: [
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
        {
          text: "Ok",
          color: "primary",
          action: async () => {
            ModalRef.hideDialog();
            try {
              ModalRef.startProcessing("Returning to Manufacturer");
              const idDto = await api.wh.mi.returnToManufacturer({
                materialItemIds: Ids,
              });
              if (idDto) this.ReturnToManufacturerSuccess(idDto.id);
              this.ClearChecked();
            } catch (e) {
              showSomeError(e);
            } finally {
              ModalRef.stopProcessing();
              this.Refresh();
            }
          },
        },
      ],
    });
  };

  ReturnToManufacturerSuccess = (id: number) => {
    ModalRef.showDialog({
      type: "success",
      title: "Successful",
      children: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Inventory Transaction Journal record is created.</span>
          {IsDesktopIntegrationOn() && (
            <Button
              onClick={() => {
                OpenRecord("ICTransactionsJournal", id);
              }}
              fillMode={"flat"}
              themeColor={"primary"}
            >
              Open Record
            </Button>
          )}
        </div>
      ),
    });
  };

  AddToBuildPlan = () => {
    CardManagement.OpenAssignMaterialItemsToBPCard(
      this.Refresh,
      this.props.buildPlanId,
    );
  };

  LoadGridData = async () => {
    const data = await api.sql.whMiDashboard({
      buildPlanId: this.props.buildPlanId,
    });
    this.allData = data.map((x) => ({
      ...x,
      isBrokenStr: x.isBroken ? "Yes" : "No",
    }));
    return this.allData;
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}
