import { AgGridReact, CustomHeaderProps } from "ag-grid-react";
import {
  CellClassParams,
  ColDef,
  Column,
  ColumnPivotModeChangedEvent,
  EditableCallbackParams,
  GetRowIdFunc,
  GridReadyEvent,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  NewValueParams,
  SetFilterModel,
  SideBarDef,
  ValueGetterParams,
} from "ag-grid-enterprise";
import { useLazyAsync } from "../../../../core/tools/Hooks";
import api from "../../../../core/api/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoaderComponent from "../../../../Components/Common/Loader";
import { showSomeError } from "../../../../helpers/errorHelpers";
import {
  PropsSQLDBStatusResponse,
  PropsSQLDBStatusResponseBOM,
  PropsSQLDBStatusResponseFinancial,
  PropsSQLDBStatusResponseRoles,
  SQL_DB_Status_Response,
  SQL_DB_Status_Response_ScenarioMilestones,
} from "../../../../core/api/generated/conterra";
import OpenCardLink from "../../../../Components/OpenCardLink";
import ViewsToolPanel from "../../SideBar/Views";
import StatusPanel_ReloadRows from "../../StatusBar/Reload";
import StatusPanel_ClearFilters from "../../StatusBar/ClearFilters";
import DataToolPanel, { DataToolPanelProps } from "../../SideBar/Data";
import {
  CONTERRA_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from "../../../../core/tools/formats";
import moment from "moment";
import styles from "../../aggrid.module.scss";
import { Chip } from "@progress/kendo-react-buttons";
import { IUpdateParams } from "../../../Milestones/interfaces";
import { RunScriptAsync } from "../../../../helpers/runscripts";
import { ModalRef } from "../../../../Components/Common/Modal/Modal";
import { ReferenceRecordsDataSource } from "../../../../helpers/queries";
import { IComboboxItem } from "../../../../helpers/interfaces";
import {
  COMMON_COL_TYPES,
  COMMON_COL_TYPES_DESCRIPTION,
} from "../../commonColTypesDescription";
import StatusPanel_Actions from "../../StatusBar/Actions";
import StatusPanel_Export from "../../StatusBar/Export";
import StatusPanel_QuickFilterText from "../../StatusBar/QuickFilterText";
import {
  CurrentViewContext,
  predefinedViewsIds,
  predefinedViewsNames,
} from "../../helpers";
import StatusPanel_CurrentView from "../../StatusBar/CurrentView";

const HeaderArrowInnerComponent = (props: CustomHeaderProps) => {
  return (
    <div className={`customInnerHeader ${styles.THWithArrowInner}`}>
      <span>{props.displayName}</span>
      <span className={`mdi mdi-chevron-right ${styles.THArrow}`}></span>
    </div>
  );
};

function sumByBoolean(p: IAggFuncParams<SQL_DB_Status_Response>) {
  return p.values.reduce(
    (a, b) => a + (typeof b === "number" ? b : b ? 1 : 0),
    0,
  );
}

enum COL_TYPES {
  booleanForPivot = "booleanForPivot",
}

const getRowId: GetRowIdFunc<SQL_DB_Status_Response> = (p) => {
  return p.data.id.toString();
};
const bpStateLib = {
  true: "Active",
  false: "Closed",
};
export default function AGGridStatusDashboard() {
  const [selectedView, setSelectedView] = useState<{
    id: string;
    name: string;
  }>({ id: predefinedViewsIds.default, name: predefinedViewsNames["default"] });
  const [employees, setEmployees] = useState<IComboboxItem[]>();
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const result: IComboboxItem[] =
          await ReferenceRecordsDataSource("Employees");
        setEmployees([{ Id: 0, Name: "Not Assigned" }, ...result]);
      } catch (e: any) {
        showSomeError(e);
      }
    };
    getEmployees();
  }, []);
  const [getMilestones, milestones] = useLazyAsync(api.sql.dbStatusMilestones);
  useEffect(() => getMilestones(), [getMilestones]);
  const handleGridReady = useCallback(async (e: GridReadyEvent) => {
    await e.api.setColumnFilterModel(PropsSQLDBStatusResponse.active, {
      filterType: "set",
      values: ["true"],
    });
    e.api.onFilterChanged();
  }, []);
  const [getRowsMethod, rows] = useLazyAsync(api.sql.dbStatus);
  const [onDate, setOnDate] = useState<Date>();
  const getRows = useCallback(
    () =>
      getRowsMethod({
        onDate: onDate ? moment(onDate).format(ISO_DATE_FORMAT) : null,
      }),
    [getRowsMethod, onDate],
  );
  const updateMilestone = useCallback(
    async (params: IUpdateParams) => {
      ModalRef.startProcessing(undefined, "transparent");
      try {
        await RunScriptAsync("FSMBuildPlans_UpdateMilestone", params);
      } catch (error: any) {
        getRows();
        showSomeError(error);
      } finally {
        ModalRef.stopProcessing();
      }
    },
    [getRows],
  );

  const handlePivotModeToggle = useCallback(
    (e: ColumnPivotModeChangedEvent) => {
      if (
        selectedView.id !== predefinedViewsIds.ownerStats &&
        e.api.isPivotMode()
      ) {
        e.api.setValueColumns([]);
      }
    },
    [selectedView.id],
  );

  const MILESTONES_COLS = useMemo<ColDef[]>(() => {
    if (!milestones.data || !employees) return [];

    function isEditable(params: CellClassParams | EditableCallbackParams) {
      const colId = params.colDef.colId;
      if (!colId) return false;
      const msCode = colId.split("_")[0];
      const msId = milestoneByColId(colId).id;
      const hasMilestone = !!params.data?.scenarioMilestones.find(
        (x: SQL_DB_Status_Response_ScenarioMilestones) =>
          x.milestoneId === msId,
      );
      return (
        hasMilestone &&
        params.data?.milestones?.[`${msCode}_IsApplicable`] !== false
      );
    }

    function getCellClass(cellClassParams: CellClassParams) {
      if (cellClassParams.node.footer) return "";
      if (isEditable(cellClassParams)) return "";
      return styles.NotApplicableMilestoneCell;
    }

    function milestoneByColId(colId?: string) {
      if (!colId) throw new Error("Col ID is required");
      const msCodeLowerCase = colId.split("_")[0].toLowerCase();
      const milestone = milestones.data!.find(
        (x) => x.code.toLowerCase() === msCodeLowerCase,
      );
      if (!milestone) throw new Error("Milestone not found");
      return milestone;
    }

    function onProjectedChanged(event: NewValueParams) {
      const newValue = event.newValue;
      updateMilestone({
        BPID: event.data.id,
        MSID: milestoneByColId(event.colDef.colId).id,
        FieldName: "ProjectedDate",
        NewDate: newValue
          ? moment(newValue).format(CONTERRA_DATE_FORMAT)
          : null,
      });
    }

    function onActualChanged(event: NewValueParams) {
      const newValue = event.newValue;
      updateMilestone({
        BPID: event.data.id,
        MSID: milestoneByColId(event.colDef.colId).id,
        FieldName: "ActualDate",
        NewDate: newValue
          ? moment(newValue).format(CONTERRA_DATE_FORMAT)
          : null,
      });
    }

    function onResponsibleChanged(event: NewValueParams) {
      updateMilestone({
        BPID: event.data.id,
        MSID: milestoneByColId(event.colDef.colId).id,
        FieldName: "ResponsibleId",
        NewInteger: event.newValue || null,
      });
    }

    function onCommentsChanged(event: NewValueParams) {
      updateMilestone({
        BPID: event.data.id,
        MSID: milestoneByColId(event.colDef.colId).id,
        FieldName: "Comments",
        NewString: event.newValue,
      });
    }

    const columns: ColDef[] = [];
    milestones.data.forEach((ms) => {
      // костыль из-за преобразования имени поля на беке
      function msCodeConvertion(msCodeServer: string) {
        let msCodeFinal = "";
        let i = 0;
        let firstLowerCaseCharIndex = -1;
        for (let char of msCodeServer) {
          if (firstLowerCaseCharIndex !== -1) break;
          if (char.toLowerCase() === char) firstLowerCaseCharIndex = i;
          i++;
        }
        let j = 0;
        for (let char of msCodeServer) {
          if (j === 0 || j < firstLowerCaseCharIndex - 1) {
            msCodeFinal += char.toLowerCase();
          } else {
            msCodeFinal += char;
          }
          j++;
        }
        return msCodeFinal;
      }

      const lowerCamelCaseCode = msCodeConvertion(ms.code); //ms.code[0].toLowerCase() + ms.code.slice(1);
      // Projected
      columns.push({
        colId: lowerCamelCaseCode + "_Projected",
        field: "milestones." + lowerCamelCaseCode + "_Projected",
        headerName: ms.name + " Projected",
        type: COMMON_COL_TYPES.dateFormatted,
        cellClass: getCellClass,
        editable: isEditable,
        onCellValueChanged: onProjectedChanged,
        initialWidth: 160,
        minWidth: 150,
      });
      // Actual
      columns.push({
        colId: lowerCamelCaseCode + "_Actual",
        field: "milestones." + lowerCamelCaseCode + "_Actual",
        headerName: ms.name + " Actual",
        initialWidth: 160,
        minWidth: 150,
        type: COMMON_COL_TYPES.dateFormatted,
        cellClass: getCellClass,
        editable: isEditable,
        onCellValueChanged: onActualChanged,
      });
      // Responsible
      const employeesRefData: { [key: string]: string } = {};
      const employeesKeys: (number | string)[] = [];
      employees.forEach((x) => {
        employeesRefData[x.Id] = x.Name;
        employeesKeys.push(x.Id);
      });
      columns.push({
        colId: lowerCamelCaseCode + "_ResponsibleId",
        field: `milestones.${lowerCamelCaseCode}_ResponsibleId`,
        refData: employeesRefData,
        headerName: ms.name + " Responsible",
        cellEditor: "agRichSelectCellEditor",
        initialWidth: 150,
        minWidth: 150,
        cellEditorParams: {
          values: employeesKeys,
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
          searchType: "matchAny",
          valueListMaxWidth: 270,
          valuePlaceholder: "Responsible",
        },
        cellClass: getCellClass,
        editable: isEditable,
        onCellValueChanged: onResponsibleChanged,
      });
      // Comments
      columns.push({
        colId: lowerCamelCaseCode + "_Comments",
        field: "milestones." + lowerCamelCaseCode + "_Comments",
        headerName: ms.name + " Comments",
        initialWidth: 150,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        cellClass: (cellClassParams: CellClassParams) =>
          getCellClass(cellClassParams) || "comment-cell",
        editable: isEditable,
        onCellValueChanged: onCommentsChanged,
      });
    });
    return columns;
  }, [updateMilestone, employees, milestones.data]);

  useEffect(getRows, [getRows]);

  const DEFAULT_COL_DEF = useMemo(
    () =>
      ({
        filter: true,
        floatingFilter: true,
        enableRowGroup: true,
        initialHide: true,
        enablePivot: true,
        wrapText: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        filterParams: {
          buttons: ["reset", "apply"],
          excelMode: "windows", // apply btn works after filtering filterlist
        },
      }) as ColDef,
    [],
  );

  const COL_TYPES_DESCRIPTION = useMemo(
    () =>
      ({
        [COL_TYPES.booleanForPivot]: {
          aggFunc: sumByBoolean,
          enableValue: true,
          initialWidth: 150,
          minWidth: 120,
          cellRendererSelector: (
            p: ICellRendererParams<SQL_DB_Status_Response>,
          ) => {
            if (typeof p.value !== "number") return;
            return {
              component: (pp: ICellRendererParams<SQL_DB_Status_Response>) => (
                <div className={styles.CenterCellContent}>
                  <a
                    onClick={async () => {
                      if (!p.colDef?.colId) return;

                      let groupNode: IRowNode | null = p.node;
                      const filters: {
                        column: Column;
                        value: string | null;
                      }[] = [];
                      while (!!groupNode && groupNode.level >= 0) {
                        if (groupNode.rowGroupColumn) {
                          filters.push({
                            column: groupNode.rowGroupColumn,
                            value: groupNode.key,
                          });
                        }
                        groupNode = groupNode.parent;
                      }
                      filters.push({
                        column: p.column!,
                        value: "true",
                      });
                      p.api.updateGridOptions({ pivotMode: false });
                      p.api.setRowGroupColumns([]);
                      for (const filter of filters)
                        await p.api.setColumnFilterModel<SetFilterModel>(
                          filter.column,
                          {
                            filterType: "set",
                            values: [filter.value],
                          },
                        );
                      p.api.onFilterChanged();
                    }}
                  >
                    {pp.value}
                  </a>
                </div>
              ),
            };
          },
        },
      }) as Record<COL_TYPES, ColDef>,
    [],
  );
  const CALCULATED_COLS = useMemo(
    () =>
      [
        {
          colId: "_SOEmpty",
          headerName: "No Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !p.data?.excludeFromWIP &&
            p.data?.financial?.salesOrder === null,
        },
        {
          colId: "_BudgetNotApproved",
          headerName: "Budget not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !p.data?.financial?.budgetApproved &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasSpend",
          headerName: `Budget Not Approved, Has Spend`,
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !p.data?.financial?.budgetApproved &&
            (p.data?.financial?.totalSpend || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasRevenue",
          headerName: "Budget Not Approved, Has Revenue",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !p.data?.financial?.budgetApproved &&
            (p.data?.financial?.submittedInvoices || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotEqualSalesOrder",
          headerName: "Budget doesn't equal Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.financial?.soProjectedRevenueVariance &&
              !p.data?.excludeFromWIP
            );
          },
        },
        {
          colId: "_SpendExceedsBudget",
          headerName: "Spend Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !!p.data?.financial?.budgetApproved &&
            (p.data?.financial.totalSpend || 0) >
              (p.data?.financial.projectedSpend || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_RevenueExceedsBudget",
          headerName: "Revenue Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !!p.data?.financial?.budgetApproved &&
            (p.data?.financial.submittedInvoices || 0) >
              (p.data?.financial.projectedRevenue || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_CXStartPending",
          headerName: "CX Start Pending",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 165,
          minWidth: 165,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
          },
          headerClass: `${styles.THWithArrow} ${styles.THArrowBlack}`,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            p.data?.isOverheadScenario !== true &&
            !p.data?.milestones?.constStart_Actual,
        },
        {
          colId: "_CXStartActualCXCompletePending",
          headerName: "CX Started and not Completed",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 180,
          minWidth: 180,
          headerClass: `${styles.THWithArrow} ${styles.THArrowGreyPink}`,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
          },
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.milestones?.constStart_Actual &&
              !p.data?.milestones.consCompl_Actual
            );
          },
        },
        {
          colId: "_CXCompleteActualCOPApprovedPending",
          headerName: "CX Completed, COP not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 210,
          minWidth: 210,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.milestones?.consCompl_Actual &&
              !p.data?.milestones.copAppr_Actual
            );
          },
          headerClass: `${styles.THWithArrow} ${styles.THArrowBlue}`,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
          },
        },
        {
          colId: "_COPApprovedRemainingToInvoice",
          headerName: "COP Approved, w/ Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 210,
          minWidth: 210,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.milestones?.copAppr_Actual &&
              (p.data?.financial?.remainingToInvoice || 0) > 0
            );
          },
          headerClass: `${styles.THWithArrow} ${styles.THArrowGreenBlue}`,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
          },
        },
        {
          colId: "_COPApprovedNoRemainingToInvoice",
          headerName: "COP Approved, No Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 210,
          minWidth: 210,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.milestones?.copAppr_Actual &&
              !p.data?.financial?.remainingToInvoice
            );
          },
          headerClass: `${styles.THWithArrow} ${styles.THArrowOrange}`,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
          },
        },
        {
          colId: "_COPApprovedReadyToClose",
          headerName: "COP Approved Ready to Close",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          initialWidth: 210,
          minWidth: 210,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              p.data?.isOverheadScenario !== true &&
              !!p.data?.milestones?.copAppr_Actual &&
              !p.data?.bom?.opened &&
              !p.data?.financial?.vimPoBalance &&
              !p.data?.financial?.remainingToInvoice
            );
          },
          headerClass: `${styles.THWithArrow} ${styles.THArrowRed}`,
          headerComponentParams: {
            innerHeaderComponent: HeaderArrowInnerComponent,
            innerHeaderComponentParams: {
              className: styles.THArrowRed,
            },
          },
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    [],
  );

  const COL_DEF = useMemo(
    () =>
      [
        {
          colId: PropsSQLDBStatusResponse.rowNum,
          field: PropsSQLDBStatusResponse.rowNum,
          headerName: "#",
          pinned: true,
          initialWidth: 70,
          filter: false,
          suppressHeaderMenuButton: true,
          initialHide: false,
          aggFunc: "count",
          chartDataType: "excluded",
        },
        {
          colId: PropsSQLDBStatusResponse.number,
          field: PropsSQLDBStatusResponse.number,
          headerName: "Build Plan",
          initialWidth: 300,
          initialHide: false,
          pinned: true,
          chartDataType: "columns",
          cellRenderer: (p: ICellRendererParams<SQL_DB_Status_Response>) => (
            <OpenCardLink
              text={p.value}
              refName={"FSMBuildPlans"}
              dataAttr={p.data?.["id"]}
            />
          ),
        },
        {
          colId: PropsSQLDBStatusResponse.active,
          field: PropsSQLDBStatusResponse.active,
          headerName: "BP State",
          initialWidth: 100,
          initialHide: false,
          type: COL_TYPES.booleanForPivot,
          refData: bpStateLib,
          cellRenderer: (p: ICellRendererParams) => {
            if (!p.valueFormatted) return null;
            return (
              <Chip
                themeColor={p.value ? "success" : "error"}
                text={p.valueFormatted}
                icon={"circle"}
              />
            );
          },
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.project,
          headerName: "Project",
          initialHide: false,
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.scenario,
          headerName: "Scenario",
          initialHide: false,
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.region,
          headerName: "Region",
          initialHide: false,
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.market,
          headerName: "Market",
          initialHide: false,
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.customer,
          headerName: "Customer",
          initialHide: false,
          initialWidth: 140,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.customerJob,
          headerName: "Customer Job",
          initialWidth: 260,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.carrier,
          headerName: "Carrier",
          initialHide: false,
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.class,
          headerName: "Class",
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.category,
          headerName: "Category",
          initialWidth: 200,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.site,
          headerName: "Site ID",
          initialHide: false,
          cellRenderer: (p: ICellRendererParams<SQL_DB_Status_Response>) => (
            <OpenCardLink
              text={p.value}
              refName={"FSMSites"}
              dataAttr={p.data?.["siteId"]}
            />
          ),
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.siteAddress,
          headerName: "Site Address",
          initialWidth: 200,
          chartDataType: "excluded",
        },
        {
          field: PropsSQLDBStatusResponse.owner,
          headerName: "Build Plan Owner",
          initialHide: false,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.profitCenter,
          headerName: "Profit Center",
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.roles}.${PropsSQLDBStatusResponseRoles.crewLead}`,
          headerName: "Crew Lead",
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.roles}.${PropsSQLDBStatusResponseRoles.copLead}`,
          headerName: "Cop Lead",
          chartDataType: "columns",
        },
        {
          colId: PropsSQLDBStatusResponse.permitStatus,
          field: PropsSQLDBStatusResponse.permitStatus,
          headerName: "Permit Status",
          chartDataType: "columns",
        },
        {
          colId: PropsSQLDBStatusResponse.permitStatusComment,
          field: PropsSQLDBStatusResponse.permitStatusComment,
          headerName: "Permit Status Comment",
          type: COMMON_COL_TYPES.longReadonlyText,
          chartDataType: "excluded",
        },
        {
          colId: PropsSQLDBStatusResponse.cxStatus,
          field: PropsSQLDBStatusResponse.cxStatus,
          headerName: "CX Status",
          chartDataType: "columns",
        },
        {
          colId: PropsSQLDBStatusResponse.cxStatusComment,
          field: PropsSQLDBStatusResponse.cxStatusComment,
          headerName: "CX Status Comment",
          type: COMMON_COL_TYPES.longReadonlyText,
          chartDataType: "excluded",
        },
        {
          colId: PropsSQLDBStatusResponse.generated,
          field: PropsSQLDBStatusResponse.generated,
          headerName: "Generated",
          initialHide: false,
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          colId: PropsSQLDBStatusResponse.started,
          field: PropsSQLDBStatusResponse.started,
          headerName: "Started",
          initialHide: false,
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          colId: PropsSQLDBStatusResponse.closed,
          field: PropsSQLDBStatusResponse.closed,
          headerName: "Closed",
          initialHide: false,
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.salesOrder}`,
          headerName: "Sales Order Total",
          headerTooltip: "Total of all Sales Orders in Build Plan",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastQuoteDate}`,
          headerName: "Sales Order Date",
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastQuoteSubmitted}`,
          headerName: "Submitted to Customer",
          chartDataType: "columns",
          type: COMMON_COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastPODate}`,
          headerName: "PO Received",
          chartDataType: "columns",
          type: COMMON_COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.unallocatedCustomerPO}`,
          headerName: "Unallocated Customer PO Total",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.allocatedCustomerPO}`,
          headerName: "Allocated Customer PO Total",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.sopoVariance}`,
          headerName: "Sales Order & PO Variance",
          headerTooltip: "SO Total - Unallocated PO Total - Allocated PO Total",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.soProjectedRevenueVariance}`,
          headerName: "Sales Order and Budget Revenue Variance",
          headerTooltip: "Sales Order Total - Budget Total Revenue",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedRevenue}`,
          headerName: "Budget Total Revenue",
          headerTooltip: "Total Planned Revenue from Budget Dashboard",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetStatus}`,
          headerName: "Budget Status",
          initialWidth: 130,
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetApproved}`,
          headerName: "Budget Approved",
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.initialSent}`,
          headerName: "BOM Sent to Warehouse",
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.total}`,
          headerName: "BOM Total",
          headerTooltip: "Total of All BOMs Amount Associated to Build Plan",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.kitted}`,
          headerName: "Kitted to Date",
          headerTooltip: "Total of All Kitted BOMs",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const data = p.data?.bom;
            if (!data?.kitted || !data?.total) return;
            if (data.kitted > data.total) return { color: "red" };
            return;
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetHrs}`,
          headerName: "Budgeted Field Labor Hours",
          headerTooltip: "Total Planned Labor Hours from Budget Dashboard",
          type: COMMON_COL_TYPES.float,
          initialWidth: 140,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.hoursConsumed}`,
          headerName: "Hours Consumed",
          headerTooltip: "Total Spend Labor Hours Allocated to Build Plan",
          type: COMMON_COL_TYPES.float,
          chartDataType: "series",
          initialWidth: 130,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const consumedHours = p.value || 0;
            const budgetHours = p.data?.["financial"]?.["budgetHrs"] || 0;
            if (consumedHours > budgetHours) return { color: "red" };
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.hoursRemaining}`,
          headerName: "Remaining Hours to Complete",
          headerTooltip: "Budgeted Field Labor Hours - Hours Consumed",
          type: COMMON_COL_TYPES.float,
          initialWidth: 140,
          chartDataType: "series",
        },
        {
          field: PropsSQLDBStatusResponse.pwByDefault,
          headerName: "PW by default",
          type: COMMON_COL_TYPES.booleanYesNo,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.pwLastUpdate,
          headerName: "PW last update",
          type: COMMON_COL_TYPES.dateTimeFormatted,
          chartDataType: "columns",
        },
        {
          field: PropsSQLDBStatusResponse.completion,
          headerName: "Estimated Completion",
          headerTooltip: "% as Noted in Build Plan",
          type: COMMON_COL_TYPES.percentage,
          initialWidth: 140,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedSpend}`,
          headerName: "Projected Spend",
          headerTooltip: "Current Planned Spend from Budget Dashboard",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.opened}`,
          headerName: "Open BOMs",
          type: COMMON_COL_TYPES.integer,
          initialWidth: 130,
          minWidth: 100,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.vimPoBalance}`,
          headerName: "VIM PO Balance",
          type: COMMON_COL_TYPES.float,
          initialWidth: 130,
          minWidth: 100,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingToInvoice}`,
          headerName: "Remaining to Invoice",
          headerTooltip: "Total of Remaining To Bill in Sales Orders",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingSpend}`,
          headerName: "Remaining Spend",
          headerTooltip: "Projected Spend - Total Spend To Date",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            if (p.value < 0) return { color: "red" };
            return;
          },
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastInvoiceDate}`,
          headerName: "Invoice Submitted",
          chartDataType: "series",
          type: COMMON_COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingCashflow}`,
          headerName: "Remaining Cashflow",
          headerTooltip: "Remaining to Invoice - Remaining spend",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.readyToBill}`,
          headerName: "Ready To Bill",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.submittedInvoices}`,
          headerName: "Total Invoiced to Date",
          headerTooltip: "Total of Receivable and Paid Total from Sales Orders",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.totalSpend}`,
          headerName: "Total Spend to Date",
          headerTooltip: "Total Actual Spend from Budget Dashboard",
          type: COMMON_COL_TYPES.currency,
          aggFunc: "sum",
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.totalSpendPercent}`,
          headerName: "% of Budgeted Spend",
          headerTooltip: "%, Total Spend to Date/Projected Spend",
          initialWidth: 135,
          type: COMMON_COL_TYPES.percentage,
          chartDataType: "series",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedBillingDate}`,
          headerName: "Projected Billing Date",
          type: COMMON_COL_TYPES.dateFormatted,
          chartDataType: "columns",
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const financialData = p.data?.["financial"];
            if (!financialData) return;
            const projectedBillingDate = moment(
              financialData["projectedBillingDate"],
            ).toDate();
            const remainingToInvoiceDate = moment(
              financialData["remainingToInvoice"],
            ).toDate();
            if (
              projectedBillingDate < new Date() &&
              remainingToInvoiceDate.valueOf() > 0
            ) {
              return { color: "red" };
            }
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.pl}`,
          headerName: "P&L",
          headerTooltip: "Total Invoice to Date - Total Spend to Date",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.wip}`,
          headerName: "WIP",
          headerTooltip:
            "Variance Under Spend - Variance Under Billed, as Calculated in WIP Dashboard",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.wipAdjustedPL}`,
          headerName: "WIP Adjusted P&L",
          headerTooltip: "WIP + P&L",
          type: COMMON_COL_TYPES.currency,
          chartDataType: "series",
          aggFunc: "sum",
        },
        {
          field: PropsSQLDBStatusResponse.excludeFromWIP,
          headerName: "Exclude from WIP",
          initialWidth: 120,
          type: COMMON_COL_TYPES.booleanYesNo,
          chartDataType: "columns",
        },
        {
          headerName: "Calculated Indicators",
          marryChildren: true,
          children: CALCULATED_COLS,
        },
        {
          headerName: "Milestones",
          children: MILESTONES_COLS,
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    [CALCULATED_COLS, MILESTONES_COLS],
  );

  const sideBar = useMemo<SideBarDef>(() => {
    return {
      toolPanels: [
        {
          id: "data",
          labelDefault: "Data",
          labelKey: "data",
          iconKey: "grip",
          toolPanel: DataToolPanel,
          toolPanelParams: {
            onDateChange: setOnDate,
          } as DataToolPanelProps,
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            contractColumnSelection: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "views",
          labelDefault: "Views",
          labelKey: "views",
          iconKey: "pivot",
          toolPanel: ViewsToolPanel,
          toolPanelParams: {
            ownerStatsColIds: [
              PropsSQLDBStatusResponse.active,
              ...CALCULATED_COLS.map((x) => x.colId!),
            ],
            milestonesStatsColIds: [
              PropsSQLDBStatusResponse.number,
              ...MILESTONES_COLS.map((x) => x.colId!),
              PropsSQLDBStatusResponse.permitStatus,
              PropsSQLDBStatusResponse.permitStatusComment,
              PropsSQLDBStatusResponse.cxStatus,
              PropsSQLDBStatusResponse.cxStatusComment,
            ],
          },
        },
      ],
      defaultToolPanel: "",
    };
  }, [CALCULATED_COLS, setOnDate, MILESTONES_COLS]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        {
          statusPanel: StatusPanel_QuickFilterText,
          align: "left",
        },
        {
          statusPanel: StatusPanel_ClearFilters,
          align: "left",
        },
        {
          statusPanel: () => {
            if (!onDate) return <span></span>;
            return (
              <div className="ag-status-name-value">
                <span className="component">
                  {`Data on ${moment(onDate).format("L")}`}
                </span>
              </div>
            );
          },
          align: "left",
        },
        // { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent", align: "left" },
        {
          statusPanel: StatusPanel_CurrentView,
          align: "center",
        },
        {
          statusPanel: StatusPanel_Actions,
          align: "right",
        },
        {
          statusPanel: StatusPanel_Export,
          align: "right",
        },
        {
          statusPanel: StatusPanel_ReloadRows,
          align: "right",
          statusPanelParams: {
            getRows,
          },
        },
      ],
    };
  }, [getRows, onDate]);

  if (milestones.loading) return <LoaderComponent />;

  return (
    <div
      className={"ag-theme-quartz"}
      style={{ width: "100%", height: "100%" }}
    >
      <CurrentViewContext.Provider
        value={{
          value: selectedView,
          setValue: setSelectedView,
        }}
      >
        <AgGridReact
          sideBar={sideBar}
          statusBar={statusBar}
          getRowId={getRowId}
          rowData={rows.data}
          columnTypes={{
            ...COMMON_COL_TYPES_DESCRIPTION,
            ...COL_TYPES_DESCRIPTION,
          }}
          columnDefs={COL_DEF}
          loading={rows.loading}
          defaultColDef={DEFAULT_COL_DEF}
          maintainColumnOrder={true}
          onGridReady={handleGridReady}
          onColumnPivotModeChanged={handlePivotModeToggle}
        />
      </CurrentViewContext.Provider>
    </div>
  );
}
