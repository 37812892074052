import { Dispatch, memo, useCallback, useEffect, useState } from "react";
import { settingsStorage } from "../../../helpers/settings";
import {
  InputClearValue,
  InputSeparator,
  InputSuffix,
  TextBox,
  TextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { filterIcon, xIcon } from "@progress/kendo-svg-icons";

type Props = {
  filterName: string;
  onChange: Dispatch<string>;
  placeholder?: string;
};

const CalendarTextFilterCmp = ({
  filterName,
  onChange,
  placeholder,
}: Props) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const filterStringValue = settingsStorage.getForCurrentUser(
      `DispatchCalendar_${filterName}`,
    );

    if (filterStringValue) {
      setValue(filterStringValue);
      onChange(filterStringValue);
    }
  }, [filterName, onChange]);

  const handleChange = useCallback(
    (e: TextBoxChangeEvent) => {
      const textValue = (e.value as string) || "";
      setValue(textValue);
      settingsStorage.setForCurrentUser(
        `DispatchCalendar_${filterName}`,
        textValue,
      );
      onChange(textValue);
    },
    [filterName, onChange],
  );

  const handleClear = useCallback(() => {
    setValue("");
    settingsStorage.setForCurrentUser(`DispatchCalendar_${filterName}`, "");
    onChange("");
  }, [filterName, onChange]);

  return (
    <TextBox
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      fillMode="flat"
      suffix={() => (
        <InputSuffix>
          {value !== "" && (
            <>
              <InputClearValue onClick={handleClear}>
                <SvgIcon icon={xIcon} />
              </InputClearValue>
              <InputSeparator />
            </>
          )}
          <SvgIcon icon={filterIcon} />
        </InputSuffix>
      )}
      style={{ width: 200 }}
    />
  );
};

export const CalendarTextFilter = memo(CalendarTextFilterCmp);
