import { ITab, ITabSetting, tabId } from "./interfaces";
import BPBudget from "../BP/Budget/Budget";
import LaborHours from "../BP/Labor/Labor";
import CIMSummary from "../BP/CIMSummary";
import VIMSummary from "../BP/VIMSummary";
import BPHistory from "../BP/History";
import BPChatHistory from "../BP/ChatHistory/ChatHistory";
import BPTasks from "../BP/Tasks/BPTasks";
import CODSummary from "../BP/CODSummary";
import WorkOrders from "../BP/WorkOrders";
import RelatedDocuments from "../RelatedDocuments/RelatedDocuments";
import BPGeneral from "../BP/General/BPGeneral";
import LiveMap from "../../Pages/LiveMap/LiveMap";
import Milestones from "../../Pages/Milestones/Milestones";
import BOMBPTab from "../BP/BOMTab";
import DailyStatus from "../../Pages/DailyDashboard/DailyStatus";
import CheckListResults from "../../Pages/CLM/ChecklistResults";
import CLMStatusDashboard from "../../Pages/CLM/StatusDashboard";
import CLMResources from "../../Pages/CLM/Resources";
import PLStatement from "../../Pages/PLDashboard/PLStatement";
import PLDetails from "../../Pages/PLDashboard/PLDetailing";

import TimeCard from "../TC/Allocation";
import TCHistory from "../TC/History";
import TCAttestations from "../../Components/TC/Attestations";
import TCAudits from "../../Components/TC/AuditResults";
import TCRequests from "../../Components/TC/Requests";
import LaborCosts from "../PP/LaborCosts";
import TCMap from "../TC/Map";
import CostRates from "../../Components/PP/CostRates";
import PayrollPeriod from "../../Components/PP/PayrollPeriod";
import PPSummary from "../../Components/PP/PayrollSummury";
import PayrollStatus from "../PP/PayrollStatus";
import PPTimeCards from "../PP/TimeCards";
import PayrollChecks from "../PP/PayrollChecks";
import InventoryBalance from "../../Pages/InventoryDashboard/InventoryBalance";
import Materials from "../../Pages/InventoryDashboard/Materials";
import Utilization from "../../Pages/InventoryDashboard/Utilization";
import Checklists from "../../Pages/CLM/Checklists";
import MaterialItems from "../../Pages/InventoryDashboard/MaterialItems";
import { CalendarPage } from "../../Pages/CalendarPage/CalendarPage";

export const TABS: {
  [key in tabId]: ITab;
} = {
  Budget: {
    name: "Budget",
    component: BPBudget,
  },
  CIM: {
    name: "CIM",
    component: CIMSummary,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  VIM: {
    name: "VIM",
    component: VIMSummary,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  Milestones: {
    name: "Milestones",
    component: Milestones,
    hasSetDefaultFilters: true,
  },
  BOM: {
    name: "BOM",
    component: BOMBPTab,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  Documents: {
    name: "Documents",
    component: RelatedDocuments,
  },
  History: {
    name: "BP History",
    component: BPHistory,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  ChatHistory: {
    name: "Chat History",
    component: BPChatHistory,
  },
  Labor: {
    name: "Labor",
    component: LaborHours,
  },
  Tasks: {
    name: "Tasks",
    component: BPTasks,
  },
  Map: {
    name: "Map",
    component: LiveMap,
  },
  COD: {
    name: "COD",
    component: CODSummary,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  WorkOrders: {
    name: "Work Orders",
    component: WorkOrders,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  Calendar: {
    name: "Calendar",
    component: CalendarPage,
  },
  Daily: {
    name: "Daily Status",
    component: DailyStatus,
  },
  InventoryBalance: {
    name: "Inventory Balance",
    component: InventoryBalance,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  General: {
    // todo rename BPGeneral
    name: "General",
    component: BPGeneral,
  },
  // CLM tabs
  SimplifiedCalendar: {
    name: "Calendar",
    component: CalendarPage,
  },
  StatusDashboard: {
    name: "Status Dashboard",
    component: CLMStatusDashboard,
    hasSetDefaultFilters: true,
  },
  ReviewInterface: {
    // Close Out ReviewInterface
    name: "Review Interface",
    component: CheckListResults,
  },
  ChecklistResults: {
    name: "Checklists",
    component: CheckListResults,
  },
  Checklists: {
    name: "Checklists",
    component: Checklists,
  },
  Resources: {
    name: "Resources",
    component: CLMResources,
    hasSetDefaultFilters: true,
  },
  PL: {
    name: "P&L",
    component: PLStatement,
    hasExportToExcel: true,
  },
  PLDetails: {
    name: "P&L Details",
    component: PLDetails,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  Materials: {
    name: "Materials",
    component: Materials,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  Utilization: {
    name: "Utilization",
    component: Utilization,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  TCAllocation: {
    name: "Allocation",
    component: TimeCard,
  },
  TCTimelines: {
    name: "Timelines",
    component: TCMap,
  },
  TCHistory: {
    name: "History",
    component: TCHistory,
    hasSetDefaultFilters: true,
  },
  TCAttestations: {
    name: "Attestations",
    component: TCAttestations,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  TCAudits: {
    name: "Audit Results",
    component: TCAudits,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  TCRequests: {
    name: "Requests",
    component: TCRequests,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPStatus: {
    name: "Status",
    component: PayrollStatus,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPTimeCards: {
    name: "Time Cards",
    component: PPTimeCards,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPSummary: {
    name: "Summary",
    component: PPSummary,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPDetails: {
    name: "Details",
    component: PayrollPeriod,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPLaborCost: {
    name: "Labor Cost",
    component: LaborCosts,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPCostRates: {
    name: "Cost Rates",
    component: CostRates,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  PPChecks: {
    name: "Closure Process",
    component: PayrollChecks,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
  MaterialItems: {
    name: "Material Items",
    component: MaterialItems,
    hasExportToExcel: true,
    hasSetDefaultFilters: true,
  },
};

export const GetTabId = (tab: tabId | ITabSetting) => {
  return typeof tab === "string" ? tab : tab.id;
};

export const GetTabName = (tab: tabId | ITabSetting) => {
  if (typeof tab === "object" && tab.name) return tab.name;
  return TABS[GetTabId(tab)].name;
};

export const IsTabExist = (tab: string | ITabSetting) => {
  const id = typeof tab === "string" ? tab : tab.id;
  return !!TABS[id as tabId];
};
