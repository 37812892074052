import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import FXCard from "../Common/FXCard/FXCard";
import FilterCombobox from "../Common/Form/FilterCombobox";
import styles from "./card.module.scss";
import { IOpenSolicitTKRequest } from "./interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import api from "../../core/api/api";
import {
  SQL_TK_RequestAvaliablePayrollItems_Response,
  SQL_TK_RequestAvaliableReasons_Response,
} from "../../core/api/generated/conterra";
import { IComboboxItem } from "../../helpers/interfaces";
import { showSomeError } from "../../helpers/errorHelpers";

const REQUEST_TYPES = [
  { Id: "TimeAdjustment", Name: "Time Adjustment" },
  { Id: "TimeOff", Name: "Time Off" },
  { Id: "PayrollItem", Name: "Miscellaneous" },
];

export default function SolicitRequestCard(props: IOpenSolicitTKRequest) {
  const [employees, setEmployees] = useState<IComboboxItem[]>();
  const [employee, setEmployee] = useState<IComboboxItem | undefined>();
  const [reasons, setReasons] =
    useState<SQL_TK_RequestAvaliableReasons_Response[]>();
  const [reason, setReason] =
    useState<SQL_TK_RequestAvaliableReasons_Response | null>(null);
  const [payrollItems, setPayrollItems] =
    useState<SQL_TK_RequestAvaliablePayrollItems_Response[]>();
  const [payrollItem, setPayrollItem] =
    useState<SQL_TK_RequestAvaliablePayrollItems_Response | null>(null);
  const [date, setDate] = useState(moment(props.date).toDate());
  const handleDateChange = useCallback((e: any) => {
    setDate((e.value || undefined) && new Date(e.value));
  }, []);
  const [requestType, setRequestType] = useState(REQUEST_TYPES[0]);
  const [comment, setComment] = useState("");
  const handleCommentChange = useCallback((e: any) => {
    setComment(e.value);
  }, []);

  useEffect(() => {
    const loadReasons = async () => {
      const response = await api.sql.tkRequestAvaliableReasons();
      setReasons(response);
    };
    const loadPayrollItems = async () => {
      const response = await api.sql.tkRequestAvaliablePayrollItems();
      setPayrollItems(response);
    };
    const loadData = async () => {
      try {
        const employees = await ReferenceRecordsDataSource("Employees");
        setEmployees(employees);
        if (props.employeeId) {
          const employee = employees.find(
            (e: IComboboxItem) => e.Id === props.employeeId,
          );
          setEmployee(employee);
        }
      } catch (e: any) {
        showSomeError(e);
      }
    };
    loadData();
    loadReasons();
    loadPayrollItems();
  }, []);

  useEffect(() => {
    if (requestType?.Id !== "TimeOff" && reason) {
      setReason(null);
    }
    if (requestType?.Id !== "PayrollItem" && payrollItem) {
      setPayrollItem(null);
    }
  }, [requestType, reason, payrollItem, setPayrollItem, setReason]);

  const handleSolicit = useCallback(() => {
    if (!employee || !date || !comment || !requestType) return;
    api.script
      .tkNewRequest({
        Solicitation: {
          RequestType: requestType.Id,
          EmployeeID: +employee.Id,
          RequestDate: moment(date).format("DD.MM.YYYY"),
          Comment: comment,
          PayrollItemID: payrollItem ? +payrollItem.id : undefined,
          ReasonID: reason ? +reason.id : undefined,
        },
      })
      .catch(showSomeError)
      .finally(() => {
        props.afterSave?.();
        props.finally?.();
      });
  }, [employee, date, comment, requestType, payrollItem, reason]);

  return (
    <FXCard
      title={"Solicit Request"}
      onClose={props.finally}
      initialWidth={450}
      initialHeight={340}
      originalPaddings={true}
    >
      <div className={styles.FormWrapper}>
        <FilterCombobox
          placeholder="Employee *"
          data={employees || []}
          onChange={setEmployee}
          className={styles.FormField}
          loading={!employees}
          value={employee}
        />
        <div className={styles.DatePickerRow}>
          <DatePicker
            onChange={handleDateChange}
            className={styles.Datepicker}
            value={date}
            formatPlaceholder={{ month: "m", day: "d", year: "y" }}
          ></DatePicker>
        </div>
        <FilterCombobox
          placeholder="Type *"
          data={REQUEST_TYPES}
          onChange={setRequestType}
          className={styles.FormField}
          value={requestType}
        />
        {requestType?.Id === "PayrollItem" && (
          <FilterCombobox
            placeholder="Payroll Item"
            data={payrollItems}
            onChange={setPayrollItem}
            className={styles.FormField}
            value={payrollItem}
            dataItemKey={"id"}
            textField={"name"}
          />
        )}
        {requestType?.Id === "TimeOff" && (
          <FilterCombobox
            placeholder="Reason"
            data={reasons}
            onChange={setReason}
            className={styles.FormField}
            value={reason}
            dataItemKey={"id"}
            textField={"name"}
          />
        )}
        <TextArea
          className={styles.TextArea}
          rows={8}
          placeholder="Comment"
          onChange={handleCommentChange}
        ></TextArea>
        <div className={`${styles.FormFooter} k-action-buttons`}>
          <Button onClick={props.finally}>Cancel</Button>
          <Button
            onClick={handleSolicit}
            themeColor="primary"
            disabled={!employee || !date || !comment || !requestType}
          >
            Solicit
          </Button>
        </div>
      </div>
    </FXCard>
  );
}
