import { Button, Toolbar } from "@progress/kendo-react-buttons";
import styles from "./tkToolbar.module.scss";
import React, { useEffect, useMemo } from "react";
import { INewComboboxItem } from "../../../helpers/interfaces";
import { IDispatchDataFilter, IFilters, TKFilterKey } from "../interfaces";
import CardManagement from "../../../Components/Cards/CardManagement";
import {
  FilterByDepartment,
  FilterByDispatch,
  FilterByEmployee,
  FilterByEmployeeClass,
  FilterByPP,
  FilterByReviewer,
  FilterByState,
} from "./Filters";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { isTKCanCompleteTCs, isTKManager } from "../../../core/tools/roles";
import {
  PropsSQLDBTKReviewRequest,
  SQL_DB_TK_Review_Response,
} from "../../../core/api/generated/conterra";
import { DateRangeFilter } from "./DateRangeFilter";
import { useLazyAsync } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import { sortByNameNewCombobox } from "../../../helpers/helpers";
import { Card } from "@progress/kendo-react-layout";

interface IProps {
  isDateSortAsc: boolean;
  isHideRelatedTCs: boolean;
  toolbarExpandState: boolean;
  filters: IFilters;
  compare: JSX.Element;
  timecardsActions: JSX.Element;
  filteredTimeCards: SQL_DB_TK_Review_Response[];

  handleToggleShowRelatedTCs: () => void;
  handleToggleSortByDate: () => void;

  refreshList(): void;

  onClearFilters(): void;

  onServerFilterChange(
    value: INewComboboxItem | null,
    filterName: TKFilterKey
  ): void;

  onDateFilterChange(value: SelectionRange | null): void;

  onToggleToolbar(): void;
}

const TKListToolbar = (props: IProps) => {
  const {
    toolbarExpandState,
    filters,
    refreshList,
    isDateSortAsc,
    isHideRelatedTCs,
    handleToggleShowRelatedTCs,
    handleToggleSortByDate,
    onClearFilters,
    onDateFilterChange,
    timecardsActions,
    onToggleToolbar,
    onServerFilterChange,
    compare,
    filteredTimeCards,
  } = props;
  const {
    date: filterByDate,
    finishDate: filterByFinishDate,
    resourceId: filterByResourse,
  } = filters;
  const [getResources, { data: resources, loading: resourcesLoading }] =
    useLazyAsync(api.sql.tkGetResources);
  useEffect(getResources, [getResources]);
  const { employees, departments, employeeClassNames } = useMemo(() => {
    const data: {
      employees: INewComboboxItem[];
      departments: INewComboboxItem[];
      employeeClassNames: INewComboboxItem[];
    } = {
      employees: [],
      departments: [],
      employeeClassNames: [],
    };

    if (!resources?.length) return data;
    const uniqueDepertments: { [key: number]: string } = {};
    const uniqueEmployeeClassNames: { [key: number]: string } = {};

    for (let {
      id,
      name,
      departmentId,
      departmentName,
      employeeClassName,
      employeeClassId,
    } of resources) {
      data.employees.push({ id, name });
      if (departmentId && departmentName && !uniqueDepertments[departmentId]) {
        uniqueDepertments[departmentId] = departmentName;
        data.departments.push({ id: departmentId, name: departmentName });
      }
      if (
        employeeClassId &&
        employeeClassName &&
        !uniqueEmployeeClassNames[employeeClassId]
      ) {
        uniqueEmployeeClassNames[employeeClassId] = employeeClassName;
        data.employeeClassNames.push({
          id: employeeClassId,
          name: employeeClassName,
        });
      }
    }
    data.departments.sort(sortByNameNewCombobox);
    data.employees.sort(sortByNameNewCombobox);
    data.employeeClassNames.sort(sortByNameNewCombobox);
    return data;
  }, [resources]);

  const solicitTKRequest = () => {
    CardManagement.OpenSolicitTKRequestCard({ afterSave: refreshList });
  };

  const hasAppliedFilters = useMemo(() => {
    if (!isDateSortAsc || !isHideRelatedTCs) return true;

    for (let filterName in filters) {
      if (filters[filterName as keyof IFilters]) return true;
    }
  }, [filters, isHideRelatedTCs, isDateSortAsc]);

  const dispatchDataParams: IDispatchDataFilter = useMemo(
    () => ({
      resource: filterByResourse,
      date: filterByDate,
      finishDate: filterByFinishDate,
    }),
    [filterByDate, filterByResourse, filterByFinishDate]
  );

  const renderFilters = () => {
    const showTCActionsAsIsAdmin = isTKManager() || isTKCanCompleteTCs();

    return (
      <>
        <FilterByPP
          onChange={onServerFilterChange}
          value={filters["periodId"]}
          filteredTimeCards={filteredTimeCards}
        />
        <FilterByDispatch
          onChange={onServerFilterChange}
          value={filters["dispatchId"]}
          params={dispatchDataParams}
          filteredTimeCards={filteredTimeCards}
        />
        <FilterByState
          onChange={onServerFilterChange}
          value={filters["reviewStateId"]}
        />
        {showTCActionsAsIsAdmin && (
          <FilterByReviewer
            onChange={onServerFilterChange}
            value={filters["reviewerId"]}
            filteredTimeCards={filteredTimeCards}
          />
        )}
        <FilterByEmployee
          onChange={onServerFilterChange}
          value={filters["resourceId"]}
          filteredTimeCards={filteredTimeCards}
          data={employees}
          loading={resourcesLoading}
        />
        <FilterByEmployeeClass
          onChange={onServerFilterChange}
          value={filters["employeeClassId"]}
          filteredTimeCards={filteredTimeCards}
          data={employeeClassNames}
          loading={resourcesLoading}
        />
        <FilterByDepartment
          onChange={onServerFilterChange}
          value={filters["departmentId"]}
          filteredTimeCards={filteredTimeCards}
          data={departments}
          loading={resourcesLoading}
        />
      </>
    );
  };

  return (
    <Toolbar style={{ flex: "0 0 auto" }}>
      <div className={styles.ToolbarRow}>
        {timecardsActions}
        {compare}
        <Card className={styles.Card}>
          <DateRangeFilter
            onChange={onDateFilterChange}
            start={filters["date"] || null}
            end={filters["finishDate"] || null}
          />
        </Card>
        <div style={{ flex: 1 }}></div>
        <Button
          iconClass={
            toolbarExpandState ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"
          }
          title={"Toggle filters"}
          onClick={onToggleToolbar}
          fillMode={"flat"}
        ></Button>
        <Button
          iconClass={"mdi mdi-filter-off"}
          title={"Clear Filters"}
          disabled={!hasAppliedFilters}
          onClick={() => {
            if (!isDateSortAsc) handleToggleSortByDate();
            if (!isHideRelatedTCs) handleToggleShowRelatedTCs();
            onClearFilters();
          }}
          fillMode={"flat"}
        ></Button>
        <Button
          iconClass={"mdi mdi-email"}
          title={"Solicit TK Request"}
          onClick={solicitTKRequest}
          fillMode={"flat"}
        ></Button>
        <Button
          icon={!isHideRelatedTCs || isDateSortAsc ? "sort-asc" : "sort-desc"}
          title={"Sort by Date"}
          disabled={!isHideRelatedTCs}
          onClick={handleToggleSortByDate}
          fillMode={"flat"}
        ></Button>
        <Button
          iconClass={"mdi mdi-weather-night"}
          title={"Show Related Time Cards"}
          selected={!isHideRelatedTCs}
          togglable={true}
          onClick={handleToggleShowRelatedTCs}
          fillMode={"flat"}
        ></Button>
        <Button
          icon={"refresh"}
          title={"Refresh"}
          onClick={refreshList}
          fillMode={"flat"}
        ></Button>
      </div>
      <div
        className={styles.ToolbarRow}
        style={!toolbarExpandState ? { display: "none" } : {}}
      >
        {renderFilters()}
      </div>
    </Toolbar>
  );
};

export default TKListToolbar;
