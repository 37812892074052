import React, { useCallback, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useBooleanState } from "../../core/tools/Hooks";
import { WorkshiftMobileDataResponse } from "../../core/api/generated/tk";
import WorkShiftInfoStore from "../../stores/WorkShiftInfoStore";
import WorkShiftInfoTooltipContent from "./TooltipContent";
import WorkShiftInfoBtn from "./BtnIcon";

const WorkShiftInfo = (props: {
  workShiftId: number;
  osName: string;
  fullMode?: boolean;
}) => {
  const { workShiftId, osName, fullMode } = props;
  const isLoading = useBooleanState(false);
  const [detailsInfo, setDetailsInfo] = useState<
    WorkshiftMobileDataResponse | undefined
  >();
  const loadInfo = useCallback(async () => {
    if (!workShiftId) {
      return;
    }
    isLoading.setTrue();
    const res = await WorkShiftInfoStore.GetWorkShiftInfo(
      workShiftId,
      !!fullMode,
    );
    setDetailsInfo(res);
    isLoading.setFalse();
  }, [workShiftId, fullMode]);

  return (
    <div>
      <Tooltip
        tooltipStyle={{
          marginRight: "-10px",
          marginTop: fullMode ? "30px" : 0,
        }}
        position={"left"}
        style={{ width: 320 }}
        content={() => (
          <WorkShiftInfoTooltipContent
            workShiftId={workShiftId}
            data={detailsInfo}
            isFullMode={!!fullMode}
            isLoading={isLoading.value}
          />
        )}
        anchorElement="target"
        showCallout={false}
        parentTitle={true}
        onOpen={() => {
          if (!detailsInfo) loadInfo();
        }}
      >
        <WorkShiftInfoBtn key={workShiftId} osName={osName} />
      </Tooltip>
    </div>
  );
};

export default WorkShiftInfo;
