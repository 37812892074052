import React, { useCallback, useRef } from "react";
import { useRefresher } from "../../../core/tools/Hooks";
import api from "../../../core/api/api";
import {
  AvailableMaterialItemDto,
  PropsAvailableMaterialItemDto,
} from "../../../core/api/generated/warehouse";
import DashboardGrid from "../../Dashboard/Grid";
import { IColumnSetting } from "../../Dashboard/interfaces";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

interface IRowItem {
  id: number;
  sn: string;
  shipmentPackageId: number;
  shipmentPackageName: string;
  materialId: number;
  materialName: string;
  vendorId: number;
  vendorName: string;
  vendorPartNo: string;
  createdAt: string;
  mfgProductNo: string;
  warehouseAreaId?: number;
  warehouseAreaName?: string;
}

export const MaterialItemsSelect = (props: {
  bomId: number;
  materialId?: number;
  onChange: (value: number[]) => void;
}) => {
  const forceRefresher = useRefresher();
  const { bomId, materialId } = props;
  const filteredDataRef = useRef<IRowItem[]>([]);
  const selectedIds = useRef<number[]>([]);

  const OnChangeCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const { value: selectAll } = e.target;
      selectedIds.current = !selectAll
        ? []
        : filteredDataRef.current.map((item) => item.id);
      props.onChange(selectedIds.current);
      forceRefresher();
    },
    [props.onChange, forceRefresher]
  );

  const OnChangeCheck = useCallback(
    (e: CheckboxChangeEvent) => {
      const { value: selected, name: id } = e.target;
      if (selected) {
        selectedIds.current.push(+id!);
      } else {
        selectedIds.current = selectedIds.current.filter((sId) => sId !== +id!);
      }
      props.onChange(selectedIds.current);
      forceRefresher();
    },
    [props.onChange, forceRefresher]
  );

  const renderCheckAll = useCallback(() => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all", width: 32 }}>
        <Checkbox
          id={"all-mi-check"}
          name={"all"}
          label={" "}
          onChange={OnChangeCheckAll}
        />
      </div>
    );
  }, [OnChangeCheckAll]);
  const renderColumnCheck = useCallback(
    ({ id }: AvailableMaterialItemDto) => {
      const checked =
        selectedIds.current.findIndex((selectedId) => id === selectedId) > -1;
      return (
        <div style={{ textAlign: "center", pointerEvents: "all" }}>
          <Checkbox
            id={id + "check"}
            name={id + ""}
            label={" "}
            value={checked}
            checked={checked}
            onChange={OnChangeCheck}
          />
        </div>
      );
    },
    [OnChangeCheck]
  );
  /*  const renderSNLink = useCallback(
    (dataItem: AvailableMaterialItemDto) => (
      <ButtonLink
        text={dataItem.sn}
        dataAttr={dataItem}
        onClick={(
          e: React.BaseSyntheticEvent<object, any, any> | undefined,
          dataAttr: SQL_WH_MI_Dashboard_Response
        ) => {
          // todo доделать карточку получать внутри инфу для вкладки later
          // general отдельно
          CardManagement.OpenMaterialItemCard(dataAttr);
        }}
      />
    ),
    []
  );*/

  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "checked",
      title: " ",
      type: "string",
      filterable: false,
      columnMenu: false,
      sortable: false,
      format: {
        type: "string",
        customFormat: true,
        getCustomFormat: renderColumnCheck,
      },
      titleHTML: renderCheckAll(),
      gridWidth: 40,
    },
    {
      field: PropsAvailableMaterialItemDto.sn,
      title: "SN",
      type: "string",
      format: {
        type: "string",
        /*customFormat: true,
        getCustomFormat: renderSNLink,*/
      },
      gridWidth: 100,
    },
    {
      field: PropsAvailableMaterialItemDto.tag,
      title: "Tag #",
      type: "string",
      format: {
        type: "string",
      },
      gridWidth: 100,
    },
    {
      field: PropsAvailableMaterialItemDto.assembly,
      title: "Assembly #",
      type: "string",
      format: {
        type: "string",
      },
      gridWidth: 100,
    },
    {
      field: "materialName", //PropsAvailableMaterialItemDto.material,
      title: "Material",
      type: "string",
      format: {
        type: "link",
        fieldId: "materialId",
        refName: "FSMMaterials",
      },
      gridWidth: 280,
    },
    {
      field: "shipmentPackageName", //PropsAvailableMaterialItemDto.shipmentPackage,
      title: "Shipment Package",
      type: "string",
      format: {
        type: "string",
        fieldId: "shipmentPackageId",
        /*customFormat: true,
        getCustomFormat: renderLinkCell,*/
      },
      gridWidth: 90,
    },
    {
      field: "warehouseAreaName", //PropsAvailableMaterialItemDto.warehouseArea,
      title: "Warehouse Area",
      type: "string",
      format: {
        type: "string",
        fieldId: "warehouseAreaId",
        // customFormat: true,
        // getCustomFormat: renderLinkCell,
      },
      gridWidth: 180,
    },
    {
      field: "vendorName", // PropsAvailableMaterialItemDto.vendor,
      title: "Vendor",
      type: "string",
      format: {
        type: "string",
        fieldId: "vendorId",
        // customFormat: true,
        // getCustomFormat: renderLinkCell,
      },
      gridWidth: 180,
    },
    {
      field: PropsAvailableMaterialItemDto.vendorPartNo,
      title: "Part #",
      type: "string",
      format: {
        type: "string",
      },
      gridWidth: 120,
    },
    {
      field: PropsAvailableMaterialItemDto.mfgProductNo,
      title: "MFG Product #",
      type: "string",
      format: {
        type: "string",
      },
      gridWidth: 120,
    },
    {
      field: PropsAvailableMaterialItemDto.createdAt,
      title: "Created",
      type: "date",
      format: {
        type: "date",
      },
    },
    {
      field: "buildPlanName", //PropsAvailableMaterialItemDto.buildPlan,
      title: "Build Plan",
      type: "string",
      format: {
        type: "link",
        fieldId: "buildPlanId",
        refName: "FSMBuildPlans",
      },
      gridWidth: 250,
    },
  ]);

  const loadData = useCallback(async () => {
    const response = await api.wh.bom.getAvailableMaterialItems(bomId, {
      materialId,
    });
    return response.map((item) => {
      const {
        vendor,
        material,
        shipmentPackage,
        warehouseArea,
        buildPlan,
        ...rest
      } = item;
      return {
        ...rest,
        shipmentPackageId: shipmentPackage.id,
        shipmentPackageName: shipmentPackage.name,
        materialId: material.id,
        materialName: material.name,
        vendorId: vendor.id,
        vendorName: vendor.name,
        warehouseAreaId: warehouseArea?.id,
        warehouseAreaName: warehouseArea?.name,
        buildPlanId: buildPlan?.id,
        buildPlanName: buildPlan?.name,
      };
    });
  }, [bomId]);

  return (
    <div style={{ height: 550 }}>
      <DashboardGrid
        isActive={true}
        isParentPage={true}
        columnsSettings={columnsRef.current}
        getData={loadData}
        getRowKey={({ id }: AvailableMaterialItemDto) => id.toString()}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        onChangeGridData={(data: IRowItem[]) => {
          filteredDataRef.current = data;
        }}
      ></DashboardGrid>
    </div>
  );
};
