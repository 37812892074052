import styles from "../vim.module.scss";
import { IVIMInvoice } from "../interfaces";

interface props {
  invoice: IVIMInvoice;
  selected: boolean;

  onSelect(e: any): void;
}

const Invoice = (props: props) => {
  const { invoice, selected } = props;
  const {
    Id,
    Name,
    Date,
    DateFormatted,
    Amount,
    Number,
    VendorName,
    CODRequesterName,
    Classifier,
    IsSuspended,
  } = invoice;
  const CODRequestor = CODRequesterName ? ` - ${CODRequesterName}` : "";
  const vendorRowTitle = `${VendorName || "<Vendor>"} ${CODRequestor}`;
  return (
    <div
      key={Id}
      className={styles.ListItem}
      data-selected={selected}
      data-id={Id}
      data-name={Name}
      onClick={props.onSelect}
    >
      <div className={styles.Row}>
        <div className={styles.Date}>
          {Date ? (
            DateFormatted
          ) : (
            <span className={styles.ListPlaceholder}>Date</span>
          )}
        </div>
        <div>
          {Amount !== null ? (
            `$${Amount}`
          ) : (
            <span className={styles.ListPlaceholder}>Amount</span>
          )}
        </div>
        <div className={styles.RightCell}>
          {Number !== null ? (
            `#${Number}`
          ) : (
            <span className={styles.ListPlaceholder}>Number</span>
          )}
        </div>
      </div>
      <div className={styles.Row}>
        <div title={vendorRowTitle} className={styles.ListItemVendorName}>
          {VendorName || <span className={styles.ListPlaceholder}>Vendor</span>}
          {CODRequestor}
        </div>
        <div className={styles.RightCell}>
          {IsSuspended && (
            <span className={styles.ListItemSuspended}>Suspended</span>
          )}
          {!!Classifier && <span>&nbsp;{Classifier}</span>}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
