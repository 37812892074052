import axios, { AxiosError } from "axios";

export enum ErrorType {
  Error = "error",
  Warning = "warning",
  Info = "info",
}

enum ErrorLevel {
  Info = "Information",
  Warning = "Warning",
  Exception = "Exception",
}

const KNOWN_EXCEPTION_CODES = [
  "FxValidationException",
  "FxScriptException",
  "FxAccessDeniedException",
  "FxNotFoundException",
  "FxException",
];

interface IKnownExceptionData {
  Code: string;
  Message: string;
  Level: ErrorLevel;
}

export const transformApiError = (error: unknown) => {
  if (axios.isCancel(error)) {
    return new ApiCancellationError();
  }

  if (error instanceof AxiosError && error.isAxiosError) {
    if (error.response) {
      const responseData = error.response?.data as IKnownExceptionData;
      if (
        !!responseData?.Code &&
        KNOWN_EXCEPTION_CODES.includes(responseData?.Code)
      ) {
        if (responseData?.Code !== "FxScriptException")
          return new ApiWarningError(responseData.Message);

        switch (responseData.Level) {
          case ErrorLevel.Info:
            return new ApiInformationError(responseData.Message);
          case ErrorLevel.Warning:
            return new ApiWarningError(responseData.Message);
          case ErrorLevel.Exception:
            return new ApiExceptionError();
        }
      } else if (error.response.status >= 500) {
        return new ApiServerError();
      }
    } else {
      return new ApiNetworkError();
    }
  }
  return new ApiSyntaxError();
};

export class ApiError extends Error {
  type: ErrorType;
  suppressErrorHandler: boolean = false;

  constructor(error: AxiosError | string) {
    super(error instanceof AxiosError ? error.message : error);

    this.type = ErrorType.Error;
    if (error instanceof AxiosError) {
      this.suppressErrorHandler =
        !!(
          error.config as {
            suppressErrorHandler?: boolean;
          }
        )?.suppressErrorHandler || error.response?.status === 401;
    }
  }
}

export class ApiInformationError extends ApiError {
  constructor(error: string) {
    super(error);
    this.name = "Information";
    this.type = ErrorType.Info;
  }
}

export class ApiWarningError extends ApiError {
  constructor(error: string) {
    super(error);
    this.name = "Warning";
    this.type = ErrorType.Warning;
  }
}

export class ApiExceptionError extends ApiError {
  constructor() {
    super("Operation Error. Contact Administrator.");
    this.name = "Error";
  }
}

export class ApiServerError extends ApiError {
  constructor() {
    super("Unknown Server Error.");
    this.name = "Error";
  }
}

export class ApiNetworkError extends ApiError {
  constructor() {
    super("No Connectivity. Try again.");
    this.name = "Network Error";
  }
}

export class ApiCancellationError extends ApiError {
  constructor() {
    super("Cancelled");
    this.name = "Cancelled";
  }
}

export class ApiSyntaxError extends ApiError {
  constructor() {
    super("Syntax Error");
    this.name = "Syntax Error";
  }
}
