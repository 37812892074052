import React from "react";
import {
  AxisLabelVisualArgs,
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  SeriesMarkers,
} from "@progress/kendo-react-charts";
import {
  Button,
  Chip,
  ChipMouseEvent,
  Toolbar,
} from "@progress/kendo-react-buttons";
import { TagData } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import moment from "moment";
import { ReferenceRecordsDataSource } from "../helpers/queries";
import BaseComponent from "../Components/BaseComponent";
import Loader from "../Components/Common/Loader";
import FXChartTitle from "../Components/Common/FXChartTitle";
import { IComboboxItem } from "../helpers/interfaces";
import FilterMultiSelect from "../Components/Common/Form/FilterMultiSelect";
import { sortByNameCombobox } from "../helpers/helpers";
import ComboboxFilter from "../Components/Dashboard/ComboboxFilter";
import {
  CREW_LEAD_SUMMARY_FILTER_BY_CLASS_LS,
  CREW_LEAD_SUMMARY_FILTERS_SS,
} from "./CrewLeadSummary/helpers";
import commonStyles from "../assets/styles/common.module.scss";
import styles from "./CrewLeadSummary/index.module.scss";
import { settingsStorage } from "../helpers/settings";
import { showSomeError } from "../helpers/errorHelpers";

type colorType = "red" | "green";
const COMPANY_SUMMURY_TOTAL_BG = "#bfbfbf";
const Maroon = "#800000",
  Brown = "#9A6324",
  Olive = "#808000",
  Navy = "#000075",
  Red = "#e6194B",
  Orange = "#f58231",
  Yellow = "#ffe119",
  Lime = "#bfef45",
  Green = "#3cb44b",
  Cyan = "#42d4f4",
  Blue = "#4363d8",
  Purple = "#911eb4",
  Magenta = "#f032e6",
  Pink = "#fabed4",
  Apricot = "#fabed4",
  Beige = "#fffac8",
  Mint = "#aaffc3",
  Lavender = "#dcbeff";

const SERIES_COLORS = [
  "#ff6358",
  "#ffe162",
  "#4cd180",
  "#4b5ffa",
  "#ac58ff",
  "#ff5892",
  Cyan,
  Purple,
  Lavender,
  Magenta,
  Orange,
  Yellow,
  Red,
  Green,
  Lime,
  Blue,
  Maroon,
  Apricot,
  Olive,
  Mint,
  Brown,
  Beige,
  Pink,
  Navy,
];
const NONAME_CREWLEAD_NAME = "Not Assigned";
let months = moment.months();
const currentMonth = moment().month();
if (currentMonth !== 11) {
  const firstMonths = months.splice(currentMonth + 1);
  months = firstMonths.concat(months);
}

const additionalCategoryRowHeight = 40;
const dismissedSeriesOpacity = 0.3;
const Categories = [" ", ...months, "Total"];
const SELECTED_CREWLEADS_COUNT_BY_DEFAULT = 5;

interface ICrewLeadMonthData {
  ActualHours: number;
  CrewLeadId: number | null;
  CrewLeadName: string | null;
  EstBudgetHours: number;
  Month: string;
  ClassId: number | null;
  Variance: number; // front calculation
}

interface ICrewLeadClosedBPData {
  ActualHours: number;
  BudgetHours: number;
  CrewLeadId: number | null;
  CrewLeadName: string | null;
  Month: string;
  ClassId: number | null;
  Variance: number; // front calculation
}

interface IBPSeries extends IComboboxItem {
  FullData: Array<ICrewLeadMonthData>;
  ChartData: Array<number | null>;
  TotalVariance: number;
  AverageVariance: number;
}

interface IClosedBPSeries extends IComboboxItem {
  FullData: Array<ICrewLeadClosedBPData>;
  ChartData: Array<number | null>;
  CategoriesData: Array<string>;
}

interface IAllChartInitDataItem {
  ActualHours: number;
  ClassId: number | null;
  CrewLeadId: number | null;
  CrewLeadName: string | null;
  EstBudgetHours: number;
  Month: string;
  Variance: number; // front calculation
}

interface IClosedChartInitDataItem {
  ActualHours: number;
  ClassId: number | null;
  CrewLeadId: number | null;

  CrewLeadName: string | null;
  Month: string;
  BudgetHours: number;
  Variance: number; // front calculation
}

interface props {
  switcher: any;

  onClassesListDefined(classesList: Array<IComboboxItem>): void;
}

interface state {
  loading: boolean;
  crewLeadsList: Array<IComboboxItem>;
  classesList: Array<IComboboxItem>;
  selectedCrewLeads: Array<IComboboxItem>;

  allBPsSeries: Array<IBPSeries>;
  summaryAllBPsSeries: Array<number | null>;
  allBPChartCrossingValues: Array<number>;
  totalCompanyVariance: number;
  averageCompanyVariance: number;
  actualHours: Array<string>;
  estBudgetHours: Array<string>;
  variances: Array<string>;

  closedBPsSeries: Array<IClosedBPSeries>;
  summaryClosedBPsSeries: Array<Array<number> | null>;
  closeBPChartData: Array<{ value: number; color: colorType } | null>;
  closedBPsChartCrossingValues: Array<number>;

  remountKey: number;
}

class CrewLeadDashboard extends BaseComponent<props, state> {
  allBPChartRef: any = React.createRef();
  closedBPChartRef: any = React.createRef();
  highlightedSeriesName: string = "";
  classValue: IComboboxItem | null = null;
  crewLeadsInfo: {
    [key: number]: {
      selected: boolean;
      color: string;
      actualHoursTotal: number; // ??
      name: string;
    };
  } = {};
  showCompanySummary = true;
  allChartInitData: Array<IAllChartInitDataItem> = [];
  closedChartInitData: Array<IClosedChartInitDataItem> = [];
  selectedCrewLeads: Array<IComboboxItem> = [];

  constructor(props: props) {
    super(props);
    this.state = {
      loading: false,
      crewLeadsList: [],
      classesList: [],
      selectedCrewLeads: this.selectedCrewLeads,

      allBPsSeries: [],
      allBPChartCrossingValues: [],
      summaryAllBPsSeries: [],
      totalCompanyVariance: 0,
      averageCompanyVariance: 0,
      actualHours: [],
      estBudgetHours: [],
      variances: [],

      closedBPsSeries: [],
      summaryClosedBPsSeries: [],
      closeBPChartData: [],
      closedBPsChartCrossingValues: [],

      remountKey: +new Date(),
    };
  }

  componentDidMount() {
    this.LoadData();
  }

  render() {
    let { additionslCategoriesClosedBPS, closedBPsCategoriesRows } =
      this.GetClosedBPsUnderChartTable();
    return (
      <div className={commonStyles.ScreenHeightContainerWithToolbar}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <>
            <Toolbar style={{ alignItems: "flex-start" }}>
              {this.props.switcher}
              <ComboboxFilter
                defaultValue={this.classValue}
                filterData={this.state.classesList}
                filter={{
                  type: "combobox",
                  id: "",
                  placeholder: "Filter by Class",
                }}
                onChange={this.OnChangeClass}
              />
              <div style={{ flex: 1 }}>
                <FilterMultiSelect
                  key={this.state.remountKey}
                  selectAll={true}
                  defaultValue={this.selectedCrewLeads}
                  filter={{ width: "auto" }}
                  className={styles.CrewLeadMultiselect}
                  popupSettings={{
                    popupClass: styles.CrewLeadMultiselectList,
                    width: 250,
                  }}
                  onChange={this.OnChangeCrewLeads}
                  placeholder={"Crew Leads"}
                  data={this.state.crewLeadsList}
                  width={500}
                  autoClose={false}
                  tagRender={this.renderCrewLeadsTag}
                  itemRender={this.renderCrewLeadFilterItem}
                />
              </div>
              <div
                style={{
                  height: 27,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  id={"showCompanySummary"}
                  label={"Show Company Summary"}
                  value={this.showCompanySummary}
                  color={COMPANY_SUMMURY_TOTAL_BG}
                  style={{ color: COMPANY_SUMMURY_TOTAL_BG }}
                  onChange={this.OnChangeShowCompanySummary}
                />
              </div>
              <Button icon="refresh" onClick={this.Refresh} />
            </Toolbar>
            <div className={commonStyles.ScrollableContainer}>
              <Chart
                ref={this.allBPChartRef}
                style={{
                  height: 350 + additionalCategoryRowHeight * 3,
                  marginBottom: "15px",
                }}
                transitions={false}
                zoomable={true}
                pannable={true}
              >
                <FXChartTitle text="All Build Plans" />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    key="Categories"
                    categories={Categories}
                    axisCrossingValue={[0, 14]}
                    line={{ width: 3, color: "#656565" }}
                    labels={{ visible: false }}
                    majorTicks={{ visible: false }}
                  />
                  <ChartCategoryAxisItem
                    key="month"
                    name="month"
                    categories={Categories}
                    axisCrossingValue={[0, 14]}
                    labels={{
                      margin: {
                        top: -25,
                        bottom: -15,
                      },
                    }}
                    majorTicks={{ size: 30 }}
                  />
                  <ChartCategoryAxisItem
                    key="Actual"
                    name="Actual"
                    categories={this.state.actualHours}
                    labels={this.GetAllBPsLabelSettings()}
                    title={this.GetTitleSettings()}
                    majorTicks={{ size: 30 }}
                  />
                  <ChartCategoryAxisItem
                    key="Budget"
                    name="Budget"
                    categories={this.state.estBudgetHours}
                    labels={this.GetAllBPsLabelSettings()}
                    title={this.GetTitleSettings()}
                    majorTicks={{ size: 30 }}
                  />
                  <ChartCategoryAxisItem
                    key="Variance"
                    name="Variance"
                    categories={this.state.variances}
                    labels={this.GetAllBPsLabelSettings()}
                    majorTicks={{ size: 30 }}
                  />
                </ChartCategoryAxis>
                <ChartSeries>{this.GetAllBPSeries()}</ChartSeries>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    name="left"
                    axisCrossingValue={this.state.allBPChartCrossingValues}
                    labels={{ format: "n2" }}
                  />
                  <ChartValueAxisItem
                    labels={{
                      mirror: false,
                      format: "n2",
                    }}
                  />
                </ChartValueAxis>
              </Chart>
              {/* SECOND CHART */}
              <Chart
                ref={this.closedBPChartRef}
                style={{
                  height:
                    350 +
                    additionalCategoryRowHeight * additionslCategoriesClosedBPS,
                  marginBottom: "15px",
                }}
                transitions={false}
                zoomable={true}
                pannable={true}
              >
                <FXChartTitle text="Closed Build Plans" />
                <ChartCategoryAxis>{closedBPsCategoriesRows}</ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    name="left"
                    axisCrossingValue={this.state.closedBPsChartCrossingValues}
                    labels={{ format: "n2" }}
                  />
                  <ChartValueAxisItem
                    labels={{
                      mirror: false,
                      format: "n2",
                    }}
                  />
                </ChartValueAxis>
                <ChartSeries>{this.GetClosedBPSeries()}</ChartSeries>
              </Chart>
            </div>
          </>
        )}
      </div>
    );
  }

  GetClosedBPsUnderChartTable = () => {
    let closedBPsCategoriesRows = [];
    let additionslCategoriesClosedBPS = 0;
    closedBPsCategoriesRows.push(
      <ChartCategoryAxisItem
        key="Categories0"
        categories={Categories}
        axisCrossingValue={[0, 14]}
        line={{ width: 3, color: "#656565" }}
        labels={{ visible: false }}
        majorTicks={{ visible: false }}
      />,
    );
    closedBPsCategoriesRows.push(
      <ChartCategoryAxisItem
        key="Categories1"
        categories={Categories}
        axisCrossingValue={[0, 14]}
      />,
    );
    if (this.selectedCrewLeads.length) {
      this.state.closedBPsSeries.forEach((series) => {
        let visible = this.crewLeadsInfo[+series.Id].selected;
        if (visible) {
          additionslCategoriesClosedBPS += 1;
          closedBPsCategoriesRows.push(
            <ChartCategoryAxisItem
              key={series.Id}
              name={series.Name}
              categories={series.CategoriesData}
              labels={this.GetAllBPsLabelSettings()}
              title={this.GetTitleSettings()}
              majorTicks={{ size: 30 }}
            />,
          );
        }
      });
    } else {
      let summaryData: Array<string> = [];
      additionslCategoriesClosedBPS += 1;
      let total = 0;
      this.state.summaryClosedBPsSeries.forEach((item) => {
        if (item !== null) {
          summaryData.push(item[1].toFixed(2));
          total += item[1];
        } else {
          summaryData.push("");
        }
      });
      summaryData[13] = total.toFixed(2);
      summaryData[0] = "Company Summary";
      closedBPsCategoriesRows.push(
        <ChartCategoryAxisItem
          key={"CompanySummary"}
          name={"Company Summary"}
          categories={summaryData}
          labels={this.GetAllBPsLabelSettings()}
          title={this.GetTitleSettings()}
          majorTicks={{ size: 30 }}
        />,
      );
    }
    return { closedBPsCategoriesRows, additionslCategoriesClosedBPS };
  };

  renderCrewLeadFilterItem = (
    el: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    props: any,
  ) => {
    let id = props.dataItem.Id;
    let color = this.crewLeadsInfo[id]?.color;
    const itemChildren = (
      <div>
        <Checkbox
          id={id + ""}
          checked={props.selected}
          label={props.dataItem.Name}
          style={{ zIndex: 0, position: "relative" }}
        />
        <div
          style={{
            borderRight: "2px solid " + color,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            zIndex: 2,
          }}
        ></div>
      </div>
    );
    return React.cloneElement(el, el.props, itemChildren);
  };

  renderCrewLeadsTag = (
    tagData: TagData,
    tag: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  ) => {
    let props = tag.props;
    let id = tagData.data[0].Id;
    let color = this.crewLeadsInfo[id]?.color;
    return (
      <Chip
        key={id + ""}
        {...props}
        fillMode="outline"
        onClick={this.OnClickCrewLeadChip}
        className={styles.CrewLeadChips}
        style={{ color, borderColor: color }}
      />
    );
    return tag; // for ts
  };

  GetAllBPsLabelSettings = () => {
    return {
      margin: { top: -25 },
      title: "dddfdgs",
      content: (e: any) => {
        let text = e.text;
        if (e.index === 0) {
          let maxLength = 7;
          if (window.innerWidth >= 1800) maxLength = 22;
          else if (window.innerWidth >= 1600) maxLength = 18;
          else if (window.innerWidth >= 1300) maxLength = 15;
          else if (window.innerWidth >= 1000) maxLength = 11;
          if (text.length > maxLength) {
            text = text.substring(0, maxLength - 3) + "..."; // can add \n
          }
        }
        return text;
      },
      visual: (e: AxisLabelVisualArgs) => {
        let el: any = e.createVisual();
        let isAllBPTitle =
          e.value === "Actual" ||
          e.value === "Budget" ||
          e.value === "Variance";
        let isTitle =
          !isAllBPTitle && e.value.length && isNaN(parseInt(e.value));
        if (isAllBPTitle || isTitle) {
          el.children[0].options.font = "700 11px 'Segoe UI'";
        } else if (e.format === "Variance") {
          el.children[0].options.fill.color = e.value > 0 ? "red" : "green";
        }
        return el;
      },
    };
  };

  GetTitleSettings = (): object => {
    return {
      text: " ",
      margin: {
        top: -15,
      },
    };
  };

  GetArrowMarkerSettings = (color: string): SeriesMarkers => {
    return {
      visible: true,
      background: color,
      size: 20,
      type: "triangle",
      rotation: -90,
      border: {
        width: 0,
      },
    };
  };

  GetAllBPSeries = () => {
    let series: Array<any> = [];
    series.push(
      <ChartSeriesItem
        key={"allBPSummary"}
        type="area"
        data={this.state.summaryAllBPsSeries}
        name={"allBPSummary"}
        visible={this.showCompanySummary}
        color={COMPANY_SUMMURY_TOTAL_BG}
        visibleInLegend={false}
        highlight={{
          visible: false,
        }}
      >
        <ChartSeriesItemTooltip
          visible={true}
          render={(context: any) => {
            let month = context.point.category;
            let value = context.point.value.toFixed(2);
            return `Company Summary \n ${month} Variance: ${value}`;
          }}
        />
      </ChartSeriesItem>,
    );
    series.push(
      <ChartSeriesItem
        key={"allBPSummaryAvg"}
        type="line"
        data={[this.state.averageCompanyVariance]}
        visible={this.showCompanySummary}
        color={COMPANY_SUMMURY_TOTAL_BG}
        visibleInLegend={false}
        name={"allBPSummaryAvg"}
        markers={this.GetArrowMarkerSettings(COMPANY_SUMMURY_TOTAL_BG)}
        highlight={{
          visible: false,
        }}
      >
        <ChartSeriesItemTooltip
          visible={true}
          format={`Company Summary <br/> Monthly Average Variance: {0}`}
        />
      </ChartSeriesItem>,
    );

    this.state.allBPsSeries.forEach((item) => {
      let Id = +item.Id;
      let crewLeadInfo = this.crewLeadsInfo[Id];
      let color = crewLeadInfo.color;
      let visible = !!crewLeadInfo.selected;
      series.push(
        <ChartSeriesItem
          key={item.Id}
          type="line"
          visibleInLegend={false}
          data={item.ChartData}
          name={item.Name}
          visible={visible}
          color={color}
          highlight={{
            visible: false,
          }}
        >
          <ChartSeriesItemTooltip
            visible={true}
            render={(context: any) => {
              let crewLeadName = context.point.series.name;
              let month = context.point.category;
              let value = context.point.value.toFixed(2);
              return `${crewLeadName} ${month} Variance: ${value}`;
            }}
          />
        </ChartSeriesItem>,
      );
      series.push(
        <ChartSeriesItem
          key={item.Id + "totals"}
          type="line"
          visibleInLegend={false}
          data={[item.AverageVariance]}
          color={color}
          name={item.Name}
          visible={visible}
          markers={this.GetArrowMarkerSettings(color)}
          highlight={{
            visible: false,
          }}
        >
          <ChartSeriesItemTooltip
            visible={true}
            format={`${item.Name} <br/> Monthly Average Variance: {0}`}
          />
        </ChartSeriesItem>,
      );
    });
    return series;
  };

  GetClosedBPSeries = () => {
    let series: Array<any> = [];
    series.push(
      <ChartSeriesItem
        key={"summaryClosedBP"}
        type="rangeColumn"
        name={"summaryClosedBP"}
        visible={this.showCompanySummary}
        data={this.state.summaryClosedBPsSeries}
        missingValues="gap"
        color={COMPANY_SUMMURY_TOTAL_BG}
        visibleInLegend={false}
        opacity={this.highlightedSeriesName ? dismissedSeriesOpacity : 1}
        zIndex={0}
        highlight={{
          visible: false,
        }}
      >
        <ChartSeriesItemTooltip
          visible={true}
          render={(context: any) => {
            let month = context.point.category;
            let value = context.point.value.to.toFixed(2);
            return `Company Summary ${month} Variance: ${value}`;
          }}
        />
      </ChartSeriesItem>,
    );
    this.state.closedBPsSeries.forEach((item) => {
      let Id = +item.Id;
      let crewLeadInfo = this.crewLeadsInfo[Id];
      series.push(
        <ChartSeriesItem
          key={item.Id}
          type="column"
          name={item.Name}
          visible={crewLeadInfo.selected}
          data={item.ChartData}
          color={crewLeadInfo.color}
          visibleInLegend={false}
          zIndex={
            this.highlightedSeriesName &&
            this.highlightedSeriesName !== item.Name
              ? 1
              : 99999999999
          }
          highlight={{ visible: false }}
        >
          <ChartSeriesItemTooltip
            visible={true}
            render={(context: any) => {
              let crewLeadName = context.point.series.name;
              let month = context.point.category;
              let value = context.point.value.toFixed(2);
              return `${crewLeadName} ${month} Variance: ${value}`;
            }}
          />
        </ChartSeriesItem>,
      );
    });
    return series;
  };

  OnClickCrewLeadChip = (event: ChipMouseEvent) => {
    event.syntheticEvent.stopPropagation();
    let seriesName = event.target.props.text || "";
    this.ToggleHighLightSeries(seriesName, event.target.element);
  };

  ToggleHighLightSeries = (
    seriesName: string,
    chip?: HTMLDivElement | null,
  ) => {
    let element = document.getElementsByClassName("highlighted")[0];
    if (element) element.classList.remove("highlighted");
    if (this.highlightedSeriesName !== seriesName && chip) {
      chip.classList.add("highlighted");
    }

    let chartInstance = this.allBPChartRef.current?.chartInstance;
    let closedChartInstance = this.closedBPChartRef.current?.chartInstance;
    if (chartInstance && closedChartInstance) {
      chartInstance.options.series.forEach((item: any) => {
        if (item.type === "line") {
          let color = item.color;
          let highlight =
            this.highlightedSeriesName !== seriesName &&
            item.name === seriesName;
          let dismiss =
            this.highlightedSeriesName !== seriesName &&
            item.name !== seriesName;
          item.opacity = dismiss ? dismissedSeriesOpacity : 1;
          item.zIndex = highlight ? 99999999 : 1;
          if (item.markers?.type !== "triangle") {
            item.markers = { background: highlight ? color : "#fff" };
          }
        }
      });
      closedChartInstance.options.series.forEach((item: any) => {
        item.opacity =
          this.highlightedSeriesName !== seriesName && item.name !== seriesName
            ? dismissedSeriesOpacity
            : 1;
      });
      chartInstance.redraw();
      closedChartInstance.redraw();
      if (this.highlightedSeriesName !== seriesName) {
        chartInstance.toggleHighlight(
          true,
          (i: any) => i.series.name === seriesName,
        );
        chartInstance.showTooltip((i: any) => i.series.name === seriesName);
      }
      this.highlightedSeriesName =
        this.highlightedSeriesName !== seriesName ? seriesName : "";
    }
  };

  OnChangeShowCompanySummary = () => {
    this.showCompanySummary = !this.showCompanySummary;
    let summaryData = this.PrepareSummary(this.state.allBPsSeries);
    this.setState({ ...summaryData });
  };

  OnChangeCrewLeads = (value: IComboboxItem[] | undefined, dataAttr?: any) => {
    this.ToggleHighLightSeries("");
    this.selectedCrewLeads = value || [];
    let selectedIds: Array<number> = [];
    for (let crewLead of this.state.crewLeadsList) {
      let { Id } = crewLead;
      Id = +Id;
      if (!value || value.findIndex((item) => +item.Id === Id) === -1) {
        this.crewLeadsInfo[Id].selected = false;
      } else {
        this.crewLeadsInfo[Id].selected = true;
        selectedIds.push(Id);
      }

      if (value)
        window.sessionStorage.setItem(
          CREW_LEAD_SUMMARY_FILTERS_SS,
          JSON.stringify(value.map((item) => item.Id)),
        );
      else window.sessionStorage.removeItem(CREW_LEAD_SUMMARY_FILTERS_SS);

      let result = this.PrepareSummary(this.state.allBPsSeries);
      this.setState({ ...result });
    }
  };

  OnChangeClass = (value: IComboboxItem | null, filter: any) => {
    this.ToggleHighLightSeries("");
    this.classValue = value;
    if (this.classValue)
      settingsStorage.setForCurrentUser(
        CREW_LEAD_SUMMARY_FILTER_BY_CLASS_LS,
        JSON.stringify(this.classValue),
      );
    else
      settingsStorage.removeForCurrentUser(
        CREW_LEAD_SUMMARY_FILTER_BY_CLASS_LS,
      );
    window.sessionStorage.removeItem(CREW_LEAD_SUMMARY_FILTERS_SS);
    let chartsData = this.PrepareChartsData();
    let summary = this.PrepareSummary(chartsData.allBPsSeries);
    this.setState({ remountKey: +new Date(), ...chartsData, ...summary });
  };

  PrepareChartsData = () => {
    let allBPsSeries: Array<IBPSeries> = [];
    let closedBPsSeries: Array<IClosedBPSeries> = [];
    let crewLeadsList: Array<IComboboxItem> = [];
    let allBPChartCrossingValues: Array<number> = [0];
    let minVariance = 0;
    let summaryAllBPsSeries: Array<number | null> = [];
    let summaryClosedBPsSeries: Array<Array<number> | null> = [null];
    let unicCrewLeads: {
      [key: number]: {
        Name: string;
        data: ICrewLeadMonthData[];
      };
    } = {};
    let classId = this.classValue?.Id || null;
    let unicMonthsCompany: Array<string> = [];
    this.allChartInitData.forEach((item) => {
      if (classId === null || classId === item.ClassId) {
        let CrewLeadId = item.CrewLeadId || 0;
        if (!unicCrewLeads[CrewLeadId]) {
          let Name = item.CrewLeadName || NONAME_CREWLEAD_NAME;
          crewLeadsList.push({ Id: CrewLeadId, Name });
          this.crewLeadsInfo[CrewLeadId].actualHoursTotal = 0;
          unicCrewLeads[CrewLeadId] = { Name, data: [] };
        }
        unicCrewLeads[CrewLeadId].data.push(item);
        minVariance = Math.min(item.Variance, minVariance);
        if (unicMonthsCompany.findIndex((month) => month === item.Month) === -1)
          unicMonthsCompany.push(item.Month);
      }
    });

    while (allBPChartCrossingValues.length < 5) {
      allBPChartCrossingValues.push(minVariance - 10000); // 10000 random value - minvalueY < then smoothChartMinVariance
    }

    let closedBPsChartCrossingValues: Array<number> = [0];
    var closedBPMinVariance = 0;
    let unicCrewLeadsClosedBP: {
      [key: number]: {
        Name: string;
        data: ICrewLeadClosedBPData[];
      };
    } = {};

    this.closedChartInitData.forEach((item) => {
      if (classId === null || classId === item.ClassId) {
        let Id = item.CrewLeadId || 0;
        let Name = item.CrewLeadName || NONAME_CREWLEAD_NAME;
        if (!unicCrewLeads[Id] && !unicCrewLeadsClosedBP[Id]) {
          crewLeadsList.push({ Id, Name });
        }
        if (!unicCrewLeadsClosedBP[Id]) {
          unicCrewLeadsClosedBP[Id] = { Name, data: [] };
        }
        unicCrewLeadsClosedBP[Id].data.push(item);
        closedBPMinVariance = Math.min(item.Variance, closedBPMinVariance);
      }
    });

    let sumClosedBPMonths: Array<number | null> = [];
    let totalCompanyVariance = 0;

    crewLeadsList.forEach((crewLead) => {
      let { Id, Name } = crewLead;
      Id = +Id;
      let FullData = unicCrewLeads[Id]?.data;
      if (FullData) {
        let ChartData: Array<number | null> = [];
        let TotalCrewLeadVariance = 0;
        let numberMonthWithData = 0;
        Categories.forEach((month, i) => {
          if (month === " ") {
            // averages category
            ChartData.push(null);
            summaryAllBPsSeries[i] = null;
            return;
          }
          let dataItem = FullData.find((item) => item.Month === month);
          let Variance = dataItem ? dataItem.Variance : null;
          if (Variance !== null) {
            TotalCrewLeadVariance += Variance;
            totalCompanyVariance += Variance;
            let sumValue: number = summaryAllBPsSeries[i] || 0;
            summaryAllBPsSeries[i] = sumValue + Variance;
            numberMonthWithData += 1;
          } else if (summaryAllBPsSeries[i] === undefined) {
            summaryAllBPsSeries[i] = null;
          }

          let actualHours = dataItem?.ActualHours || null;
          if (dataItem && actualHours !== null) {
            let crewLeadId = dataItem?.CrewLeadId || 0;
            this.crewLeadsInfo[+crewLeadId].actualHoursTotal += actualHours;
          }
          ChartData.push(Variance);
        });
        allBPsSeries.push({
          Id,
          Name,
          ChartData,
          FullData,
          AverageVariance: +(
            TotalCrewLeadVariance / numberMonthWithData
          ).toFixed(2),
          TotalVariance: +TotalCrewLeadVariance.toFixed(2),
        });
      }

      let closedBpFullData = unicCrewLeadsClosedBP[Id]?.data;
      if (closedBpFullData) {
        let ChartData: Array<number | null> = [null];
        let CategoriesData: Array<string> = [Name];
        let totalVariance = 0;
        months.forEach((month, i) => {
          let dataItem = closedBpFullData.find((item) => item.Month === month);
          let Variance = dataItem ? dataItem.Variance : null;
          ChartData.push(Variance);
          CategoriesData.push(Variance !== null ? Variance.toFixed(2) : "");
          let sumValue = sumClosedBPMonths[i] || 0;
          if (Variance !== null) {
            sumClosedBPMonths[i] = sumValue + Variance;
            totalVariance += Variance;
          } else if (sumClosedBPMonths[i] === undefined)
            sumClosedBPMonths[i] = null;
        });
        CategoriesData.push(totalVariance.toFixed(2));
        ChartData.push(null);
        closedBPsSeries.push({
          Id,
          Name,
          ChartData,
          FullData: closedBpFullData,
          CategoriesData,
        });
      }
    });

    sumClosedBPMonths.forEach((value) => {
      summaryClosedBPsSeries.push(value !== null ? [0, value] : null);
    });
    summaryClosedBPsSeries.push(null);
    crewLeadsList.sort((a, b) => {
      let actualHoursA: number = this.crewLeadsInfo[+a.Id].actualHoursTotal;
      let actualHoursB: number = this.crewLeadsInfo[+b.Id].actualHoursTotal;
      return actualHoursB - actualHoursA;
    });

    this.selectedCrewLeads = [];
    let savedSelectedCrewLeads = window.sessionStorage.getItem(
      CREW_LEAD_SUMMARY_FILTERS_SS,
    );
    let crewLeads = savedSelectedCrewLeads
      ? JSON.parse(savedSelectedCrewLeads)
      : [];
    if (crewLeads.length) {
      for (let i = 0; i < crewLeads.length; i++) {
        let Id = crewLeads[i];
        if (this.crewLeadsInfo[+Id]) {
          this.selectedCrewLeads.push({
            Id,
            Name: this.crewLeadsInfo[+Id].name,
          });
          this.crewLeadsInfo[+Id].selected = true;
        }
      }
    } else {
      for (let i = 0; i < SELECTED_CREWLEADS_COUNT_BY_DEFAULT; i++) {
        let item = crewLeadsList[i];
        if (item) {
          // if crewLeadsList.length < SELECTED_CREWLEADS_COUNT_BY_DEFAULT
          this.selectedCrewLeads.push(item);
          this.crewLeadsInfo[+item.Id].selected = true;
        }
      }
      if (this.selectedCrewLeads.length)
        window.sessionStorage.setItem(
          CREW_LEAD_SUMMARY_FILTERS_SS,
          JSON.stringify(this.selectedCrewLeads.map((item) => item.Id)),
        );
      else window.sessionStorage.removeItem(CREW_LEAD_SUMMARY_FILTERS_SS);
    }

    while (closedBPsChartCrossingValues.length < crewLeadsList.length + 2) {
      closedBPsChartCrossingValues.push(closedBPMinVariance - 10000);
    }

    return {
      crewLeadsList,
      selectedCrewLeads: this.selectedCrewLeads,
      allBPsSeries,
      summaryAllBPsSeries,
      closedBPsSeries,
      allBPChartCrossingValues,
      totalCompanyVariance,
      averageCompanyVariance: +(
        totalCompanyVariance / unicMonthsCompany.length
      ).toFixed(2),
      closedBPsChartCrossingValues,
      summaryClosedBPsSeries,
    };
  };

  PrepareSummary = (allBPsSeries: Array<IBPSeries>) => {
    let actualHours: Array<string> = [];
    let estBudgetHours: Array<string> = [];
    let variances: Array<string> = [];
    let varianceSum = 0;
    let actualHoursSum = 0;
    let estBudgetHoursSum = 0;
    let selectedCrewLeadsCount = this.selectedCrewLeads.length;

    actualHours.push("Actual");
    estBudgetHours.push("Budget");
    variances.push("Variance");

    let monthsSum: { [key: string]: ICrewLeadMonthData } = {};
    let doCountAllCrewLeads =
      this.showCompanySummary && !selectedCrewLeadsCount;
    let doCountSelectedCrewLeads = !!selectedCrewLeadsCount;
    if (doCountAllCrewLeads || doCountSelectedCrewLeads) {
      allBPsSeries.forEach((series) => {
        if (
          doCountSelectedCrewLeads &&
          !this.crewLeadsInfo[+series.Id]?.selected
        )
          return;
        series.FullData.forEach((data) => {
          if (!monthsSum[data.Month]) {
            monthsSum[data.Month] = {
              ActualHours: 0,
              CrewLeadId: null,
              CrewLeadName: null,
              EstBudgetHours: 0,
              Month: data.Month,
              Variance: 0,
              ClassId: data.ClassId,
            };
          }

          let MonthSumItem = monthsSum[data.Month];
          varianceSum += data.Variance;
          actualHoursSum += data.ActualHours;
          estBudgetHoursSum += data.EstBudgetHours;

          MonthSumItem.ActualHours += data.ActualHours;
          MonthSumItem.EstBudgetHours += data.EstBudgetHours;
          MonthSumItem.Variance += data.Variance;
        });
      });
    }

    months.forEach(function (month, i) {
      let sumItem = monthsSum[month];
      actualHours.push(sumItem?.ActualHours.toFixed(2) || "");
      estBudgetHours.push(sumItem?.EstBudgetHours.toFixed(2) || "");
      variances.push(sumItem?.Variance.toFixed(2) || "");
    });

    actualHours.push(actualHoursSum.toFixed(2));
    estBudgetHours.push(estBudgetHoursSum.toFixed(2));
    variances.push(varianceSum.toFixed(2));

    return {
      actualHours,
      estBudgetHours,
      variances,
    };
  };

  LoadData = async () => {
    try {
      this.setState({ loading: true });
      const classes = (await ReferenceRecordsDataSource(
        "Classes",
      )) as IComboboxItem[];
      const chartsData = await this.GetSQLData({ spName: "DB_CrewLeadKPI" });
      this.allChartInitData = chartsData[0] || [];
      this.closedChartInitData = chartsData[1] || [];

      const unicClassIds: number[] = [];
      const unicCrewLeadIds: number[] = [];

      const prepareItem = (
        item: IAllChartInitDataItem | IClosedChartInitDataItem,
      ) => {
        if (
          item.ClassId !== null &&
          unicClassIds.findIndex((id) => id === item.ClassId) === -1
        )
          unicClassIds.push(item.ClassId);
        let CrewLeadId = item.CrewLeadId || 0;
        if (this.crewLeadsInfo[CrewLeadId] === undefined) {
          let index = unicCrewLeadIds.length;
          let colorIndex =
            index > SERIES_COLORS.length - 1
              ? index % SERIES_COLORS.length
              : index;
          this.crewLeadsInfo[CrewLeadId] = {
            selected: false,
            color: SERIES_COLORS[colorIndex], // now ??
            actualHoursTotal: 0,
            name: item.CrewLeadName || NONAME_CREWLEAD_NAME,
          };
          unicCrewLeadIds.push(CrewLeadId);
        }
        item.Month = moment.months(moment(item.Month).get("month"));
        // @ts-ignore
        if (item.EstBudgetHours !== undefined) {
          // @ts-ignore
          item.Variance = item.ActualHours - item.EstBudgetHours; // allBPs
        } else {
          // @ts-ignore
          item.Variance = item.ActualHours - item.BudgetHours; // closedBPs
        }
      };

      let saveClass = settingsStorage.getForCurrentUser(
        CREW_LEAD_SUMMARY_FILTER_BY_CLASS_LS,
      );
      this.classValue = saveClass ? JSON.parse(saveClass) : null;
      this.crewLeadsInfo = {};
      this.allChartInitData.forEach(prepareItem);
      this.closedChartInitData.forEach(prepareItem);

      let classesList: Array<IComboboxItem> = [];
      unicClassIds.forEach((id) => {
        let classItem = classes.find((item) => item.Id === id);
        if (classItem) classesList.push(classItem);
      });

      let bpChartsData = this.PrepareChartsData();
      let bpChartSummary = this.PrepareSummary(bpChartsData.allBPsSeries);
      classesList.sort(sortByNameCombobox);
      this.props.onClassesListDefined(classesList);
      this.setState({
        classesList,
        ...bpChartsData,
        ...bpChartSummary,
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  Refresh = () => {
    this.LoadData();
  };
}

export default CrewLeadDashboard;
