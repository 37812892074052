import moment from "moment";
import { jwtDecode } from "jwt-decode";
import DI_TYPES from "../inversify/diTypes";
import type {
  IApi,
  ISasTokenService,
  ISettingsStorage,
} from "../inversify/interfaces";
import { SasTokenAccessType } from "../api/generated/documents";
import { inject, injectable } from "inversify";

export type TParsedSasToken = {
  InstanceId: string;
  Login: string;
  SasTokenAccessType: string;
  UserId: string;
  exp: number;
  iat: number;
  nbf: number;
};

@injectable()
export default class SasTokenService implements ISasTokenService {
  STORAGE_KEY = "STORAGE_SAS_TOKEN";

  constructor(
    @inject(DI_TYPES.IApi) private _api: IApi,
    @inject(DI_TYPES.ISettingsStorage)
    private _settingsStorage: ISettingsStorage,
  ) {}

  async getSasToken(accessType: SasTokenAccessType) {
    const keyStorage = this.getKeySasToken(accessType);
    const sasTokenFromStorage = this._settingsStorage.get(keyStorage);

    if (sasTokenFromStorage) {
      const decodeToken: TParsedSasToken = jwtDecode(sasTokenFromStorage);
      const nowDate = moment();
      const expireTimeDate = moment(decodeToken.exp * 1000);

      if (nowDate.isAfter(expireTimeDate, "milliseconds")) {
        return this.requestAndRecordSasToken(accessType);
      }

      return sasTokenFromStorage;
    }

    return await this.requestAndRecordSasToken(accessType);
  }

  private getKeySasToken(accessType: SasTokenAccessType) {
    const instanceId = this._api.authProvider.getInstanceId() as string;
    const userId = this._api.authProvider.getUserId() as string;

    return [this.STORAGE_KEY, accessType, instanceId, userId].join("_");
  }

  private async requestAndRecordSasToken(accessType: SasTokenAccessType) {
    const keyStorage = this.getKeySasToken(accessType);
    const newSasToken = await this._api.documents.getSasToken({ accessType });

    this._settingsStorage.set(keyStorage, newSasToken);

    return newSasToken;
  }
}
