import {createContext} from "react";

export const predefinedViewsIds = {
    default: "default",
    milestones: "milestones",
    ownerStats: "ownerStats",
    cashflowAndWIP: "cashflowAndWIP",
} as const;
export const predefinedViewsNames = {
    [predefinedViewsIds.default]: "Default",
    [predefinedViewsIds.ownerStats]: "Owner Stats",
    [predefinedViewsIds.milestones]: "Milestones",
    [predefinedViewsIds.cashflowAndWIP]: "Cashflow and WIP Adj P&L",
} as const;
/*type predefinedViewId =
    (typeof predefinedViewsIds)[keyof typeof predefinedViewsIds];*/
export const CurrentViewContext = createContext<{
    value: { id: string, name: string };
    setValue: (value: { id: string, name: string }) => void;
}>({
    value: {id: '', name: ''},
    setValue: (newValue: { id: string, name: string }) => undefined
});