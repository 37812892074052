// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** PnlSourceResult */
export interface PnlSourceResult {
  /** String */
  type: string;
  /** String */
  title: string;
  /**
   * Double
   * @format double
   */
  amount: number;
}
export const PropsPnlSourceResult = {
  type: "type",
  title: "title",
  amount: "amount",
};
/** StatementDescription */
export interface StatementDescription {
  /**
   * Int32
   * @format int32
   */
  id: number;
  /** String */
  title: string;
  /** List`1 */
  defaultDimensions: string[];
  /** List`1 */
  rows: StatementRow[];
}
export const PropsStatementDescription = {
  id: "id",
  title: "title",
  defaultDimensions: "defaultDimensions",
  rows: "rows",
};
/** StatementRow */
export interface StatementRow {
  /**
   * Nullable`1
   * @format int32
   */
  number?: number | null;
  /** String */
  title: string;
  type: StatementRowType;
  /**
   * Nullable`1
   * @format int32
   */
  percentageOf?: number | null;
  /**
   * Int32
   * @format int32
   */
  mainAccountId: number;
  /** List`1 */
  accountIds: number[];
  /** List`1 */
  subRows: StatementRow[];
  /** Boolean */
  isOverhead: boolean;
}
export const PropsStatementRow = {
  number: "number",
  title: "title",
  type: "type",
  percentageOf: "percentageOf",
  mainAccountId: "mainAccountId",
  accountIds: "accountIds",
  subRows: "subRows",
  isOverhead: "isOverhead",
};
/** StatementRowType */
export enum StatementRowType {
  FirstRow = "FirstRow",
  TopAccount = "TopAccount",
  ChildAccount = "ChildAccount",
  Balance = "Balance",
  Delimiter = "Delimiter",
}
export const EnumStatementRowType = {
  FirstRow: "FirstRow",
  TopAccount: "TopAccount",
  ChildAccount: "ChildAccount",
  Balance: "Balance",
  Delimiter: "Delimiter",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix PNL
 * @version v1
 */

export class Api_pnl {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  pnl = {
    getDefaultStatement: (params: RequestParams = {}) =>
      this.http.request<StatementDescription>({
        path: `/Pnl/GetDefaultStatement`,
        method: "GET",
        format: "json",
        ...params,
      }),
    getExpensesBySources: (
      query?: {
        /**
         * Nullable`1
         * @format date-time
         */
        DateFrom?: string;
        /**
         * Nullable`1
         * @format date-time
         */
        DateTo?: string;
        /**
         * Int32
         * @format int32
         */
        PeriodType?: number;
        /**
         * Nullable`1
         * @format int32
         */
        StatementId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        BuildPlanId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        ProjectId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        RegionId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        ProfitCenterId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        CustomerId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        SiteId?: number;
        /**
         * Nullable`1
         * @format int32
         */
        ClassId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PnlSourceResult[]>({
        path: `/Pnl/GetExpensesBySources`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    getPnlStatements: (params: RequestParams = {}) =>
      this.http.request<StatementDescription[]>({
        path: `/Pnl/GetPnlStatements`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
