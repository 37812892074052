import { Button } from "@progress/kendo-react-buttons";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import {
  Checkbox,
  CheckboxChangeEvent,
  RadioButtonProps,
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import { IFXCardProps } from "./interfaces";
import { UpdateDocument } from "../../helpers/helpers";
import { showSomeError } from "../../helpers/errorHelpers";
import formStyles from "./card.module.scss";
import { isMobile } from "../../helpers/settings";
import MobilePopup from "../Common/MobilePopup/Index";
import { IComboboxItem } from "../../helpers/interfaces";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import api from "../../core/api/api";
import { SQL_BP_Workorders_Response } from "../../core/api/generated/conterra";
import React from "react";

interface props extends IFXCardProps {
  sourceId?: number;
  documentId?: number;
  isBuildPlanSourceObjectId: boolean;

  afterSave?(): void;
}

interface state {
  files: UploadFileInfo[];
  processing: boolean;
  docKinds: Array<IComboboxItem>;
  workOrders: SQL_BP_Workorders_Response[];
  isWOLoading: boolean;
  isWOSourceMode: boolean;
}

const maxSizeMB = 50;
const maxAllowedFileSize = maxSizeMB * 1024 * 1024; // bytes

class UploadDocumentsCard extends BaseComponent<props, state> {
  files: File[] = [];
  publish: boolean = false;
  docKindId: number | null = null;
  woId: number | null = null;
  isWOSourceMode: boolean = false;
  modes: RadioButtonProps[] = [
    {
      label: "Build Plan",
      value: "false",
    },
    {
      label: "Work Order",
      value: "true",
    },
  ];

  constructor(props: props) {
    super(props);
    this.state = {
      files: [],
      processing: false,
      docKinds: [],
      workOrders: [],
      isWOLoading: false,
      isWOSourceMode: this.isWOSourceMode,
    };
  }

  componentDidMount() {
    this.LoadKinds();
    const { sourceId, isBuildPlanSourceObjectId } = this.props;
    if (isBuildPlanSourceObjectId && sourceId) this.LoadWOs();
  }

  render() {
    const { sourceId: propsSourceId } = this.props;
    const sourceId = this.isWOSourceMode ? this.woId : propsSourceId;
    const hasSourceId = !!sourceId;
    const hasSizeError = !!this.state.files.find((file) =>
      file.validationErrors?.find(
        (message) => message === "invalidMaxFileSize",
      ),
    );
    const hasFilesError = this.state.files.length > 5;
    const hasError = !hasSourceId || hasSizeError || hasFilesError;
    const onlyUpdateDocument = !!this.props.documentId;
    if (isMobile) {
      return (
        <MobilePopup
          height={onlyUpdateDocument ? 200 : 450}
          width={400}
          style={{ maxWidth: "100%", maxHeight: "100%", overflow: "auto" }}
          onHide={this.props.finally}
        >
          <div style={{ flex: 1, width: "100%" }}>
            {this.renderComboboxes()}
            {this.renderUploadArea(onlyUpdateDocument)}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              {this.renderError(hasError, hasFilesError, hasSizeError)}
              {this.renderPublishCheckBox(hasError, onlyUpdateDocument)}
            </div>
            {this.renderUploadBtn(hasError)}
          </div>
        </MobilePopup>
      );
    }

    let initialHeight = 0;
    if (this.props.isBuildPlanSourceObjectId) {
      initialHeight = onlyUpdateDocument ? 230 : 485;
    } else {
      initialHeight = onlyUpdateDocument ? 200 : 450;
    }

    return (
      <FXCard
        title={`Upload Document${onlyUpdateDocument ? "" : "s"}`}
        onClose={this.props.finally}
        initialWidth={450}
        initialHeight={initialHeight}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          {this.renderComboboxes()}
          <div style={{ maxHeight: onlyUpdateDocument ? 100 : 345 }}>
            {this.renderUploadArea(onlyUpdateDocument)}
          </div>
          <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <div style={{ flex: 1 }}>
              {this.renderError(hasError, hasFilesError, hasSizeError)}
              {this.renderPublishCheckBox(hasError, onlyUpdateDocument)}
            </div>
            <Button
              onClick={this.props.finally}
              disabled={this.state.processing}
            >
              Cancel
            </Button>
            {this.renderUploadBtn(hasError)}
          </div>
        </div>
      </FXCard>
    );
  }

  renderComboboxes = () => {
    const { sourceId, isBuildPlanSourceObjectId } = this.props;
    if (!isBuildPlanSourceObjectId || !sourceId) return null;
    const { isWOSourceMode } = this.state;
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "0 4px 8px",
          }}
        >
          <div>Attach document to:</div>
          <RadioGroup
            value={this.state.isWOSourceMode ? "true" : "false"}
            onChange={this.OnChangeMode}
            data={this.modes}
          />
        </div>

        {isWOSourceMode ? (
          <FilterCombobox
            key={"uploadWO"}
            placeholder="Select Work Order"
            data={this.state.workOrders}
            onChange={this.OnChangeWOCombobox}
            className={formStyles.FormField}
            loading={this.state.isWOLoading}
            dataItemKey={"id"}
            textField={"name"}
            popupSettings={
              isMobile
                ? { popupClass: formStyles.MobileComboboxPopup }
                : undefined
            }
            required={true}
          />
        ) : (
          <FilterCombobox
            key={"uploadKind"}
            placeholder="Select Document Kind"
            data={this.state.docKinds}
            onChange={this.OnChangeDocKind}
            className={formStyles.FormField}
            loading={this.state.processing}
            popupSettings={
              isMobile
                ? { popupClass: formStyles.MobileComboboxPopup }
                : undefined
            }
          />
        )}
      </div>
    );
  };

  OnChangeMode = (event: RadioGroupChangeEvent) => {
    this.woId = null;
    this.docKindId = null;
    this.isWOSourceMode = event.value === "true";
    this.setState({ isWOSourceMode: this.isWOSourceMode });
  };

  renderUploadArea = (onlyUpdateDocument: boolean) => {
    return (
      <Upload
        className={`${formStyles.AddDocumentFiles} ${formStyles.MobileAddDocumentFiles}`}
        accept={
          "image/*,video/*,.doc,.docx,.xlsx,.xls,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        }
        autoUpload={false}
        batch={false}
        multiple={!onlyUpdateDocument}
        withCredentials={false}
        showFileList={true}
        showActionButtons={false}
        restrictions={{ maxFileSize: maxAllowedFileSize }}
        files={this.state.files}
        onAdd={this.OnChangeFiles}
        onRemove={this.OnChangeFiles}
        disabled={this.state.processing}
        selectMessageUI={() => (
          <div>Select file{onlyUpdateDocument ? "" : "s"}...</div>
        )}
      />
    );
  };

  renderError = (
    hasError: boolean,
    hasFilesError: boolean,
    hasSizeError: boolean,
  ) => {
    if (!hasError) return null;
    return (
      <div style={{ color: "red" }}>
        {hasFilesError ? "Max 5 files." : ""}{" "}
        {hasSizeError ? `Max file size is ${maxSizeMB}Mb` : ""}
      </div>
    );
  };

  renderPublishCheckBox = (hasError: boolean, onlyUpdateDocument: boolean) => {
    if (onlyUpdateDocument || hasError || !this.state.files.length) return null;
    return (
      <Checkbox
        defaultChecked={this.publish}
        onChange={this.OnPublishChange}
        label={`Publish Document${
          this.state.files.length > 1 ? "s" : ""
        } for Field`}
        id="PublishNewDocument"
        disabled={this.state.processing}
      />
    );
  };

  renderUploadBtn = (hasError: boolean) => {
    return (
      <Button
        disabled={hasError || this.state.processing || !this.state.files.length}
        onClick={this.UploadFiles}
        themeColor="primary"
      >
        Upload
      </Button>
    );
  };

  OnChangeDocKind = (value: IComboboxItem | null) => {
    this.docKindId = value ? +value.Id : null;
  };

  OnChangeWOCombobox = (value: SQL_BP_Workorders_Response | null) => {
    this.woId = value?.id || null;
    this.forceUpdate();
  };

  OnPublishChange = (e: CheckboxChangeEvent) => (this.publish = e.value);

  OnChangeFiles = (e: UploadOnAddEvent | UploadOnRemoveEvent) => {
    this.files = [];
    e.newState.forEach((uploadItem) => {
      const file = uploadItem?.getRawFile?.();
      if (uploadItem.status === 2 && file) this.files.push(file);
    });
    this.setState({ files: e.newState });
  };

  UploadFiles = () => {
    const { sourceId: propsSourceId } = this.props;
    const sourceId = this.isWOSourceMode ? this.woId : propsSourceId;
    const fileInfo = this.state.files.find(({ status }) => status === 2);
    const file = fileInfo?.getRawFile?.();

    if (file && fileInfo && sourceId) {
      this.UploadDocuments(this.woId || sourceId, fileInfo, file);
    } else {
      if (this.props.afterSave) this.props.afterSave();
      let notUploadedFile = this.state.files.find((file) => file.status !== 4);
      if (!notUploadedFile) {
        if (this.props.finally) this.props.finally();
      }
    }
  };

  UploadDocuments = async (
    SourceId: number,
    fileInfo: UploadFileInfo,
    file: File,
  ) => {
    if (file && fileInfo && SourceId) {
      try {
        fileInfo.status = 3;
        this.forceUpdate();
        const extIndex = fileInfo.name.indexOf(fileInfo.extension!);
        const documentId = await this.GetDocumentId(
          SourceId,
          fileInfo.name.slice(0, extIndex),
        );
        if (documentId) {
          const onProgress = (progress: number) => {
            fileInfo.progress = progress;
            this.forceUpdate();
          };
          await UpdateDocument(+documentId, file, onProgress);
        }
        fileInfo.status = 4;
      } catch (e) {
        fileInfo.status = 0;
        fileInfo.progress = 0;
        fileInfo.validationErrors = ["statusUploadFailed"];
      } finally {
        this.forceUpdate();
        this.UploadFiles();
      }
    }
    /* statuses:
          UploadFailed = 0,
          Initial = 1,
          Selected = 2,
          Uploading = 3,
          Uploaded = 4,
          RemoveFailed = 5,
          Removing = 6
        */
  };

  GetDocumentId = async (SourceId: number, FileName: string) => {
    if (this.props.documentId) return this.props.documentId;
    else if (SourceId) {
      return api.script.documentsCreateNew({
        SourceId,
        IsPublished: this.publish,
        DocKindId: (!this.isWOSourceMode && this.docKindId) || undefined,
        FileName,
      });
    }
  };

  LoadKinds = async () => {
    this.setState({ processing: true });
    const docKinds = (await ReferenceRecordsDataSource(
      "BuildPlanDocumentKinds",
    )) as IComboboxItem[];
    this.setState({ processing: false, docKinds });
  };
  LoadWOs = async () => {
    try {
      this.setState({ isWOLoading: true });
      const { sourceId } = this.props;
      const workOrders = await api.sql.bpWorkorders({ bpId: sourceId });
      this.setState({ workOrders });
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ isWOLoading: false });
    }
  };
}

export default UploadDocumentsCard;
