import {CustomToolPanelProps} from "ag-grid-react";
import {useCallback, useContext, useEffect, useMemo} from "react";
import {
  PropsSQLDBStatusResponse,
  PropsSQLDBStatusResponseFinancial,
} from "../../../core/api/generated/conterra";
import {Button} from "@progress/kendo-react-buttons";
import {settingsStorage} from "../../../helpers/settings";
import {useBooleanState, useRefresher} from "../../../core/tools/Hooks";
import {ColumnState, FilterModel} from "ag-grid-community";
import styles from "../aggrid.module.scss";
import ViewRow from "./ViewItem";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import {Reveal} from "@progress/kendo-react-animation";
import {ModalRef} from "../../../Components/Common/Modal/Modal";
import {
  CurrentViewContext,
  predefinedViewsIds,
  predefinedViewsNames
} from "../helpers";

const storageKey = "aggrid-views-statusBD";

function readViews(): IView[] {
  const viewsJSON = settingsStorage.getForCurrentUser(storageKey);
  return viewsJSON ? JSON.parse(viewsJSON) : [];
}

function saveViews(views: IView[]) {
  settingsStorage.setForCurrentUser(storageKey, JSON.stringify(views));
}

interface IView {
  title: string;
  id: string;
  columnStateJSON: string;
  rowGroupColIdsJSON: string;
  filterModelJSON: string;
  pivotMode: boolean | undefined;
}

export interface ViewsToolPanelProps extends CustomToolPanelProps {
  ownerStatsColIds: string[];
  milestonesStatsColIds: string[];
}

export default function ViewsToolPanel(props: ViewsToolPanelProps) {
  const { api: gridApi, ownerStatsColIds, milestonesStatsColIds } = props;
  const isExpanded = useBooleanState(true);
  const { value: selectedView, setValue: setSelectedView } = useContext(
      CurrentViewContext
  );

  const pivotByOwner = useCallback(async () => {
    await gridApi.setFilterModel({
      [PropsSQLDBStatusResponse.active]: {
        filterType: "set",
        values: ["true"],
      },
    });
    gridApi.updateGridOptions({ pivotMode: true });
    gridApi.setValueColumns(ownerStatsColIds);
    gridApi.setRowGroupColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.autoSizeColumns([PropsSQLDBStatusResponse.owner]);
    gridApi.closeToolPanel();
  }, [gridApi, ownerStatsColIds]);

  const pivotByMilestones = useCallback(() => {
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    gridApi.setColumnsVisible(milestonesStatsColIds, true);
    const restColumns = gridApi
      .getAllGridColumns()
      .filter((x: any) => !milestonesStatsColIds.includes(x.colId));
    gridApi.setColumnsVisible(restColumns, false);
    gridApi.closeToolPanel();
  }, [gridApi, milestonesStatsColIds]);

  const resetState = useCallback(async () => {
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    await gridApi.setFilterModel({
      [PropsSQLDBStatusResponse.active]: {
        filterType: "set",
        values: ["true"],
      },
    });
    gridApi.onFilterChanged();
    gridApi.closeToolPanel();
  }, [gridApi]);

  const cashflowView = useCallback(async () => {
    const filterModel = {
      [PropsSQLDBStatusResponse.active]: {
        filterType: "set",
        values: ["true"],
      },
      [`${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetApproved}`]:
        {
          dateFrom: null,
          dateTo: null,
          filterType: "date",
          type: "notBlank",
        },
    };
    await gridApi.setFilterModel(filterModel);
    gridApi.onFilterChanged();

    const visibleColumnsIds = [
      PropsSQLDBStatusResponse.number,
      PropsSQLDBStatusResponse.active,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetApproved}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingToInvoice}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingSpend}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingCashflow}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.submittedInvoices}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.totalSpend}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.pl}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.wip}`,
      `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.wipAdjustedPL}`,
      "_BudgetNotEqualSalesOrder",
      "_SpendExceedsBudget",
      "_RevenueExceedsBudget",
    ];
    gridApi.resetColumnState();
    gridApi.updateGridOptions({ pivotMode: false });
    gridApi.setColumnsVisible(visibleColumnsIds, true);
    const restColumns = gridApi
      .getAllGridColumns()
      .filter((x: any) => !visibleColumnsIds.includes(x.colId));
    gridApi.setColumnsVisible(restColumns, false);
    gridApi.closeToolPanel();
  }, [gridApi]);

  const predefinedViews = useMemo(() => {
    return [
      {
        id: predefinedViewsIds.default,
        apply: resetState,
      },
      {
        id: predefinedViewsIds.ownerStats,
        apply: pivotByOwner,
      },
      {
        id: predefinedViewsIds.milestones,
        apply: pivotByMilestones,
      },
      {
        id: predefinedViewsIds.cashflowAndWIP,
        apply: cashflowView,
      },
    ];
  }, [resetState, pivotByOwner, pivotByMilestones, cashflowView]);

  const isCustomViewSelected = useMemo(() => {
    return (
      selectedView.id !== predefinedViewsIds.default &&
      selectedView.id!== predefinedViewsIds.ownerStats &&
      selectedView.id !== predefinedViewsIds.milestones &&
      selectedView.id !== predefinedViewsIds.cashflowAndWIP
    );
  }, [selectedView.id]);

  const refresher = useRefresher();
  const saveView = useCallback(
    (viewId?: string) => {
      const colState = gridApi.getColumnState();
      const rowGroupColIds = gridApi
        .getRowGroupColumns()
        .map((x) => x.getColId());
      const views = readViews();
      const view = views.find((x) => x.id === viewId);

      const gridState = {
        pivotMode: gridApi.getGridOption("pivotMode"),
        columnStateJSON: JSON.stringify(colState),
        rowGroupColIdsJSON: JSON.stringify(rowGroupColIds),
        filterModelJSON: JSON.stringify(gridApi.getFilterModel()),
      };
      if (view) {
        Object.assign(view, gridState);
        saveViews(views);
        ModalRef.showNotification({
          id: view.id,
          type: { style: "info" },
          text: `"${view.title}" is updated successfully.`,
          style: { fontSize: 12 },
        });
      } else {
        views.push({
          id: new Date().valueOf().toString(),
          title: `View #${views.length + 2}`,
          ...gridState,
        });
        saveViews(views);
        refresher();
      }
    },
    [gridApi, refresher]
  );
  const deleteView = useCallback(
    (id: string) => {
      const views = readViews();
      saveViews(views.filter((x) => x.id !== id));
      refresher();
    },
    [refresher]
  );
  const renameView = useCallback(
    (id: string, title: string) => {
      const views = readViews();
      saveViews(views.map((x) => (x.id !== id ? x : { ...x, title })));
      if (selectedView.id === id) {
        setSelectedView({id, name: title})
      }
    },
    [selectedView.id, setSelectedView]
  );
  const applyView = useCallback(
    (id: string) => {
      const views = readViews();
      const view = views.find((x) => x.id === id);
      if (!view) {
        alert("View description not found");
        return;
      }
      gridApi.applyColumnState({
        state: JSON.parse(view.columnStateJSON) as ColumnState[],
        applyOrder: true,
      });
      gridApi.setFilterModel(
        JSON.parse(view.filterModelJSON || "{}") as FilterModel
      );
      gridApi.setRowGroupColumns(JSON.parse(view.rowGroupColIdsJSON));
      gridApi.updateGridOptions({ pivotMode: view.pivotMode });
      gridApi.closeToolPanel();
    },
    [gridApi]
  );

  useEffect(() => {
    const predefinedViewSetting = predefinedViews.find(x => x.id === selectedView.id);
    if(predefinedViewSetting) {
      predefinedViewSetting.apply()
    } else {
      applyView(selectedView.id)
    }
  }, [selectedView]);

  const currentViews = readViews();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className={`${styles.ViewRow} ${styles.SaveCurrentViewPanel}`}>
        <Button
          fillMode={"flat"}
          onClick={() => saveView()}
          iconClass={"mdi mdi-content-save-outline"}
        >
          SAVE CURRENT VIEW
        </Button>
      </div>
      {predefinedViews.map((x) => {
        const isSelected = selectedView.id === x.id;
        const name = predefinedViewsNames[x.id]
        return (
          <div
            className={`${styles.ViewRow} ${
              isSelected ? styles.ViewRowSelected : ""
            }`}
          >
            <Button
              key={x.id}
              themeColor={isSelected ? "primary" : undefined}
              fillMode={"flat"}
              className={styles.PredefinedViewBtn}
              onClick={() => setSelectedView({id: x.id, name})}
            >
              {name}
            </Button>
          </div>
        );
      })}
      <div style={{ flex: 1, overflow: "hidden" }}>
        {!!currentViews.length && (
          <ExpansionPanel
            className={`${styles.CustomViewsExpansionPanel} ${
              isCustomViewSelected && !isExpanded.value
                ? styles.CustomViewSelected
                : ""
            }`}
            title={<span style={{ textTransform: "none" }}>Custom Views</span>}
            subtitle={`(${currentViews.length})`}
            expanded={isExpanded.value}
            onAction={isExpanded.toggle}
            tabIndex={-1}
          >
            <Reveal>
              <ExpansionPanelContent hidden={!isExpanded.value}>
                <div className="content" style={{ margin: "-1rem" }}>
                  <div>
                    {currentViews.map((view) => (
                      <ViewRow
                        key={view.id}
                        id={view.id}
                        title={view.title}
                        deleteView={deleteView}
                        isSelected={selectedView.id === view.id}
                        applyName={() => setSelectedView({id: view.id, name: view.title})}
                        editName={renameView}
                        updateViewSettings={saveView}
                      />
                    ))}
                  </div>
                </div>
              </ExpansionPanelContent>
            </Reveal>
          </ExpansionPanel>
        )}
      </div>
    </div>
  );
}
