import "./inversify/container"; // Setup DI Container
import React from "react";
import ReactDOM from "react-dom/client";
import "hammerjs"; // kendo chart need this dependency.
import {
  ModuleRegistry,
  provideGlobalGridOptions,
  themeQuartz,
} from "ag-grid-community";
import {
  AllEnterpriseModule,
  ClientSideRowModelModule,
  IntegratedChartsModule,
  LicenseManager,
} from "ag-grid-enterprise";
import { AgChartsEnterpriseModule } from "ag-charts-enterprise";
import "@progress/kendo-font-icons/dist/index.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./assets/icons/foundationIcons/css/foundation-icons.css";
import "./App.scss";
import { App } from "./App";
import "./tools/errorHandlers";

import "./assets/styles/fxaggrid.scss";
import config from "./config";

ModuleRegistry.registerModules([
  AllEnterpriseModule,
  ClientSideRowModelModule,
  IntegratedChartsModule.with(AgChartsEnterpriseModule),
]);
LicenseManager.setLicenseKey(config.AG_GRID_KEY);
const myTheme = themeQuartz.withParams({
  spacing: 8,
  fontSize: 12,
  oddRowBackgroundColor: "#f5f5f5",
  columnBorder: true,
  wrapperBorder: false,
});

provideGlobalGridOptions({
  theme: myTheme,
  grandTotalRow: "bottom",
  pivotPanelShow: "onlyWhenPivoting",
  suppressAggFuncInHeader: true,
  rowGroupPanelShow: "onlyWhenGrouping",
  enableCharts: true,
  cellSelection: true,
  rowSelection: {
    mode: "singleRow",
    checkboxes: false,
    enableClickSelection: true,
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
