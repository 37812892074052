import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import moment from "moment";
import BaseComponent from "../../Components/BaseComponent";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import {
  dateRangePeriodId,
  dateRangePeriodIds,
  FILTER_OPERATORS,
  GetDefaultDateFrom,
  GetDefaultDateTo,
  GetDefaultGridFilter,
  GetGridHeight,
  GetNewRemountKey,
  GridRowHeight,
  IsComplexGridFilter,
} from "../../Components/Dashboard/helpers";
import {
  IColumnValue,
  IGridFilter,
  IGridFilterItem,
} from "../../Components/Dashboard/interfaces";
import GridRow from "../../Components/Dashboard/GridRow";
import GridFilterCell from "../../Components/Dashboard/GridFilterCell";
import {
  formatFinancial,
  PLFormatPercentage,
  TYPE_TO_EXCEL_FORMAT,
} from "../../helpers/helpers";
import OpenCardLink from "../../Components/OpenCardLink";
import Loader from "../../Components/Common/Loader";
import {
  dimension,
  IColumn,
  IGroupedFDAmounts,
  IGroupedFDDataItem,
  IPLParams,
  IPNLTabsSettings,
  IStatement,
  IStatementColumn,
  periodTypeId,
} from "./interfaces";
import CustomColumnMenu from "./ColumnMenu";
import styles from "./pl.module.scss";
import gridStyles from "../../Components/Dashboard/dashboard.module.scss";
import dashboardStyles from "../../Components/Dashboard/dashboard.module.scss";
import commonStyles from "../../assets/styles/common.module.scss";
import BPTabs from "../../Components/Tabs/BPTabs";
import { ITabSetting } from "../../Components/Tabs/interfaces";
import {
  CalculateGroupedData,
  GetGroupedData,
  GetPreparedStatement,
  GetStatementColumns,
  groupFDAmounts,
  STATEMENT_COLUMNS,
} from "./helpers";
import api from "../../core/api/api";
import { StatementDescription } from "../../core/api/generated/pnl";
import { ISettingsStorage } from "../../core/inversify/interfaces";
import DI_TYPES from "../../core/inversify/diTypes";
import { showSomeError } from "../../helpers/errorHelpers";
import DI from "../../inversify/container";
import DateRangeFilter from "../../Components/Dashboard/DateRangeFilter";

type columnValuesFieldId =
  | "RegionID"
  | "ProfitCenterID"
  | "ProjectID"
  | "CustomerID"
  | "BPID"
  | "SiteID"
  | "ClassID";

const DATE_FORMAT = "YYYY-MM-DD";
const STORAGE_FIELDS = {
  periodType: "PLDS-periodType",
  dateFrom: "PLDS-date-from",
  dateTo: "PLDS-date-to",
  statement: "PLDS-statement",
  periodId: "PLDS-period-type", // todo rename value
  onlyWithValues: "PLDS-only-with-values",
  gridFilter: "PLDS-gridFilter",
  confirmedOnly: "PLDS-confirmedOnly",
};
const PERIOD_TYPES: Array<IComboboxItem> = [
  { Name: "In the Period for all BPs", Id: 0 },
  { Name: "In the Period Only for Closed BPs", Id: 1 },
  { Name: "For BPs Closed in the Period", Id: 2 },
];
const PERIOS_TYPES_TITLES = {
  0: "All Transactions w/in Date Parameters (Active and Closed)",
  1: "Transactions w/in Date Parameters for Closed BPs Only",
  2: "All Transactions for BPs Closed w/in Date Parameters",
};

const DEFAULT_PERIOD_ID = dateRangePeriodIds.currYearToDate;
const PERIOD_TYPE_DEFAULT = PERIOD_TYPES[0];
const ONLY_WITH_VALUES_DEFAULT = true;
const CONFIRMED_ONLY_DEFAULT = false;

interface props {
  isActive: boolean;
}

interface state {
  loading: boolean;
  statements: Array<IComboboxItem>;
  gridColumns: Array<IStatementColumn | IColumn>;
  sort: SortDescriptor[];
  gridData: Array<simpleObject>;
  selectedRow: simpleObject | null;
  gridFilter: any;
  reMountKey: number;
  skip: number;
  tabs: Array<ITabSetting>;
}

const settingsStorage = DI.get<ISettingsStorage>(DI_TYPES.ISettingsStorage);

class PLDashboard extends BaseComponent<props, state> {
  tabsSettings: IPNLTabsSettings | null = null;
  serverSettings!: IPLParams;
  statements: Array<IStatement> = [];
  statement: IComboboxItem | null = null;
  dimensions: string[] | null = null; /*Array<dimension>*/
  groupedFDAmounts: IGroupedFDAmounts = {};
  groupedFDData: Array<IGroupedFDDataItem> = [];
  gridData: Array<simpleObject> = [];
  filteredGridData: Array<simpleObject> = [];
  excelGridRef: any;
  gridRef: any;
  isUserSort: boolean = false;
  dateFrom!: Date;
  dateTo!: Date;
  periodId: dateRangePeriodId = DEFAULT_PERIOD_ID;
  onlyWithValues!: boolean;
  preventSkip: number | null = null;
  preventScrolltop: number | null = null;
  columnValues: { [key in columnValuesFieldId]?: Array<IColumnValue> } = {};
  columnValuesFilter: { [key: string]: Array<IGridFilterItem> } = {};
  collapsedColumns: { [key: string]: boolean } = {};
  markedRowKey: string | number = "";

  constructor(props: props) {
    super(props);
    this.RestoreSettings(false);
    this.state = {
      tabs: [],
      statements: [],
      gridData: [],
      loading: false,
      gridColumns: [],
      sort: [],
      selectedRow: null,
      gridFilter: JSON.parse(
        settingsStorage.getForCurrentUser(STORAGE_FIELDS.gridFilter)!,
      ),
      reMountKey: GetNewRemountKey(),
      skip: 0,
    };
  }

  componentDidMount() {
    this.LoadConfig();
  }

  initExportRef = (ref: any) => {
    if (!this.excelGridRef) this.excelGridRef = ref;
  };

  initGridRef = (ref: any) => {
    if (ref !== this.gridRef) this.gridRef = ref;
  };

  render() {
    const {
      selectedRow,
      gridData,
      gridColumns,
      loading,
      reMountKey,
      tabs,
      skip,
      sort,
      gridFilter,
      statements,
    } = this.state;
    const columns = gridColumns.filter(
      (c) => !this.IsStatementColumn(c) || c.visible !== false,
    );
    const data = selectedRow ? [{ ...selectedRow, marked: true }] : gridData;
    const activeStatementId = this.serverSettings.statementId;
    return (
      <div className={styles.Container}>
        {loading && <Loader />}
        <Toolbar>
          <div className={gridStyles.ToolbarContent}>
            <div className={gridStyles.ToolbarRow}>
              <DateRangeFilter
                key={"period" + reMountKey}
                defaultPeriodId={this.periodId}
                defaultDateFrom={this.dateFrom}
                defaultDateTo={this.dateTo}
                allowEmpty={false}
                onChange={this.OnDateRangeChange}
              />
              <span style={{ width: 78 }}>Transactions:</span>
              <DropDownList
                key={"periodTypes" + reMountKey}
                data={PERIOD_TYPES}
                textField="Name"
                dataItemKey="Id"
                defaultValue={PERIOD_TYPES[this.serverSettings.periodType]}
                onChange={this.OnChangePeriodType}
                style={{ width: "220px" }}
                popupSettings={{ height: "500px" }}
                fillMode={"flat"}
                itemRender={(el, props) => {
                  let id: periodTypeId = props.dataItem.Id;
                  return (
                    <li
                      id={props.id}
                      role="option"
                      aria-selected={props.selected}
                      className={el.props.className}
                      // @ts-ignore
                      onClick={el.props.onClick}
                      title={PERIOS_TYPES_TITLES[id]}
                    >
                      {props.dataItem.Name}
                    </li>
                  );
                }}
              ></DropDownList>
              <ToolbarSpacer />
              <div className={dashboardStyles.RightBtns}>
                <Button
                  onClick={this.SetDefaultSettings}
                  title="Set Default Filters"
                  icon="filter-clear"
                  className={
                    loading || this.IsFiltersInDefault()
                      ? ""
                      : dashboardStyles.BlueResetBtn
                  }
                />
                <Button
                  icon="file-excel"
                  title="Export to Excel"
                  onClick={this.ExportToExcel}
                ></Button>
                <Button icon="refresh" onClick={this.Refresh}></Button>
              </div>
            </div>
            <div className={gridStyles.ToolbarRow}>
              <DropDownList
                key={"statement" + reMountKey}
                data={statements}
                textField="Name"
                dataItemKey="Id"
                defaultValue={this.statement}
                onChange={this.OnChangeStatement}
                style={{ width: "170px" }}
                fillMode={"flat"}
              ></DropDownList>

              <Checkbox
                id="onlywithValues"
                key={"onlyValues" + reMountKey}
                label={"Show only with Values"}
                defaultChecked={this.onlyWithValues}
                onChange={this.OnChangeOnlyWithValues}
              />
              <Checkbox
                id="confirmedOnly"
                key={"confirmedOnly" + reMountKey}
                label={"Confirmed Only"}
                defaultChecked={this.serverSettings.confirmedOnly}
                onChange={this.OnChangeConfirmedOnly}
              />
            </div>
          </div>
        </Toolbar>
        {!!gridColumns.length && (
          <div className={styles.GridContainer}>
            <ExcelExport
              data={gridData}
              ref={this.initExportRef}
              fileName="PL.xlsx"
            >
              <ExcelExportColumn
                key="num"
                field="ProductID"
                width={45}
                title="#"
                locked={true}
                headerCellOptions={{ wrap: true }}
              />
              {gridColumns.map((column) => {
                if (this.IsStatementColumn(column)) {
                  if (column.visible) {
                    return (
                      <ExcelExportColumn
                        key={column.field}
                        field={column.field}
                        width={column.width || 120}
                        title={column.title}
                        locked={true}
                        headerCellOptions={{ wrap: true }}
                      />
                    );
                  } else {
                    return null;
                  }
                }
                if (column.subColumns) {
                  return (
                    <ExcelExportColumnGroup
                      key={column.field}
                      title={column.title}
                      headerCellOptions={{ wrap: true }}
                    >
                      {column.subColumns.map((subColumn: IColumn) => (
                        <ExcelExportColumn
                          key={subColumn.field}
                          field={subColumn.field}
                          width={150}
                          title={subColumn.title}
                          locked={false}
                          headerCellOptions={{ wrap: true }}
                          cellOptions={{
                            format:
                              TYPE_TO_EXCEL_FORMAT[
                                subColumn.type === "Percent"
                                  ? "percent"
                                  : "currency"
                              ],
                            textAlign:
                              subColumn.type === "Percent" ? "center" : "right",
                          }}
                        />
                      ))}
                    </ExcelExportColumnGroup>
                  );
                }
                return (
                  <ExcelExportColumn
                    key={column.field}
                    field={column.field}
                    title={column.title}
                    width={120}
                    headerCellOptions={{ wrap: true }}
                    cellOptions={{
                      format:
                        TYPE_TO_EXCEL_FORMAT[
                          column.type === "Percent" ? "percent" : "currency"
                        ],
                      textAlign: column.type === "Percent" ? "center" : "right",
                    }}
                  />
                );
              })}
            </ExcelExport>
            <Grid
              key={activeStatementId}
              data={data}
              rowHeight={GridRowHeight}
              pageSize={selectedRow ? 1 : 80}
              skip={selectedRow ? 0 : skip}
              total={data.length}
              scrollable={"virtual"}
              onPageChange={this.OnChangePage}
              style={{
                height: GetGridHeight(selectedRow, this.gridRef, true),
              }}
              className={`${styles.PLGrid} ${
                selectedRow ? styles.PLGridCollapse : ""
              }`}
              resizable={true}
              selectedField="marked"
              onRowClick={this.OnMarkRow}
              onRowDoubleClick={this.OnSelectRow}
              sortable={{ mode: "multiple" }}
              sort={sort}
              onSortChange={this.OnSortGrid}
              ref={this.initGridRef}
              filterable={true}
              filter={gridFilter}
              filterOperators={FILTER_OPERATORS}
              rowRender={this.renderRow}
            >
              <Column
                key={"num-" + activeStatementId}
                width={45}
                title="#"
                locked={true}
                sortable={true}
                columnMenu={this.renderColumnMenu}
                field="RowNum"
                filterable={false}
                footerCell={(props) => (
                  <td
                    colSpan={props.colSpan}
                    // @ts-ignore
                    className={props.className}
                    style={props.style}
                  >
                    {data.length}
                  </td>
                )}
                headerClassName="RowNum"
              />
              {columns.map((column) => {
                if (this.IsStatementColumn(column)) {
                  return (
                    <Column
                      key={column.field + "-" + activeStatementId}
                      field={column.field}
                      width={column.width || 120}
                      title={column.title}
                      // locked={true}
                      columnMenu={this.renderColumnMenu}
                      resizable={true}
                      format={JSON.stringify(column.linkFormat)}
                      cell={this.renderStatementCell}
                      filterCell={this.renderFilterCell}
                      headerClassName={`${
                        column.field
                      } ${this.GetColumnHeaderClass(column.field)}`}
                    ></Column>
                  );
                }
                if (column.subColumns && column.subColumns.length) {
                  let isCollapsedParentColumn =
                    !!this.collapsedColumns[column.title || ""];
                  let children: Array<JSX.Element> = [];

                  for (let subColumn of column.subColumns) {
                    if (
                      !isCollapsedParentColumn ||
                      subColumn.title === "Total"
                    ) {
                      children.push(
                        <Column
                          key={subColumn.field + "-" + activeStatementId}
                          field={subColumn.field}
                          width={155}
                          title={subColumn.title}
                          className={subColumn.type}
                          resizable={true}
                          cell={this.renderFormatCell}
                          footerCell={this.renderFormatCell}
                          filterCell={this.renderFilterCell}
                          columnMenu={this.renderColumnMenu}
                          headerClassName={`${
                            subColumn.field
                          } ${this.GetColumnHeaderClass(subColumn.field)}`}
                          filter="numeric"
                        />,
                      );
                    }
                  }

                  return (
                    <Column
                      key={column.field + "-" + activeStatementId}
                      title={column.title}
                      resizable={true}
                      filterable={false}
                      headerClassName={commonStyles.TextCenter}
                      headerCell={(props) => {
                        let icon = this.collapsedColumns[props.title || ""]
                          ? "plus"
                          : "minus";
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              size="small"
                              icon={icon}
                              fillMode="flat"
                              style={{ marginRight: 8 }}
                              data-column={props.title}
                              onClick={this.ToggleParentColumn}
                            ></Button>
                            {props.title}
                          </div>
                        );
                      }}
                    >
                      {children}
                    </Column>
                  );
                }

                return (
                  <Column
                    key={column.field + activeStatementId}
                    field={column.field}
                    title={column.title}
                    width={155}
                    className={column.type}
                    cell={this.renderFormatCell}
                    resizable={true}
                    footerCell={this.renderFormatCell}
                    filterCell={this.renderFilterCell}
                    columnMenu={this.renderColumnMenu}
                    headerClassName={`${
                      column.field
                    } ${this.GetColumnHeaderClass(column.field)}`}
                    filter="numeric"
                  />
                );
              })}
            </Grid>
            <div className={dashboardStyles.Container}>
              {!!tabs.length && (
                <BPTabs
                  key={selectedRow?.Key || 0}
                  isActive={this.props.isActive}
                  props={{
                    tabs,
                    doNotSaveFilters: true,
                  }}
                  parentId="PLDashboard"
                  rowData={selectedRow}
                  unSelect={this.UnSelectRow}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  IsFiltersInDefault = () => {
    let filterBycolumnValues = 0;
    for (let column in this.columnValuesFilter) {
      let filter = this.columnValuesFilter[column];
      filterBycolumnValues += filter.length;
    }

    let gridFilter = JSON.parse(
      settingsStorage.getForCurrentUser(STORAGE_FIELDS.gridFilter)!,
    );
    return !(
      filterBycolumnValues ||
      this.periodId !== DEFAULT_PERIOD_ID ||
      this.onlyWithValues !== ONLY_WITH_VALUES_DEFAULT ||
      this.serverSettings.confirmedOnly !== CONFIRMED_ONLY_DEFAULT ||
      (this.statement &&
        !this.statement.Name.toLowerCase().includes("default")) || // разобраться почему нет statement
      gridFilter?.filters?.length
    );
  };

  ToggleParentColumn = (e: any) => {
    let column = e.currentTarget.getAttribute("data-column");
    this.collapsedColumns[column] = !this.collapsedColumns[column];
    this.forceUpdate();
  };

  GetColumnHeaderClass = (field: string) => {
    let statementColumn = STATEMENT_COLUMNS.find(
      (item) => item.field === field,
    );
    let filterbyValue =
      statementColumn && this.columnValuesFilter[statementColumn.fieldId];
    if (filterbyValue && filterbyValue.length)
      return gridStyles.FilteredColumnTH;

    let filtered = this.state.gridFilter?.filters.find(
      (filter: IGridFilter | IGridFilterItem) => {
        if (IsComplexGridFilter(filter)) {
          let firstFilter = filter.filters[0];
          if (!IsComplexGridFilter(firstFilter) && firstFilter.field === field)
            return true;
        }
        return false;
      },
    );
    return filtered ? gridStyles.FilteredColumnTH : "";
  };

  renderRow = (row: any, props: any) => {
    return <GridRow row={row} props={props} />;
  };

  renderFormatCell = (props: simpleObject) => {
    let isFooter = !props.dataItem;
    let field = props.field || "";
    let column = this.state.gridColumns.find((col) => col.field === field);
    if (column && this.IsStatementColumn(column)) return <td></td>; // for ts
    let format = column?.type;
    let isPercentage = format === "Percent";
    let isFinancialMarkCell =
      format === "Balance" ||
      format === "FirstRow" ||
      format === "WIPAdjustedProfitMargin" ||
      format === "WipDelta";
    let hasServiceRevenueRow = this.GetCurrentStatement()?.hasServiceRevenueRow;

    let value;
    if (isFooter) {
      if (isPercentage) {
        let columnOfField = "";
        if (column && column.field === "WIPAdjustedProfitMarginPercent") {
          columnOfField = hasServiceRevenueRow
            ? "Service Revenue"
            : "Total Revenue";
        } else {
          let columnOf = this.state.gridColumns.find((col) => {
            if (
              !this.IsStatementColumn(col) &&
              column &&
              !this.IsStatementColumn(column)
            ) {
              return (
                col.number !== null &&
                col.number !== undefined &&
                +col.number === column.percentageOf
              );
            }
            return false;
          });
          if (
            column &&
            !this.IsStatementColumn(column) &&
            columnOf &&
            !this.IsStatementColumn(columnOf)
          ) {
            columnOfField = columnOf!.field;
          }

          /* if (columnOfField === "Service Revenue" && !hasServiceRevenueRow) {
                                                                                                                                                                                                columnOfField = "Total Revenue";
                                                                                                                                                                                              } */
        }

        let columnOfVal = this.state.gridData.reduce(
          (sum: number, item: simpleObject) => {
            let val = item[columnOfField];
            if (!isNaN(val) && isFinite(val)) return sum + val;
            return sum;
          },
          0,
        );

        let valSum = this.state.gridData.reduce(
          (sum: number, item: simpleObject) => {
            if (
              column &&
              !this.IsStatementColumn(column) &&
              column.valueField
            ) {
              let val = item[column.valueField];
              if (!isNaN(val) && isFinite(val)) return sum + val;
            }
            return sum;
          },
          0,
        );

        if (column && column.field === "WIPAdjustedProfitMarginPercent") {
          let revenue = columnOfVal;
          let WIPAdjustedProfitMargin = valSum;
          let WipDelta = this.state.gridData.reduce(
            (sum: number, item: simpleObject) => {
              let val = item.WipDelta;
              if (!isNaN(val) && isFinite(val)) return sum + val;
              return sum;
            },
            0,
          );
          let percentWIPAdjustedProfitMargin =
            revenue !== null
              ? (WIPAdjustedProfitMargin / (revenue + WipDelta)) * 100
              : null;
          if (
            WIPAdjustedProfitMargin < 0 &&
            percentWIPAdjustedProfitMargin !== null
          )
            percentWIPAdjustedProfitMargin *= -1;
          value = percentWIPAdjustedProfitMargin;
        } else {
          value = (Math.abs(valSum) / Math.abs(columnOfVal)) * 100;
          if (valSum < 0) value *= -1;
        }
      } else {
        value = this.state.gridData.reduce(
          (sum: number, item: simpleObject) => {
            let val = item[field];
            if (!isNaN(val) && isFinite(val)) return sum + val;
            return sum;
          },
          0,
        );
      }
    } else {
      value = props.dataItem[field];
    }

    let style: simpleObject = {
      pointerEvents: "none",
      textAlign: isPercentage ? "center" : "right",
    };
    if (isFinancialMarkCell) style.color = value >= 0 ? "green" : "red";
    if (isFinancialMarkCell || isPercentage) style.fontWeight = 700;

    return (
      <td
        colSpan={props.colSpan}
        className={`${props.className}`}
        style={props.style}
      >
        <div style={style} className={`${!isPercentage && styles.CurrencyBox}`}>
          {!isPercentage ? <span className={styles.Currency}>$</span> : ""}
          {isPercentage ? PLFormatPercentage(value) : formatFinancial(value)}
        </div>
      </td>
    );
  };

  renderStatementCell = (props: GridCellProps) => {
    let format = props.format && JSON.parse(props.format);
    let text = props.dataItem[props.field || ""];
    let inner = format ? (
      <OpenCardLink
        text={text}
        dataAttr={props.dataItem[format.fieldId]}
        refName={format.refName}
        refresh={this.Refresh}
      />
    ) : (
      text
    );
    return (
      <td className={props.className} style={props.style}>
        {inner}
      </td>
    );
  };

  renderLinkCell = (props: any) => {
    return (
      <td className={props.className}>
        <OpenCardLink
          text={props.dataItem[props.dataItem.field]}
          dataAttr={props.dataItem[props.dataItem.fieldId]}
          onClick={props.dataItem.openLink}
        />
      </td>
    );
  };

  renderFilterCell = (props: any) => {
    return (
      <GridFilterCell
        key={props.field + this.state.reMountKey}
        props={props}
        defaultValue={props.value}
        field={props.field}
        onChange={
          props.filterType === "text"
            ? this.OnGridTextFilterChange
            : this.OnGridNumericFilterChange
        }
        onOperatorChange={this.OnGridFilterOperatorChange}
        clear={this.ClearGridFilter}
      />
    );
  };

  renderColumnMenu = (props: any) => {
    const selectedStatement = this.statements.find(
      (x) => x.id === this.serverSettings.statementId,
    );
    const statementColumn = STATEMENT_COLUMNS.find(
      (x) => x.field === props.column.field,
    );
    return (
      <CustomColumnMenu
        {...props}
        defaultProps={props}
        columns={
          statementColumn
            ? this.state.gridColumns.filter((col) =>
                this.IsStatementColumn(col),
              )
            : undefined
        }
        onColumnsSubmit={statementColumn ? this.OnColumnsSubmit : undefined}
        defaultColumnsId={
          statementColumn ? selectedStatement!.defaultDimensions : undefined
        }
        onFilterChange={this.OnComplexFilterChange}
        fieldId={statementColumn ? statementColumn.fieldId : undefined}
        getColumnValues={statementColumn ? this.GetColumnValues : undefined}
        filterByValues={this.FilterByValues}
      />
    );
  };

  GetColumnValues = (
    field: string,
    fieldId: columnValuesFieldId,
  ): Array<IColumnValue> => {
    return this.columnValues[fieldId] || [];
  };

  FilterByValues = (
    filters: Array<IGridFilterItem>,
    values: Array<IColumnValue>,
    fieldId: columnValuesFieldId,
    fieldName: string,
  ) => {
    this.OnSelectRow(null);
    this.columnValuesFilter[fieldId] = filters;
    this.columnValues[fieldId] = values;
    let gridFilter = this.state.gridFilter;
    if (gridFilter) {
      gridFilter.filters = gridFilter.filters.filter(
        (f: IGridFilterItem | IGridFilter) => {
          // remove quickfilter
          if (!IsComplexGridFilter(f)) {
            return f.field !== fieldName;
          } else {
            // remove complex filter
            let firstFilter = f.filters[0];
            if (
              IsComplexGridFilter(f) &&
              !IsComplexGridFilter(firstFilter) &&
              firstFilter.field !== fieldName
            )
              return true;
          }
          return false;
        },
      );
    }
    this.SetGridFilter(gridFilter, true);
  };

  OnChangePage = (event: any) => {
    if (!this.state.selectedRow) {
      let skip = this.preventSkip;
      if (skip !== null) {
        if (this.gridRef && this.gridRef.vs.container.scroll) {
          this.gridRef.vs.container.scroll(0, this.preventScrolltop);
          this.preventScrolltop = null;
        } else if (this.gridRef.vs.container) {
          this.gridRef.vs.attendedSkip = skip;
          this.gridRef.vs.prevScrollPos = this.preventScrolltop;
          this.gridRef.vs.propsSkip = skip;
          this.gridRef.vs.realSkip = skip;
          this.preventScrolltop = null;
        }
      }
      this.setState({ skip: skip !== null ? skip : event.page.skip });
      this.preventSkip = null;
    }
  };

  IsStatementColumn(col: IStatementColumn | IColumn): col is IStatementColumn {
    return (col as IStatementColumn).isStatementColumn !== undefined;
  }

  LoadConfig = async () => {
    try {
      this.setState({ loading: true });
      const tabSettings = await this.GetSQLData({ spName: "PL_AvailableTabs" });
      const statements = await api.pnl.getPnlStatements();
      this.tabsSettings = tabSettings[0];
      this.SetStatementsConfig(statements);
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  SetStatementsConfig = (statements: StatementDescription[]) => {
    if (!statements) return;
    this.statements = statements.map(GetPreparedStatement);
    let statementsList: IComboboxItem[] = statements.map(({ id, title }) => ({
      Id: id!,
      Name: title!,
    }));
    let selectedStatementId = this.statement?.Id;
    if (!selectedStatementId) {
      let oldSelectedStatementId =
        settingsStorage.getForCurrentUser("PLDS-statement-id");
      if (oldSelectedStatementId) {
        settingsStorage.removeForCurrentUser("PLDS-statement-id");
        selectedStatementId = oldSelectedStatementId;
      }
    }
    const selectedStatement = statementsList.find((statement) =>
      selectedStatementId
        ? +selectedStatementId === statement.Id
        : statement.Name.toLowerCase().includes("default"),
    );

    if (
      !this.statement ||
      (selectedStatement && this.statement.Id !== selectedStatement.Id)
    ) {
      this.statement = selectedStatement || null;
      if (selectedStatement) {
        this.serverSettings.statementId = +selectedStatement?.Id;
      }
      this.setState({ reMountKey: GetNewRemountKey() });
    }
    this.dimensions = this.GetCurrentStatement()!.defaultDimensions || [];
    this.setState({ statements: statementsList });
    this.LoadData();
  };

  LoadData = async () => {
    this.serverSettings.dateFrom = moment(this.dateFrom).format(DATE_FORMAT);
    this.serverSettings.dateTo = moment(this.dateTo).format(DATE_FORMAT);
    this.columnValuesFilter = {};
    this.columnValues = {};

    try {
      this.setState({ loading: true, selectedRow: null });
      const groupedFD = await this.GetSQLData({
        spName: "GetGroupedFD",
        params: this.serverSettings,
      });
      const amounts = groupedFD[0] || [];
      const data = groupedFD[1] || [];
      const currentStatement = this.GetCurrentStatement();
      const statementColumns = GetStatementColumns(
        this.dimensions || currentStatement!.defaultDimensions || [],
      );
      let columns = currentStatement!.columns;
      if (!this.tabsSettings?.Statement) {
        const excludedColumns = [
          "Total Revenue",
          "WIP",
          "WIP Adjusted Profit Margin",
          "WIP Adjusted Profit Margin %",
        ];
        columns = columns.filter(
          (column) =>
            excludedColumns.findIndex(
              (title: string) => title === column.title,
            ) === -1,
        );
      }
      for (let column of columns) {
        if (column.subColumns?.length) {
          let title = column.title;
          if (this.collapsedColumns[title] === undefined)
            this.collapsedColumns[title] = true;
        }
      }
      let gridColumns = statementColumns.concat(columns);
      this.groupedFDAmounts = groupFDAmounts(amounts);
      this.groupedFDData = data;
      this.filteredGridData = this.PrepareGridData();
      let sort = this.isUserSort
        ? this.state.sort
        : currentStatement!.defaultSort;
      let gridData = this.GetFinalGridData(sort, this.state.gridFilter);
      this.SetcolumnValuesData(data);
      this.setState({ gridColumns, gridData, sort });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  SetcolumnValuesData = (data: Array<simpleObject>) => {
    this.columnValues = {};

    const valuesFields: Array<[columnValuesFieldId, string]> = [
      ["RegionID", "RegionName"],
      ["ProfitCenterID", "ProfitCenterName"],
      ["ProjectID", "ProjectName"],
      ["CustomerID", "CustomerName"],
      ["BPID", "BPName"],
      ["SiteID", "SiteName"],
      ["ClassID", "ClassName"],
    ];

    let unicValues: {
      [key: string]: {
        // fieldId
        [key: number]: boolean;
      };
    } = {};

    data.forEach((item) => {
      for (let fields of valuesFields) {
        let FieldId = fields[0];
        if (!unicValues[FieldId]) unicValues[FieldId] = {};
        if (!this.columnValues[FieldId]) this.columnValues[FieldId] = [];
        if (!this.columnValues[FieldId]) this.columnValues[FieldId] = [];
        let Id = item[FieldId];
        if (!unicValues[FieldId][Id]) {
          let fieldName = fields[1];
          unicValues[FieldId][Id] = true;
          this.columnValues[FieldId]?.push({
            Id,
            Name: item[fieldName],
            Selected: true,
            FieldId,
          });
        }
      }
    });
  };

  RestoreSettings = (force: boolean) => {
    if (force) this.isUserSort = false;

    const state: simpleObject = {};
    if (!force) {
      let savedDateFrom = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.dateFrom,
      );
      let savedDateTo = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.dateTo,
      );
      // if old P&L localstorage
      if (savedDateFrom) {
        let format =
          savedDateFrom?.indexOf("/") > -1 ? "MM/DD/YYYY" : DATE_FORMAT;
        state.dateFrom = moment(savedDateFrom, format);
      }
      if (savedDateTo) {
        let format = savedDateTo.indexOf("/") > -1 ? "MM/DD/YYYY" : DATE_FORMAT;
        state.dateTo = moment(savedDateTo, format);
      }

      state.periodId =
        settingsStorage.getForCurrentUser(STORAGE_FIELDS.periodId) ||
        DEFAULT_PERIOD_ID;
      state.gridFilter = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.gridFilter,
      );
      let periodType = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.periodType,
      );
      if (periodType) state.periodType = JSON.parse(periodType);
      let onlyWithValues = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.onlyWithValues,
      );
      if (onlyWithValues) state.onlyWithValues = JSON.parse(onlyWithValues);
      let confirmedOnly = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.confirmedOnly,
      );
      if (confirmedOnly) state.confirmedOnly = JSON.parse(confirmedOnly);
      let gridFilter = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.gridFilter,
      );
      if (gridFilter) state.gridFilter = JSON.parse(gridFilter);
      let statement = settingsStorage.getForCurrentUser(
        STORAGE_FIELDS.statement,
      );
      if (statement) state.statement = JSON.parse(statement);
    }

    if (force || !state.dateFrom) state.dateFrom = GetDefaultDateFrom();
    if (force || !state.dateTo) state.dateTo = GetDefaultDateTo();
    if (force) state.periodId = DEFAULT_PERIOD_ID;
    if (force || state.periodType === undefined)
      state.periodType = PERIOD_TYPE_DEFAULT;
    if (force || state.onlyWithValues === undefined)
      state.onlyWithValues = ONLY_WITH_VALUES_DEFAULT;
    if (force || state.confirmedOnly === undefined)
      state.confirmedOnly = CONFIRMED_ONLY_DEFAULT;
    if (force || !state.gridFilter) state.gridFilter = GetDefaultGridFilter();
    if (force || !state.statement) state.statement = this.GetDefaultStatement(); // ??

    let formattedDateFrom = state.dateFrom.format(DATE_FORMAT);
    let formattedDateTo = state.dateTo.format(DATE_FORMAT);

    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.dateFrom,
      formattedDateFrom,
    );
    settingsStorage.setForCurrentUser(STORAGE_FIELDS.dateTo, formattedDateTo);
    settingsStorage.setForCurrentUser(STORAGE_FIELDS.periodId, state.periodId);
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.periodType,
      JSON.stringify(state.periodType),
    );
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.confirmedOnly,
      state.confirmedOnly.toString(),
    );
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.onlyWithValues,
      state.onlyWithValues.toString(),
    );
    if (state.statement)
      settingsStorage.setForCurrentUser(
        STORAGE_FIELDS.statement,
        JSON.stringify(state.statement),
      );
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.gridFilter,
      JSON.stringify(state.gridFilter),
    );

    this.dateFrom = state.dateFrom.toDate();
    this.dateTo = state.dateTo.toDate();
    this.periodId = state.periodId;
    this.onlyWithValues = state.onlyWithValues;
    if (state.statement) this.statement = state.statement;

    this.serverSettings = {
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      periodType: state.periodType.Id,
      statementId: state.statement?.Id,
      confirmedOnly: state.confirmedOnly,
    };
    if (force) {
      this.setState({
        reMountKey: GetNewRemountKey(),
        gridFilter: state.gridFilter,
      });
      this.LoadData();
    }
  };

  GetCurrentStatement = () => {
    return this.statements.find(
      ({ id }) => id === this.serverSettings.statementId,
    );
  };

  PrepareGridData = () => {
    let currentStatement = this.GetCurrentStatement();
    let columns = currentStatement!.columns;
    let groupedData = GetGroupedData(
      this.groupedFDData,
      this.dimensions || this.GetCurrentStatement()!.defaultDimensions || [],
    );
    this.gridData = CalculateGroupedData(
      groupedData,
      columns,
      this.groupedFDAmounts,
      !!currentStatement?.hasServiceRevenueRow,
    );
    return this.GetFilteredGridData();
  };

  GetFilteredGridData = () => {
    if (
      settingsStorage.getForCurrentUser(STORAGE_FIELDS.onlyWithValues) ===
      "false"
    )
      return this.gridData;
    let currentStatement = this.GetCurrentStatement();
    let columns = currentStatement!.columns;
    let gridData: Array<simpleObject> = this.gridData.filter((row) => {
      let isVisible = false;
      columns.forEach((column) => {
        if (column.type !== "Percent" && !isVisible) {
          let value = row[column.field];
          isVisible = value !== null && value !== 0;
        }
      });
      return isVisible;
    });

    return orderBy(gridData, this.state.sort);
  };

  Refresh = () => {
    this.LoadData();
  };

  OnColumnsSubmit = (columns: Array<IStatementColumn | IColumn>) => {
    let dimensions: Array<dimension> = [];
    this.state.gridColumns.forEach((column) => {
      if (this.IsStatementColumn(column)) {
        let newColumn = columns.find((col) => column.title === col.title);
        column.visible = !!(
          newColumn &&
          this.IsStatementColumn(newColumn) &&
          newColumn.visible
        );
        if (column.visible) dimensions.push(column.id);
      }
    });
    // this.isUserSort = true; ??
    this.dimensions = dimensions;
    this.filteredGridData = this.PrepareGridData();
    this.SetGridFilter(undefined, true);
  };

  OnDateRangeChange = (value: {
    periodId: dateRangePeriodId;
    from: Date;
    to: Date;
  }) => {
    this.dateFrom = value.from;
    this.dateTo = value.to;
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.dateFrom,
      moment(this.dateFrom).format(DATE_FORMAT),
    );
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.dateTo,
      moment(this.dateTo).format(DATE_FORMAT),
    );
    if (this.periodId !== value.periodId) {
      settingsStorage.setForCurrentUser(
        STORAGE_FIELDS.periodId,
        value.periodId,
      );
      this.periodId = value.periodId;
      // this.setState({ reMountKey: GetNewRemountKey() });// ??
    }

    this.LoadData();
  };

  OnChangeStatement = (e: any) => {
    this.isUserSort = false;
    this.dimensions = null;
    let value = e.target.value;
    this.serverSettings.statementId = value.Id;
    this.statement = value;
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.statement,
      JSON.stringify(value),
    );
    this.SetGridFilter(undefined);
    this.LoadData();
  };

  OnChangePeriodType = (e: any) => {
    let value = e.value;
    this.serverSettings.periodType = value.Id;
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.periodType,
      JSON.stringify(value),
    );
    this.LoadData();
  };

  OnChangeOnlyWithValues = (e: any) => {
    settingsStorage.setForCurrentUser(STORAGE_FIELDS.onlyWithValues, e.value);
    this.onlyWithValues = e.value;
    this.filteredGridData = this.GetFilteredGridData();
    let gridData = this.GetFinalGridData(
      this.state.sort,
      this.state.gridFilter,
    );
    this.setState({ gridData, selectedRow: null });
  };

  OnChangeConfirmedOnly = (e: any) => {
    settingsStorage.setForCurrentUser(STORAGE_FIELDS.confirmedOnly, e.value);
    this.serverSettings.confirmedOnly = e.value;

    if (this.tabsSettings?.Detailing) {
      this.setState((prevState) => ({
        tabs: prevState.tabs.map((x) =>
          x.id === "PLDetails"
            ? { ...x, confirmedOnly: this.serverSettings.confirmedOnly }
            : { ...x },
        ),
      }));
    }

    this.LoadData();
  };

  GetDefaultStatement = () => {
    if (!this.state || !this.statements || !this.statements.length)
      return undefined;
    return (
      this.state.statements.find((item: IComboboxItem) =>
        item.Name.toLowerCase().includes("default"),
      ) || this.state.statements[0]
    );
  };

  SetDefaultSettings = () => {
    this.RestoreSettings(true);
  };

  OnMarkRow = (e: { dataItem: simpleObject }) => {
    let lastMarkedKey = this.markedRowKey;
    let rowData: simpleObject = e.dataItem;
    let key = rowData.Key;
    if (lastMarkedKey && lastMarkedKey === key) return; // or delete mark??
    // todo save marked row ???
    let gridData = this.GetFinalGridData(
      this.state.sort,
      this.state.gridFilter,
    );
    this.markedRowKey = key;
    this.setState({ gridData });
  };

  UnSelectRow = () => this.OnSelectRow(null);

  OnSelectRow = (e: { dataItem: simpleObject } | null) => {
    let lastSelectedKey = this.state.selectedRow && this.state.selectedRow.Key;
    let selectedRow: simpleObject | null = e?.dataItem || null;
    if (
      selectedRow === null ||
      (lastSelectedKey && lastSelectedKey === selectedRow.Key)
    ) {
      selectedRow = null;
    }
    if (selectedRow?.Key) this.markedRowKey = selectedRow.Key;
    let skip = this.state.skip;
    let scroll = this.gridRef.vs.prevScrollPos;
    let gridData = this.GetFinalGridData(
      this.state.sort,
      this.state.gridFilter,
    );
    let detailsProps = {
      dataItem: selectedRow,
      statement: this.GetCurrentStatement()!,
      amounts: this.groupedFDAmounts,
      dataFrom: this.serverSettings.dateFrom,
      dataTo: this.serverSettings.dateTo,
      periodType: this.serverSettings.periodType,
    };
    let tabs: ITabSetting[] = [];
    if (selectedRow !== null) {
      if (this.tabsSettings?.Statement) {
        tabs.push({
          id: "PL",
          name: "Statement",
          props: { plProps: detailsProps },
        });
      }
      if (this.tabsSettings?.Detailing) {
        tabs.push({
          id: "PLDetails",
          name: "Details",
          props: {
            queryProps: detailsProps,
            confirmedOnly: this.serverSettings.confirmedOnly,
          },
        });
      }
    }
    this.setState({ selectedRow, gridData, tabs });
    if (selectedRow === null) {
      //@ts-ignore
      let el = $(this.gridRef.element).find(
        ".k-grid-content.k-virtual-content",
      );
      let scrollLeft = el.scrollLeft();
      el.scrollLeft(0).scrollLeft(100).scrollLeft(scrollLeft);
    } else {
      this.preventSkip = skip;
      this.preventScrolltop = scroll;
    }
  };

  OnSortGrid = (e: simpleObject) => {
    this.isUserSort = true;
    let gridData = this.GetFinalGridData(e.sort, this.state.gridFilter);
    this.setState({
      sort: e.sort,
      gridData: gridData,
    });
  };

  GetFinalGridData = (sort: any, filter: any) => {
    for (let row of this.filteredGridData) {
      row.marked = row.Key === this.markedRowKey;
    }
    let filterByGridFilters = filterBy(this.filteredGridData, filter);
    let filterByValues = GetDefaultGridFilter();
    for (let key in this.columnValuesFilter) {
      let filter = this.columnValuesFilter[key];
      if (filter.length)
        filterByValues.filters.push({ filters: filter, logic: "or" });
    }
    let data = orderBy(filterBy(filterByGridFilters, filterByValues), sort);
    data.forEach((row: simpleObject, i: number) => {
      row.RowNum = i + 1;
    });
    return data;
  };

  GetGridFilterClearedComplexFilter = (field: string) => {
    let gridFilter = this.state.gridFilter;
    if (!gridFilter) gridFilter = GetDefaultGridFilter();
    else {
      gridFilter.filters = gridFilter.filters.filter(
        (f: IGridFilterItem | IGridFilter) => {
          if (!IsComplexGridFilter(f)) return true;
          else {
            let firstFilter = f.filters[0];
            if (
              IsComplexGridFilter(f) &&
              !IsComplexGridFilter(firstFilter) &&
              firstFilter.field !== field
            )
              return true;
          }
          return false;
        },
      );
    }
    return gridFilter;
  };

  ClearValuesFilter = (fieldId: columnValuesFieldId) => {
    if (this.columnValuesFilter[fieldId]) {
      delete this.columnValuesFilter[fieldId];
      let values = this.columnValues[fieldId];
      if (values) {
        for (let val of values) {
          val.Selected = false;
        }
      }
    }
  };

  OnGridNumericFilterChange = (value: any, filter: IGridFilterItem) => {
    let field = filter.field;
    let gridFilter = this.GetGridFilterClearedComplexFilter(field);
    if (value) {
      value = +value;
      let old;
      if (gridFilter.filters.length) {
        old = gridFilter.filters.find((filter: any) => filter.field === field);
      }
      if (old) old.value = value;
      else {
        gridFilter.filters.push({
          field,
          value,
          operator: "gte",
        });
      }
    } else {
      gridFilter.filters = gridFilter.filters.filter(
        (filter: any) => filter.field !== field,
      );
    }
    this.SetGridFilter(gridFilter);
  };

  OnGridTextFilterChange = (value: any, filter: IGridFilterItem) => {
    let field = filter.field;
    let statementColumn = STATEMENT_COLUMNS.find(
      (item) => item.field === field,
    );
    // @ts-ignore
    let fieldId: columnValuesFieldId =
      statementColumn && statementColumn.fieldId;
    if (fieldId) this.ClearValuesFilter(fieldId);
    let gridFilter = this.GetGridFilterClearedComplexFilter(field);

    let old;
    if (gridFilter.filters.length) {
      old = gridFilter.filters.find((filter: any) => filter.field === field);
    }
    if (old) old.value = value;
    else {
      gridFilter.filters.push({
        field,
        value,
        operator: "contains",
      });
    }
    this.SetGridFilter(gridFilter);
  };

  OnComplexFilterChange = (
    gridFilter: IGridFilter,
    fieldId: string | columnValuesFieldId,
    fieldName: string,
  ) => {
    // @ts-ignore
    if (fieldId as columnValuesFieldId) this.ClearValuesFilter(fieldId);
    if (gridFilter) {
      gridFilter.filters = gridFilter.filters.filter(
        (f: IGridFilterItem | IGridFilter) => {
          if (!IsComplexGridFilter(f)) return f.field !== fieldName;
          return true;
        },
      );
    }
    this.SetGridFilter(gridFilter, true);
  };

  OnGridFilterOperatorChange = (props: any) => {
    let field = props.item.field;
    let operator = props.item.operator;
    let gridFilter = this.GetGridFilterClearedComplexFilter(field);
    let oldFilterIndex = gridFilter.filters.findIndex(
      (filter: any) => filter.field === field,
    );
    let oldFilter = oldFilterIndex !== -1 && gridFilter.filters[oldFilterIndex];
    if (oldFilter) {
      if (oldFilter.operator === operator && oldFilter.value === null) {
        gridFilter.filters.splice(oldFilterIndex, 1);
      } else oldFilter.operator = operator;
    } else {
      gridFilter.filters.push({
        field,
        value: null,
        operator,
      });
    }
    this.SetGridFilter(gridFilter);
  };

  SetGridFilter = (
    gridFilter: IGridFilter | undefined,
    unselectRow?: boolean,
  ) => {
    if (gridFilter === undefined) gridFilter = GetDefaultGridFilter();
    settingsStorage.setForCurrentUser(
      STORAGE_FIELDS.gridFilter,
      JSON.stringify(gridFilter),
    );
    let gridData = this.GetFinalGridData(this.state.sort, gridFilter);
    this.setState((state) => ({
      gridFilter,
      gridData,
      selectedRow: unselectRow ? null : state.selectedRow,
      reMountKey: +new Date(),
    }));
  };

  ClearGridFilter = (filter: IGridFilterItem) => {
    let field = filter.field;
    let gridFilter = this.state.gridFilter;
    gridFilter.filters = gridFilter.filters.filter(
      (filter: any) => filter.field !== field,
    );
    this.setState({ reMountKey: GetNewRemountKey() });
    this.SetGridFilter(gridFilter);
  };

  ExportToExcel = () => {
    this.excelGridRef.save();
  };
}

export default PLDashboard;
