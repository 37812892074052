import { useCalendarContext } from "./CalendarContext";
import { Tooltip } from "react-tooltip";
import moment from "moment/moment";
import { useCallback } from "react";
import { useCalendarTooltipContext } from "./CalendarTooltipContext";
import styles from "./calendar.module.scss";

export const CalendarDispatchTooltip = () => {
  const ctx = useCalendarContext();
  const ctxTooltip = useCalendarTooltipContext();

  const getWoType = useCallback(
    (workOrderTypeId?: number) =>
      workOrderTypeId ? ctx.data.common?.woTypes.get(workOrderTypeId) : "",
    [ctx.data.common?.woTypes],
  );

  const hovered = ctxTooltip.hoveredDispatch;

  return hovered ? (
    <div className={styles.DispatchTooltipContainer}>
      <Tooltip
        place="right"
        variant="info"
        anchorSelect={`[id="dispatch_${hovered.dispatch.id}"]`}
        delayShow={500}
        opacity={1}
        className={styles.DispatchTooltip}
      >
        <div className={styles.DispatchTooltipInner}>
          <div className={styles.DispatchTooltipTitle}>
            {hovered.dispatch.title}
          </div>
          <div className={styles.DispatchTooltipContent}>
            <p>
              WO Type: {getWoType(hovered.dispatch.details?.workOrderTypeId)}
            </p>
            <p>Started: {moment(hovered.date).format("L LT")}</p>
            <p>Project: {hovered.dispatch.details?.projectId}</p>
            <p>Scenario: {hovered.dispatch.details?.scenarioId}</p>
            <p>Notes:</p>
            <pre>{hovered.dispatch.details?.comments}</pre>
          </div>
        </div>
      </Tooltip>
    </div>
  ) : null;
};
