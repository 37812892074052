import React, { useMemo, useState } from "react";
import { HoverData } from "./models";
import {
  CalendarTooltipContext,
  ICalendarTooltipContext,
} from "./CalendarTooltipContext";

type Props = {
  children: React.ReactNode;
};

export const CalendarTooltipContextProvider = ({ children }: Props) => {
  const [hoveredDispatch, setHoveredDispatch] = useState<HoverData | null>(
    null,
  );

  const ctx = useMemo(() => {
    const ctxData: ICalendarTooltipContext = {
      hoveredDispatch,
      hoverDispatch: setHoveredDispatch,
    };
    return {
      ...ctxData,
    };
  }, [hoveredDispatch]);

  return (
    <CalendarTooltipContext.Provider value={ctx}>
      {children}
    </CalendarTooltipContext.Provider>
  );
};
