import EventEmitter from "eventemitter2";
import { injectable } from "inversify";

import { IAuthInfo, ISettingsStorage } from "../core/inversify/interfaces";
import moment from "moment";
import { IComboboxItem } from "../helpers/interfaces";

@injectable()
export default class SettingsStorage implements ISettingsStorage {
  protected _authInfo: IAuthInfo | undefined;
  private eventEmitter = new EventEmitter();
  private localStorage = window.localStorage;

  constructor() {
    // will not raise event if changed from current tab
    window.addEventListener("storage", (event) => {
      if (event.key) {
        this.eventEmitter.emit(event.key);
      }
    });
  }

  hasKey(key: string): boolean {
    return this.localStorage.getItem(key) !== null;
  }

  setAuthInfo(authInfo: IAuthInfo | undefined) {
    this._authInfo = authInfo;
  }

  get(key: string) {
    return this.localStorage.getItem(key) || undefined;
  }

  set(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getForCurrentUser(key: string) {
    return (
      this.localStorage.getItem(this._getKeyForCurrentUser(key)) || undefined
    );
  }

  setForCurrentUser(key: string, value: string) {
    this.localStorage.setItem(this._getKeyForCurrentUser(key), value);
  }

  removeForCurrentUser(key: string) {
    this.localStorage.removeItem(this._getKeyForCurrentUser(key));
  }

  getForCurrentInstance(key: string) {
    return (
      this.localStorage.getItem(this._getKeyForCurrentInstance(key)) ||
      undefined
    );
  }

  setForCurrentInstance(key: string, value: string) {
    this.localStorage.setItem(this._getKeyForCurrentInstance(key), value);
  }

  removeForCurrentInstance(key: string) {
    this.localStorage.removeItem(this._getKeyForCurrentInstance(key));
  }

  subscribeNotMineChanges = (key: string, listener: () => void) => {
    this.eventEmitter.on(key, listener);

    return () => this.eventEmitter.off(key, listener);
  };

  private _getKeyForCurrentUser(key: string) {
    return `${this._authInfo?.InstanceId}_${this._authInfo?.UserId}_${key}`;
  }

  private _getKeyForCurrentInstance(key: string) {
    return `${this._authInfo?.InstanceId}_${key}`;
  }
}

export function removeValuesForCurrentUser(
  storage: ISettingsStorage,
  keys: { [key: string]: string },
) {
  for (const key in keys) {
    storage.removeForCurrentUser(keys[key]);
  }
}

export function getValuesForCurrentUser(
  storage: ISettingsStorage,
  keys: { [key: string]: string },
) {
  const filters: {
    [key: string]: IComboboxItem | Date | boolean | string | number;
  } = {};
  for (const key in keys) {
    const valueStr = storage.getForCurrentUser(keys[key]);
    if (valueStr) {
      if (typeof +valueStr === "number" && !isNaN(+valueStr)) {
        filters[key] = +valueStr;
      } else if (valueStr === "true") {
        filters[key] = true;
      } else if (valueStr === "false") {
        filters[key] = false;
      } else if (moment(valueStr).isValid()) {
        filters[key] = moment(valueStr).toDate();
      } else {
        try {
          filters[key] = JSON.parse(valueStr);
        } catch {
          /* empty */
        }
      }
    }
  }
  return filters;
}
