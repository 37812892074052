import { IStatusPanelParams } from "ag-grid-community";
import styles from "../aggrid.module.scss";
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import React, { useCallback } from "react";

export default function StatusPanel_Export(props: IStatusPanelParams) {
  const { api } = props;

  const handleExport = useCallback(
    (event: DropDownButtonItemClickEvent) => {
      if (event.item === "CSV") api.exportDataAsCsv();
      if (event.item === "Excel") api.exportDataAsExcel();
    },
    [api]
  );
  return (
    <div className={styles.ActionsContainer}>
      <DropDownButton
        icon={"file-excel"}
        buttonClass={styles.DownloadGroupButton}
        fillMode={"flat"}
        items={["Excel", "CSV"]}
        size={"small"}
        onItemClick={handleExport}
      />
    </div>
  );
}
