import { useCalendarContext } from "../CalendarContext";
import { useCallback, useMemo } from "react";
import { CalendarSelectFilter, ComboBoxItem } from "./CalendarSelectFilter";
import styles from "../calendar.module.scss";
import { CalendarTextFilter } from "./CalendarTextFilter";
import { Toolbar } from "@progress/kendo-react-buttons";

export const CalendarFilters = () => {
  const { setFilters, data, buildPlan, filters } = useCalendarContext();

  const handleSelectRegion = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        regionIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectMarket = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        marketIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectBuildPlanClass = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        buildPlanClassIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectCustomer = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        customerIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectCrewContainer = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        crewContainerIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectBuildPlanOwner = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        buildPlanOwnerIds: ids,
      }));
    },
    [setFilters],
  );

  const handleSelectResource = useCallback(
    (ids: number[]) => {
      setFilters((prev) => ({
        ...prev,
        resourceIds: ids,
      }));
    },
    [setFilters],
  );

  const handleTitleChange = useCallback(
    (value: string) => {
      setFilters((prev) => ({
        ...prev,
        title: value.toLowerCase(),
      }));
    },
    [setFilters],
  );

  const regions = useMemo(() => {
    return Array.from(
      data.common?.regions || [],
      ([key, value]) => ({ key, value: value.name }) as ComboBoxItem,
    );
  }, [data.common?.regions]);

  const markets = useMemo(() => {
    return Array.from(
      data.common?.markets || [],
      ([key, value]) => ({ key, value: value.name }) as ComboBoxItem,
    );
  }, [data.common?.markets]);

  const classes = useMemo(() => {
    return Array.from(
      data.common?.classes || [],
      ([key, value]) => ({ key, value: value }) as ComboBoxItem,
    );
  }, [data.common?.classes]);

  const customers = useMemo(() => {
    return Array.from(
      data.common?.customers || [],
      ([key, value]) => ({ key, value: value }) as ComboBoxItem,
    );
  }, [data.common?.customers]);

  const crewContainers = useMemo(() => {
    return Array.from(
      data.common?.crewContainers || [],
      ([key, value]) => ({ key, value: value }) as ComboBoxItem,
    );
  }, [data.common?.crewContainers]);

  const bpOwners = useMemo(() => {
    return Array.from(
      data.common?.bpOwners || [],
      ([key, value]) => ({ key, value: value }) as ComboBoxItem,
    );
  }, [data.common?.bpOwners]);

  const resources = useMemo(() => {
    const items = Array.from(
      data.common?.resources || [],
      ([key, value]) => ({ key, value: value }) as ComboBoxItem,
    );

    return items.filter((x) => {
      return (
        (filters.regionIds.length === 0 ||
          filters.marketIds.length > 0 ||
          filters.regionIds.some((regionId) => {
            const region = data.common?.regions.get(regionId);
            return region && region.employeeIds.includes(x.key);
          })) &&
        (filters.marketIds.length === 0 ||
          filters.marketIds.some((marketId) => {
            const market = data.common?.markets.get(marketId);
            return market && market.employeeIds.includes(x.key);
          }))
      );
    });
  }, [
    data.common?.markets,
    data.common?.regions,
    data.common?.resources,
    filters.marketIds,
    filters.regionIds,
  ]);

  if (buildPlan.buildPlanId) {
    return null;
  }

  return (
    <Toolbar className={styles.CalendarFiltersTop}>
      <div className={styles.CalendarFilters}>
        <CalendarSelectFilter
          filterName="RegionsFilter"
          items={regions}
          onSelect={handleSelectRegion}
          placeholder="Filter by Region"
        />
        <CalendarSelectFilter
          filterName="MarketsFilter"
          items={markets}
          onSelect={handleSelectMarket}
          placeholder="Filter by Market"
        />
        <CalendarSelectFilter
          filterName="ClassesFilter"
          items={classes}
          onSelect={handleSelectBuildPlanClass}
          placeholder="Filter by Class"
        />
        <CalendarSelectFilter
          filterName="CustomersFilter"
          items={customers}
          onSelect={handleSelectCustomer}
          placeholder="Filter by Customer"
        />
      </div>
      <div className={styles.CalendarFilters}>
        <CalendarSelectFilter
          filterName="CrewContainersFilter"
          items={crewContainers}
          onSelect={handleSelectCrewContainer}
          placeholder="Filter by Crew Container"
        />
        <CalendarSelectFilter
          filterName="BpOwnersFilter"
          items={bpOwners}
          onSelect={handleSelectBuildPlanOwner}
          placeholder="Filter by BP Owner"
        />
        <CalendarSelectFilter
          filterName="ResourcesFilter"
          items={resources}
          onSelect={handleSelectResource}
          placeholder="Filter by Resource"
        />
        <CalendarTextFilter
          filterName="TitleFilter"
          placeholder="Title"
          onChange={handleTitleChange}
        />
      </div>
    </Toolbar>
  );
};
