import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  HeadersDefaults,
  ResponseType,
} from "axios";
import {
  IAuthProvider,
  IHttpFormDataBuilder,
  ILogger,
} from "../inversify/interfaces";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {
  ApiCancellationError,
  ApiExceptionError,
  ApiInformationError,
  ApiNetworkError,
  ApiSyntaxError,
  ApiWarningError,
  transformApiError,
} from "./apiExceptions";

export type QueryParamsType = Record<string | number, unknown>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

// noinspection JSUnusedGlobalSymbols
export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient {
  readonly instance: AxiosInstance;
  private _formBuilder: IHttpFormDataBuilder;

  constructor(
    authProvider: IAuthProvider,
    logger: ILogger,
    formBuilder: IHttpFormDataBuilder,
  ) {
    this._formBuilder = formBuilder;
    this.instance = axios.create({
      baseURL: authProvider.getApiUrl(),
    });

    createAuthRefreshInterceptor(
      this.instance,
      authProvider.handleUnauthorized,
    );

    this.instance.interceptors.request.use(async (requestConfig) => {
      const headers = requestConfig.headers as AxiosHeaders;
      const headersToAdd = authProvider.getHeaders();
      for (const [key, value] of Object.entries(headersToAdd)) {
        headers.set(key, value);
      }
      requestConfig.baseURL = authProvider.getApiUrl();
      return requestConfig;
    }, Promise.reject);

    this.instance.interceptors.request.use((requestConfig) => {
      logger.verbose(
        "Request {HttpMethod} {HttpUrl} {HttpParams}",
        requestConfig?.method,
        requestConfig?.url,
        requestConfig?.params ? JSON.stringify(requestConfig?.params) : "",
      );
      return requestConfig;
    }, Promise.reject);

    this.instance.interceptors.response.use(
      (response) => {
        logger
          .enrich({
            Status: response.status,
            StatusText: response.statusText,
          })
          .verbose(
            "Response {HttpMethod} {HttpUrl} {HttpParams}",
            response.config?.method,
            response.config?.url,
            response.config?.params
              ? JSON.stringify(response.config?.params)
              : "",
          );
        return response;
      },
      (error) => {
        const transformedError = transformApiError(error);
        const messageTemplate =
          " {HttpMethod} {HttpUrl} {HttpCode} {HttpParams}: {ExceptionMessage}";
        const messageParams = [
          error.config?.method,
          error.config?.url,
          error.code,
          error.config?.params ? JSON.stringify(error.config?.params) : "",
          error.message,
        ];
        if (transformedError instanceof ApiCancellationError) {
          logger.verbose(
            "Request Cancelled" + messageTemplate,
            ...messageParams,
          );
        } else if (transformedError instanceof ApiNetworkError) {
          logger.verbose(
            "Request Network Error" + messageTemplate,
            ...messageParams,
          );
        } else if (transformedError instanceof ApiSyntaxError) {
          logger.fatal(
            "Request Processing Failed" + messageTemplate,
            ...messageParams,
          );
        } else if (
          transformedError instanceof ApiInformationError ||
          transformedError instanceof ApiWarningError ||
          transformedError instanceof ApiExceptionError
        ) {
          logger.verbose(
            "Request Expected Error" + messageTemplate,
            ...messageParams,
          );
        } else {
          logger.error("Request Error " + messageTemplate, ...messageParams);
        }
        return Promise.reject(transformedError);
      },
    );
  }

  public request = <T>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const requestParams = this.mergeRequestParams(params, {});
    const responseFormat = format || undefined;

    if (type === ContentType.FormData && body && typeof body === "object") {
      body = this._formBuilder.build(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return new Promise((resolve, reject) => {
      // TODO abort request?
      this.instance
        .request({
          ...requestParams,
          data: body,
          headers: {
            ...(requestParams.headers || {}),
            ...(type ? { "Content-Type": type } : {}),
          },
          params: query,
          responseType: responseFormat,
          url: path,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || params2?.method;

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
}
