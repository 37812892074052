// noinspection JSUnusedGlobalSymbols

import moment from "moment";

const getDurationTimeWithLabel =
  (labelHours: string, labelMinute: string) =>
  (start: string, finish: string) => {
    const duration = moment
      .utc()
      .startOf("day")
      .add(moment(finish).diff(moment(start), "minutes"), "minutes");

    return duration.get("hours") === 0
      ? duration.format(`m[${labelMinute}]`)
      : duration.format(`H[${labelHours}] m[${labelMinute}]`);
  };

export const getLocalTimeMomentFromServerTimeStr = (serverTime: string) => {
  return moment.utc(serverTime).local();
};

getDurationTimeWithLabel("h", "min");
getDurationTimeWithLabel("h", "m");
