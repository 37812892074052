import formStyles from "../Cards/card.module.scss";
import BaseComponent from "../BaseComponent";
import { Button } from "@progress/kendo-react-buttons";
import FilterCombobox from "../Common/Form/FilterCombobox";
import {
  Input,
  InputChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import Loader from "../Common/Loader";
import { IComboboxItem } from "../../helpers/interfaces";

import styles from "./ObjectMap/objectmap.module.scss";
import { RunScriptAsync } from "../../helpers/runscripts";

import React from "react";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { ILocationCardProps } from "../Cards/interfaces";
import { IAddressInfo } from "./ObjectMap/interfaces";
import ObjectMap from "./ObjectMap/ObjectMap";
import { showSomeError } from "../../helpers/errorHelpers";

interface IEditableInfo {
  LocationName: string;
  LocationType: IComboboxItem | null;
  TaxCode: IComboboxItem | null;
}

interface state {
  lastResizeCard: number;
  loadedInitialData: boolean;

  locationTypes: Array<IComboboxItem>;
  taxCodes: Array<IComboboxItem>;

  processing: boolean;
  remountKey: number;
  invalidForm: boolean;

  addressString: string;
}

interface IServerLocationInfo {
  LocationId: number;
  LocationName: string;
  LocationTypeId: number;
  AddressId: number;
  TaxCodeId: number | null;
}

class LocationCardView extends BaseComponent<ILocationCardProps, state> {
  isNewLocation = this.props.locationId === undefined;
  EditableInfo: IEditableInfo = {
    LocationName: "",
    LocationType: null,
    TaxCode: null,
  };
  AddressInfo: IAddressInfo | null = null;
  ServerLocationInfo: IServerLocationInfo | null = null;

  constructor(props: ILocationCardProps) {
    super(props);
    this.state = {
      lastResizeCard: +new Date(),
      loadedInitialData: false,
      locationTypes: [],
      taxCodes: [],
      addressString: "",
      processing: false,
      remountKey: +new Date(),
      invalidForm: true,
    };
  }

  componentDidMount(): void {
    this.LoadInitialData();
  }

  render() {
    if (this.props.isModalCard) return this.renderContent();
    return (
      <div
        style={{
          padding: 16,
          boxSizing: "border-box",
          height: "100vh",
          background: "#f9f9f9",
        }}
      >
        {this.renderContent()}
      </div>
    );
  }

  renderContent = () => {
    if (!this.state.loadedInitialData) return <Loader />;
    return (
      <>
        {this.state.processing && <Loader />}
        <div
          className={formStyles.FormWrapper}
          style={{ position: "relative" }}
        >
          <div style={{ display: "flex" }}>
            <div className={styles.Left}>
              <Input
                key={this.state.remountKey + "name"}
                className={`${formStyles.FormField} ${formStyles.TextField}`}
                placeholder="Location Name"
                defaultValue={this.EditableInfo.LocationName}
                name="LocationName"
                onChange={this.OnChangeTextField}
              />
            </div>
            <div className={styles.Right}>
              <FilterCombobox
                key={this.state.remountKey + "types"}
                placeholder="Location Types *"
                defaultValue={this.EditableInfo.LocationType}
                data={this.state.locationTypes}
                onChange={this.OnChangeField}
                className={`${formStyles.FormField}`}
                dataAttr={"LocationType"}
                required={true}
              />
              <FilterCombobox
                key={this.state.remountKey + "taxCode"}
                placeholder="Tax Code"
                defaultValue={this.EditableInfo.TaxCode}
                data={this.state.taxCodes}
                onChange={this.OnChangeField}
                className={`${formStyles.FormField}`}
                dataAttr={"TaxCode"}
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <ObjectMap
              lastResize={this.props.lastResizeModalCard}
              addressId={this.ServerLocationInfo?.AddressId}
              coordinates={this.props.coordinates}
              onChange={this.OnAddressChange}
              objectType={"Location"}
            />
          </div>
          {this.renderFooter()}
        </div>
      </>
    );
  };

  renderFooter = () => {
    return (
      <div
        className={`${formStyles.FormFooter} k-action-buttons`}
        style={{ paddingLeft: 8, paddingRight: 8 }}
      >
        <span className={formStyles.InvalidMessage}>
          {this.state.invalidForm && <span>Fill all mandatory fields</span>}
        </span>
        {!!this.props.closeModalCard && (
          <Button onClick={this.props.closeModalCard}>Cancel</Button>
        )}
        <Button
          onClick={this.UpsertLocation}
          themeColor="primary"
          disabled={this.state.invalidForm}
        >
          {this.isNewLocation ? "Create Location" : "Save"}
        </Button>
      </div>
    );
  };

  OnChangeTextField = (e: InputChangeEvent | TextAreaChangeEvent) => {
    let field = e.target.name! as keyof IEditableInfo; /* : 'LocationName' */
    this.OnChangeField(e.value, field);
  };

  OnChangeField = (value: any, dataAttr: keyof IEditableInfo) => {
    this.EditableInfo[dataAttr] = value;
    this.setState({ invalidForm: this.IsInValidForm() });
  };

  IsInValidForm = () => {
    if (this.isNewLocation) {
      return (
        !this.EditableInfo.LocationName ||
        !this.EditableInfo.LocationType ||
        !this.AddressInfo
      );
    } else {
      return !this.EditableInfo.LocationName || !this.EditableInfo.LocationType;
    }
  };

  LoadInitialData = async () => {
    try {
      const locationTypes = (await ReferenceRecordsDataSource("LocationTypes", {
        activeOnly: true,
      })) as IComboboxItem[];
      const taxCodes = (await ReferenceRecordsDataSource("TaxCodes", {
        activeOnly: true,
      })) as IComboboxItem[];

      if (!this.isNewLocation) {
        const locationDataResult: any = await this.GetSQLData({
          spName: "Locations_GetData",
          params: { locationId: this.props.locationId },
        });
        const locationInfo = locationDataResult[0][0] as IServerLocationInfo;
        if (locationInfo) {
          const { LocationName, LocationTypeId, TaxCodeId } = locationInfo;
          this.ServerLocationInfo = locationInfo;
          this.EditableInfo = {
            LocationName,
            LocationType:
              locationTypes.find(
                (type: IComboboxItem) => type.Id === LocationTypeId,
              ) || null,
            TaxCode: taxCodes.find((x) => x.Id === TaxCodeId) || null,
          };
        }
      }

      this.setState({
        locationTypes,
        taxCodes,
        invalidForm: this.IsInValidForm(),
        remountKey: +new Date(),
      });
    } finally {
      this.setState({ loadedInitialData: true });
    }
  };

  UpsertLocation = async () => {
    try {
      this.setState({ processing: true });
      let locationId = await RunScriptAsync("Locations_Upsert", {
        LocationId: this.props.locationId,
        LocationJSON: JSON.stringify({
          Name: this.EditableInfo.LocationName,
          TypeId: this.EditableInfo.LocationType?.Id || null,
          AddressJSON: JSON.stringify(this.AddressInfo),
          TaxCodeId: this.EditableInfo.TaxCode?.Id || null,
        }),
      });
      if (this.props.onFinish)
        this.props.onFinish(this.props.locationId || +locationId!);
      if (this.props.closeModalCard) this.props.closeModalCard();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };

  OnAddressChange = (AddressInfo: IAddressInfo | null) => {
    this.AddressInfo = AddressInfo;
    this.setState({
      invalidForm: this.IsInValidForm(),
    });
  };

  OnCardResize = () => {
    this.setState({ lastResizeCard: +new Date() });
  };
}

export default LocationCardView;
