import LiveMap from "../../../Pages/LiveMap/LiveMap";
import { useCalendarContext } from "../CalendarContext";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styles from "../calendar.module.scss";

// eslint-disable-next-line react/display-name
export const CalendarMapView = forwardRef<object>((_, ref) => {
  const ctx = useCalendarContext();
  const [date, setDate] = useState<Date>(new Date());

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    setDate(ctx.selectedDate.toDate());
  }, [ctx.selectedDate]);

  return (
    <div className={styles.CalendarMapView}>
      <LiveMap
        date={date}
        isActive={true}
        pageId={"Calendar"}
        bpId={ctx.buildPlan.mapBuildPlanId}
      />
    </div>
  );
});
