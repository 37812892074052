// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AccuracyAuthorization
 *  Possible values: 0 = Full, 1 = Reduced
 */
export enum AccuracyAuthorization {
  Value0 = 0,
  Value1 = 1,
}
export const EnumAccuracyAuthorization = {
  Value0: 0,
  Value1: 1,
};

/**
 * AuthorizationStatus
 *  Possible values: 0 = NotDetermined, 1 = Restricted, 2 = Denied, 3 = Always, 4 = WhenInUse
 */
export enum AuthorizationStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}
export const EnumAuthorizationStatus = {
  Value0: 0,
  Value1: 1,
  Value2: 2,
  Value3: 3,
  Value4: 4,
};

/** GpsPoint */
export interface GpsPoint {
  /**
   * Int64
   * @format int64
   */
  time: number;
  /**
   * Decimal
   * @format double
   */
  lat: number;
  /**
   * Decimal
   * @format double
   */
  lng: number;
  /**
   * Double
   * @format double
   */
  acc: number;
}
export const PropsGpsPoint = {
  time: "time",
  lat: "lat",
  lng: "lng",
  acc: "acc",
};
/** GpsRecordArray */
export interface GpsRecordArray {
  /**
   * DateTime
   * @format date-time
   */
  timestamp: string;
  /**
   * Decimal
   * @format double
   */
  latitude: number;
  /**
   * Decimal
   * @format double
   */
  longitude: number;
  /**
   * Double
   * @format double
   */
  accuracy: number;
}
export const PropsGpsRecordArray = {
  timestamp: "timestamp",
  latitude: "latitude",
  longitude: "longitude",
  accuracy: "accuracy",
};
/** GpsSyncBatchRequest */
export interface GpsSyncBatchRequest {
  /** List`1 */
  location: GpsRecordArray[];
}
export const PropsGpsSyncBatchRequest = {
  location: "location",
};
/** LocationTrackingStatusRequest */
export interface LocationTrackingStatusRequest {
  /**
   * Int64
   * @format int64
   */
  timestamp: number;
  /**
   * Int32
   * @format int32
   */
  isMoving: number;
  /**
   * Int32
   * @format int32
   */
  isGps: number;
  /**
   * Int32
   * @format int32
   */
  isCollectingPoints: number;
  /**
   * Int32
   * @format int32
   */
  isNetwork: number;
  /**
   * Int32
   * @format int32
   */
  isPowerSave: number;
  /**
   * Int32
   * @format int32
   */
  isBackgroundFetchAllowed: number;
  /**
   * Int32
   * @format int32
   */
  didDeviceReboot: number;
  /**
   * Int32
   * @format int32
   */
  didLaunchInBackground: number;
  /**
   * Int32
   * @format int32
   */
  isIgnoringBatteryOptimizations: number;
  /**
   * Int32
   * @format int32
   */
  isBatteryCharging: number;
  /**  Possible values: 0 = NotDetermined, 1 = Restricted, 2 = Denied, 3 = Always, 4 = WhenInUse */
  authorization: AuthorizationStatus;
  /**  Possible values: 0 = Full, 1 = Reduced */
  accuracy: AccuracyAuthorization;
  /**
   * Int32
   * @format int32
   */
  notSyncedPoints: number;
  /**
   * Int32
   * @format int32
   */
  batteryLevel: number;
  /**
   * Int32
   * @format int32
   */
  hasJustLaunched: number;
  /**
   * Int64
   * @format int64
   */
  startupTimestamp: number;
  /**
   * Int32
   * @format int32
   */
  isTracking: number;
  /**
   * Int32
   * @format int32
   */
  isFirstRun: number;
}
export const PropsLocationTrackingStatusRequest = {
  timestamp: "timestamp",
  isMoving: "isMoving",
  isGps: "isGps",
  isCollectingPoints: "isCollectingPoints",
  isNetwork: "isNetwork",
  isPowerSave: "isPowerSave",
  isBackgroundFetchAllowed: "isBackgroundFetchAllowed",
  didDeviceReboot: "didDeviceReboot",
  didLaunchInBackground: "didLaunchInBackground",
  isIgnoringBatteryOptimizations: "isIgnoringBatteryOptimizations",
  isBatteryCharging: "isBatteryCharging",
  authorization: "authorization",
  accuracy: "accuracy",
  notSyncedPoints: "notSyncedPoints",
  batteryLevel: "batteryLevel",
  hasJustLaunched: "hasJustLaunched",
  startupTimestamp: "startupTimestamp",
  isTracking: "isTracking",
  isFirstRun: "isFirstRun",
};
/** LocationTrackingStatusSaveRequest */
export interface LocationTrackingStatusSaveRequest {
  /** List`1 */
  data: LocationTrackingStatusRequest[];
}
export const PropsLocationTrackingStatusSaveRequest = {
  data: "data",
};

import { AxiosRequestConfig, ResponseType } from "axios";

interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({ path, type, query, format, body, ...params }: FullRequestParams) => Promise<T>;
}

/**
 * @title FieldClix Time Keeping
 * @version v1
 */

export class Api_gps {
  private readonly http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  gps = {
    locationTrackingStatus: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: LocationTrackingStatusSaveRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/location-tracking-status`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    processGpsDataForWorkshift: (
      query: {
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/process-gps-data`,
        method: "POST",
        query: query,
        ...params,
      }),
    getSasToken: (params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Gps/Sas`,
        method: "GET",
        format: "json",
        ...params,
      }),
    savePoints: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsPoint[],
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/save-points`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    syncBatch: (
      query: {
        /** String */
        sas: string;
        /**
         * Int32
         * @format int32
         */
        workshiftId: number;
      },
      data: GpsSyncBatchRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<void>({
        path: `/Gps/sync-batch`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
