import React, { useEffect, useRef, useState } from "react";
import styles from "./map.module.scss";
import { getSQLData } from "../../helpers/queries";
import {
  BindNodeTooltip,
  fitBoundsGroup,
  getFigureObjectLayer,
  getObjectMapData,
  ICONS,
  OpenObject,
  zIndexMarkers,
} from "./helpers";
import ProcessingButton from "../Common/Form/ProcessingButton";
import FilterCombobox from "../Common/Form/FilterCombobox";
import { ModalRef } from "../Common/Modal/Modal";
import { showSomeError } from "../../helpers/errorHelpers";

interface IProps {
  map: any;
  className: string;
}

interface ISite {
  Id: number;
  SiteID: string;
  AddressId: number;
}

const MapSearchSitesControl = (props: IProps) => {
  const { map, className } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddressInfo, setIsLoadingAddressInfo] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sites, setSites] = useState<ISite[] | null>(null);
  const figuresGroupRef = useRef<any>(null);
  const selectedRef = useRef<ISite | null>(null);
  const bringToFront = () => {
    if (
      map &&
      figuresGroupRef.current &&
      map.hasLayer(figuresGroupRef.current)
    ) {
      figuresGroupRef.current.bringToFront();
    }
  };

  useEffect(() => {
    if (map) {
      map.on("layeradd", bringToFront);
    }
    return () => {
      if (map) {
        map.off("layeradd", bringToFront);
        figuresGroupRef.current?.removeFrom?.(map);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!isOpen || sites || isLoading) return;
    loadSites();
  }, [isOpen]);

  const loadSites = async () => {
    try {
      setIsLoading(true);
      const result = await getSQLData({ spName: "FSMSites_Search" });
      setSites(result[0]);
    } catch (e: any) {
      showSomeError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const OpenNodeCard = (e: any) => {
    const markerLayer = e.target;
    let { refName, objectId } = markerLayer.options;
    if (!objectId || !refName) return;
    OpenObject(e, onSaveObject);
  };

  const onSaveObject = async (objectId: number) => {
    try {
      ModalRef.startProcessing("", "rgba(255, 255, 255, 0.2)");
      const result = await getObjectMapData(objectId);
      if (!result) return;
      const {
        Lat,
        Lng,
        ObjectId,
        ObjectName,
        ObjectType,
        AddressString,
        Boundaries,
        Radius,
      } = result.mainAddress;
      if (!Lat || !Lng) return;
      const coords = [Lat, Lng];
      figuresGroupRef.current.eachLayer((layer: any) => {
        if (+layer.options.objectId === ObjectId) {
          if (layer.options.icon) {
            layer.setLatLng(coords);
            layer.unbindTooltip();
            BindNodeTooltip(layer, ObjectName, AddressString);
          } else {
            layer.removeFrom(figuresGroupRef.current);
            const FigureLayer = getFigureObjectLayer(
              ObjectId,
              ObjectType,
              ICONS.Search.secondaryColor,
              ICONS.Search.secondaryColor,
              coords,
              Boundaries,
              Radius,
              {},
              zIndexMarkers.searchGroup
            );
            FigureLayer.addTo(figuresGroupRef.current);
          }
        }
      });
      fitBoundsGroup(figuresGroupRef.current, map);
    } catch (e) {
      showSomeError(e);
    } finally {
      ModalRef.stopProcessing();
    }
  };

  const selectItem = async (value: ISite | null) => {
    selectedRef.current = value;
    figuresGroupRef.current?.removeFrom?.(map);
    setIsOpen(false);
    if (value === null) return;
    try {
      setIsLoadingAddressInfo(true);
      const result = await getObjectMapData(undefined, value.AddressId);
      if (!result) return;
      const {
        Lat,
        Lng,
        ObjectId,
        ObjectName,
        ObjectType,
        AddressString,
        Boundaries,
        Radius,
      } = result.mainAddress;
      if (!Lat || !Lng) return;
      const coords = [Lat, Lng];
      const MarkerLayer = window.L.mapquest
        .textMarker(coords, {
          position: "right",
          type: "marker",
          icon: ICONS.Search,
          draggable: false,
          zIndexOffset: zIndexMarkers.searchGroup,
          refName: "FSMSites",
          objectId: ObjectId,
        })
        .on("contextmenu", OpenNodeCard);
      BindNodeTooltip(MarkerLayer, ObjectName, AddressString);
      const FigureLayer = getFigureObjectLayer(
        ObjectId,
        ObjectType,
        ICONS.Search.secondaryColor,
        ICONS.Search.secondaryColor,
        coords,
        Boundaries,
        Radius,
        {},
        zIndexMarkers.searchGroup
      );
      figuresGroupRef.current = new window.L.FeatureGroup([
        MarkerLayer,
        FigureLayer,
      ]);
      figuresGroupRef.current.addTo(map);
      figuresGroupRef.current.setZIndex(zIndexMarkers.searchGroup);
      fitBoundsGroup(figuresGroupRef.current, map);
    } catch (e) {
      showSomeError(e);
    } finally {
      setIsLoadingAddressInfo(false);
    }
  };

  return (
    <div className={`${className} ${styles.SearchControl}`}>
      <ProcessingButton
        className={styles.SearchControlToggle}
        ButtonProps={{
          icon: "search",
          onClick: toggleOpen,
        }}
        processing={isLoadingAddressInfo}
        loaderThemeColor={"light"}
      />
      {isOpen && (
        <div style={{ width: 200, flex: " 0 0 auto", marginLeft: 10 }}>
          <FilterCombobox
            placeholder={"Sites"}
            onChange={selectItem}
            data={sites || []}
            loading={isLoading}
            textField={"SiteID"}
            dataItemKey={"AddressId"}
            defaultValue={selectedRef.current}
          />
        </div>
      )}
    </div>
  );
};

export default MapSearchSitesControl;
