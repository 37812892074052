import { memo } from "react";
import styles from "./calendar.module.scss";

export const CalendarCmp = () => {
  return (
    <div className={styles.LegendContainerTop}>
      <div className={styles.LegendContainer}>
        <div title="Not Published">&nbsp;</div>
        <div title="Published">&nbsp;</div>
        <div title="Completed">&nbsp;</div>
        <div title="Cancelled">&nbsp;</div>
      </div>
    </div>
  );
};

export const CalendarLegend = memo(CalendarCmp);
