import moment from "moment/moment";
import {
  SQL_DispatchCalendar_CommonData_Response_WorkCalendar,
  SQL_DispatchCalendar_PeriodData_Response_Dispatches,
} from "../../core/api/generated/conterra";

export type CalendarMode = "day" | "week" | "month" | "resources" | "map";
export type CalendarProcessing = "init" | "processing" | "error" | "success";

export enum FirstDayOfWeek {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7,
}

export type DayData = {
  date: moment.Moment;
  isToday: boolean;
  isInCurrentPeriod: boolean;
  weekNumber: number;
};

export type Position = {
  x: number;
  y: number;
};

export type ContextMenuData = {
  open: boolean;
  position?: Position;
  date?: moment.Moment;
  dispatch?: SQL_DispatchCalendar_PeriodData_Response_Dispatches;
};

export type HoverData = {
  dispatch: SQL_DispatchCalendar_PeriodData_Response_Dispatches;
  date: moment.Moment;
};

export type ItemWithEmployees = {
  name: string;
  employeeIds: number[];
};

export type CommonData = {
  regions: Map<number, ItemWithEmployees>;
  markets: Map<number, ItemWithEmployees>;
  ourCompanies: Map<number, string>;
  bpOwners: Map<number, string>;
  resources: Map<number, string>;
  classes: Map<number, string>;
  customers: Map<number, string>;
  workCalendar?: SQL_DispatchCalendar_CommonData_Response_WorkCalendar;
  crewContainers: Map<number, string>;
  woTypes: Map<number, string>;
  projects: Map<number, string>;
  scenarios: Map<number, string>;
  reasons: Map<number, string>;
};

export type FilterData = {
  regionIds: number[];
  marketIds: number[];
  buildPlanClassIds: number[];
  customerIds: number[];
  crewContainerIds: number[];
  buildPlanOwnerIds: number[];
  resourceIds: number[];
  title: string;
  isMy: boolean;
};

export const DispatchStatuses = {
  Scheduled: "Scheduled",
  Published: "Published",
  Completed: "Completed",
  Cancelled: "Cancelled",
} as const;
