import { useBooleanState } from "../../core/tools/Hooks";
import { clsx } from "clsx";
import styles from "./calendar.module.scss";
import React, {
  DispatchWithoutAction,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
} from "react";

type Props = {
  titleContent: React.ReactNode;
  children: React.ReactNode;
  tooltip?: string;
  clickOnWholeTitle?: boolean;
  expandedByDefault?: boolean;
};

export type CollapsibleRef = {
  expand: DispatchWithoutAction;
  collapse: DispatchWithoutAction;
};

// eslint-disable-next-line react/display-name
const CollapsibleCmp = forwardRef<CollapsibleRef, Props>(
  (
    {
      titleContent,
      children,
      tooltip,
      clickOnWholeTitle,
      expandedByDefault,
    }: Props,
    ref,
  ) => {
    const expanded = useBooleanState(expandedByDefault || false);

    useImperativeHandle(ref, () => ({
      expand: () => expanded.setTrue(),
      collapse: () => expanded.setFalse(),
    }));

    const handleClick = useCallback<React.MouseEventHandler<HTMLElement>>(
      (e) => {
        e.stopPropagation();
        expanded.toggle();
      },
      [expanded],
    );

    return (
      <>
        <div
          className={styles.CollapsibleTitle}
          onClick={clickOnWholeTitle ? handleClick : undefined}
        >
          <div className={styles.CollapsibleTitleContent}>{titleContent}</div>
          <div
            className={clsx(
              styles.CollapsibleIcon,
              expanded.value && styles.CollapsibleIconExpanded,
            )}
            title={tooltip}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
        <div
          className={clsx(
            styles.CollapsibleContent,
            expanded.value && styles.CollapsibleContentExpanded,
          )}
        >
          <div>{children}</div>
        </div>
      </>
    );
  },
);

export const Collapsible = memo(CollapsibleCmp);
