import { useCallback, useMemo } from "react";
import CardManagement from "../../../Components/Cards/CardManagement";
import { useCalendarContext } from "../CalendarContext";
import { RecordLink } from "../RecordLink";
import moment from "moment/moment";
import { Tooltip } from "react-tooltip";
import { tableAlignMiddleCenterIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import styles from "../calendar.module.scss";
import { clsx } from "clsx";

export const CalendarDayView = () => {
  const ctx = useCalendarContext();

  const handleWoClick = useCallback(
    (dsId: number) => {
      CardManagement.OpenDispatchCard({
        newDispatch: false,
        dsId,
        afterSave: ctx.data.refresh,
      });
    },
    [ctx.data.refresh],
  );

  const getWorkOrderTypeName = useCallback(
    (workOrderTypeId: number) => ctx.data.common?.woTypes.get(workOrderTypeId),
    [ctx.data.common?.woTypes],
  );

  const getResourceName = useCallback(
    (resourceId: number) => ctx.data.common?.resources.get(resourceId),
    [ctx.data.common?.resources],
  );

  const getScenarioName = useCallback(
    (scenarioId: number) => ctx.data.common?.scenarios.get(scenarioId),
    [ctx.data.common?.scenarios],
  );

  const getMarketName = useCallback(
    (marketId: number) => ctx.data.common?.markets.get(marketId)?.name,
    [ctx.data.common?.markets],
  );

  const woOnDay = useMemo(() => {
    return ctx.data.woOnDay.filter((x) => {
      return (
        (ctx.filters.regionIds.length === 0 ||
          ctx.filters.regionIds.includes(x.regionId)) &&
        (ctx.filters.marketIds.length === 0 ||
          ctx.filters.marketIds.includes(x.marketId)) &&
        (ctx.filters.buildPlanClassIds.length === 0 ||
          (x.buildPlanClassId !== null &&
            ctx.filters.buildPlanClassIds.includes(x.buildPlanClassId))) &&
        (ctx.filters.customerIds.length === 0 ||
          (x.customerId !== null &&
            ctx.filters.customerIds.includes(x.customerId))) &&
        (ctx.filters.crewContainerIds.length === 0 ||
          (x.crewContainerId !== null &&
            ctx.filters.crewContainerIds.includes(x.crewContainerId))) &&
        (ctx.filters.buildPlanOwnerIds.length === 0 ||
          ctx.filters.buildPlanOwnerIds.includes(x.buildPlanOwnerId)) &&
        (ctx.filters.resourceIds.length === 0 ||
          x.resources.some((r) =>
            ctx.filters.resourceIds.includes(r.employeeId),
          )) &&
        (ctx.filters.title === "" ||
          x.title.toLowerCase().indexOf(ctx.filters.title) > -1) &&
        (!ctx.filters.isMy || x.isMy)
      );
    });
  }, [ctx]);

  return (
    <>
      <div className={styles.CalendarDayView}>
        <table className={styles.CalendarDayViewTable}>
          <thead>
            <tr>
              <th></th>
              <th>WO #</th>
              <th>WO Status</th>
              <th>Site ID</th>
              <th>WO Type</th>
              <th>Crew Lead</th>
              <th>
                Expected
                <br />
                Clock&nbsp;In
              </th>
              <th>
                Actual
                <br />
                Clock&nbsp;In
              </th>
              <th>
                Expected
                <br />
                Site Arrival
              </th>
              <th>
                Actual
                <br />
                Site Arrival
              </th>
              <th>
                Expected
                <br />
                Site Departure
              </th>
              <th>
                Actual
                <br />
                Site Departure
              </th>
              <th>
                Reported %<br />
                Completed
              </th>
              <th>Build Plan</th>
              <th>Scenario</th>
              <th>Market</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {woOnDay.map((x) => (
              <tr key={x.id} onClick={() => handleWoClick(x.id)}>
                <td>
                  <div
                    className={clsx(
                      x.arrived && !x.departed && styles.CalendarDayOnSite,
                    )}
                  >
                    &nbsp;
                  </div>
                </td>
                <td>
                  <RecordLink
                    recordId={x.id}
                    refName={"FSMWorkOrders"}
                    onFinish={ctx.data.refresh}
                  >
                    {x.workOrderNumber}
                  </RecordLink>
                </td>
                <td>{x.status}</td>
                <td>{x.title}</td>
                <td>{getWorkOrderTypeName(x.workOrderTypeId)}</td>
                <td>{getResourceName(x.crewLeadEmployeeId)}</td>
                <td>{x.scheduledClockIn}</td>
                <td
                  className={
                    x.isClockedInCorrect
                      ? styles.CalendarDayCorrect
                      : styles.CalendarDayIncorrect
                  }
                >
                  {x.clockedIn ? moment(x.clockedIn).format("LT") : ""}
                </td>
                <td>{x.scheduledArrival}</td>
                <td
                  className={
                    x.isArrivedCorrect
                      ? styles.CalendarDayCorrect
                      : styles.CalendarDayIncorrect
                  }
                >
                  {x.arrived ? moment(x.arrived).format("LT") : ""}
                </td>
                <td>{x.scheduledDepart}</td>
                <td
                  className={
                    x.isDepartedCorrect
                      ? styles.CalendarDayCorrect
                      : styles.CalendarDayIncorrect
                  }
                >
                  {x.departed ? moment(x.departed).format("LT") : ""}
                </td>
                <td>{x.buildPlanProgress}</td>
                <td>
                  <RecordLink
                    recordId={x.buildPlanId}
                    refName={"FSMBuildPlans"}
                    onFinish={ctx.data.refresh}
                  >
                    {x.buildPlanNumber}
                  </RecordLink>
                </td>
                <td>{getScenarioName(x.scenarioId)}</td>
                <td>{getMarketName(x.marketId)}</td>
                <td>
                  {x.comments ? (
                    <SvgIcon
                      data-tooltip-id="calendar-day-comment-tooltip"
                      data-tooltip-content={x.comments}
                      icon={tableAlignMiddleCenterIcon}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.CalendarDayCommentTooltipContainer}>
        <Tooltip
          place="left"
          id="calendar-day-comment-tooltip"
          className={styles.CalendarDayCommentTooltip}
          opacity={1}
        />
      </div>
    </>
  );
};
