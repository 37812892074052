import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "./Components/Common/Modal/Modal";
import {
  IntegrationWithDesktop,
  IntegrationWithDesktopContext,
  isMobile,
  settingsStorage,
} from "./helpers/settings";
import { authProvider } from "./core/api/api";
import Auth from "./Pages/Auth";
import Home from "./Pages/Home/Home";
import { CardsStack } from "./Components/Common/FXCard/Cards";
import { IconsContext, IconsContextType } from "@progress/kendo-react-common";
import { getParamsFromUrl } from "./helpers/helpers";
import BPTabsPage from "./Pages/BPTabs";
import { simpleObject } from "./helpers/interfaces";
import BPHistory from "./Components/BP/History";
import ReportRecordUsage from "./Pages/ReportRecordUsage/ReportRecordUsage";
import TCHistory from "./Components/TC/History";
import TCTabsPage from "./Pages/TCTabs";
import RelatedDocuments from "./Components/RelatedDocuments/RelatedDocuments";
import CheckListResults from "./Pages/CLM/ChecklistResults";
import MobileChecklistResults from "./Pages/CLM/ChecklistResults/MobileChecklistResults";
import CostRates from "./Components/PP/CostRates";
import LaborCosts from "./Components/PP/LaborCosts";
import LocationCardView from "./Components/Map/LocationCardView";
import SiteCardView from "./Components/Map/SiteCardView";
import TKReviewNew from "./Pages/TKReview";
import BOMCardForm from "./Components/Cards/BomCard/Content";
import { showSomeError } from "./helpers/errorHelpers";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    showSomeError(error, "", info.componentStack);
    console.log(info);
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return fallback;
    }

    return children;
  }
}

export const App = () => {
  const [isIntegrationWithDesktop, setIsIntegrationWithDesktop] =
    useState<boolean>(
      settingsStorage.getForCurrentUser(IntegrationWithDesktop) === "true",
    );

  const setIntegrationWithDesktop = (value: boolean) => {
    settingsStorage.setForCurrentUser(IntegrationWithDesktop, value.toString());
    setIsIntegrationWithDesktop(value);
  };

  const isAuthorized = authProvider.useIsAuthorized();

  useEffect(() => {
    document.title = authProvider.getInstanceId() || "FieldClix";
  }, [isAuthorized]);

  const renderPage = (folder: string, params: simpleObject) => {
    if (!folder) return null;
    if (folder === "BPHistory") {
      return <BPHistory woId={params.woId} />;
    } else if (folder === "RecordUsage") {
      return <ReportRecordUsage recordId={+params.recordId} />;
    } else if (folder === "TKTimeCardHistory") {
      return <TCHistory tcId={+params.tcId} isActive={true} />;
    } else if (folder === "TKTimeCardTabs") {
      return <TCTabsPage tcId={+params.tcId} />;
    } else if (folder === "Documents") {
      return (
        <RelatedDocuments
          isActive={true}
          SourceId={params.SourceId}
          isMobile={isMobile}
        />
      );
    } else if (folder === "CLPMReview") {
      const Component = isMobile ? MobileChecklistResults : CheckListResults;
      return (
        <Component
          isActive={true}
          isCLM={true}
          pageId={"CLPM"}
          buildPlanId={params.bpId}
        />
      );
    } else if (folder === "TKCostRates") {
      return <CostRates isActive={true} urlParams={params} />;
    } else if (folder === "TKLaborCost") {
      return <LaborCosts isActive={true} urlParams={params} />;
    } else if (folder === "LocationCard") {
      return <LocationCardView locationId={+params.Id} isModalCard={false} />;
    } else if (folder === "SiteCard") {
      return <SiteCardView siteId={+params.Id} isModalCard={false} />;
    } else if (folder === "TKReview") {
      return <TKReviewNew /*isActive={true}*/ />;
    } else if (folder === "BOM") {
      return <BOMCardForm bomId={params.SourceId} closeCard={() => {}} />;
    } else {
      return null;
    }
  };

  const renderContent = () => {
    const params = getParamsFromUrl();
    const { folder, buildPlanId } = params;
    if (folder) return renderPage(folder, params);
    if (buildPlanId) {
      return (
        <BPTabsPage
          rowData={{ BPID: buildPlanId }}
          isActive={true}
          parentId="BPPage"
        />
      );
    }
    return <Home isIntegrationWithDesktop={isIntegrationWithDesktop} />;
  };

  const iconsContextProviderValue = useMemo(
    () => ({ type: "font" }) as IconsContextType,
    [],
  );

  const integrationWithDesktopContextProviderValue = useMemo(
    () => ({
      value: isIntegrationWithDesktop,
      setValue: setIntegrationWithDesktop,
    }),
    [isIntegrationWithDesktop],
  );

  return (
    <IconsContext.Provider value={iconsContextProviderValue}>
      <IntegrationWithDesktopContext.Provider
        value={integrationWithDesktopContextProviderValue}
      >
        <CardsStack />
        <Modal />
        <ErrorBoundary>
          {isAuthorized ? renderContent() : <Auth />}
        </ErrorBoundary>
      </IntegrationWithDesktopContext.Provider>
    </IconsContext.Provider>
  );
};
