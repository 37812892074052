import styles from "../aggrid.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { useCallback, useMemo, useRef } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import { stopPropagationWrapper } from "../../../helpers/Hooks";
import { Input } from "@progress/kendo-react-inputs";

interface IProps {
  id: string;
  title: string;
  isSelected: boolean;

  updateViewSettings(id: string): void;
  deleteView(id: string): void;

  applyName(id: string): void;

  editName(id: string, title: string): void;
}

export default function ViewRow(props: IProps) {
  const {
    id,
    title,
    applyName,
    editName,
    deleteView,
    updateViewSettings,
    isSelected,
  } = props;
  const isEditMode = useBooleanState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const saveTitle = useCallback(() => {
    const value = inputRef.current?.value || "";
    if (value) editName(id, value);
    isEditMode.setFalse();
  }, [editName]);

  const nameEl = useMemo(() => {
    if (isEditMode.value) {
      return (
        <Input
          ref={inputRef}
          defaultValue={title}
          onBlur={saveTitle}
          onKeyDown={stopPropagationWrapper(() => {})}
        />
      );
    }
    return (
      <Button
        key={"apply" + id}
        fillMode={"flat"}
        onClick={() => applyName(id)}
        className={styles.AdditionalViewNameBtn}
      >
        {title}
      </Button>
    );
  }, [isEditMode.value, isSelected]);

  return (
    <div
      className={`${styles.ViewRow} ${
        isSelected ? styles.ViewRowSelected : ""
      }`}
    >
      {isEditMode.value ? (
        <Button
          key={"save" + id}
          fillMode={"flat"}
          onClick={saveTitle}
          iconClass={"mdi mdi-check"}
          themeColor={"success"}
        ></Button>
      ) : (
        <Button
          key={"edit" + id}
          fillMode={"flat"}
          onClick={isEditMode.setTrue}
          iconClass={"mdi mdi-pencil"}
          className={styles.EditViewNameBtn}
        ></Button>
      )}

      <div style={{ flex: 1 }}>{nameEl}</div>
      {isSelected && (
        <Button
          key={" updateSettings" + id}
          fillMode={"flat"}
          onClick={() => updateViewSettings(id)}
          iconClass={"mdi mdi-content-save-outline"}
        ></Button>
      )}
      <Button
        key={"delete" + id}
        fillMode={"flat"}
        onClick={() => deleteView(id)}
        iconClass={"mdi mdi-delete-outline"}
      ></Button>
    </div>
  );
}
