import styles from "./tkTC.module.scss";
import React from "react";
import {stopPropagationWrapper} from "../../../helpers/Hooks";

const CompareCheckbox = ({
                             isChecked,
                             handleClick,
                         }: {
    isChecked: boolean;
    handleClick: () => void;
}) => {
    return (
        <div className={styles.Check}>
      <span
          style={{
              color: isChecked ? "var(--color-primary)" : undefined,
          }}
          className={`${styles.CompareCheckIcon} ${
              isChecked
                  ? "mdi mdi-checkbox-marked-circle-outline"
                  : "mdi mdi-checkbox-blank-circle-outline"
          }`}
          onClick={stopPropagationWrapper(handleClick)}
      ></span>
        </div>
    );
};

export default CompareCheckbox;
