import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import styles from "./workshiftinfo.module.scss";
import { stopPropagationWrapper } from "../../helpers/Hooks";

const WorkShiftInfoBtn = (props: {
  osName: string; //"android" | "ios";
}) => {
  const { osName } = props;
  return (
    <Button
      className={styles.Btn}
      iconClass={
        "fi-social-" +
        (osName === "android" ? "android" : "apple") +
        " " +
        styles.BtnIcon
      }
      fillMode={"flat"}
      rounded={"full"}
      themeColor={"dark"}
      title={"workShiftInfo"}
      onClick={stopPropagationWrapper(() => {})}
    ></Button>
  );
};

export default WorkShiftInfoBtn;
