import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import BaseComponent from "../../../Components/BaseComponent";
import DocumentViewer from "../../../Components/Common/DocumentViewer/DocumentViewer";
import {
  DOCS_EXT,
  IMAGES_EXT,
} from "../../../Components/Common/DocumentViewer/helpers";
import Switch from "../../../Components/Common/Switch";
import { GetDocumentUrl } from "../../../helpers/helpers";
import { ICLMSettings } from "../../../stores/interfaces";
import styles from "./checklistResults.module.scss";
import { IsDisableEditStatus, OnChangeStatus } from "./helpers";
import {
  IDocumentItem,
  IFileItem,
  IPreviewDocument,
  IPreviewItem,
} from "./interfaces";
import { DocumentExtentions } from "../../../Components/Common/DocumentViewer/interfaces";
import { showSomeError } from "../../../helpers/errorHelpers";

interface props {
  dataItem: IDocumentItem;
  buildPlanId: number;
  pageId: string;
  settings: ICLMSettings;
  mobile?: {
    onPreviewClose(dataItem: null): void;
    onPopupShow(dataItem: IDocumentItem, fileId?: number): void;
    onChangeStatusPopupShow(dataItem: IDocumentItem, fileId?: number): void;
    arrows: any;
    refreshList(): void;
  };
  abort?: () => AbortSignal | undefined;

  onSelectFile(fileId: number): void;

  editComments(dataItem: IDocumentItem): void;
}

interface state {
  loading: boolean;
  documentLoading: boolean;
  previewDocument: IPreviewDocument | null;
  files: Array<IFileItem>;
  previews: Array<IPreviewItem>;
  showOriginal: boolean;
}

class ChecklistResultCarousel extends BaseComponent<props, state> {
  fileId: number | null = null;
  timerStatusUpdate: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      documentLoading: false,
      previewDocument: null,
      files: this.props.dataItem.files,
      previews: [],
      showOriginal: false,
    };
  }

  async componentDidMount() {
    const { dataItem, mobile, settings } = this.props;
    const {
      files,
      sampleExt,
      approvedFileId,
      sampleDocumentId,
      resultId,
      status,
    } = dataItem;
    if (mobile) {
      if (!settings.IsSubmitter && resultId && status === "Pending") {
        this.timerStatusUpdate = setTimeout(async () => {
          let dataItem = this.props.dataItem;
          try {
            await OnChangeStatus(
              "Reviewed",
              dataItem,
              this.fileId,
              this.props.abort?.()
            );
          } catch (e) {
            showSomeError(e);
          } finally {
            this.props.mobile?.refreshList();
          }
        }, 3000);
      }
    }

    if (!files.length && sampleExt) {
      if (sampleDocumentId && sampleExt) {
        this.ShowDocument(0, sampleExt, sampleDocumentId, "Example ");
      }
    } else if (files.length) {
      const sortPreview = (a: IFileItem, b: IFileItem) => b.v - a.v;
      files.sort(sortPreview);
      let file = files[0];
      if (files.length > 1) {
        if (approvedFileId) {
          file = files.find(({ id }) => id === approvedFileId) || files[0];
        }
        this.SetPreviews(files);
      }

      const approvedText = approvedFileId == file.id ? "Approved " : "";
      this.ShowDocument(
        file.id,
        file.e,
        file.docId,
        approvedText + file.name,
        file
      );
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.CancelLoadPreview();
  }

  render() {
    const { dataItem, mobile, settings } = this.props;
    const { previewDocument, previews } = this.state;

    const { status, resultId } = dataItem;
    if (mobile) {
      const isDisableChangeStatus =
        IsDisableEditStatus(dataItem, settings) ||
        (settings.IsReviewer && status === "CustomerRejected") ||
        resultId === null;
      return (
        <div className={styles.PreviewerCarouselBox}>
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            <div style={{ flex: 1, flexDirection: "column" }}>
              <b>{dataItem.groupName}</b>
              <b>{dataItem.cliName}</b>
              {!!dataItem.cliDesc && (
                <div>
                  <b>Description: </b>
                  {dataItem.cliDesc}
                </div>
              )}
            </div>
          </Toolbar>
          <div className={styles.PreviewerCarouselBox}>
            {this.renderDocumentPreview()}
          </div>
          {this.renderSmallPreviewsList()}
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            {mobile.arrows}
            {previewDocument && (
              <>
                <Button
                  title="Download Document"
                  icon="download"
                  onClick={this.DownloadDocument}
                  fillMode="flat"
                />
                <Button
                  title="Open Document in New Tab"
                  icon="hyperlink-open"
                  onClick={this.OpenDocumentInNewTab}
                  fillMode="flat"
                />
              </>
            )}
            {!!previewDocument && this.IsImg(previewDocument.ext) && (
              <Button
                iconClass="mdi mdi-quality-high"
                fillMode={"flat"}
                themeColor={this.state.showOriginal ? "success" : undefined}
                onClick={this.ToggleOriginal}
              />
            )}

            {!isDisableChangeStatus && (
              <Button onClick={this.OpenChangeStatusPopup}>Status</Button>
            )}
            <Button
              title=""
              iconClass="mdi mdi-dots-horizontal"
              onClick={this.ShowMobileActions}
              fillMode="flat"
            />
            <ToolbarSpacer />
            <Button
              title="Close Preview"
              icon="close"
              onClick={() => mobile?.onPreviewClose(null)}
              fillMode="flat"
            />
          </Toolbar>
        </div>
      );
    }

    return (
      <div className={styles.PreviewerCarouselBox}>
        <Toolbar className={styles.Toolbar}>
          <div style={{ flex: 1, flexDirection: "column" }}>
            <b>{dataItem.cliName}</b>
            {!!dataItem.cliDesc && (
              <div>
                <b>Description: </b>
                {dataItem.cliDesc}
              </div>
            )}
          </div>
          <div
            style={{
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div>
              {settings.IsReviewer && (
                <Button
                  title="Edit Comments"
                  icon="edit"
                  fillMode="flat"
                  onClick={this.EditComments}
                />
              )}
              {!!previewDocument && (
                <>
                  <Button
                    title="Download Document"
                    icon="download"
                    onClick={this.DownloadDocument}
                    fillMode="flat"
                  />

                  <Button
                    title="Open Document in New Tab"
                    icon="hyperlink-open"
                    onClick={this.OpenDocumentInNewTab}
                    fillMode="flat"
                  />
                </>
              )}
            </div>
            <div style={{ height: 27, display: "flex" }}>
              {!!previewDocument && this.IsImg(previewDocument.ext) && (
                <Switch
                  checked={this.state.showOriginal}
                  id="showOriginal"
                  onChange={this.ToggleOriginal}
                  label="Original"
                  rtl={true}
                />
              )}
            </div>
          </div>

          {!!dataItem.comments && (
            <div className={styles.CLIComments}>
              <b>Comments: </b>
              {dataItem.comments}
            </div>
          )}
        </Toolbar>
        <div className={styles.PreviewerCarouselBox}>
          {this.renderDocumentPreview()}
        </div>
        {this.renderSmallPreviewsList()}
      </div>
    );
  }

  renderSmallPreviewsList = () => {
    const { previewDocument, previews } = this.state;
    return (
      <div className={styles.PreviewList}>
        {!!previews.length &&
          previews.map(({ fileId, src }) => {
            const selected = previewDocument?.id === fileId;
            return (
              <img
                key={fileId}
                src={src}
                className={`${styles.PreviewItem} ${
                  selected && styles.PreviewItemActive
                }`}
                data-id={fileId}
                onClick={this.SelectPreview}
              />
            );
          })}
      </div>
    );
  };

  renderDocumentPreview = () => {
    const { previewDocument, showOriginal } = this.state;
    const isImg = !!previewDocument && this.IsImg(previewDocument.ext);
    const previewUrl =
      isImg && !showOriginal
        ? previewDocument.previewImgSrc
        : previewDocument?.originalSrc;

    return (
      <DocumentViewer
        containerId={this.props.pageId}
        key={"document"}
        document={
          previewDocument
            ? {
                id: previewDocument.id,
                extension: previewDocument.ext,
                previewUrl,
                alt: previewDocument.name,
              }
            : undefined
        }
        documentLoading={this.state.documentLoading}
      />
    );
  };

  ShowMobileActions = () => {
    const { mobile, dataItem } = this.props;
    if (!mobile) return;
    mobile.onPopupShow(dataItem, this.state.previewDocument?.id);
  };

  OpenChangeStatusPopup = () => {
    const { mobile, dataItem } = this.props;
    if (!mobile) return;
    mobile.onChangeStatusPopupShow(dataItem, this.state.previewDocument?.id);
  };

  SetPreviews = (files: IFileItem[]) => {
    const previews: IPreviewItem[] = [];
    const sincForeach = async (files: IFileItem[], index: number) => {
      try {
        const { docId, id: fileId } = files[index];
        const previewUrl = await GetDocumentUrl(docId, true, true);
        previews.push({ fileId, src: previewUrl! });
      } catch (e) {
        showSomeError(e);
      } finally {
        if (files[index + 1]) sincForeach(files, index + 1);
        else this.setState({ previews });
      }
    };
    sincForeach(files, 0);
  };

  CancelLoadPreview = () => {
    // window.stop(); // todo something else it stop loading everything not only preview
  };

  IsImg = (ext: DocumentExtentions) =>
    IMAGES_EXT.indexOf(ext.toLowerCase()) > -1;

  IsDoc = (ext: DocumentExtentions) => DOCS_EXT.indexOf(ext.toLowerCase()) > -1;

  ShowDocument = async (
    fileId: number,
    ext: DocumentExtentions,
    documentId: number,
    name: string,
    file?: IFileItem
  ) => {
    try {
      this.fileId = fileId;
      this.CancelLoadPreview();
      this.setState({ documentLoading: true });
      this.props.onSelectFile(fileId);
      const isIMG = this.IsImg(ext);
      const isDoc = this.IsDoc(ext);
      let originalSrc = await GetDocumentUrl(documentId, true, false);
      let previewImgSrc: string | null = "";
      if (isIMG) {
        previewImgSrc = await GetDocumentUrl(documentId, true, true);
      } else if (isDoc) {
        originalSrc =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          encodeURIComponent(originalSrc!);
      }
      this.setState({
        previewDocument: {
          id: fileId,
          docId: documentId,
          ext,
          originalSrc: originalSrc!,
          previewImgSrc,
          name,
        },
        documentLoading: false,
      });
    } catch (e) {
      showSomeError(e);
    }
  };

  SelectPreview = async (e: any) => {
    const fileId = e.nativeEvent.target.dataset.id;
    const {
      dataItem: { files, approvedFileId },
    } = this.props;
    const file = files.find((f) => +f.id === +fileId);
    if (!file) return;
    const approvedText = approvedFileId == file.id ? "Approved " : "";
    this.ShowDocument(
      file.id,
      file.e,
      file.docId,
      approvedText + file.name,
      file
    );
  };

  OpenDocumentInNewTab = async () => {
    try {
      const selectedFile = this.state.previewDocument;
      if (!selectedFile) return;
      const originalSrc = await GetDocumentUrl(selectedFile.docId, true, false);
      if (originalSrc) window.open(originalSrc, "__blank");
    } catch (e) {
      showSomeError(e);
    }
  };

  DownloadDocument = async () => {
    try {
      const { id: fileId, docId } = this.state.previewDocument || {};
      if (!docId) return;
      const src = (await GetDocumentUrl(docId, false)) || "";
      if (!src) return;
      window.location.href = src;
    } catch (e) {
      showSomeError(e);
    }
  };

  EditComments = () => {
    this.props.editComments(this.props.dataItem);
  };

  ToggleOriginal = () => {
    this.setState((state) => ({
      showOriginal: !state.showOriginal,
    }));
  };
}

export default ChecklistResultCarousel;
