import { CustomStatusPanelProps } from "ag-grid-react";
import { useCallback } from "react";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import ClearableInput from "../../../Components/Common/Form/ClearableInput";
import debounce from "lodash.debounce";

export default function StatusPanel_QuickFilterText(
  props: CustomStatusPanelProps
) {
  const { api: gridApi } = props;

  const applyFilter = debounce((value: string) => {
    gridApi.setGridOption("quickFilterText", value);
  }, 100);

  const handleChange = useCallback(
    (e?: InputChangeEvent | undefined) => {
      applyFilter(e?.value || "");
    },
    [gridApi]
  );

  return (
    <div
      style={{
        width: 200,
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ClearableInput
        onChange={handleChange}
        defaultValue={""}
        clear={() => applyFilter("")}
        style={{ width: "100%" }}
        placeholder={"Filter any column"}
      />
    </div>
  );
}
