import { Component } from "react";
import {
  ComboBox,
  DropDownsPopupSettings,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { IComboboxItem } from "../../helpers/interfaces";

interface props {
  data?: Array<IComboboxItem>;
  filter?: any;
  width?: number;
  placeholder?: string;
  defaultValue?: any;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  textField?: string;
  dataItemKey?: string;
  valueRender?: (
    rendering: React.ReactElement<HTMLSpanElement>,
    e?: any
  ) => React.ReactNode;
  opened?: boolean;
  popupSettings?: DropDownsPopupSettings;
  getData?: () => Promise<IComboboxItem[]>;

  onChange(value: any, filter: any): void;
}

interface state {
  data: Array<IComboboxItem>;
  loading: boolean;
  value: null | IComboboxItem;
}

class ComboboxFilterVirtual extends Component<props, state> {
  data: Array<IComboboxItem> = this.props.data || [];
  filteredData: Array<IComboboxItem> = [];
  filter: any;
  skip: number = 0;
  total: number = 0;
  pageSize: number = 11;
  filterTimeout: any;

  constructor(props: props) {
    super(props);
    this.state = {
      data: this.data,
      loading: false,
      value:
        this.props.data && this.props.defaultValue
          ? this.props.defaultValue
          : null,
    };
  }

  componentDidMount() {
    if (!this.props.data) this.LoadData();
    else this.ProcessingData();
  }

  componentDidUpdate(
    prevProps: Readonly<props>,
    prevState: Readonly<state>,
    snapshot?: any
  ) {
    if (this.props.data && prevProps.data !== this.props.data) {
      this.data = this.props.data;
      this.setState({ data: this.data });
    }
  }

  render() {
    const { filter, dataItemKey, textField } = this.props;
    return (
      <ComboBox
        required={this.props.required}
        className={this.props.className}
        loading={this.state.loading || this.props.loading}
        virtual={{
          total: this.filteredData.length,
          pageSize: this.pageSize,
          skip: this.skip,
        }}
        onPageChange={this.OnPageChange}
        data={this.state.data}
        placeholder={filter?.placeholder || this.props.placeholder}
        value={this.state.value}
        textField={textField || "Name"}
        dataItemKey={dataItemKey || "Id"}
        opened={this.props.opened}
        style={{ width: filter?.width || this.props.placeholder }}
        onChange={this.OnChangeCombobox}
        filterable={true}
        onFilterChange={this.FilterCombobox}
        disabled={this.props.filter.disabled || this.props.disabled}
        valueRender={this.props.valueRender}
        popupSettings={this.props.popupSettings}
        fillMode={"flat"}
      />
    );
  }

  OnPageChange = (event: any) => {
    this.skip = event.page.skip;
    this.SetData();
  };

  FilterCombobox = (e: any) => {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filter = e.filter;
    this.filterTimeout = setTimeout(() => {
      this.filteredData = filterBy(this.data, this.filter);
      this.skip = 0;
      this.total = this.filteredData.length;
      this.SetData();
    }, 1000);
  };

  OnChangeCombobox = (e: any) => {
    let value = e.value;
    this.setState({ value });
    this.props.onChange(value, this.props.filter);
  };

  SetData = async (value?: IComboboxItem) => {
    const newSubsetData =
      this.skip !== this.skip + this.pageSize
        ? this.filteredData.slice(this.skip, this.skip + this.pageSize)
        : this.filteredData;
    this.setState({ data: newSubsetData });
    if (value !== undefined) this.setState({ value });
  };

  LoadData = async () => {
    if (!this.props.getData) return;
    try {
      this.setState({ loading: true });
      this.data = await this.props.getData();
      this.ProcessingData();
    } finally {
      this.setState({ loading: false });
    }
  };

  ProcessingData = () => {
    this.filteredData = this.data;
    this.total = this.data.length;
    this.pageSize = this.total <= 11 ? this.total : 11;
    let value;
    if (this.props.defaultValue) {
      let index = this.filteredData.findIndex(
        (item) => item.Id === this.props.defaultValue.Id
      );
      if (index > -1) {
        this.skip = index;
        value = this.filteredData[index];
      }
    }
    this.SetData(value);
  };
}

export default ComboboxFilterVirtual;
