import { Button } from "@progress/kendo-react-buttons";
import { canGenerateBP } from "../../../core/tools/roles";
import React from "react";
import CardManagement from "../../../Components/Cards/CardManagement";
import styles from "../aggrid.module.scss";

export default function StatusPanel_Actions() {
  if (!canGenerateBP()) return null;
  return (
    <div className={styles.ActionsContainer}>
      <Button fillMode={"flat"} onClick={() => CardManagement.OpenGBPCard()}>
        NEW BUILD PLAN
      </Button>
    </div>
  );
}
