import DI_TYPES from "../inversify/diTypes";
import { Api_conterra } from "./generated/conterra";
import { ContentType, HttpClient } from "./httpClient";
import { Api_pnl } from "./generated/pnl";
import { Api_gps } from "./generated/gps";
import { Api_documents } from "./generated/documents";
import { Api_auth } from "./generated/auth";
import { Api_warehouse } from "./generated/warehouse";
import { Api_tk } from "./generated/tk";
import { AxiosInstance } from "axios";
import { inject, injectable } from "inversify";
import type {
  IApi,
  IAuthProvider,
  IHttpFormDataBuilder,
  ILogger,
} from "../inversify/interfaces";

@injectable()
export default class ApiImplementation implements IApi {
  auth: Api_auth["auth"];
  axios: AxiosInstance;
  documents: Api_documents["documents"];
  gps: Api_gps["gps"];
  pnl: Api_pnl["pnl"];
  script: Api_conterra["scripts"];
  sql: Api_conterra["sql"];
  tk: Api_tk;
  wh: Api_warehouse;
  getSqlData: (spName: string, data?: unknown) => Promise<any>;
  runScript: (
    scriptName: string,
    data: object,
    myParams?: object,
  ) => Promise<string | null>;
  public authProvider: IAuthProvider;

  constructor(
    @inject(DI_TYPES.ILogger) logger: ILogger,
    @inject(DI_TYPES.IAuthProvider) authProvider: IAuthProvider,
    @inject(DI_TYPES.IHttpFormDataBuilder)
    httpFormBuilder: IHttpFormDataBuilder,
  ) {
    this.authProvider = authProvider;
    const httpClient = new HttpClient(
      authProvider,
      logger.enrich({ Module: "HTTP" }),
      httpFormBuilder,
    );
    const runScript = (
      scriptName: string,
      data: object = {},
      myParams?: object,
    ) => {
      return httpClient.request<string | null>({
        path: `/old-script/${scriptName}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...(myParams || {}),
      });
    };

    const getSqlData = (spName: string, data?: unknown) => {
      return httpClient.request<any>({
        path: `/sql-old/${spName}`,
        method: "POST",
        format: "json",
        body: data,
      });
    };
    const conterra = new Api_conterra(httpClient);

    this.sql = conterra.sql;
    this.script = conterra.scripts;
    this.pnl = new Api_pnl(httpClient).pnl;
    this.gps = new Api_gps(httpClient).gps;
    this.documents = new Api_documents(httpClient).documents;
    this.auth = new Api_auth(httpClient).auth;
    this.wh = new Api_warehouse(httpClient);
    this.tk = new Api_tk(httpClient);
    this.runScript = runScript;
    this.getSqlData = getSqlData;
    this.axios = httpClient.instance;
  }
}
