import { Button, ButtonGroup, Toolbar } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronLeftIcon, chevronRightIcon } from "@progress/kendo-svg-icons";
import { DispatchWithoutAction, useCallback, useMemo } from "react";
import { CalendarMode } from "./models";
import { useCalendarContext } from "./CalendarContext";
import CardManagement from "../../Components/Cards/CardManagement";
import { RunScriptAsync } from "../../helpers/runscripts";
import { ModalRef } from "../Common/Modal/Modal";
import { showSomeError } from "../../helpers/errorHelpers";
import {
  getFirstDayOfPeriod,
  getFormattedPeriod,
  getNextPeriodDate,
  getPreviousPeriodDate,
} from "./utils";
import { CalendarLegend } from "./CalendarLegend";
import moment from "moment/moment";
import styles from "./calendar.module.scss";

type Props = {
  onToggleFilters: DispatchWithoutAction;
  filtersExpanded: boolean;
};

export const CalendarToolbar = ({
  onToggleFilters,
  filtersExpanded,
}: Props) => {
  const ctx = useCalendarContext();

  const handleMode = useCallback(
    (mode: CalendarMode) => {
      ctx.changeMode(mode);
    },
    [ctx],
  );

  const handleScope = useCallback(
    (isMy: boolean) =>
      ctx.setFilters((prev) => ({
        ...prev,
        isMy,
      })),
    [ctx],
  );

  const handleRefresh = useCallback(() => {
    ctx.data.fullRefresh();
  }, [ctx]);

  const handleCollapseAll = useCallback(() => {
    ctx.collapsible.emit(false);
  }, [ctx]);

  const handleExpandAll = useCallback(() => {
    ctx.collapsible.emit(true);
  }, [ctx]);

  const handleToday = useCallback(() => {
    ctx.setSelectedDate(
      getFirstDayOfPeriod(moment(), ctx.mode, ctx.firstDayOfWeek),
    );
  }, [ctx]);

  const handlePrevPeriod = useCallback(() => {
    ctx.setSelectedDate(
      getPreviousPeriodDate(ctx.selectedDate, ctx.mode, ctx.firstDayOfWeek),
    );
  }, [ctx]);

  const handleNextPeriod = useCallback(() => {
    ctx.setSelectedDate(
      getNextPeriodDate(ctx.selectedDate, ctx.mode, ctx.firstDayOfWeek),
    );
  }, [ctx]);

  const handleNewBp = useCallback(() => {
    if (ctx.useSimpleGBP) {
      CardManagement.NewSimpleGBP(ctx.data.refresh);
    } else {
      CardManagement.OpenGBPCard();
    }
  }, [ctx.data.refresh, ctx.useSimpleGBP]);

  const handleNewWo = useCallback(() => {
    CardManagement.NewWO(ctx.buildPlan.buildPlanId, ctx.data.refresh);
  }, [ctx.buildPlan.buildPlanId, ctx.data.refresh]);

  const handleNewDispatch = useCallback(() => {
    CardManagement.OpenDispatchCard({
      newDispatch: true,
      buildPlanId: ctx.buildPlan.buildPlanId,
      dsId: undefined,
      afterSave: ctx.data.refresh,
    });
  }, [ctx.buildPlan.buildPlanId, ctx.data.refresh]);

  const handlePublish = useCallback(() => {
    if (ctx.selectedDispatches.dispatches.length === 0) {
      ModalRef.showDialog({
        text: "Nothing selected",
        type: "info",
      });
      return;
    }

    RunScriptAsync(
      "PublishDispatchScheduleItems",
      { IDs: ctx.selectedDispatches.dispatches.join() },
      true,
    )
      .then(() => {
        ModalRef.showDialog({
          text: "Publish successfully completed",
          type: "info",
        });
      })
      .catch(showSomeError)
      .finally(ctx.data.refresh);
  }, [ctx.data.refresh, ctx.selectedDispatches]);

  const title = useMemo(
    () => getFormattedPeriod(ctx.selectedDate, ctx.mode, ctx.firstDayOfWeek),
    [ctx.firstDayOfWeek, ctx.mode, ctx.selectedDate],
  );

  return (
    <Toolbar className={styles.CalendarToolbar}>
      <ButtonGroup>
        <Button onClick={handlePrevPeriod} fillMode="flat">
          <SvgIcon icon={chevronLeftIcon} />
        </Button>
        <Button onClick={handleNextPeriod} fillMode="flat">
          <SvgIcon icon={chevronRightIcon} />
        </Button>
      </ButtonGroup>

      <Button onClick={handleToday} fillMode="flat">
        today
      </Button>

      <ButtonGroup>
        <Button
          togglable={true}
          onClick={() => handleMode("month")}
          selected={ctx.mode === "month"}
          fillMode="flat"
        >
          Month
        </Button>
        <Button
          togglable={true}
          onClick={() => handleMode("week")}
          selected={ctx.mode === "week"}
          fillMode="flat"
        >
          Week
        </Button>
        <Button
          togglable={true}
          onClick={() => handleMode("day")}
          selected={ctx.mode === "day"}
          fillMode="flat"
        >
          Day
        </Button>
        {ctx.buildPlan.buildPlanId === undefined ? (
          <>
            <Button
              togglable={true}
              onClick={() => handleMode("resources")}
              selected={ctx.mode === "resources"}
              fillMode="flat"
            >
              Resources
            </Button>
            <Button
              togglable={true}
              onClick={() => handleMode("map")}
              selected={ctx.mode === "map"}
              fillMode="flat"
            >
              Map
            </Button>
          </>
        ) : null}
      </ButtonGroup>

      {ctx.buildPlan.buildPlanId === undefined ? (
        <ButtonGroup>
          <Button
            togglable={true}
            onClick={() => handleScope(true)}
            selected={ctx.filters.isMy}
            fillMode="flat"
          >
            My
          </Button>
          <Button
            togglable={true}
            onClick={() => handleScope(false)}
            selected={!ctx.filters.isMy}
            fillMode="flat"
          >
            All
          </Button>
        </ButtonGroup>
      ) : null}

      <Button
        iconClass="k-icon k-font-icon k-i-refresh"
        onClick={handleRefresh}
        title="Refresh"
        fillMode="flat"
      ></Button>

      <ButtonGroup>
        <Button
          iconClass="k-icon mdi mdi-collapse-all"
          onClick={handleCollapseAll}
          title="Collapse all"
          disabled={!["month", "week"].includes(ctx.mode)}
          fillMode="flat"
        ></Button>

        <Button
          iconClass="k-icon mdi mdi-expand-all"
          onClick={handleExpandAll}
          title="Expand all"
          disabled={!["month", "week"].includes(ctx.mode)}
          fillMode="flat"
        ></Button>
      </ButtonGroup>

      {ctx.buildPlan.buildPlanId === undefined && ctx.mode !== "map" ? (
        <Button
          iconClass={
            filtersExpanded ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"
          }
          title="Toggle filters"
          onClick={onToggleFilters}
          fillMode="flat"
        ></Button>
      ) : null}

      <div className={styles.CalendarToolbarTitle}>{title}</div>

      <ButtonGroup>
        {ctx.buildPlan.buildPlanId === undefined ? (
          <Button onClick={handleNewBp} fillMode="flat">
            NEW BP
          </Button>
        ) : null}
        {!ctx.useSimpleGBP ? (
          <Button onClick={handleNewWo} fillMode="flat">
            NEW WO
          </Button>
        ) : null}
        <Button onClick={handleNewDispatch} fillMode="flat">
          NEW DISPATCH
        </Button>
        <Button
          onClick={handlePublish}
          disabled={ctx.selectedDispatches.dispatches.length === 0}
          fillMode="flat"
        >
          PUBLISH {ctx.selectedDispatches.dispatches.length}
        </Button>
      </ButtonGroup>

      <CalendarLegend />
    </Toolbar>
  );
};
