// noinspection JSUnusedGlobalSymbols
// FIXME
// import "intl";
// import "intl/locale-data/jsonp/en";

export const formatCurrency = (amount: number | bigint) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const formatTimeDiff = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const hrsStr = hrs > 0 ? hrs + "h " : "";
  const min = Math.floor((seconds % 3600) / 60);
  return hrsStr + min + "m";
};

export const formatHoursDuration = (hours: number) => {
  const minus = hours < 0 ? "-" : "";
  const seconds = Math.abs(hours * 3600);
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds % 3600) / 60);

  return `${minus}${hrs < 10 ? "0" : ""}${hrs}:${min < 10 ? "0" : ""}${min}`;
};

export const CONTERRA_DATETIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const CONTERRA_DATE_FORMAT = "DD.MM.YYYY";
// noinspection SpellCheckingInspection
export const ISO_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
// noinspection SpellCheckingInspection
export const FILE_NAME_DATETIME_FORMAT = "YYYYMMDD-HHmmss";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export function FormatSize(bytes: number) {
  return Math.round((bytes || 0) / 1000) / 1000 + " MB";
}
