import React, {useCallback, useEffect, useRef} from "react";
import DashboardGrid from "../Dashboard/Grid";
import {IColumnSetting} from "../Dashboard/interfaces";
import styles from "./tc.module.scss";
import {TRenderToolbar} from "../TabsNew/interfaces";
import api from "../../core/api/api";
import {SQL_TK_GetTCAttestations_Response} from "../../core/api/generated/conterra";
import {useLatestNotEmptyValue} from "../../core/tools/Hooks";

interface IProps {
    tcId?: number;
    isActive: boolean;
    pageId?: string;
    renderToolbar?: TRenderToolbar;
    reloadDependency?: unknown;
    refreshTC: () => void;
}

const TCAttestations = (props: IProps) => {
    const {tcId, isActive, reloadDependency, renderToolbar} = props;
    const currentTcId = useLatestNotEmptyValue(tcId);
    const gridRefreshRef = useRef<() => void>(() => {
    });
    const columnsRef = useRef<IColumnSetting[]>([
        {
            field: "rowNum",
            title: "#",
            type: "string",
            aggregate: "count",
            gridWidth: 40,
            filterable: false,
            columnMenu: false,
        },
        {
            field: "title",
            title: "Attestation",
            type: "string",
            format: {
                type: "string",
                fieldId: "attestationId",
            },
        },
        {
            field: "confirmedText",
            title: "Confirmed",
            gridWidth: 110,
            type: "string",
            format: {
                type: "string",
                fieldId: "confirmed",
            },
        },
        {
            field: "text",
            title: "Text",
            gridWidth: 70,
            type: "string",
            format: {
                type: "icon",
                iconClass: "k-icon k-font-icon k-i-file-txt",
            },
        },
        {
            field: "comment",
            title: "Comment",
            type: "string",
        },
    ]);

    const loadData = useCallback(async () => {
        const data = await api.sql.tkGetTcAttestations({tcId: currentTcId});
        return data.map((item) => ({
            ...item,
            confirmedText: item.confirmed ? "Yes" : "No",
        }));
    }, [currentTcId]);
    const lastAppliedDependency = useRef<unknown>(reloadDependency);
    useEffect(() => {
        if (isActive && lastAppliedDependency.current !== reloadDependency) {
            lastAppliedDependency.current = reloadDependency;
            gridRefreshRef.current?.();
        }
    }, [reloadDependency, isActive]);

    const initRefresh = useCallback((refresh: () => void) => {
        gridRefreshRef.current = refresh;
    }, []);

    const getRowKey = useCallback(
        ({rowNum}: SQL_TK_GetTCAttestations_Response) => rowNum + "",
        []
    );

    const getRowClass = useCallback(
        (x: SQL_TK_GetTCAttestations_Response) =>
            !x.confirmed ? styles.NotConfirmedAttestationsRow : "",
        []
    );

    if (!currentTcId) return null;
    return (
        <DashboardGrid
            isActive={isActive}
            isParentPage={true}
            columnsSettings={columnsRef.current}
            getData={loadData}
            getRowKey={getRowKey}
            initRefresh={initRefresh}
            excelFileName="Attestations.xlsx"
            columnMenu={{
                filters: true,
                columns: false,
            }}
            getRowClass={getRowClass}
            toolbar={{renderToolbar}}
        />
    );
};

export default TCAttestations;
