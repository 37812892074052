import { IComboboxItem } from "../../../helpers/interfaces";
import React, { useEffect, useState } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import { getSQLData } from "../../../helpers/queries";
import FilterCombobox from "../../../Components/Common/Form/FilterCombobox";
import { showSomeError } from "../../../helpers/errorHelpers";

export const UserSelect = (props: {
  onSelect: (value: IComboboxItem | null) => void;
}) => {
  const [users, setUsers] = useState<IComboboxItem[]>();
  const isLoading = useBooleanState(false);
  useEffect(() => {
    const loadAvailableUsers = async () => {
      try {
        isLoading.setTrue();
        const response = await getSQLData({ spName: "TK_GetUsers" });
        const data = response[0].map(
          ({ value, text }: { value: number; text: string }) => ({
            Id: value,
            Name: text,
          }),
        );
        setUsers(data);
      } catch (e) {
        showSomeError(e);
      } finally {
        isLoading.setFalse();
      }
    };
    loadAvailableUsers();
  }, []);

  return (
    <FilterCombobox
      placeholder={"Select User"}
      onChange={props.onSelect}
      loading={isLoading.value}
      data={users}
      required={true}
    />
  );
};
