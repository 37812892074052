import { inject, injectable } from "inversify";
import DI_TYPES from "../core/inversify/diTypes";
import { SQL_Settings_Response } from "../core/api/generated/conterra";
import type {
  IApi,
  IServerSettingsService,
  ISettingsStorage,
} from "../core/inversify/interfaces";

const STORAGE_KEY_SETTINGS_SERVER = "FXSettings";
const STORAGE_KEY_SLU = "FXSlu";

@injectable()
export default class ServerSettingsService implements IServerSettingsService {
  settings: Record<string, number | string | boolean | null | undefined>;

  constructor(
    @inject(DI_TYPES.ISettingsStorage)
    private _storage: ISettingsStorage,
    @inject(DI_TYPES.IApi) private _api: IApi,
  ) {
    const settingsFromStorage = this._storage.getForCurrentInstance(
      STORAGE_KEY_SETTINGS_SERVER,
    );
    this.settings = settingsFromStorage ? JSON.parse(settingsFromStorage) : {};
    this._api.authProvider.subscribeSettingsLastUpdateChange(() =>
      this.updateServerSetting(),
    );

    this.updateServerSetting();
  }

  updateServerSetting = () => {
    const sluStorage = this._storage.get(STORAGE_KEY_SLU);
    const slu = this._api.authProvider.getSettingsLastUpdate();

    if (!slu) {
      return;
    }

    if (Number(sluStorage) > slu) {
      return;
    }

    if (!this._api.authProvider.getIsAuthorized()) return;

    this._api.sql.settings().then((settings) => {
      const parsedSettings = this.parseServerSettings(settings);

      this._storage.setForCurrentInstance(
        STORAGE_KEY_SETTINGS_SERVER,
        JSON.stringify(parsedSettings),
      );

      this._storage.set(STORAGE_KEY_SLU, String(slu));

      this.settings = parsedSettings;
    });
  };

  getServerSettings() {
    return this.settings;
  }

  private parseServerSettings(settings: SQL_Settings_Response[]) {
    const parsedSettings: Record<
      string,
      number | string | boolean | null | undefined
    > = {};
    const parsedFns = [this.getBoolean, this.getNumber, this.getString];

    settings.forEach((setting) => {
      for (const fn of parsedFns) {
        if (setting.value === null || setting.value === "") {
          parsedSettings[setting.name] = setting.value;
          break;
        }

        const parsedValue = fn(setting.value);

        if (parsedValue !== null) {
          parsedSettings[setting.name] = parsedValue;
          break;
        }
      }
    });

    return parsedSettings;
  }

  private getBoolean(value: string) {
    if (value === "true" || value === "false") {
      return value === "true";
    }

    return null;
  }

  private getNumber(value: string) {
    if (!isNaN(Number.parseInt(value, 10))) {
      return Number(value);
    }

    return null;
  }

  private getString(value: string) {
    if (/^\d+$|^true$|^false$/.test(value)) {
      return null;
    }

    return value;
  }
}
