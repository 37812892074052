import {SQL_DB_TK_Review_Response_DispatchInfo} from "../../../core/api/generated/conterra";
import {Button, Chip, ChipHandle} from "@progress/kendo-react-buttons";
import React, {useCallback, useEffect} from "react";
import {useBooleanState} from "../../../core/tools/Hooks";
import {INewComboboxItem} from "../../../helpers/interfaces";
import {Popover} from "@progress/kendo-react-tooltip";
import CardManagement from "../../../Components/Cards/CardManagement";
import styles from "./tkTC.module.scss";
import {stopPropagationWrapper} from "../../../helpers/Hooks";

export const DispatchesChips = (props: {
    dispatches: SQL_DB_TK_Review_Response_DispatchInfo[];
    onSelectDispatch?: (dispatch: INewComboboxItem) => void;
}) => {
    const {dispatches, onSelectDispatch} = props;
    const isShownPopover = useBooleanState(false);
    const anchor = React.useRef<ChipHandle>(null);

    useEffect(() => {
        if (isShownPopover.value) {
            document.addEventListener("click", isShownPopover.setFalse);
        } else {
            document.removeEventListener("click", isShownPopover.setFalse);
        }
        return () => {
            document.removeEventListener("click", isShownPopover.setFalse);
        };
    }, [isShownPopover.value]);

    const onFilterBtnClick = useCallback(
        (id: number, name: string) => {
            if (!onSelectDispatch) return;
            onSelectDispatch({id, name});
            isShownPopover.setFalse();
        },
        [onSelectDispatch, isShownPopover]
    );

    const onLinkBtnClick = useCallback((dsId: number) => {
        isShownPopover.setFalse();
        CardManagement.OpenDispatchCard({
            newDispatch: false,
            dsId,
        });
    }, []);

    const renderPopover = () => {
        return (
            <Popover
                show={isShownPopover.value}
                anchor={anchor.current ? anchor.current.element : null}
                callout={false}
                collision={{
                    vertical: "flip",
                    horizontal: "fit",
                }}
                position={"bottom"}
            >
                <div style={{margin: -4}}>
                    {dispatches.map(({id, name}) => (
                        <div key={id} className={styles.DispatchRow}>
                            <div className={styles.DispatchName}>{name}</div>
                            {id ? (
                                <>
                                    {onSelectDispatch ? (
                                        <Button
                                            icon="filter"
                                            fillMode="flat"
                                            title={"Set Filter by Dispatch"}
                                            onClick={stopPropagationWrapper(() =>
                                                onFilterBtnClick(id, name)
                                            )}
                                        />
                                    ) : null}
                                    <Button
                                        icon="hyperlink-open"
                                        fillMode="flat"
                                        title={"Open Card"}
                                        onClick={stopPropagationWrapper(() => onLinkBtnClick(id))}
                                    />
                                </>
                            ) : undefined}
                        </div>
                    ))}
                </div>
            </Popover>
        );
    };

    return (
        <>
            <Chip
                ref={anchor}
                size={"medium"}
                rounded={"medium"}
                text={`${dispatches.length} Dispatch${
                    dispatches.length > 1 ? "es" : ""
                }`}
                onClick={stopPropagationWrapper(isShownPopover.toggle)}
            />
            {renderPopover()}
        </>
    );
};
