import React, {useContext} from "react";
import styles from "../aggrid.module.scss";
import {CurrentViewContext} from "../helpers";
import ButtonLink from "../../../Components/Common/Buttons/ButtonLink";

export default function StatusPanel_CurrentView() {
    const { value: currentGridView, setValue } = useContext(
        CurrentViewContext
    );

  if (!currentGridView.id) return null;
  return (
    <div className={styles.ActionsContainer}>
        <span>Current View:</span> <ButtonLink text={currentGridView.name} onClick={() => setValue({...currentGridView})}/>
    </div>
  );
}
