import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Card } from "@progress/kendo-react-layout";
import moment from "moment";
import React, { Fragment } from "react";
import { IComboboxItem, simpleObject } from "../../../helpers/interfaces";
import { OpenRecord, RunScriptAsync } from "../../../helpers/runscripts";
import BaseComponent from "../../BaseComponent";
import OpenCardLink from "../../OpenCardLink";
import FXCard from "../../Common/FXCard/FXCard";
import ClearableDateInput from "../../Common/Form/ClearableDateInput";
import FilterCombobox from "../../Common/Form/FilterCombobox";
import Loader from "../../Common/Loader";
import { maxDate, minDate } from "../../Dashboard/helpers";
import CardManagement from "../CardManagement";
import OpenCardIconLink from "../OpenCardIconLink";
import formStyles from "../card.module.scss";
import { IFXCardProps } from "../interfaces";
import api from "../../../core/api/api";
import {
  PropsSQLDBCalendarDispatchInfoResponseDispatchInfo,
  PropsSQLDBCalendarResourcesResponseCrewContainers,
  SQL_DB_Calendar_DispatchInfo_Response_CrewMembers,
  SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo,
  SQL_DB_Calendar_Resources_Response_CrewContainers,
  SQL_DB_Calendar_WOCertificateInfo_Response,
} from "../../../core/api/generated/conterra";
import {
  IResourceItem,
  IScheduledEmployees,
  IScheduledEmployeesReasons,
} from "./interfaces";
import WOCertificatesTable from "./WoCertificates";
import ColumnComboboxFilter from "./ColumnComboboxFilter";
import { showSomeError } from "../../../helpers/errorHelpers";
import { sortLetterNumberSpecialCharOrder } from "../../../helpers/helpers";

interface IWOItem extends IComboboxItem {
  AllowOvernight?: boolean;
  PrevailingWage?: boolean;
  WorkTypeTitle: string;
}

interface IDispatchInfo
  extends SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo {
  Arrive: Date | null;
  ArriveType: "After" | "Before";
  ClockInAfter: Date | null;
  ClockOutBefore: Date | null;
  CrewContainerId: number | null;
  CrewIDs: string;
  DateObject: Date | null;
  Depart: Date | null;
  DepartType: "After" | "Before";
  DateTo: Date | null;
  WorkDaysOnly: boolean;
}

interface IProps extends IFXCardProps {
  newDispatch: boolean;
  buildPlanId?: number | undefined;
  woId?: number;
  dsId?: number;
  date?: Date;
  crewLead?: { id: number; name: string };

  afterSave?(): void;
}

interface state {
  workOrders: Array<IWOItem>;
  resources: IResourceItem[];
  selectedCrewMembers: IResourceItem[] | undefined;
  selectedCrewLead: IResourceItem | null;
  loading: boolean;
  selectedWO: IWOItem | null;
  isReadOnlyForm: boolean;
  remountKey: number;
  remountDateKey: number;
  selectedCrewContainer: SQL_DB_Calendar_Resources_Response_CrewContainers | null;
  crews: SQL_DB_Calendar_Resources_Response_CrewContainers[];
  woCertificates: SQL_DB_Calendar_WOCertificateInfo_Response[];
}

class DispatchCard extends BaseComponent<IProps, state> {
  DSId: number | null = this.props.dsId || null;
  BPId: number | null = this.props.buildPlanId || null;
  InitialCrew: SQL_DB_Calendar_DispatchInfo_Response_CrewMembers[] = [];
  InitialCrewLead: {
    id: number;
    name: string;
  } | null = this.props.crewLead || null;
  ScheduledEmployees: IScheduledEmployees = {};
  ScheduledEmployeesReasons: IScheduledEmployeesReasons = {};
  ScheduledWODates: Array<{ Date: string; DispatchId: number }> = [];

  DispatchInfo: Partial<IDispatchInfo> = {
    DateObject: new Date(),
    Arrive: null,
    ArriveType: "After",
    CrewIDs: "",
    Depart: null,
    DepartType: "After",
    DateTo: null,
    WorkDaysOnly: false,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      workOrders: [],
      resources: [],
      loading: false,
      selectedCrewMembers: undefined,
      selectedWO: null,
      selectedCrewLead: null,
      selectedCrewContainer: null,
      isReadOnlyForm: false,
      remountKey: +new Date(),
      remountDateKey: +new Date(),
      crews: [],
      woCertificates: [],
    };
  }

  componentDidMount() {
    if (this.props.newDispatch) this.InitNewDispatch();
    else this.InitEditDispatch();
  }

  InitNewDispatch = async () => {
    try {
      this.setState({ loading: true });
      const woResut = await this.LoadAvailableWorkOrders();
      const dispatchInfoResult = await this.LoadDispatchInfo();
      const workOrders: IWOItem[] = woResut[0] || [];
      const dispatchInfo = dispatchInfoResult?.[0].dispatchInfo[0];
      const dispatchCrew = dispatchInfoResult?.[0].crewMembers;
      if (dispatchInfo && dispatchCrew) {
        // dispatchInfo.date = new Date();
        this.SetDispatchInfo(dispatchInfo, dispatchCrew);
      }

      let initialWoId = dispatchInfo
        ? dispatchInfo.workOrderId
        : this.props.woId;
      let selectedWO =
        initialWoId !== undefined
          ? workOrders.find((wo) => +wo.Id === initialWoId)
          : undefined;

      if (!selectedWO && workOrders.length === 1) selectedWO = workOrders[0];

      if (selectedWO) {
        this.DispatchInfo.workOrderId = +selectedWO.Id;
        if (!dispatchInfoResult) {
          this.DispatchInfo.allowOvernight = !!selectedWO.AllowOvernight;
          this.DispatchInfo.prevailingWage = !!selectedWO.PrevailingWage;
          this.DispatchInfo.workTypeTitle = selectedWO.WorkTypeTitle;
        }
        await this.GetSetAvailableResources();
      }

      this.setState({
        workOrders,
        selectedWO: selectedWO || null,
        isReadOnlyForm: false,
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  InitEditDispatch = async () => {
    try {
      this.setState({ loading: true });
      const dispatchInfoResult = await this.LoadDispatchInfo();
      if (!dispatchInfoResult) return;
      const dispatchInfo = dispatchInfoResult[0].dispatchInfo[0];
      const dispatchCrew = dispatchInfoResult[0].crewMembers;
      this.SetDispatchInfo(dispatchInfo, dispatchCrew);
      const selectedWO = {
        Id: dispatchInfo.workOrderId,
        Name: dispatchInfo.workOrderName,
        WorkTypeTitle: dispatchInfo.workTypeTitle,
      };
      this.DispatchInfo.workOrderId = dispatchInfo.workOrderId;
      this.DispatchInfo.DateObject = moment(dispatchInfo.date).toDate();
      await this.GetSetAvailableResources();
      this.setState({
        workOrders: [selectedWO],
        selectedWO,
        isReadOnlyForm: !dispatchInfo.active,
        remountDateKey: +new Date(),
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  SetDispatchInfo = (
    dispatchInfo: SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo,
    dispatchCrew: SQL_DB_Calendar_DispatchInfo_Response_CrewMembers[],
  ) => {
    this.InitialCrewLead = {
      id: dispatchInfo.crewLeadId,
      name: dispatchInfo.crewLeadName,
    };
    this.DispatchInfo = { ...this.DispatchInfo, ...dispatchInfo };
    this.DispatchInfo.ClockInAfter = dispatchInfo.clockInAfter
      ? moment(dispatchInfo.clockInAfter).toDate()
      : null;
    this.DispatchInfo.ClockOutBefore = dispatchInfo.clockOutBefore
      ? moment(dispatchInfo.clockOutBefore).toDate()
      : null;
    this.DispatchInfo.DateObject = dispatchInfo.date
      ? moment(dispatchInfo.date).toDate()
      : null;

    let arrive = dispatchInfo.arriveAfter || dispatchInfo.arriveBefore;
    if (arrive) {
      this.DispatchInfo.Arrive = moment(arrive).toDate();
      this.DispatchInfo.ArriveType = dispatchInfo.arriveAfter
        ? "After"
        : "Before";
    } else {
      this.DispatchInfo.Arrive = null;
      this.DispatchInfo.ArriveType = "After";
    }

    let depart = dispatchInfo.departAfter || dispatchInfo.departBefore;
    if (depart) {
      this.DispatchInfo.Depart = moment(depart).toDate();
      this.DispatchInfo.DepartType = dispatchInfo.departAfter
        ? "After"
        : "Before";
    } else {
      this.DispatchInfo.Depart = null;
      this.DispatchInfo.DepartType = "After";
    }

    this.InitialCrew = [...dispatchCrew];
  };

  render() {
    let checkBoxClassName = formStyles.Checkbox;
    if (this.state.isReadOnlyForm)
      checkBoxClassName += " " + formStyles.CheckboxReadonly;

    return (
      <FXCard
        title={this.renderTitle()}
        onClose={this.Close}
        initialWidth={900}
        initialHeight={700}
        originalPaddings={true}
        maximizeButton={() => <span></span>}
      >
        <div className={formStyles.FormWrapper}>
          {this.state.loading && (
            <div>
              <Loader />
            </div>
          )}
          {!this.state.loading && (
            <>
              <FilterCombobox
                placeholder="Work Order *"
                data={this.state.workOrders}
                onChange={this.OnChangeWO}
                className={formStyles.FormField}
                value={this.state.selectedWO}
                disabled={!this.props.newDispatch}
              />
              <Fragment key={this.state.remountKey}>
                <div className={formStyles.DatePickerRow}>
                  <DatePicker
                    id={"DateObject"}
                    onChange={this.OnDateChange}
                    min={minDate}
                    max={maxDate}
                    className={formStyles.Datepicker}
                    defaultValue={this.DispatchInfo.DateObject || undefined}
                    disabled={
                      !this.props.newDispatch || !this.DispatchInfo.workOrderId
                    }
                    formatPlaceholder={{
                      month: "m",
                      day: "d",
                      year: "y",
                    }}
                    key={"date" + this.state.remountDateKey}
                  ></DatePicker>
                  {this.props.newDispatch && (
                    <>
                      <span className={formStyles.DateSeparator}>-</span>
                      <DatePicker
                        id={"DateTo"}
                        onChange={this.OnDateChange}
                        min={minDate}
                        max={maxDate}
                        className={formStyles.Datepicker}
                        disabled={!this.DispatchInfo.workOrderId}
                        defaultValue={this.DispatchInfo.DateTo || undefined}
                        formatPlaceholder={{
                          month: "m",
                          day: "d",
                          year: "y",
                        }}
                      ></DatePicker>
                      <Checkbox
                        id={"WorkDaysOnly"}
                        defaultChecked={this.DispatchInfo.WorkDaysOnly}
                        label="Work Days Only"
                        className={formStyles.Checkbox}
                        onChange={this.OnChangeCheckbox}
                        disabled={!this.DispatchInfo.workOrderId}
                      />
                    </>
                  )}
                </div>
                <FilterCombobox
                  placeholder="Crew Container"
                  data={this.state.crews}
                  onChange={this.OnChangeCrew}
                  className={formStyles.FormField}
                  disabled={
                    this.state.isReadOnlyForm || !this.DispatchInfo.workOrderId
                  }
                  value={this.state.selectedCrewContainer}
                  textField={
                    PropsSQLDBCalendarResourcesResponseCrewContainers.name
                  }
                  dataItemKey={
                    PropsSQLDBCalendarResourcesResponseCrewContainers.crewContainerId
                  }
                />
                <ColumnComboboxFilter
                  placeholder="Crew Lead *"
                  resources={this.state.resources}
                  onSingleChange={this.OnChangeCrewLead}
                  className={formStyles.FormField}
                  value={this.state.selectedCrewLead}
                  disabled={
                    this.state.isReadOnlyForm || !this.DispatchInfo.workOrderId
                  }
                  isMultiSelect={false}
                  woCertificates={this.state.woCertificates}
                  scheduledEmployees={this.ScheduledEmployees}
                  scheduledEmployeesReasons={this.ScheduledEmployeesReasons}
                />
                <ColumnComboboxFilter
                  placeholder="Crew Members"
                  resources={this.state.resources}
                  className={formStyles.FormField}
                  onMultiChange={this.OnChangeMultiselect}
                  multiValues={this.state.selectedCrewMembers}
                  disabled={
                    this.state.isReadOnlyForm || !this.DispatchInfo.workOrderId
                  }
                  isMultiSelect={true}
                  woCertificates={this.state.woCertificates}
                  scheduledEmployees={this.ScheduledEmployees}
                  scheduledEmployeesReasons={this.ScheduledEmployeesReasons}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <TextArea
                      className={formStyles.TextArea}
                      rows={6}
                      placeholder="Comments"
                      onChange={this.OnChangeComments}
                      disabled={!this.DispatchInfo.workOrderId}
                      readOnly={this.state.isReadOnlyForm}
                      defaultValue={this.DispatchInfo.comments || undefined}
                    ></TextArea>
                  </div>
                  <div
                    style={{
                      width: "120px",
                      textAlign: "right",
                      marginTop: 0,
                    }}
                  >
                    <div className={formStyles.CheckboxRow}>
                      <Checkbox
                        id={
                          PropsSQLDBCalendarDispatchInfoResponseDispatchInfo.allowOvernight
                        }
                        defaultChecked={this.DispatchInfo.allowOvernight}
                        label="Allow Overnight"
                        className={checkBoxClassName}
                        onChange={this.OnChangeCheckbox}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dir={"RTL"}
                      />
                    </div>
                    <div className={formStyles.CheckboxRow}>
                      <Checkbox
                        id={
                          PropsSQLDBCalendarDispatchInfoResponseDispatchInfo.remoteHotel
                        }
                        defaultChecked={this.DispatchInfo.remoteHotel}
                        label="Remote Hotel"
                        className={checkBoxClassName}
                        onChange={this.OnChangeCheckbox}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dir={"RTL"}
                      />
                    </div>
                    <div className={formStyles.CheckboxRow}>
                      <Checkbox
                        id={
                          PropsSQLDBCalendarDispatchInfoResponseDispatchInfo.prevailingWage
                        }
                        defaultChecked={this.DispatchInfo.prevailingWage}
                        label="Prevailing Wage"
                        className={checkBoxClassName}
                        onChange={this.OnChangeCheckbox}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dir={"RTL"}
                      />
                    </div>
                    <div className={formStyles.CheckboxRow}>
                      <span>Work Type:&nbsp;</span>
                      {this.DispatchInfo.workTypeTitle}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "225px" }}>
                    <div className={formStyles.TimePickerRow}>
                      <div className={`${formStyles.TimePickerLabel}`}>
                        ClockIn Expected
                      </div>
                      <TimePicker
                        className={formStyles.TimePicker}
                        formatPlaceholder={{
                          hour: "hh",
                          minute: "mm",
                        }}
                        id="ClockInAfter"
                        defaultValue={this.DispatchInfo.ClockInAfter}
                        onChange={this.OnChangeTime}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dateInput={this.renderDateTimeInput}
                      />
                    </div>
                    <div className={formStyles.TimePickerRow}>
                      <div className={`${formStyles.TimePickerLabel}`}>
                        ClockOut Expected
                      </div>
                      <TimePicker
                        className={formStyles.TimePicker}
                        formatPlaceholder={{
                          hour: "hh",
                          minute: "mm",
                        }}
                        id="ClockOutBefore"
                        defaultValue={this.DispatchInfo.ClockOutBefore}
                        onChange={this.OnChangeTime}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dateInput={this.renderDateTimeInput}
                      />
                    </div>
                  </div>
                  <div style={{ width: "315px" }}>
                    <div className={formStyles.TimePickerRow}>
                      <div className={formStyles.TimePickerLabel}>
                        Arrival Expected
                      </div>
                      <DropDownList
                        className={formStyles.TimePickerDropDown}
                        data={["Before", "After"]}
                        id="ArriveType"
                        defaultValue={this.DispatchInfo.ArriveType}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        onChange={this.OnChangeTimeType}
                      />
                      <TimePicker
                        className={formStyles.TimePicker}
                        id="Arrive"
                        formatPlaceholder={{
                          hour: "hh",
                          minute: "mm",
                        }}
                        defaultValue={this.DispatchInfo.Arrive}
                        onChange={this.OnChangeTime}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dateInput={this.renderDateTimeInput}
                      />
                    </div>
                    <div className={formStyles.TimePickerRow}>
                      <span className={formStyles.TimePickerLabel}>
                        Departure Expected
                      </span>
                      <DropDownList
                        className={formStyles.TimePickerDropDown}
                        data={["Before", "After"]}
                        id="DepartType"
                        defaultValue={this.DispatchInfo.DepartType}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        onChange={this.OnChangeTimeType}
                      />
                      <TimePicker
                        className={formStyles.TimePicker}
                        id="Depart"
                        formatPlaceholder={{
                          hour: "hh",
                          minute: "mm",
                        }}
                        defaultValue={this.DispatchInfo.Depart}
                        onChange={this.OnChangeTime}
                        disabled={
                          this.state.isReadOnlyForm ||
                          !this.DispatchInfo.workOrderId
                        }
                        dateInput={this.renderDateTimeInput}
                      />
                    </div>
                  </div>
                </div>
                <WOCertificatesTable
                  certificates={this.state.woCertificates}
                  crewLead={this.state.selectedCrewLead || undefined}
                  crew={this.state.selectedCrewMembers || []}
                />
                {this.HasWarnings() && (
                  <div className={formStyles.WarningsList}>
                    {this.props.newDispatch &&
                      !!this.ScheduledWODates.length && (
                        <Card
                          className={`${formStyles.WarningInfoCard} k-card-warning`}
                        >
                          <div className={formStyles.WarningInfoRow}>
                            <span>WO is already scheduled for</span>
                            {this.ScheduledWODates.map((item) => {
                              return (
                                <>
                                  &nbsp;
                                  <OpenCardLink
                                    key={item.Date}
                                    onClick={this.ChangeDispatchCard}
                                    dataAttr={item.DispatchId}
                                    style={{ textDecoration: "underline" }}
                                    text={item.Date}
                                  />
                                </>
                              );
                            })}
                          </div>
                        </Card>
                      )}
                    {this.HasCrewLeadWarning() && (
                      <Card
                        className={`${formStyles.WarningInfoCard} k-card-warning`}
                      >
                        {this.renderEmployeeWarning(
                          this.DispatchInfo.crewLeadId!,
                        )}
                      </Card>
                    )}
                    {this.HasCrewWarnings() && (
                      <Card
                        className={`${formStyles.WarningInfoCard} k-card-warning`}
                      >
                        {this.DispatchInfo.CrewIDs?.split(",").map(
                          (id) =>
                            !!this.ScheduledEmployees[+id] &&
                            this.renderEmployeeWarning(+id),
                        )}
                      </Card>
                    )}
                    {this.HasReasonWarnings() && this.renderReasonWarnings()}
                  </div>
                )}
                <div className={`${formStyles.FormFooter} k-action-buttons`}>
                  <Button onClick={this.Close}>Cancel</Button>
                  {this.props.newDispatch && (
                    <>
                      <Button
                        onClick={this.AddDispatchOnly}
                        themeColor="primary"
                        disabled={
                          !this.DispatchInfo.workOrderId ||
                          !this.state.selectedCrewLead
                        }
                      >
                        Create
                      </Button>
                      <Button
                        onClick={this.AddAndPublishDispatch}
                        themeColor="primary"
                        disabled={
                          !this.DispatchInfo.workOrderId ||
                          !this.state.selectedCrewLead
                        }
                      >
                        Create and Publish
                      </Button>
                    </>
                  )}

                  {!this.props.newDispatch && (
                    <>
                      <Button
                        onClick={this.AddDispatchOnly}
                        themeColor="primary"
                        disabled={!this.state.selectedCrewLead}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={this.AddAndPublishDispatch}
                        themeColor="primary"
                        disabled={
                          !this.DispatchInfo.canPublish ||
                          !this.state.selectedCrewLead
                        }
                      >
                        Save and Publish
                      </Button>
                    </>
                  )}
                </div>
              </Fragment>
            </>
          )}
        </div>
      </FXCard>
    );
  }

  renderTitle = () => {
    if (this.props.newDispatch) return "New Dispatch";
    return (
      <>
        <OpenCardIconLink onClick={this.OpenDispatch} title="Open Dispatch" />
        <span className={formStyles.HeaderTitle}>Edit Dispatch</span>
      </>
    );
  };

  renderDateTimeInput = (props: any) => (
    <ClearableDateInput defaultProps={props} clear={this.OnChangeTime} />
  );

  renderEmployeeWarning = (employeeId: number) => {
    const data = this.ScheduledEmployees[employeeId];
    return (
      <div className={formStyles.WarningInfoRow} key={employeeId}>
        {data.Name}&nbsp;is already scheduled for another WOs for&nbsp;
        {data.Dates.join(", ")}
      </div>
    );
  };

  renderReasonWarnings = () => {
    let ids = this.DispatchInfo.CrewIDs
      ? this.DispatchInfo.CrewIDs.split(",")
      : [];
    if (this.DispatchInfo.crewLeadId)
      ids.push(this.DispatchInfo.crewLeadId.toString());
    return (
      <Card className={`${formStyles.WarningInfoCard} k-card-warning`}>
        {ids.map((id) => this.renderEmployeeReasonWarning(+id))}
      </Card>
    );
  };

  renderEmployeeReasonWarning = (employeeId: number) => {
    let data = this.ScheduledEmployeesReasons[employeeId];
    if (!data) return null;
    return data.map((item) => (
      <div className={formStyles.WarningInfoRow} key={item.Date + employeeId}>
        {item.Name}&nbsp;has Time Card for&nbsp;{item.Date}
        &nbsp;with reason&nbsp;{item.Reason}
      </div>
    ));
  };

  HasReasonWarnings = () => {
    let ids = this.DispatchInfo.CrewIDs
      ? this.DispatchInfo.CrewIDs.split(",")
      : [];
    if (this.DispatchInfo.crewLeadId)
      ids.push(this.DispatchInfo.crewLeadId.toString());
    let hasWarnings =
      ids.length &&
      ids.findIndex((id) => !!this.ScheduledEmployeesReasons[+id]) > -1;
    return !!hasWarnings;
  };

  ChangeDispatchCard = (e: any, dispatchid: number) => {
    CardManagement.OpenDispatchCard({
      newDispatch: false,
      dsId: dispatchid,
      afterSave: this.props.afterSave,
    });
    this.Close();
  };

  HasWarnings = () => {
    return (
      !!this.ScheduledWODates.length ||
      this.HasCrewLeadWarning() ||
      this.HasCrewWarnings() ||
      this.HasReasonWarnings()
    );
  };

  HasCrewLeadWarning = () => {
    return (
      !!this.DispatchInfo.crewLeadId &&
      !!this.ScheduledEmployees[this.DispatchInfo.crewLeadId]
    );
  };

  HasCrewWarnings = () => {
    return (
      !!this.DispatchInfo.CrewIDs?.length &&
      this.DispatchInfo.CrewIDs.split(",").findIndex(
        (id) => !!this.ScheduledEmployees[+id],
      ) > -1
    );
  };

  OnChangeTimeType = (e: any) => {
    let field: "ArriveType" | "DepartType" = e.target.props.id;
    this.DispatchInfo[field] = e.value;
  };

  OnChangeTime = (e: any, isClear?: boolean) => {
    let field: "Arrive" | "Depart" | "ClockInAfter" | "ClockOutBefore" =
      e.target.props.id;
    this.DispatchInfo[field] = isClear ? undefined : e.value;
    if (isClear) this.setState({ remountKey: +new Date() });
  };

  OnChangeComments = (e: any) => {
    this.DispatchInfo.comments = e.value;
  };

  OnChangeMultiselect = (selectedCrewMembers: IResourceItem[]) => {
    this.DispatchInfo.CrewIDs = selectedCrewMembers.map((x) => x.id).join(",");
    this.setState({ selectedCrewMembers });
  };

  OnChangeCheckbox = (e: any) => {
    let field:
      | "WorkDaysOnly"
      | "prevailingWage"
      | "remoteHotel"
      | "allowOvernight" = e.target.element.id;
    this.DispatchInfo[field] = e.value;
  };

  OnDateChange = async (e: any) => {
    let field: "DateTo" | "DateObject" = e.target.props.id;
    this.DispatchInfo[field] = e.value;
    try {
      let result = await this.LoadWarningsInfo();
      if (result) {
        this.CheckWarnings(result);
      }
    } catch (e: any) {
      showSomeError(e);
    }
  };

  OnChangeWO = async (value: IWOItem | null) => {
    this.setState({ loading: true });
    this.DispatchInfo.workOrderId = value ? +value.Id : undefined;
    if (value) {
      await this.GetSetAvailableResources();
      if (this.DSId) this.DSId = null;
      this.DispatchInfo.allowOvernight = !!value.AllowOvernight;
      this.DispatchInfo.prevailingWage = !!value.PrevailingWage;
      this.DispatchInfo.workTypeTitle = value.WorkTypeTitle;
    } else {
      this.ScheduledEmployees = [];
      this.ScheduledWODates = [];
    }
    this.setState({ selectedWO: value, loading: false });
  };

  OnChangeCrew = (
    selectedCrewContainer: SQL_DB_Calendar_Resources_Response_CrewContainers | null,
  ) => {
    const { crewLeadId, crewMembers, crewContainerId } =
      selectedCrewContainer || {};
    const selectedCrewLead = crewLeadId
      ? this.state.resources.find((x) => x.id === crewLeadId)
      : undefined;
    const selectedCrewMembers = crewMembers?.length
      ? this.state.resources.filter(
          (x) => crewMembers.findIndex((y) => y.crewMemberId === x.id) > -1,
        )
      : [];
    this.DispatchInfo.CrewContainerId = crewContainerId || null;

    this.OnChangeMultiselect(selectedCrewMembers);
    this.OnChangeCrewLead(selectedCrewLead || null);
    this.setState({ selectedCrewContainer: selectedCrewContainer });
  };

  OnChangeCrewLead = (value: IResourceItem | null) => {
    this.DispatchInfo.crewLeadId = value ? +value.id : undefined;
    this.setState({ selectedCrewLead: value });
  };

  GetSetAvailableResources = async () => {
    const woId = this.DispatchInfo.workOrderId;
    if (!woId) return;
    try {
      const woCertificates = await this.LoadCertificates(woId);
      const availableResourcesResult = await this.LoadAvailableResources(woId);
      const warningsInfo = await this.LoadWarningsInfo();

      const availableResources = availableResourcesResult[0].resources.sort(
        (a, b) => sortLetterNumberSpecialCharOrder(a.name, b.name),
      );
      const crews = availableResourcesResult[0].crewContainers;
      const resources = availableResources.map((x) => {
        const resource: IResourceItem = {
          ...x,
        };
        woCertificates.forEach((y) => {
          resource[y.certificateId] =
            x.employeeCertificates.findIndex(
              (z) => z.certificateId === y.certificateId,
            ) > -1;
        });
        return resource;
      });

      const selectedCrewContainer =
        crews.find(
          (x) => x.crewContainerId === this.DispatchInfo.CrewContainerId,
        ) || null;

      let selectedCrewLead: IResourceItem | null = null;
      if (this.InitialCrewLead) {
        const crewLead = resources.find(
          (x) => x.id === this.InitialCrewLead!.id,
        );
        selectedCrewLead = crewLead
          ? { ...crewLead, isActive: true }
          : {
              ...this.InitialCrewLead,
              isActive: false,
              isLimited: false,
              employeeCertificates: [],
            };
      }

      const crewIds = [];
      const selectedCrewMembers: IResourceItem[] = [];

      for (const { crewMemberId, crewMemberName } of this.InitialCrew) {
        const crewMember = resources.find((x) => x.id === crewMemberId);
        selectedCrewMembers.push({
          ...(crewMember || {
            id: crewMemberId,
            name: crewMemberName,
            isLimited: false,
            employeeCertificates: [],
          }),
          isActive: !!crewMember,
        });
        crewIds.push(crewMemberId);
      }

      this.DispatchInfo.CrewIDs = crewIds.join(",");
      this.DispatchInfo.crewLeadId = selectedCrewLead
        ? +selectedCrewLead.id
        : undefined;
      if (warningsInfo) this.CheckWarnings(warningsInfo);

      this.setState({
        resources,
        selectedCrewLead: selectedCrewLead || null,
        selectedCrewMembers,
        crews,
        woCertificates,
        selectedCrewContainer,
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
    }
  };

  LoadAvailableWorkOrders = () => {
    return this.GetSQLData({
      spName: "AddDispatchAction_WorkOrders",
      params: { bpId: this.props.buildPlanId },
    });
  };

  LoadAvailableResources = (woId: number) => {
    return api.sql.dbCalendarResources({ woId });
  };

  LoadDispatchInfo = () => {
    if (!this.props.dsId) return;
    return api.sql.dbCalendarDispatchInfo({ dsId: this.props.dsId });
  };

  LoadCertificates = (woId: number) => {
    return api.sql.dbCalendarWoCertificateInfo({ woId });
  };

  AddDispatchOnly = () => {
    this.AddDispatch(false);
  };

  AddAndPublishDispatch = () => {
    this.AddDispatch(true);
  };

  LoadWarningsInfo = () => {
    if (!this.DispatchInfo.workOrderId) return;
    let dateFrom = this.DispatchInfo.DateObject
      ? moment(this.DispatchInfo.DateObject).format().slice(0, 19)
      : null;
    let dateTo = this.DispatchInfo.DateTo
      ? moment(this.DispatchInfo.DateTo).format().slice(0, 19)
      : null;
    return this.GetSQLData({
      spName: "AddDispatchAction_WarningsInfo",
      params: {
        woId: this.DispatchInfo.workOrderId,
        dateFrom,
        dateTo: dateTo || dateFrom,
      },
    });
  };

  CheckWarnings = async (
    result: [
      Array<{ Date: string; DispatchId: number }>,
      Array<{ EmployeeId: number; EmployeeName: string; Date: string }>,
      Array<{
        EmployeeId: number;
        EmployeeName: string;
        Date: string;
        ReasonName: string;
      }>,
    ],
  ) => {
    this.ScheduledWODates = result[0].map((item) => ({
      Date: moment(item.Date).format("L"),
      DispatchId: item.DispatchId,
    }));
    this.ScheduledEmployees = {};
    const list = result[1];
    for (const item of list) {
      const { EmployeeId: id, EmployeeName: Name, Date } = item;
      if (!this.ScheduledEmployees[id]) {
        this.ScheduledEmployees[id] = {
          Name,
          Dates: [],
        };
      }
      this.ScheduledEmployees[id].Dates.push(moment(Date).format("L"));
    }

    this.ScheduledEmployeesReasons = {};
    const list2 = result[2];
    for (const item of list2) {
      const {
        EmployeeId: id,
        EmployeeName: Name,
        Date,
        ReasonName: Reason,
      } = item;
      if (!this.ScheduledEmployeesReasons[id]) {
        this.ScheduledEmployeesReasons[id] = [];
      }
      this.ScheduledEmployeesReasons[id].push({
        Name,
        Date: moment(Date).format("L"),
        Reason,
      });
    }
    this.forceUpdate();
  };

  AddDispatch = async (Publish: boolean) => {
    const {
      workOrderId: WOId,
      CrewContainerId,
      crewLeadId,
      DateObject,
      DateTo,
      WorkDaysOnly,
      ClockOutBefore,
      ClockInAfter,
      DepartType,
      Depart,
      ArriveType,
      Arrive,
      allowOvernight,
      comments,
      remoteHotel,
      prevailingWage,
    } = this.DispatchInfo;
    if (!WOId || !crewLeadId || !DateObject) return;
    try {
      this.setState({ loading: true });
      let timeFormat = "LT";
      const params: simpleObject = {
        WOId,
        CrewContainerID: CrewContainerId || null,
        CrewLeadID: crewLeadId || null,
        CrewIDs: this.DispatchInfo.CrewIDs || null,
        DateFrom: DateObject ? moment(DateObject).format("DD.MM.YYYY") : null,
        DateTo: DateTo ? moment(DateTo).format("DD.MM.YYYY") : null,
        WorkDaysOnly,
        Publish,
        AllowOvernight: allowOvernight,
        RemoteHotel: remoteHotel,
        PrevailingWage: prevailingWage,
        Comments: comments,
        ClockInAfter: ClockInAfter
          ? moment(ClockInAfter).format(timeFormat)
          : null,
        ClockOutBefore: ClockOutBefore
          ? moment(ClockOutBefore).format(timeFormat)
          : null,
        ["Depart" + DepartType]: Depart
          ? moment(Depart).format(timeFormat)
          : null,
        ["Arrive" + ArriveType]: Arrive
          ? moment(Arrive).format(timeFormat)
          : null,
      };

      await RunScriptAsync("Calendar_AddDispatch", params);
      if (this.props.afterSave) this.props.afterSave();
      if (this.props.finally) this.props.finally();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  Close = () => {
    if (this.props.finally) this.props.finally();
  };

  OpenDispatch = () => {
    if (this.props.dsId) OpenRecord("FSMDispatchSchedule", this.props.dsId);
  };
}

export default DispatchCard;
