import {Checkbox} from "@progress/kendo-react-inputs";
import {stopPropagationWrapper} from "../../helpers/Hooks";

const TKListCheckbox = ({
                            tcId,
                            isChecked,
                            handleChange,
                        }: {
    tcId: number;
    isChecked: boolean;
    handleChange: (tcId: number) => void;
}) => {
    return (
        <Checkbox
            id={tcId + "_tc"}
            label={" "}
            size={"large"}
            checked={isChecked}
            onChange={() => handleChange(tcId)}
            onClick={stopPropagationWrapper(() => {
            })}
        />
    );
};

export default TKListCheckbox;
