import React, { DispatchWithoutAction, memo, useCallback } from "react";
import { OpenRecord } from "../../helpers/runscripts";
import CardManagement from "../../Components/Cards/CardManagement";
import { IsDesktopIntegrationOn } from "../../helpers/helpers";
import styles from "./calendar.module.scss";
import { clsx } from "clsx";

type Props = {
  children: React.ReactNode;
  refName: string;
  recordId: number;
  onFinish?: DispatchWithoutAction;
};

const RecordLinkCmp = ({ refName, onFinish, recordId, children }: Props) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (refName !== "FSMBuildPlans" && IsDesktopIntegrationOn()) {
        OpenRecord("FSMWorkOrders", recordId);
      } else if (refName === "FSMBuildPlans") {
        CardManagement.OpenBPCard(recordId, onFinish);
      }
    },
    [onFinish, recordId, refName],
  );

  return (
    <span
      className={clsx(
        (refName === "FSMBuildPlans" || IsDesktopIntegrationOn()) &&
          styles.Recordlink,
      )}
      onClick={handleClick}
    >
      {children}
    </span>
  );
};

export const RecordLink = memo(RecordLinkCmp);
