import React, {useCallback, useEffect, useRef} from "react";
import DashboardGrid from "../Dashboard/Grid";
import {IColumnSetting} from "../Dashboard/interfaces";
import CardManagement from "../Cards/CardManagement";
import styles from "./tc.module.scss";
import api from "../../core/api/api";
import {SQL_TK_GetRequestAllocation_Response, SQL_TK_GetRequestData_Response,} from "../../core/api/generated/conterra";
import {TRenderToolbar} from "../TabsNew/interfaces";
import {useLatestNotEmptyValue} from "../../core/tools/Hooks";

interface IProps {
    tcId?: number;
    isActive: boolean;
    pageId?: string;
    renderToolbar?: TRenderToolbar;
    refreshTC: () => void;
    reloadDependency?: unknown;
}

const TCRequests = (props: IProps) => {
    const {tcId, isActive, reloadDependency, refreshTC, renderToolbar} = props;
    const gridRefreshRef = useRef<() => void>(() => {
    });
    const currentTcId = useLatestNotEmptyValue(tcId);
    const allocationDataRef = useRef<SQL_TK_GetRequestAllocation_Response[]>();
    const loadRequests = useCallback(
        () =>
            api.sql.tkGetRequestData({
                tcId: currentTcId,
            }),
        [currentTcId]
    );
    const getRequestsAllocation = useCallback(
        () =>
            api.sql.tkGetRequestAllocation({
                tcId: currentTcId,
            }),
        [currentTcId]
    );
    const lastAppliedDependency = useRef<unknown>(reloadDependency);
    useEffect(() => {
        if (isActive && lastAppliedDependency.current !== reloadDependency) {
            lastAppliedDependency.current = reloadDependency;
            gridRefreshRef.current?.();
            allocationDataRef.current = undefined;
        }
    }, [reloadDependency, isActive]);

    const columnsRef = useRef<IColumnSetting[]>([
        {
            field: "createdLocal",
            title: "Created (Local)",
            type: "date",
            gridWidth: 130,
            format: {
                type: "datetime",
            },
        },
        {
            field: "requestType",
            title: "Type",
            type: "string",
            minWidth: 150,
            format: {
                type: "string",
                fieldId: "requestType",
            },
        },
        {
            field: "solicitation",
            title: "Solicitation",
            type: "string",
            minWidth: 150,
        },
        {
            field: "description",
            title: "Description",
            type: "string",
            minWidth: 150,
        },
        {
            field: "reasonName",
            title: "Reason",
            type: "string",
            gridWidth: 100,
            format: {
                type: "string",
                fieldId: "reasonId",
            },
        },
        {
            field: "payrollItemName",
            title: "Payroll Item",
            type: "string",
            gridWidth: 180,
            format: {
                type: "string",
                fieldId: "payrollItemId",
            },
        },
        {
            field: "qty",
            title: "QTY",
            type: "number",
            gridWidth: 100,
            format: {
                type: "integer",
            },
        },
        {
            field: "workOrderName",
            title: "Work Order",
            type: "string",
            minWidth: 180,
            format: {
                type: "string",
                fieldId: "workOrderId",
            },
        },
        {
            field: "reviewStarted",
            title: "Review Started",
            type: "date",
            gridWidth: 130,
            format: {
                type: "datetime",
            },
        },
        {
            field: "reviewStage",
            title: "Stage",
            type: "string",
            gridWidth: 100,
            format: {
                type: "string",
                fieldId: "stage",
            },
        },
        {
            field: "rejectionComment",
            title: "Rejection Comment",
            type: "string",
            minWidth: 100,
        },
    ]);

    const onSelectRow = useCallback(
        async (dataItem: SQL_TK_GetRequestData_Response | null) => {
            if (!dataItem) return;
            let allocation: SQL_TK_GetRequestAllocation_Response[] = [];
            if (dataItem.requestType === "Time Adjustment") {
                if (!allocationDataRef.current) {
                    allocationDataRef.current = await getRequestsAllocation();
                }
                allocation = allocationDataRef.current!.filter(
                    (x) => x.requestId === dataItem.requestId
                );
            }
            CardManagement.OpenTKRequestsActionsCard(dataItem, allocation, refreshTC);
        },
        []
    );
    const initRefresh = useCallback((refresh: () => void) => {
        gridRefreshRef.current = refresh;
    }, []);
    const getRowKey = useCallback(
        ({requestId}: SQL_TK_GetRequestData_Response) => requestId + "",
        []
    );
    const getRowClass = useCallback(
        ({reviewStage}: SQL_TK_GetRequestData_Response) =>
            reviewStage === "Pending" ||
            reviewStage === "Solicited" ||
            reviewStage === "Approved"
                ? styles.ActiveRequestRow
                : "",
        []
    );

    if (!currentTcId) return null;
    return (
        <DashboardGrid
                isActive={isActive}
                columnsSettings={columnsRef.current}
                getData={loadRequests}
                getRowKey={getRowKey}
                initRefresh={initRefresh}
                excelFileName="Requests.xlsx"
                columnMenu={{
                    filters: true,
                    columns: false,
                }}
                toolbar={{renderToolbar}}
                selectSettings={{
                    pageId: "TKDashboard",
                    onSelectRow: onSelectRow,
                }}
                getRowClass={getRowClass}
            />
    );
};

export default TCRequests;
