import BaseComponent from "../BaseComponent";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import NoSelectedBP from "../Common/NoSelectedBP";
import Loader from "../Common/Loader";
import { simpleObject } from "../../helpers/interfaces";
import commonStyles from "../../assets/styles/common.module.scss";
import { showSomeError } from "../../helpers/errorHelpers";

interface ISeries {
  name: string;
  color: string;
  data: Array<any>;
  zIndex?: number;
  visibleInLegend: boolean;
}

interface props {
  buildPlanId: number;
  autoResize?: boolean;
}

interface state {
  loading: boolean;
  chartDates: Array<any>;
  series: Array<ISeries>;
  remountChartKey: number;
}

class BPHistoryChart extends BaseComponent<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      chartDates: [],
      series: [],
      remountChartKey: +new Date(),
    };
  }

  componentDidMount() {
    if (this.props.buildPlanId) this.LoadData();
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.buildPlanId !== this.props.buildPlanId) {
      this.LoadData();
    }
  }

  render() {
    if (!this.props.buildPlanId) return <NoSelectedBP />;
    if (this.state.loading) return <Loader />;
    return (
      <>
        <Chart
          style={{
            height: this.props.autoResize ? "100%" : 350,
            marginBottom: "15px",
          }}
        >
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={this.state.chartDates}
              labels={{ step: 3 }}
              majorGridLines={{ visible: false }}
            />
          </ChartCategoryAxis>
          <ChartSeries>
            {this.state.series.map((item, idx) => (
              <ChartSeriesItem
                key={item.color}
                type="line"
                name={item.name}
                data={item.data}
                color={item.color}
                missingValues="gap"
                visibleInLegend={item.visibleInLegend}
                markers={
                  item.name === "Budget"
                    ? {
                        visible: false,
                        size: 0,
                      }
                    : undefined
                }
              >
                <ChartSeriesItemTooltip
                  visible={true}
                  render={(context: any) =>
                    context.point.category +
                    ": " +
                    context.point.value.toFixed(2)
                  }
                />
                <ChartSeriesItemTooltip
                  visible={true}
                  render={(props) => {
                    let point = props.point;
                    return (
                      <div className={commonStyles.TextCenter}>
                        {point.series.name}: {point.dataItem.toFixed(2)}
                        <br />
                        {point.category}
                      </div>
                    );
                  }}
                />
              </ChartSeriesItem>
            ))}
          </ChartSeries>
        </Chart>
      </>
    );
  }

  async LoadData() {
    this.setState({ loading: true });
    try {
      let params = { BuildPlanId: this.props.buildPlanId };
      let result: any = await this.GetSQLData({
        spName: "DB_BPHistory",
        params,
      });
      let history = result[0] || [];

      var minIndex = history.length > 21 ? history.length - 21 : 0;
      let actualRed: Array<any> = [];
      let actualGreen: Array<any> = [];
      let budgetHours: Array<any> = [];
      let estimateHours: Array<any> = [];
      let chartDates: Array<any> = [];
      let lastActual: string = "";

      history.forEach(function (data: simpleObject, i: number) {
        if (i < minIndex) return;
        var prevMarker = history[i - 1];
        data.date = moment(data.date).format("MM/DD");
        if (data.actualHours > data.estimateHours) {
          if (
            lastActual &&
            lastActual !== "red" &&
            prevMarker.actualHours !== null
          ) {
            actualGreen.push(data.actualHours);
          } else {
            actualGreen.push(null);
          }
          actualRed.push(data.actualHours);
          lastActual = "red";
        } else {
          if (
            lastActual &&
            lastActual !== "green" &&
            prevMarker.actualHours !== null
          ) {
            actualRed.push(data.actualHours);
          } else {
            actualRed.push(null);
          }
          actualGreen.push(data.actualHours);
          lastActual = "green";
        }
        budgetHours.push(data.budgetHours);
        estimateHours.push(data.estimateHours);
        chartDates.push(data.date);
      });

      let series: Array<ISeries> = [
        {
          name: "Actual",
          color: "red",
          data: actualRed,
          visibleInLegend: lastActual === "red",
          /* markers: {
                      visual: actualMarkersVisual
                    },
                    highlight: {
                      visual: actualHighlightVisual
                    },
                    tooltip: actualTooltip */
        },
        {
          name: "Actual",
          color: "#6ab187",
          data: actualGreen,
          visibleInLegend: lastActual === "green",
          /* markers: {
                      visual: actualMarkersVisual
                    },
                    highlight: {
                      visual: actualHighlightVisual
                    },
                    tooltip: actualTooltip */
        },
        {
          name: "Estimate",
          color: "#00baef",
          data: estimateHours,
          visibleInLegend: true,
        },
        {
          name: "Budget",
          color: "#006494",
          data: budgetHours,
          visibleInLegend: true,
        },
      ];
      this.setState({ series, chartDates });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  OnCardResize = () => {
    this.setState({ remountChartKey: +new Date() });
  };
}

export default BPHistoryChart;
