import styles from "./adjustmentsEdit.module.scss";
import {
  costTypeCode,
  IAdjustedTimeLineItem,
  IAdjustmentSettings,
} from "../interfaces";
import { Button } from "@progress/kendo-react-buttons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment/moment";
import { costTypeToColor } from "../helpers";
import { adjustmentsAction, EMPTY_COST_TYPE_CODE, FORMAT } from "./helpers";
import {
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo,
  SQL_TK_GetAvailableWOs_Response,
  SQL_TK_GetDataForAdjustment_Response,
  SQL_TK_GetDataForAdjustment_Response_CostTypes,
} from "../../../../core/api/generated/conterra";
import AdjustmentEditRow from "./AdjustmentEditRow";
import {
  TimePicker,
  TimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Offset, Popup, PopupProps } from "@progress/kendo-react-popup";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { getActualDuration } from "../../../../helpers/helpers";

const EditAdjustmentsList = (props: {
  tcId: number;
  tcInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo;
  dataForAdjustment: SQL_TK_GetDataForAdjustment_Response;
  workOrders: SQL_TK_GetAvailableWOs_Response[];
  settings: IAdjustmentSettings;
  SetData: (data: IAdjustedTimeLineItem[], action?: adjustmentsAction) => void;
  onChangeTime: (data: IAdjustedTimeLineItem[], isValidTime: boolean) => void;
  refreshWorkOrders: () => void;

  adjustmentRowRemountKey: number;
  timeRemountKey: number;
  adjustments: IAdjustedTimeLineItem[];
}) => {
  const {
    adjustments,
    tcId,
    workOrders,
    tcInfo,
    settings,
    dataForAdjustment: { states, costTypes, nonWorkWOs, taxCodes },
  } = props;
  const [costTypeMenuState, setCostTypeMenuState] = useState<{
    offset?: Offset;
    adjustment?: IAdjustedTimeLineItem;
  } | null>(null);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", CloseCostTypeSelect);
    };
  }, []);

  const Delete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { sortnumber } = e.currentTarget.dataset;
    const result = adjustments.filter(
      (item) => item.sortNumber !== +sortnumber!,
    );
    props.SetData(result, "delete");
  };

  const Add = (e: any) => {
    const { time, number, side } = e.currentTarget.dataset;
    let Start = moment(time);
    let Finish = moment(time);
    const result = [...adjustments];
    result.push({
      taxCode: null,

      actualDuration: 0,
      approvedDuration: 0,
      costTypeCode: EMPTY_COST_TYPE_CODE,
      costTypeName: "",
      finish: Finish.format(FORMAT),
      rowNumber: 1, // ??
      start: Start.format(FORMAT),

      // frontend fields
      approvedDurationString: "00:00",
      actualDurationString: "00:00",
      startFormatted: Start.format("LT"),
      finishFormatted: Finish.format("LT"),
      manualAllocation: [],
      costTypeColor: "",
      color: costTypeToColor[EMPTY_COST_TYPE_CODE],
      stateCode: null,
      sortNumber: side === "before" ? +number - 0.1 : +number + 0.1,
      isNewTe: true,
    });
    result.sort((a, b) => a.sortNumber - b.sortNumber);

    props.SetData(result, "add");
  };

  const Split = (e: any) => {
    const { time, finish } = e.currentTarget.dataset;
    const itemSplit = adjustments.find(
      (item) => item.start === time && item.finish === finish,
    );
    itemSplit!.start = moment(time).add(1, "m").format(FORMAT);
    props.SetData([...adjustments], "split");
  };

  const OnSelectCostType = (event: MenuSelectEvent) => {
    const { adjustment } = costTypeMenuState || {};
    if (!adjustment) return;

    const costType = event.item
      .data as SQL_TK_GetDataForAdjustment_Response_CostTypes;
    adjustment.costTypeCode = costType.code as costTypeCode;
    adjustment.costTypeName = costType.name;
    if (adjustment.costTypeCode === "LUNCH") {
      adjustment.approvedDuration = 0;
      adjustment.approvedDurationString = "00:00";
    }
    adjustment.costTypeColor = costType.color;
    const clearAllocation =
      !!adjustment.isStaticAllocation !== costType.isNonWork;
    if (clearAllocation) adjustment.manualAllocation = [];
    adjustment.isStaticAllocation = costType.isNonWork;
    if (adjustment.isStaticAllocation && nonWorkWOs.length) {
      adjustment.manualAllocation = nonWorkWOs.map((wo) => ({
        percentage: 100 / nonWorkWOs.length,
        rowNumber: adjustment!.rowNumber,
        workOrderCode: wo.staticWOCode,
        workOrderId: wo.staticWOId,
        workOrderName: wo.staticWOName,
        workOrderNumber: "",
      }));
    }
    props.SetData([...adjustments]);
  };

  const CostTypesSelect = (e: any, data: IAdjustedTimeLineItem) => {
    e.stopPropagation();
    document.addEventListener("click", CloseCostTypeSelect);
    let position = e.target.getBoundingClientRect();
    setCostTypeMenuState({
      offset: {
        left: position.left + 20,
        top: position.bottom + 1,
      },
      adjustment: data,
    });
  };

  const CloseCostTypeSelect = () => {
    document.removeEventListener("click", CloseCostTypeSelect);
    setCostTypeMenuState(null);
  };

  const OnChangeApprovedHours = (
    hours: number,
    valueString: string,
    oldData: IAdjustedTimeLineItem,
  ) => {
    oldData.approvedDuration = hours;
    oldData.approvedDurationString = valueString;
    if (hours) {
      oldData.actualDuration = hours;
      oldData.actualDurationString = valueString;
    }
    props.SetData([...adjustments]);
  };

  const UpdateStateCode = (
    oldData: IAdjustedTimeLineItem,
    newStateCode: string,
  ) => {
    oldData.stateCode = newStateCode;
    props.SetData([...adjustments]);
  };

  const UpdateTaxCode = (
    oldData: IAdjustedTimeLineItem,
    newTaxCode: string | null,
  ) => {
    oldData.taxCode = newTaxCode;
    props.SetData([...adjustments]);
  };

  const UpdateAllocation = (
    oldData: IAdjustedTimeLineItem,
    allocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
    stateCode: string | null,
  ) => {
    oldData.manualAllocation = allocation;
    oldData.stateCode = stateCode;
    props.SetData([...adjustments]);
  };

  const OnChangeTime = useCallback(
    (event: {
      value: Date | null;
      minValue: Moment;
      maxValue: Moment;
      type: "start" | "finish";
      i: number;
      isMidnight: boolean;
    }) => {
      const { value, minValue, maxValue, isMidnight, i, type } = event;
      const valueMoment = moment(value);
      let isValidTime = valueMoment.isValid();
      let result: IAdjustedTimeLineItem[] = [];

      if (value && isValidTime && !isMidnight) {
        const date = moment(tcInfo.date);
        valueMoment.set("year", date.get("year"));
        valueMoment.set("months", date.get("month"));
        valueMoment.set("date", date.get("date"));
      }

      if (isValidTime) {
        const item = adjustments[i];
        if (type === "start") {
          const oldValueString = item.start;
          item.start = valueMoment.format(FORMAT);
          item.startFormatted = valueMoment.format("LT");
          const prevItem = adjustments[i - 1];
          if (prevItem && prevItem.finish === oldValueString) {
            prevItem.finish = item.start;
            prevItem.finishFormatted = item.startFormatted;
            let [hours, durationString] = getActualDuration(
              prevItem.start,
              prevItem.finish,
            );
            if (prevItem.costTypeCode !== "LUNCH") {
              if (!!prevItem.approvedDuration) {
                prevItem.approvedDuration = hours;
                prevItem.approvedDurationString = durationString;
              }
            }
            prevItem.actualDuration = hours;
            prevItem.actualDurationString = durationString;
          }
        } else if (type === "finish") {
          item.finish = valueMoment.format(FORMAT);
          item.finishFormatted = valueMoment.format("LT");
        }
        const [hours, durationString] = getActualDuration(
          item.start,
          item.finish,
        );
        if (item.costTypeCode !== "LUNCH" && !!item.approvedDuration) {
          item.approvedDuration = hours;
          item.approvedDurationString = durationString;
        }
        item.actualDuration = hours;
        item.actualDurationString = durationString;
        if (!valueMoment.isBefore(minValue) && !valueMoment.isAfter(maxValue)) {
          isValidTime = true;
          result = [...adjustments];
        } else {
          isValidTime = false;
        }
      }
      props.onChangeTime(result, isValidTime);
    },
    [props.onChangeTime],
  );

  const renderTime = (
    i: number,
    type: "start" | "finish",
    valueString: string,
    prevDateString?: string,
    nextDateString?: string,
    PrevSplitTimeLine?: boolean,
  ) => {
    const valueMoment: Moment = moment(valueString);
    const minValue: Moment = prevDateString
      ? moment(prevDateString)
      : valueMoment.clone().set("h", 0).set("m", 0);
    const maxValue: Moment = nextDateString
      ? moment(nextDateString)
      : moment(tcInfo.date).set("h", 23).set("m", 59);
    const midnight = moment(tcInfo.date).add(1, "day").set("h", 0).set("m", 0);
    let isValidTimePickerValue = undefined;
    if (!nextDateString) {
      isValidTimePickerValue =
        !!valueMoment &&
        valueMoment.isSameOrBefore(midnight) &&
        valueMoment.isAfter(minValue);
    }
    const diff = maxValue.diff(valueMoment, "m");
    const showSplit =
      type !== "finish" && i > 0 && !PrevSplitTimeLine && diff > 2;
    let ref: any;
    return (
      <small className={styles.TETimeEdit} key={i + type + type}>
        {showSplit && (
          <Button
            className={styles.TEEditSplit}
            iconClass="mdi mdi-arrow-split-horizontal"
            fillMode="flat"
            onClick={Split}
            title="Split TimeLine"
            data-time={valueString}
            data-finish={nextDateString}
          ></Button>
        )}
        <TimePicker
          ref={(r) => {
            ref = r;
          }}
          key={i + props.timeRemountKey}
          value={valueMoment.toDate()}
          valid={isValidTimePickerValue}
          width="100%"
          nowButton={false}
          min={minValue.toDate()}
          max={maxValue.toDate()}
          formatPlaceholder={{ hour: "hh", minute: "mm" }}
          required={true}
          className={styles.KendoTimePicker}
          onChange={(e: TimePickerChangeEvent) => {
            OnChangeTime({
              value: e.value,
              minValue,
              maxValue,
              type,
              i,
              isMidnight: false,
            });
          }}
          smoothScroll={false}
          popup={
            !nextDateString
              ? (kendoProps: PopupProps) => (
                  <CustomPopup
                    kendoProps={kendoProps}
                    onMidnight={() => {
                      OnChangeTime({
                        value: midnight.toDate(),
                        minValue,
                        maxValue: midnight,
                        type,
                        i,
                        isMidnight: true,
                      });
                      ref?.setShow?.(false);
                    }}
                  />
                )
              : undefined
          }
        />
      </small>
    );
  };

  return (
    <>
      <div className={styles.ListBox}>
        {adjustments.map((data, i: number, arr) => {
          const prev = arr[i - 1];
          const next = arr[i + 1];
          const isSplitTimeLine = !!next && next.start !== data.finish;
          const isPrevSplitTimeLine = !!prev && prev.finish !== data.start;
          const renderFinishTime = isSplitTimeLine || i === arr.length - 1;

          return (
            <div className={`${styles.EditRow}`} key={i}>
              <Button
                className={styles.TEEditNewTE}
                title="Add Time Entry"
                onClick={Add}
                data-time={data.start}
                data-number={data.sortNumber}
                data-side={"before"}
                icon="plus"
                fillMode="flat"
              ></Button>
              {renderTime(
                i,
                "start",
                data.start,
                isPrevSplitTimeLine ? prev?.finish : prev?.start,
                data.finish,
                isPrevSplitTimeLine,
              )}
              <Button
                className={styles.DeleteBtn}
                title="Delete Time Entry"
                onClick={Delete}
                data-sortnumber={data.sortNumber}
                icon="trash"
                fillMode="flat"
              ></Button>
              <AdjustmentEditRow
                key={data.sortNumber! + props.adjustmentRowRemountKey}
                tcId={tcId}
                tcInfo={tcInfo}
                index={i}
                data={data}
                selectCostType={CostTypesSelect}
                updateAllocation={UpdateAllocation}
                updateApprovedHours={OnChangeApprovedHours}
                availableStates={states}
                availableTaxCodes={taxCodes}
                updateTaxCode={UpdateTaxCode}
                updateStateCode={UpdateStateCode}
                workOrders={workOrders}
                settings={settings}
                onCloseAllocationCard={props.refreshWorkOrders}
                isFinish={renderFinishTime}
              />
              <Button
                className={`${styles.TEEditNewTE} ${styles.TEEditNewTEFinish}`}
                title="Add Time Entry"
                onClick={Add}
                data-time={data.finish}
                data-number={data.sortNumber}
                data-side={"after"}
                icon="plus"
                fillMode="flat"
              ></Button>
              {renderFinishTime &&
                renderTime(i, "finish", data.finish, data.start, next?.start)}
              {isSplitTimeLine && <div style={{ height: 50 }}></div>}
            </div>
          );
        })}

        <div style={{ clear: "both" }}></div>
      </div>
      <CostTypeContextMenu
        menuState={costTypeMenuState}
        costTypes={costTypes}
        onSelect={OnSelectCostType}
      />
    </>
  );
};

const CostTypeContextMenu = (props: {
  menuState: {
    offset?: Offset | undefined;
    adjustment?: IAdjustedTimeLineItem | undefined;
  } | null;
  costTypes: SQL_TK_GetDataForAdjustment_Response_CostTypes[];
  onSelect: (event: MenuSelectEvent) => void;
}) => {
  const { costTypes, onSelect, menuState } = props;
  const { offset, adjustment } = menuState || {};
  const activeCostTypes = useMemo(
    () => costTypes.filter((x) => x.isActive),
    [costTypes],
  );
  return (
    <Popup
      show={!!offset && !!adjustment}
      offset={offset}
      popupAlign={{ horizontal: "left", vertical: "center" }}
      collision={{
        horizontal: "none",
        vertical: "fit",
      }}
    >
      <Menu vertical={true} onSelect={onSelect}>
        {activeCostTypes.map((costType) => {
          const { code, color, id, name } = costType;
          const selected = code === adjustment?.costTypeCode;
          const cssClass = `
            ${selected ? styles.SelectedMenuItem : ""} ${
              styles.CostTypeMenuItem
            }`;
          return (
            <MenuItem
              key={id}
              text={name}
              data={costType}
              cssClass={cssClass}
              cssStyle={{ color }}
            />
          );
        })}
      </Menu>
    </Popup>
  );
};

const CustomPopup = (props: {
  kendoProps: PopupProps;
  onMidnight: () => void;
}) => {
  const { kendoProps, onMidnight } = props;
  kendoProps.children = (
    <div style={{ position: "relative" }}>
      <Button
        style={{
          position: "absolute",
          top: 4,
          right: 4,
          zIndex: 10,
          textTransform: "uppercase",
        }}
        fillMode={"link"}
        themeColor={"primary"}
        onClick={onMidnight}
      >
        Midnight
      </Button>{" "}
      {kendoProps.children}
    </div>
  );
  return <Popup {...kendoProps} />;
};
export default EditAdjustmentsList;
