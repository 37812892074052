import React from "react";
import { ILastLogin } from "../../core/inversify/interfaces";
import styles from "./authform.module.scss";

const LastLoginItem = (props: {
  l: ILastLogin;
  onSelect: (l: ILastLogin) => void;
}) => {
  const { l, onSelect } = props;

  return (
    <div onClick={() => onSelect(l)} className={styles.AuthLastLoginUser}>
      {l.isAuthorized ? (
        <span
          className={"mdi mdi-account-check-outline"}
          style={{ color: "green" }}
          title={"Authorized"}
        ></span>
      ) : (
        <span
          className={"mdi mdi-account-remove-outline"}
          style={{ color: "red" }}
          title={"No Authorized"}
        ></span>
      )}
      <div>{l.InstanceId}</div>
      <div>-</div>
      <div>{l.login}</div>
    </div>
  );
};

export default LastLoginItem;
