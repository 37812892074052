import { createContext } from "react";

import DI from "../inversify/container";
import {
  IServerSettingsService,
  ISettingsStorage,
} from "../core/inversify/interfaces";
import DI_TYPES from "../core/inversify/diTypes";

const __DEV__ = window.location.host === "localhost:3000";
const IntegrationWithDesktop = "IntegrationWithDesktop";
const IntegrationWithDesktopContext = createContext<{
  value: boolean;
  setValue: (value: boolean) => void;
}>({
  value: false,
  setValue: (newValue: boolean) => undefined,
});

declare global {
  interface Window {
    isTouchDevice: boolean;
    $: any;
    L: any;
  }
}
const settingsStorage = DI.get<ISettingsStorage>(DI_TYPES.ISettingsStorage);
const serverSettings = DI.get<IServerSettingsService>(
  DI_TYPES.IServerSettingsService,
);

const IS_TOUCH_DEVICE = window.isTouchDevice;
//@ts-ignore
const isMobile = window.matchMedia(
  "only screen and (max-width: 1200px)",
).matches;

export {
  IS_TOUCH_DEVICE,
  IntegrationWithDesktop,
  IntegrationWithDesktopContext,
  __DEV__,
  isMobile,
  settingsStorage,
  serverSettings,
};
