import FXCard from "../Common/FXCard/FXCard";
import FilterCombobox from "../Common/Form/FilterCombobox";
import formStyles from "./card.module.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { showSomeError } from "../../helpers/errorHelpers";
import { getSQLData } from "../../helpers/queries";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import moment from "moment/moment";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import FilterMultiSelect from "../Common/Form/FilterMultiSelect";
import CardManagement from "./CardManagement";

type WOCardDictionaryItem = {
  id: number;
  label: string;
};

type Props = {
  afterSave?(): void;
  finally?(): void;
};

export const AddSimpleGBPCard = (props: Props) => {
  const isProcessing = useRef<boolean>(false);
  const [sites, setSites] = useState<WOCardDictionaryItem[]>([]);
  const [scenarios, setScenarios] = useState<WOCardDictionaryItem[]>([]);
  const [resources, setResources] = useState<WOCardDictionaryItem[]>([]);
  const [maxDate, setMaxDate] = useState<Date>(
    moment().add(2, "days").toDate(),
  );
  const [site, setSite] = useState<WOCardDictionaryItem | null>(null);
  const [scenarioId, setScenarioId] = useState<number>();
  const [resourceId, setResourceId] = useState<number>();
  const [crewMemberIds, setCrewMemberIds] = useState<number[]>([]);
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date());
  const [dateTo, setDateTo] = useState<Date | null>(new Date());
  const [workDaysOnly, setWorkDaysOnly] = useState(true);

  const loadReferences = useCallback(
    (selectedSiteId?: number) => {
      if (isProcessing.current) return;

      isProcessing.current = true;
      getSQLData({
        spName: "CLPM_GetParamsValuesForGBP",
      })
        .then(
          ([sitesData, scenariosData]: [
            sitesData: WOCardDictionaryItem[],
            scenariosData: WOCardDictionaryItem[],
          ]) => {
            setSites(sitesData);
            setScenarios(scenariosData);

            if (selectedSiteId) {
              const selectedSite = sitesData.find(
                (x) => x.id === selectedSiteId,
              );
              if (selectedSite) {
                setSite(selectedSite);
              }
            }

            return getSQLData({
              spName: "CLPM_GetDispatchCalendarInitialData",
            });
          },
        )
        .then(
          ([resourcesData, settingsData]: [
            resourcesData: WOCardDictionaryItem[],
            data: { maxAllowedDays: string },
          ]) => {
            setResources(resourcesData);
            setMaxDate(
              moment()
                .add(parseInt(settingsData.maxAllowedDays, 10), "days")
                .toDate(),
            );
          },
        )
        .catch((e) => showSomeError(e))
        .finally(() => (isProcessing.current = false));
    },
    [isProcessing],
  );

  useEffect(() => {
    loadReferences();
  }, []);

  const handleChangeSite = useCallback(
    (value: WOCardDictionaryItem | null) => {
      setSite(value);
    },
    [setSite],
  );

  const handleChangeScenario = useCallback(
    (value: WOCardDictionaryItem | null) => {
      setScenarioId(value?.id);
    },
    [setScenarioId],
  );

  const handleChangeResource = useCallback(
    (value: WOCardDictionaryItem | null) => {
      setResourceId(value?.id);
    },
    [setResourceId],
  );

  const handleChangeCrewMembers = useCallback(
    (value?: WOCardDictionaryItem[]) => {
      setCrewMemberIds((value || []).map((x) => x.id));
    },
    [setCrewMemberIds],
  );

  const handleChangeWorkDaysOnly = useCallback(
    (e: CheckboxChangeEvent) => {
      setWorkDaysOnly(e.value);
    },
    [setWorkDaysOnly],
  );

  const handleGenerate = useCallback(() => {
    RunScriptAsync(
      "CLPM_GBP",
      {
        SiteID: site?.id,
        ScenarioID: scenarioId,
        EmplID: resourceId,
        DateFrom: moment(dateFrom).format("DD.MM.YYYY"),
        DateTo: moment(dateTo).format("DD.MM.YYYY"),
        WorkDaysOnly: workDaysOnly,
        CrewIDs: crewMemberIds.join(","),
      },
      true,
    )
      .then(() => {
        props.afterSave?.();
        props.finally?.();
      })
      .catch((e) => showSomeError(e));
  }, [
    site,
    scenarioId,
    resourceId,
    dateFrom,
    dateTo,
    workDaysOnly,
    crewMemberIds,
  ]);

  const onSiteCreated = useCallback(
    (siteId: number) => {
      loadReferences(siteId);
    },
    [loadReferences],
  );

  const handleAddSite = useCallback(() => {
    CardManagement.OpenSiteCard({
      newSiteDefaultValues: {
        mainSiteId: undefined,
        carrierId: undefined,
      },
      onFinish: onSiteCreated,
    });
  }, [onSiteCreated]);

  const isValid = useMemo(() => {
    return site && scenarioId && resourceId && dateFrom && dateTo;
  }, [site, scenarioId, resourceId, dateFrom, dateTo]);

  return (
    <FXCard
      title={"New Build Plan"}
      onClose={props.finally}
      initialWidth={450}
      initialHeight={370}
      originalPaddings={true}
    >
      <div className={formStyles.FormWrapper}>
        <div className={formStyles.Row}>
          <div style={{ flex: "1" }}>
            <FilterCombobox
              placeholder="Site *"
              data={sites}
              loading={isProcessing.current}
              onChange={handleChangeSite}
              className={formStyles.FormField}
              textField={"label"}
              dataItemKey={"id"}
              value={site}
            />
          </div>
          <Button
            className={formStyles.AddBtn}
            icon="plus"
            fillMode="flat"
            onClick={handleAddSite}
            title={"Create Site"}
          />
        </div>
        <FilterCombobox
          placeholder="Scenario *"
          data={scenarios}
          loading={isProcessing.current}
          onChange={handleChangeScenario}
          className={formStyles.FormField}
          textField={"label"}
          dataItemKey={"id"}
        />
        <FilterCombobox
          placeholder="Crew lead *"
          data={resources}
          loading={isProcessing.current}
          onChange={handleChangeResource}
          className={formStyles.FormField}
          textField={"label"}
          dataItemKey={"id"}
        />
        <FilterMultiSelect
          placeholder="Crew Members"
          data={resources}
          className={formStyles.FormField}
          onChange={handleChangeCrewMembers}
          loading={isProcessing.current}
          textField={"label"}
          dataItemKey={"id"}
        />
        <div className={formStyles.Row} style={{ marginTop: "12px" }}>
          <div className={formStyles.FormFieldLabel}>From:</div>
          <DatePicker
            id={"dateFrom"}
            onChange={(event: DatePickerChangeEvent) =>
              setDateFrom(event.value)
            }
            className={formStyles.Datepicker}
            defaultValue={dateFrom}
            formatPlaceholder={{
              month: "m",
              day: "d",
              year: "y",
            }}
            required={true}
            max={maxDate}
          ></DatePicker>
          <Checkbox
            style={{ marginLeft: "3rem" }}
            label={"Work Days Only"}
            size={"large"}
            value={workDaysOnly}
            onChange={handleChangeWorkDaysOnly}
          />
        </div>
        <div className={formStyles.Row} style={{ marginTop: ".5rem" }}>
          <div className={formStyles.FormFieldLabel}>To:</div>
          <DatePicker
            id={"dateTo"}
            onChange={(event: DatePickerChangeEvent) => setDateTo(event.value)}
            className={formStyles.Datepicker}
            defaultValue={dateTo}
            formatPlaceholder={{
              month: "m",
              day: "d",
              year: "y",
            }}
            required={true}
            max={maxDate}
          ></DatePicker>
        </div>
        <div className={`${formStyles.FormFooter} k-action-buttons`}>
          <Button onClick={props.finally}>Cancel</Button>
          <Button
            onClick={handleGenerate}
            themeColor="primary"
            disabled={isProcessing.current || !isValid}
          >
            Generate
          </Button>
        </div>
      </div>
    </FXCard>
  );
};

export default AddSimpleGBPCard;
