import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import {
  Button,
  Chip,
  DropDownButton,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import {
  costTypeCode,
  IAdjustedTimeLineItem,
  IAdjustmentSettings,
  ITotalDurationProps,
} from "../interfaces";
import { formatHoursDuration } from "../../../../helpers/helpers";
import formStyles from "../../../Cards/card.module.scss";
import styles from "./adjustmentsEdit.module.scss";
import LoaderComponent from "../../../Common/Loader";
import { ModalRef } from "../../../Common/Modal/Modal";
import CardManagement from "../../../Cards/CardManagement";
import DurationInput from "../../../Common/Form/DurationInput";
import {
  adjustmentsAction,
  EMPTY_COST_TYPE_CODE,
  FORMAT,
  getDefaultStateCode,
} from "./helpers";
import { RunScriptAsync } from "../../../../helpers/runscripts";
import { costTypeToColor } from "../helpers";
import {
  SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation,
  SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo,
  SQL_TK_GetAvailableWOs_Response,
  SQL_TK_GetDataForAdjustment_Response,
} from "../../../../core/api/generated/conterra";
import { useBooleanState, useRefresher } from "../../../../core/tools/Hooks";
import api from "../../../../core/api/api";
import EditAdjustmentsList from "./List";
import { showSomeError } from "../../../../helpers/errorHelpers";
import { serverSettings } from "../../../../helpers/settings";
import { CONTERRA_DATETIME_FORMAT } from "../../../../core/tools/formats";

interface IUpdateItem {
  Start: string;
  Finish: string;
  ApprovedDuration: number;
  CostTypeCode: costTypeCode;
  Allocation: Array<IUpdateAllocationItem>;
  StateCode: string | null;
  TaxCode: string | null;
}

interface IUpdateAllocationItem {
  WorkOrderCode: string;
  Percentage: number;
}

interface IValidationState {
  hasEmptyCostType: boolean;
  hasEmptyAllocation: boolean;
  invalidTime: boolean;
}

interface ITotalDurations {
  approved: string;
  actual: string;
  total: string;
}

export interface IProps {
  tcId: number;
  onSave?: () => void;
  action?: JSX.Element;
  timeCardInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo;
  adjustmentInfo: {
    lunchDuration: number | null;
    comment: string | null;
  };
  timeline: IAdjustedTimeLineItem[];
  totals: ITotalDurationProps;

  resetAdjustments?(): void;
}

const AdjustmentsEdit = (props: IProps) => {
  const { tcId, timeline, timeCardInfo, adjustmentInfo, resetAdjustments } =
    props;
  const loading = useBooleanState(false);
  const processing = useBooleanState(false);
  const remount = useRefresher();
  const remountAdjustment = useRefresher();
  const [workOrders, setWorkOrders] = useState<
    SQL_TK_GetAvailableWOs_Response[]
  >([]);
  const [dataForAdjustment, setDataForAdjustment] =
    useState<SQL_TK_GetDataForAdjustment_Response>();
  const [totalDurations, setTotalDurations] = useState<ITotalDurations>({
    approved: "",
    actual: "",
    total: "",
  });
  const settingsRef = useRef<IAdjustmentSettings>({
    showStateAllocation: false,
    showTaxCodes: false,
  });
  const adjustmentsRef = useRef<IAdjustedTimeLineItem[]>([]);
  const lunchDurationRef = useRef(adjustmentInfo.lunchDuration);
  const CommentRef = useRef(adjustmentInfo.comment);
  const totalApprovedDurationRef = useRef(0);
  const [validationState, setValidationState] = useState<IValidationState>({
    hasEmptyCostType: false,
    hasEmptyAllocation: false,
    invalidTime: false,
  });
  const LoadWorkOrders = useCallback(async () => {
    try {
      const workOrders = await api.sql.tkGetAvailableWOs({ TCId: tcId });
      setWorkOrders(workOrders);
    } catch (e: any) {
      showSomeError(e);
    }
  }, [setWorkOrders]);

  const LoadData = useCallback(async () => {
    try {
      loading.setTrue();
      const [dataForAdjustments] = await api.sql.tkGetDataForAdjustment({
        TCId: tcId,
      });
      setDataForAdjustment(dataForAdjustments);
      await LoadWorkOrders();
    } catch (e: any) {
      showSomeError(e);
    } finally {
      loading.setFalse();
    }
  }, [loading, tcId, setDataForAdjustment, LoadWorkOrders]);

  useEffect(() => {
    const settings = serverSettings.getServerSettings();
    if (settings) {
      settingsRef.current = {
        showStateAllocation: !!settings["TKEnableGPSStates"],
        showTaxCodes: !!settings["TKEnableTaxCodes"],
      };
    }
    LoadData();
  }, [LoadData]);

  const Save = useCallback(async () => {
    try {
      const { showTaxCodes, showStateAllocation } = settingsRef.current;
      const updateData: IUpdateItem[] = [];
      for (let item of adjustmentsRef.current) {
        updateData.push({
          Start: moment(item.start).format(CONTERRA_DATETIME_FORMAT),
          Finish: moment(item.finish).format(CONTERRA_DATETIME_FORMAT),
          ApprovedDuration: item.approvedDuration,
          CostTypeCode: item.costTypeCode as costTypeCode,
          Allocation:
            item.manualAllocation?.map(({ workOrderCode, percentage }) => ({
              WorkOrderCode: workOrderCode,
              Percentage: percentage,
            })) || [],
          StateCode: showStateAllocation ? item.stateCode : null,
          TaxCode: showTaxCodes ? item.taxCode : null,
        });
      }
      updateData.sort((A, B) =>
        moment(A.Start).isAfter(moment(B.Start)) ? -1 : 1,
      );
      processing.setTrue();

      await RunScriptAsync("TKAdjustments_Update", {
        TCID: tcId,
        AdjustmentJSON: JSON.stringify({
          Allocation: updateData,
          LunchDuration: lunchDurationRef.current,
          Comment: CommentRef.current,
        }),
      });
      props.onSave?.();
    } catch (e) {
      showSomeError(e);
    } finally {
      processing.setFalse();
    }
  }, [adjustmentsRef, processing, props.onSave]);

  const GetData = useCallback(
    (
      timeEntries: IAdjustedTimeLineItem[],
      dataForAdjustments: SQL_TK_GetDataForAdjustment_Response,
      isRestore?: boolean,
    ): [IAdjustedTimeLineItem[], number, number, IValidationState] => {
      const adjustments: IAdjustedTimeLineItem[] = [];
      if (!timeEntries.length) {
        const StartMoment = moment(timeCardInfo.date)
          .set("h", 12)
          .set("m", 0)
          .set("s", 0);
        const FinishMoment = StartMoment.clone();
        adjustments.push({
          taxCode: null,
          isStaticAllocation: undefined,
          actualDuration: 0,
          approvedDuration: 0,
          costTypeCode: EMPTY_COST_TYPE_CODE,
          costTypeName: "",
          finish: FinishMoment.format(FORMAT),
          rowNumber: 1,
          start: StartMoment.format(FORMAT),

          // frontend fields
          approvedDurationString: "00:00",
          actualDurationString: "00:00",
          startFormatted: StartMoment.format("LT"),
          finishFormatted: FinishMoment.format("LT"),
          manualAllocation: [],
          costTypeColor: "",
          color: costTypeToColor[EMPTY_COST_TYPE_CODE],
          stateCode: null,
          sortNumber: 1,
          isNewTe: true,
        });
      }

      const { costTypes } = dataForAdjustments;
      for (let TE of timeEntries) {
        const costTypeCode = TE.costTypeCode || EMPTY_COST_TYPE_CODE;
        TE.color = TE.costTypeColor || costTypeToColor[EMPTY_COST_TYPE_CODE];
        if (TE.costTypeCode) {
          TE.isStaticAllocation = !!costTypes.find(
            (costType) => costType.code === costTypeCode,
          )?.isNonWork;
        }
        const isLunch = TE.costTypeCode === "LUNCH";
        if (isRestore && !isLunch) {
          TE.approvedDuration = TE.actualDuration;
          TE.approvedDurationString = formatHoursDuration(TE.actualDuration);
        }

        TE.actualDurationString = formatHoursDuration(TE.actualDuration);
        adjustments.push(TE);
      }

      let lastTime = "";
      let hasEmptyCostType = false;
      let hasEmptyAllocation = false;
      let isValidTime = true;
      let HasTEWithNoDuration = false;
      let totalApprovedDuration = 0;
      let totalActualDuration = 0;
      adjustments.sort((A, B) => {
        return +moment(A.start).toDate() - +moment(B.start).toDate();
      });
      adjustments.forEach((a, i) => {
        a.sortNumber = i + 1;
      });
      for (let i = 0; i < adjustments.length; i++) {
        const TE = adjustments[i];
        const isLunch = TE.costTypeCode === "LUNCH";
        totalActualDuration += TE.actualDuration;
        if (!isLunch) {
          totalApprovedDuration += TE.approvedDuration;
        }

        if (!isLunch && !TE.manualAllocation?.length) hasEmptyAllocation = true;
        if (TE.costTypeCode === "EMPTY" || !TE.costTypeCode) {
          hasEmptyCostType = true;
        }
        if (isValidTime) {
          if (
            (lastTime && moment(TE.start).isBefore(moment(lastTime))) ||
            moment(TE.finish).isBefore(TE.start)
          ) {
            isValidTime = false;
          }
        }
        if (isValidTime && !HasTEWithNoDuration && TE.finish === TE.start) {
          HasTEWithNoDuration = true;
        }
        lastTime = TE.finish;
      }
      return [
        adjustments,
        totalApprovedDuration,
        totalActualDuration,
        {
          hasEmptyCostType: hasEmptyCostType,
          hasEmptyAllocation: hasEmptyAllocation,
          invalidTime: !isValidTime || HasTEWithNoDuration,
        },
      ];
    },
    [],
  );

  const SetData = useCallback(
    (timeEntries: IAdjustedTimeLineItem[], action?: adjustmentsAction) => {
      if (!dataForAdjustment) return;

      const [
        adjustments,
        totalApprovedDuration,
        totalActualDuration,
        validationState,
      ] = GetData([...timeEntries], dataForAdjustment, action === "restore");
      adjustmentsRef.current = adjustments;
      totalApprovedDurationRef.current = totalApprovedDuration;
      setValidationState(validationState);
      setTotalDurations({
        approved: formatHoursDuration(totalApprovedDuration),
        actual: formatHoursDuration(totalActualDuration),
        total: GetTotalDuration(),
      });

      if (action) {
        remount();
        remountAdjustment();
      }
    },
    [adjustmentsRef, dataForAdjustment],
  );

  useEffect(() => {
    if (timeline && dataForAdjustment) {
      SetData(JSON.parse(JSON.stringify(timeline)));
    }
  }, [timeline, dataForAdjustment, SetData]);

  const GetTotalDuration = () => {
    const total =
      totalApprovedDurationRef.current - (lunchDurationRef.current || 0);
    return formatHoursDuration(total < 0 ? 0 : total);
  };

  const OnChangeLunchDuration = (hours: number) => {
    lunchDurationRef.current = hours;
    setTotalDurations({
      ...totalDurations,
      total: GetTotalDuration(),
    });
  };

  const OnChangeTime = useCallback(
    (adjustments: IAdjustedTimeLineItem[], isValidTime: boolean) => {
      let hasTEWithNoDuration = false;
      const { invalidTime } = validationState;
      if (isValidTime && !invalidTime) {
        hasTEWithNoDuration =
          adjustments.findIndex((item) => item.start === item.finish) > -1;
      }
      if ((!isValidTime || hasTEWithNoDuration) && !invalidTime) {
        setValidationState({
          ...validationState,
          invalidTime: !isValidTime || hasTEWithNoDuration,
        });
      }

      if (isValidTime) {
        SetData(adjustments);
        remountAdjustment();
      }
    },
    [validationState],
  );

  const UndoChanges = useCallback(() => {
    lunchDurationRef.current = adjustmentInfo.lunchDuration;
    CommentRef.current = adjustmentInfo.comment;
    SetData(JSON.parse(JSON.stringify(timeline)), "undo");
  }, [adjustmentInfo, SetData, timeline]);

  if (loading.value || !dataForAdjustment || processing.value) {
    return <LoaderComponent />;
  }

  return (
    <>
      <PanelToolbar
        resetAdjustments={resetAdjustments}
        tcId={tcId}
        tcInfo={timeCardInfo}
        adjustments={adjustmentsRef.current}
        refreshWorkOrders={LoadWorkOrders}
        setData={SetData}
        durations={totalDurations}
        lunchDuration={lunchDurationRef.current}
        lunchFieldRemountKey={remount.value}
        onChangeLunch={OnChangeLunchDuration}
      />
      <EditAdjustmentsList
        adjustmentRowRemountKey={remountAdjustment.value}
        timeRemountKey={remount.value}
        adjustments={adjustmentsRef.current}
        SetData={SetData}
        tcId={tcId}
        tcInfo={timeCardInfo}
        refreshWorkOrders={LoadWorkOrders}
        dataForAdjustment={dataForAdjustment}
        workOrders={workOrders}
        onChangeTime={OnChangeTime}
        settings={settingsRef.current}
      />
      <AdjustmentCommentField
        remountKey={remount.value}
        valueRef={CommentRef}
      />
      <Footer
        save={Save}
        undo={UndoChanges}
        validationState={validationState}
        action={props.action}
      />
    </>
  );
};

const PanelToolbar = (props: {
  resetAdjustments?(): void;
  tcId: number;
  tcInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo;
  durations: ITotalDurations;
  lunchFieldRemountKey: number;
  lunchDuration: number | null;
  onChangeLunch(hours: number | null, value: string): void;
  setData(data: IAdjustedTimeLineItem[], action?: adjustmentsAction): void;
  refreshWorkOrders: () => void;
  adjustments: IAdjustedTimeLineItem[];
}) => {
  const {
    resetAdjustments,
    tcId,
    durations,
    lunchFieldRemountKey,
    lunchDuration,
    adjustments,
    tcInfo,
  } = props;
  const { actual, approved, total } = durations;

  const ClearAll = () => {
    props.setData([], "clearAll");
  };

  const RestoreAll = () => {
    props.setData(props.adjustments, "restore");
  };

  const Allocate = (all: boolean) => {
    if (!all) {
      const unallocatedTEIndex = adjustments.findIndex(
        (item) =>
          item.costTypeCode !== "LUNCH" && !item.manualAllocation?.length,
      );
      if (unallocatedTEIndex === -1) {
        ModalRef.showDialog({
          title: "Impossible Action",
          text: "All Time Entries are allocated",
          width: 450,
        });
        return;
      }
    } else {
      const dinamicAllocationTEs = adjustments.filter(
        (item) => item.costTypeCode !== "LUNCH" && !item.isStaticAllocation,
      );
      if (!dinamicAllocationTEs.length) {
        ModalRef.showDialog({
          title: "Impossible Action",
          text: "Any Time Entry is not available for changing allocation",
          width: 450,
        });
        return;
      }
    }
    const { employeeId, date, employeeName } = tcInfo;
    CardManagement.WOAllocationCard({
      title: all ? "Allocate All" : "Allocate Unallocated",
      allocation: [],
      tcId,
      employeeId,
      employeeName,
      date,
      onResult: (
        result: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[],
        workOrders: SQL_TK_GetAvailableWOs_Response[],
      ) => {
        props.refreshWorkOrders();
        const resultJSON = JSON.stringify(result);
        adjustments.forEach((item) => {
          if (
            item.costTypeCode !== "LUNCH" &&
            !item.isStaticAllocation &&
            (all || !item.manualAllocation?.length)
          ) {
            item.manualAllocation = JSON.parse(resultJSON);
            item.stateCode = getDefaultStateCode(
              item.manualAllocation || [],
              workOrders,
            );
          }
        });
        props.setData([...adjustments]);
      },
      onClose: props.refreshWorkOrders,
    });
  };

  return (
    <div className={styles.AdjustmentCardToolbar}>
      <div className={styles.DurationItem}>
        <span>Clocked</span>
        <Chip text={actual} />
      </div>
      <div className={styles.DurationItem}>
        <span>Approved</span>
        <Chip themeColor="success" text={approved} />
      </div>
      <div className={styles.DurationItem}>
        <span>Lunch Deduction</span>
        <div style={{ width: 58, marginTop: 4 }}>
          <DurationInput
            key={"lunch" + lunchFieldRemountKey}
            duration={lunchDuration}
            onChange={props.onChangeLunch}
            start={""}
            finish={""}
          />
        </div>
      </div>
      <div className={styles.DurationItem}>
        <span>Total</span>
        <Chip themeColor="success" text={total} />
      </div>
      <div style={{ flex: 1 }}></div>
      <Button
        iconClass={"mdi mdi-eraser"}
        title={"Clear All"}
        onClick={ClearAll}
        fillMode={"flat"}
      />
      <Button
        iconClass={"mdi mdi-history"}
        title={"Restore All"}
        onClick={RestoreAll}
        fillMode={"flat"}
      />
      <DropDownButton
        buttonClass={styles.DownloadButton}
        iconClass="mdi mdi-database-outline"
        title={"Allocate"}
        items={["Allocate All", "Allocate Unallocated"]}
        fillMode={"flat"}
        onItemClick={(event: DropDownButtonItemClickEvent) => {
          Allocate(event.item === "Allocate All");
        }}
      />
      {!!resetAdjustments && (
        <Button
          title={"Reset"}
          themeColor={"error"}
          fillMode={"flat"}
          iconClass={"mdi mdi-backup-restore"}
          onClick={() => {
            ModalRef.showDialog({
              title: "Confirmation",
              text: "Do you confirm reset adjustments?",
              minWidth: 250,
              buttons: [
                {
                  text: "Cancel",
                  action: () => {
                    ModalRef.hideDialog();
                  },
                },
                {
                  text: "Yes",
                  color: "primary",
                  action: () => {
                    ModalRef.hideDialog();
                    resetAdjustments();
                  },
                },
              ],
            });
          }}
        />
      )}
    </div>
  );
};

const Footer = (props: {
  validationState: IValidationState;
  save(): void;
  undo(): void;
  action?: JSX.Element;
}) => {
  const {
    validationState: { hasEmptyAllocation, hasEmptyCostType, invalidTime },
    action,
    undo,
    save,
  } = props;
  const messages = useMemo(() => {
    const messages: string[] = [];
    if (invalidTime) {
      messages.push("Invalid Time");
    }
    if (hasEmptyCostType) {
      messages.push("Specify Cost Type for All Time Entries");
    }
    if (hasEmptyAllocation) {
      messages.push("Some Time Entries do not have a Work Order Allocation");
    }
    return messages;
  }, [hasEmptyCostType, hasEmptyAllocation, invalidTime]);

  return (
    <div className={`${formStyles.FormFooter} k-action-buttons`}>
      <span className={formStyles.InvalidMessage}>
        {messages.map((message, i) => {
          return <span key={i}>{message}</span>;
        })}
      </span>

      {action}
      <Button onClick={undo}>Undo All Changes</Button>
      <Button
        onClick={save}
        themeColor="primary"
        disabled={hasEmptyCostType || invalidTime}
      >
        Save
      </Button>
    </div>
  );
};

const AdjustmentCommentField = (props: {
  valueRef: React.MutableRefObject<string | null>;
  remountKey: number;
}) => {
  const OnChangeComment = (e: any) => {
    props.valueRef.current = e.value || null;
  };

  return (
    <TextArea
      key={"comment" + props.remountKey}
      rows={5}
      placeholder="Comment..."
      defaultValue={props.valueRef.current || ""}
      className={styles.AdjustmentsCardComment}
      onChange={OnChangeComment}
    ></TextArea>
  );
};
export default AdjustmentsEdit;
