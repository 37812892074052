import React, { useCallback, useMemo, useRef, useState } from "react";
import { IFilterSetting, IPeriod } from "./interfaces";
import {
  dateRangePeriodId,
  dateRangePeriodIds,
  GetPeriodDates,
  getPeriods,
} from "./helpers";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import styles from "./dashboard.module.scss";
import {
  CalendarHeaderTitleProps,
  DateRangePicker,
  DateRangePickerChangeEvent,
  DateRangePickerDateInputSettings,
  SelectionRange,
} from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment/moment";

const dateInputSettings: DateRangePickerDateInputSettings = {
  formatPlaceholder: {
    month: "m",
    day: "d",
    year: "y",
  },
};

interface IProps {
  defaultPeriodId: dateRangePeriodId;
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  filter?: IFilterSetting;
  allowEmpty: boolean;
  includedPeriods?: dateRangePeriodId[];

  onChange(
    value: {
      periodId: dateRangePeriodId;
      from: Date | null;
      to: Date | null;
    },
    filter?: IFilterSetting // for old dashboards
  ): void;
}

const DateRangeFilter = (props: IProps) => {
  const {
    allowEmpty,
    defaultDateTo,
    defaultDateFrom,
    defaultPeriodId,
    filter,
    onChange,
    includedPeriods,
  } = props;
  const defaultValues: SelectionRange = useMemo(() => {
    if (allowEmpty) {
      return { start: defaultDateFrom || null, end: defaultDateTo || null };
    }
    const [dateFrom, dateTo] = GetPeriodDates(defaultPeriodId);
    return { start: defaultDateFrom || dateFrom, end: defaultDateTo || dateTo };
  }, []);
  const valuesRef = useRef<SelectionRange>({ ...defaultValues });
  const periodIdRef = useRef(defaultPeriodId);
  const [values, setValues] = useState<SelectionRange>(valuesRef.current);
  const periods = useMemo(
    () => getPeriods(allowEmpty, includedPeriods),
    [allowEmpty]
  );
  const [period, setPeriod] = useState<IPeriod>(
    periods.find((x) => x.Id === periodIdRef.current)!
  );
  const pickerRef = useRef<any>();
  const applyDatePickerChanges = useCallback(
    (isOnClearPressed?: boolean) => {
      const { start, end } = valuesRef.current;
      const isValidStart = !start || moment(start).isValid();
      const isValidEnd = !end || moment(end).isValid();
      if (!isValidStart) valuesRef.current.start = null;
      if (!isValidEnd) valuesRef.current.end = null;
      setValues({ ...valuesRef.current });
      const isStartChanged = defaultDateFrom !== valuesRef.current.start;
      const isEndChanged = defaultDateTo !== valuesRef.current.end;
      const isEmptyValues =
        allowEmpty && !valuesRef.current.start && !valuesRef.current.end;
      const newPeriodId = isEmptyValues
        ? dateRangePeriodIds.notApplied
        : dateRangePeriodIds.custom;
      if (periodIdRef.current !== newPeriodId) {
        periodIdRef.current = newPeriodId;
        setPeriod(periods.find((x) => x.Id === newPeriodId)!);
      }
      if (isStartChanged || isEndChanged || isOnClearPressed) {
        handleAppliedChanges();
      }
    },
    [onChange, setValues, defaultDateFrom, defaultDateTo, setPeriod]
  );

  const handleDatePickerChange = useCallback(
    (event: DateRangePickerChangeEvent) => {
      const { show, value } = event;
      valuesRef.current = value;
      setValues(value);
      const isOnClearPressed = !show;
      if (isOnClearPressed) applyDatePickerChanges(true);
    },
    [applyDatePickerChanges, setValues, periods]
  );

  const handleOk = useCallback(() => {
    pickerRef.current?.setShow?.(false);
  }, []);

  const handleAppliedChanges = useCallback(() => {
    onChange(
      {
        periodId: periodIdRef.current,
        from: valuesRef.current.start,
        to: valuesRef.current.end,
      },
      filter
    );
  }, [onChange, period, filter]);

  const handlePeriodChange = useCallback(
    (e: DropDownListChangeEvent) => {
      const value = e.target.value;
      const periodId = value.Id;
      if (periodIdRef.current === periodId) return;
      periodIdRef.current = periodId;
      const [dateFrom, dateTo] = GetPeriodDates(periodId);
      valuesRef.current.start = dateFrom;
      valuesRef.current.end = dateTo;
      setValues({ ...valuesRef.current });
      setPeriod(value);
      handleAppliedChanges();
    },
    [handleAppliedChanges, period]
  );

  return (
    <>
      <DropDownList
        key={"period"}
        data={periods}
        textField="Name"
        dataItemKey="Id"
        value={period}
        onChange={handlePeriodChange}
        style={{ width: "170px" }}
        fillMode={"flat"}
      ></DropDownList>
      <DateRangePicker
        ref={pickerRef}
        startDateInputSettings={dateInputSettings}
        endDateInputSettings={dateInputSettings}
        className={styles.DateRangeFilter}
        onChange={handleDatePickerChange}
        clearButton={allowEmpty}
        value={values}
        onClose={() => applyDatePickerChanges()}
        calendarSettings={{
          headerTitle: (props: CalendarHeaderTitleProps) => (
            <div>
              <Button
                themeColor={"primary"}
                fillMode={"link"}
                value={props.value}
                onClick={props.onClick}
              >
                {props.children}
              </Button>

              <Button
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  zIndex: 2,
                }}
                themeColor={"primary"}
                onClick={handleOk}
              >
                Ok
              </Button>
            </div>
          ),
        }}
      />
    </>
  );
};

export default DateRangeFilter;
