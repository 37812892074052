import { useContext } from "react";
import { IntegrationWithDesktopContext } from "../../helpers/settings";
import { AppBarSection } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import CardManagement from "../../Components/Cards/CardManagement";

export default function QuickLaunch() {
  const { value: isIntegrationWithDesktop } = useContext(
    IntegrationWithDesktopContext,
  );
  if (!isIntegrationWithDesktop) return null;
  return (
    <>
      <AppBarSection>
        <Button
          className={"noFocus"}
          iconClass={"mdi mdi-play-circle"}
          fillMode={"flat"}
          color={"red"}
          onClick={() => {
            CardManagement.OpenQuickLaunchCard();
          }}
        >
          QUICK LAUNCH
        </Button>
      </AppBarSection>
      <AppBarSection>
        <span className="k-appbar-separator" />
      </AppBarSection>
    </>
  );
}
