import { Container } from "inversify";
import "reflect-metadata";
import AuthProvider from "../core/api/AuthProvider";
import DI_TYPES from "../core/inversify/diTypes";
import {
  IApi,
  IAuthProvider,
  IDeviceInfoProvider,
  IHttpFormDataBuilder,
  ILastUserService,
  ILogger,
  ISasTokenService,
  IServerSettingsService,
  ISettingsStorage,
} from "../core/inversify/interfaces";
import DeviceInfoProvider from "../tools/deviceInfoProvider";
import Logger from "../tools/logger";
import SettingsStorage from "../tools/settingStorage";
import { LastUserService } from "../tools/lastUserService";
import HttpFormBuilder from "../tools/httpFormBuilder";
import SasTokenService from "../core/tools/sasTokenService";
import ApiImplementation from "../core/api/apiImplementation";
import ServerSettingsService from "../tools/ServerSettingsService";

const DI = new Container();
DI.bind<ILogger>(DI_TYPES.ILogger).to(Logger).inSingletonScope();
DI.bind<ISettingsStorage>(DI_TYPES.ISettingsStorage)
  .to(SettingsStorage)
  .inSingletonScope();
DI.bind<IAuthProvider>(DI_TYPES.IAuthProvider)
  .to(AuthProvider)
  .inSingletonScope();
DI.bind<IDeviceInfoProvider>(DI_TYPES.IDeviceInfoProvider)
  .to(DeviceInfoProvider)
  .inSingletonScope();
DI.bind<ILastUserService>(DI_TYPES.ILastLoginService)
  .to(LastUserService)
  .inSingletonScope();
DI.bind<IHttpFormDataBuilder>(DI_TYPES.IHttpFormDataBuilder)
  .to(HttpFormBuilder)
  .inSingletonScope();
DI.bind<ISasTokenService>(DI_TYPES.ISasTokenService)
  .to(SasTokenService)
  .inSingletonScope();
DI.bind<IApi>(DI_TYPES.IApi).to(ApiImplementation).inSingletonScope();
DI.bind<IServerSettingsService>(DI_TYPES.IServerSettingsService)
  .to(ServerSettingsService)
  .inSingletonScope();

export default DI;
